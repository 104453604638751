import {
  RikerIcon,
  type RikerIconComponent,
  type RikerIconName,
  type RikerIconProps,
} from '@joggrdocs/riker';
import React from 'react';
import type * as TypeFest from 'type-fest';

import { generateComponentClasses } from '@stargate/theme';

export const codeSnippetIconClasses = generateComponentClasses(
  'CodeSnippetIcon',
  ['root'] as const
);

export type CodeSnippetIconProps = TypeFest.Simplify<
  Omit<RikerIconProps, 'name'>
>;

/**
 * The name of the code snippet icon.
 */
export const codeSnippetIconName: RikerIconName = 'code-asterisk';

/**
 * CodeSnippetIcon
 */
export const CodeSnippetIcon = React.forwardRef<
  RikerIconComponent,
  CodeSnippetIconProps
>((props, ref) => {
  return (
    <RikerIcon
      {...props}
      classes={props.classes ?? codeSnippetIconClasses}
      ref={ref}
      name={codeSnippetIconName}
    />
  );
});
