import React from 'react';

import {
  Box,
  ButtonAnchor,
  IconBrandGithub,
  IconShieldShare,
} from '@joggrdocs/riker';

import { GitHubAuthorizeButton } from '@stargate/features/github';
import * as hooks from '@stargate/hooks';

import SettingsActionCard from '../SettingsActionCard';
import SettingsActionCards from '../SettingsActionCards';
import SettingsSectionHeader from '../SettingsSectionHeader';

function Security() {
  const staticUrls = hooks.useStaticUrls();
  return (
    <Box>
      <SettingsSectionHeader
        title='Security'
        description="Manage your account's security settings."
      />
      <SettingsActionCards>
        <SettingsActionCard
          title='GitHub Authorization'
          icon={<IconBrandGithub />}
          content='Reauthorize with Github to get access to your GitHub data (or add an organization).'
          actions={
            <GitHubAuthorizeButton>
              Reauthorize with GitHub
            </GitHubAuthorizeButton>
          }
          item
        />
        <SettingsActionCard
          title='Report a Security Issue'
          icon={<IconShieldShare />}
          content='Report a security issue to the Joggr team.'
          actions={
            <ButtonAnchor href={staticUrls.securityVulnerabilityForm} external>
              Report Security Issue
            </ButtonAnchor>
          }
          item
        />
      </SettingsActionCards>
    </Box>
  );
}

export default Security;
