import { flatten } from 'flat';
import _ from 'lodash';
import { usePostHog } from 'posthog-js/react';
import React from 'react';
import type * as TypeFest from 'type-fest';

import { type User, useUser } from '@stargate/features/user';
import {
  type ActiveWorkspace,
  useWorkspace,
  useWorkspaces,
} from '@stargate/features/workspaces';
import { useDebugSession } from '@stargate/lib/debug-session';
import { useLogger } from '@stargate/logger';
import { useFrigadeUser } from '@stargate/vendors/frigade';

import { serializeEventName, serializePageUrl } from '../lib/serializer';
import type {
  NightingaleEventData,
  NightingaleEventName,
  NightingaleStatus,
} from '../types';
import {
  type UseNightingaleIdentifyHook,
  useNightingaleIdentify,
} from './use-nightingale-identify';
import { useNightingaleStore } from './use-nightingale-store';

/*
|==========================================================================
| useNightingale
|==========================================================================
|
| A hook for the Nightingale library, used to submit events to the analytics services.
|
*/

/*
|------------------
| Callback Types
|------------------
*/

export type NightingaleCapture = (
  eventName: NightingaleEventName,
  eventData?: NightingaleEventData
) => Promise<void>;

export type NightingaleCaptureWithUserId = (
  userId: string,
  eventName: NightingaleEventName,
  eventData?: NightingaleEventData
) => Promise<void>;

export type NightingaleCapturePageView = (
  location: Pick<Location, 'pathname' | 'search' | 'hash'>
) => Promise<void>;

export type NightingaleCapturePageLeave = (
  location: Pick<Location, 'pathname' | 'search' | 'hash'>
) => Promise<void>;

export type NightingaleLogin = (
  user: User,
  activeWorkspace?: ActiveWorkspace
) => Promise<void>;

export type NightingaleLogout = () => Promise<void>;

export type NightingaleIdentifyUser = UseNightingaleIdentifyHook['user'];

export type NightingaleIdentifyGroup = UseNightingaleIdentifyHook['group'];

export type UseNightingaleHook = TypeFest.Simplify<{
  /**
   * The Error object, if any.
   */
  error: Error | null;

  /**
   * The current status, which represents is the library ready to submit events.
   */
  status: NightingaleStatus;

  /**
   * Submits an event to the analytics services.
   *
   * @param eventTuple A tuple that represents an event, in the format of [Verb, Noun].
   * @param eventData A record that represents the event data.
   */
  capture: NightingaleCapture;

  /**
   * Capture events using only the user's ID and some event data.
   */
  captureWithUserId: NightingaleCaptureWithUserId;

  /**
   * Tracks when a user views a page.
   */
  capturePageView: NightingaleCapturePageView;

  /**
   * Tracks when a user leaves a page.
   */
  capturePageLeave: NightingaleCapturePageLeave;

  /**
   * Logs the user in.
   *
   * @param user An authenticated user.
   */
  login: NightingaleLogin;

  /**
   * Logs the user out.
   */
  logout: NightingaleLogout;

  /**
   * Adds a user to a group (i.e. workspace, organization, etc.), PREFER using `login` instead.
   *
   * @param workspace An authenticated user's active workspace.
   */
  identifyGroup: NightingaleIdentifyGroup;

  /**
   * Identifies the current user to the Nightingale library, PREFER using `login` instead.
   *
   * @param user An authenticated user.
   */
  identifyUser: NightingaleIdentifyUser;
}>;

export const useNightingale = (): UseNightingaleHook => {
  const [storeState, storeActions] = useNightingaleStore();
  const nightingaleIdentify = useNightingaleIdentify();
  const debugSession = useDebugSession();
  const posthog = usePostHog();
  const frigadeUser = useFrigadeUser();
  const logger = useLogger();
  const authenticatedUser = useUser();
  const activeWorkspace = useWorkspace();
  const userAvailableWorkspaces = useWorkspaces();

  /*
  |------------------
  | Callbacks: Internals
  |------------------
  */

  /**
   * Collect the base event data.
   */
  const collectBaseEventData = React.useCallback(() => {
    return {
      auth0Id: authenticatedUser.data?.auth0Id ?? null,
      githubId: authenticatedUser.data?.githubId ?? null,
      githubUsername: authenticatedUser.data?.githubUsername ?? null,
      email: authenticatedUser.data?.email ?? null,
      workspace: activeWorkspace.data?.name ?? null,
      workspaceId: activeWorkspace.data?.id ?? null,
      workspaces: userAvailableWorkspaces.data.map(
        (workspace) => workspace.name
      ),
      workspaceIds: userAvailableWorkspaces.data.map(
        (workspace) => workspace.id
      ),
    };
  }, [
    activeWorkspace.data,
    authenticatedUser.data,
    userAvailableWorkspaces.data,
  ]);

  const captureEventSafely = React.useCallback(
    async (
      eventName: NightingaleEventName,
      eventData: NightingaleEventData & { sessionId: string }
    ) => {
      try {
        if (
          storeState.status !== 'ready' ||
          !isValidUserId(frigadeUser.userId) ||
          !authenticatedUser.data
        ) {
          logger.fatal(
            {
              group: 'analytics',
              event: {
                name: eventName,
                properties: eventData,
              },
            },
            'Failed to capture event without a user'
          );
        } else {
          const collectedData = collectBaseEventData();
          const serializedEventName = serializeEventName(eventName);
          posthog.capture(serializedEventName, {
            ...eventData,
            ...collectedData,
          });
          await frigadeUser.track(serializedEventName, {
            ...flatten(eventData),
            githubUserName: collectedData.githubUsername,
            email: collectedData.email,
            auth0Id: collectedData.auth0Id,
            workspace: collectedData.workspace,
            workspaceId: collectedData.workspaceId,
          });
        }
      } catch (error) {
        logger.fatal(
          {
            error,
            group: 'analytics',
            event: {
              name: eventName,
              properties: eventData,
            },
          },
          'Failed to capture event'
        );
      }
    },
    [
      authenticatedUser.data,
      collectBaseEventData,
      frigadeUser.userId,
      storeState.status,
      posthog.capture,
      frigadeUser.track,
      logger,
    ]
  );

  /*
  |------------------
  | Callbacks: Capture
  |------------------
  */

  const captureWithUserId = React.useCallback(
    async (
      userId: string,
      eventName: NightingaleEventName,
      eventData?: NightingaleEventData
    ) => {
      try {
        const serializedEventName = serializeEventName(eventName);
        posthog.identify(userId);
        posthog.capture(serializedEventName, {
          ...eventData,
          sessionId: debugSession.id,
        });

        await frigadeUser.trackWithUserId(userId, serializedEventName, {
          ...flatten(eventData),
          sessionId: debugSession.id,
        });
      } catch (error) {
        logger.error(
          {
            error,
            group: 'analytics',
            event: {
              userId,
              name: eventName,
              properties: eventData,
            },
          },
          'Failed to capture event with user ID'
        );
      }
    },
    [
      debugSession.id,
      frigadeUser.trackWithUserId,
      logger,
      posthog.identify,
      posthog.capture,
    ]
  );

  const capture: NightingaleCapture = React.useCallback(
    async (eventTuple, eventData) => {
      await captureEventSafely(eventTuple, {
        sessionId: debugSession.id,
        ...eventData,
      });
    },
    [captureEventSafely, debugSession.id]
  );

  const capturePageView: NightingaleCapturePageView = React.useCallback(
    async (location) => {
      posthog.capture('$pageview', {
        sessionId: debugSession.id,
        $current_url: serializePageUrl(location),
        location_path: location.pathname,
        location_search: location.search,
        location_hash: location.hash,
      });

      await frigadeUser.track('$pageview', {
        sessionId: debugSession.id,
        $current_url: serializePageUrl(location),
        location_path: location.pathname,
        location_search: location.search,
        location_hash: location.hash,
      });
    },
    [debugSession.id, frigadeUser.track, posthog.capture]
  );

  const capturePageLeave: NightingaleCapturePageLeave = React.useCallback(
    async (location) => {
      posthog.capture('$pageleave', {
        sessionId: debugSession.id,
        $current_url: serializePageUrl(location),
        location_path: location.pathname,
        location_search: location.search,
        location_hash: location.hash,
      });

      await frigadeUser.track('$pageleave', {
        sessionId: debugSession.id,
        $current_url: serializePageUrl(location),
        location_path: location.pathname,
        location_search: location.search,
        location_hash: location.hash,
      });
    },
    [debugSession.id, frigadeUser.track, posthog.capture]
  );

  /*
  |------------------
  | Callbacks: Identification
  |------------------
  */

  const identifyUser: NightingaleIdentifyUser = React.useCallback(
    async (user) => {
      // If we have a guest user, merge it with the authenticated user.
      if (frigadeUser.guestUserId) {
        await frigadeUser.mergeWithGuestUser(user.id);
      }
      frigadeUser.identify(user.id);

      await nightingaleIdentify.user(user);
    },
    [
      frigadeUser.guestUserId,
      frigadeUser.identify,
      nightingaleIdentify.user,
      frigadeUser.mergeWithGuestUser,
    ]
  );

  const identifyGroup: NightingaleIdentifyGroup = React.useCallback(
    async (workspace) => {
      await nightingaleIdentify.group(workspace);
    },
    [nightingaleIdentify.group]
  );

  const login: NightingaleLogin = React.useCallback(
    async (user, activeWorkspace) => {
      try {
        await identifyUser(user);

        if (activeWorkspace) {
          await identifyGroup(activeWorkspace);
        }
      } catch (error) {
        storeActions.setStatus('error');

        if (error instanceof Error) {
          storeActions.setError(error);
        }

        throw error;
      }
    },
    [identifyGroup, identifyUser, storeActions]
  );

  const logout: NightingaleLogout = React.useCallback(async () => {
    try {
      await nightingaleIdentify.reset();
      storeActions.setStatus('idle');
    } catch (error) {
      storeActions.setStatus('error');

      if (error instanceof Error) {
        storeActions.setError(error);
      }

      throw error;
    }
  }, [nightingaleIdentify.reset, storeActions]);

  /*
  |------------------
  | Queue Consumer
  |------------------
  */

  /**
   * Handle processing the event queue whenever it is not empty and not processing.
   */

  // biome-ignore lint/correctness/useExhaustiveDependencies: We only care about the storeState.status, authenticatedUser.data, and frigadeUser.userId.
  React.useEffect(() => {
    // If the store is not ready and the queue is not empty, move to ready state and if the user is identified.
    if (
      storeState.status !== 'ready' &&
      isValidUserId(frigadeUser.userId) &&
      authenticatedUser.data
    ) {
      storeActions.setStatus('ready');
    }

    // If the store is ready and the user is not identified, move to loading state.
    if (
      storeState.status === 'ready' &&
      (!isValidUserId(frigadeUser.userId) || !authenticatedUser.data)
    ) {
      storeActions.setStatus('loading');
    }
  }, [storeState.status, authenticatedUser.data, frigadeUser.userId]);

  return {
    /*
    |------------------
    | State
    |------------------
    */

    status: storeState.status,
    error: storeState.error,

    /*
    |------------------
    | Event Tracking
    |------------------
    */

    capture,
    captureWithUserId,
    capturePageView,
    capturePageLeave,

    /*
    |------------------
    | User Identification
    |------------------
    */

    identifyGroup: nightingaleIdentify.group,
    identifyUser: nightingaleIdentify.user,
    login,
    logout,
  };
};

/*
|------------------
| Utils
|------------------
*/

/**
 * A function that checks if a user ID is valid.
 *
 * @param userId A user ID.
 * @returns A boolean that represents if the user ID is valid.
 */
const isValidUserId = (userId: string | null): userId is string => {
  if (typeof userId === 'string') {
    return userId.length > 0 && !userId.includes('guest');
  }
  return false;
};
