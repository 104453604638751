import React from 'react';

/*
|==========================================================================
| useMermaidDownload
|==========================================================================
|
| Creates a download link callback for the mermaid diagram.
|
*/
export const useMermaidDownload = () => {
  return React.useCallback((svg: string, graphType?: string) => {
    if (!svg) return false;
    const blob = new Blob([svg], { type: 'text/plain;charset=utf-8' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${['mermaid', graphType ?? '', 'diagram'].join('-')}.svg`;
    link.click();
    URL.revokeObjectURL(url);
    return true;
  }, []);
};

export default useMermaidDownload;
