import * as Spotlight from '@spotlightjs/spotlight';

/**
 * Hook to access the Spotlight (Sentry devtool) SDK.
 *
 * @returns The Spotlight SDK.
 */
export const useSpotlight = () => {
  return Spotlight;
};
