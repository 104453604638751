import _ from 'lodash';

import {
  type HttpErrorCode,
  getHttpErrorMessage,
} from '../lib/http-error-codes';
import {
  type HttpBadRequestError,
  type HttpConflictError,
  type HttpError,
  type HttpFatalError,
  type HttpForbiddenError,
  type HttpNotFoundError,
  type HttpUnauthorizedError,
  createError,
  isBadRequestHttpStatus,
  isConflictHttpStatus,
  isFatalHttpStatus,
  isForbiddenHttpStatus,
  isHttpError,
  isHttpErrorStatus,
  isNotFoundHttpStatus,
  isUnauthorizedHttpStatus,
} from '../lib/http-errors';

/*
|==========================================================================
| use-http-error
|==========================================================================
|
| A hook for managing, creating and other operations related to HTTP errors.
|
*/

export interface UseHttpErrorsHook {
  isHttpError: typeof isHttpError;
  isHttpErrorStatus: typeof isHttpErrorStatus;
  isNotFoundHttpStatus: typeof isNotFoundHttpStatus;
  isConflictHttpStatus: typeof isConflictHttpStatus;
  isBadRequestHttpStatus: typeof isBadRequestHttpStatus;
  isUnauthorizedHttpStatus: typeof isUnauthorizedHttpStatus;
  isForbiddenHttpStatus: typeof isForbiddenHttpStatus;
  isFatalHttpStatus: typeof isFatalHttpStatus;

  /**
   * Get a user-friendly error name from an error. This will omit any 500-level
   * errors and only show useful information.
   *
   * @param error An error.
   * @param defaultName An optional default name to display.
   * @returns A user-friendly error name.
   */
  getUserFriendlyErrorName: (error: unknown, defaultName?: string) => string;

  /**
   * Get a user-friendly error message from an error. This will omit any
   * 500-level errors and only show useful information.
   *
   * @param error An error.
   * @param defaultMessage An optional default message to display.
   * @returns A user-friendly error message.
   */
  getUserFriendlyErrorMessage: (
    error: unknown,
    defaultMessage?: string
  ) => string;

  /**
   * Create a new HTTP not found error.
   *
   *
   * @param errorCode An error code.
   * @param message A message to display.
   * @returns A new HTTP not found error.
   */
  notFound: (errorCode?: HttpErrorCode, message?: string) => HttpNotFoundError;

  /**
   * Create a new HTTP bad request error.
   *
   * @param errorCode An error code.
   * @param message A message to display.
   * @returns A new HTTP bad request error.
   */
  badRequest: (
    errorCode?: HttpErrorCode,
    message?: string
  ) => HttpBadRequestError;

  /**
   * Create a new HTTP unauthorized error.
   *
   * @param errorCode An error code.
   * @param message A message to display.
   * @returns A new HTTP unauthorized error.
   */
  unauthorized: (
    errorCode?: HttpErrorCode,
    message?: string
  ) => HttpUnauthorizedError;

  /**
   * Create a new HTTP forbidden error.
   *
   * @param errorCode An error code.
   * @param message A message to display.
   * @returns A new HTTP forbidden error.
   */
  forbidden: (
    errorCode?: HttpErrorCode,
    message?: string
  ) => HttpForbiddenError;

  /**
   * Create a new HTTP conflict error.
   *
   * @param errorCode An error code.
   * @param message A message to display.
   * @returns A new HTTP conflict error.
   */
  conflict: (errorCode?: HttpErrorCode, message?: string) => HttpConflictError;

  /**
   * Create a new HTTP 5XX error (we treat all 5XX errors as fatal errors)
   *
   * @param errorCode An error code.
   * @param message A message to display.
   * @returns A new HTTP internal server error.
   */
  fatal: (errorCode?: HttpErrorCode, message?: string) => HttpFatalError;

  /**
   * Create a new HTTP error.
   *
   * @param statusCode A HTTP status code.
   * @param errorCode An error code.
   * @param message A message to display.
   * @returns A new HTTP error.
   */
  createError: (
    statusCode?: number,
    errorCode?: HttpErrorCode,
    message?: string
  ) => HttpError;
}

export const useHttpErrors = (): UseHttpErrorsHook => {
  const getUserFriendlyErrorMessage: UseHttpErrorsHook['getUserFriendlyErrorMessage'] =
    (
      error,
      defaultMessage = 'An error occurred. Please try again later.'
    ): string => {
      if (isHttpError(error)) {
        const errorCodeMessage =
          error.errorCode !== 'UNK_000'
            ? getHttpErrorMessage(error.errorCode)
            : null;

        if (isFatalHttpStatus(error.statusCode) && _.isNil(errorCodeMessage)) {
          return defaultMessage;
        }

        return errorCodeMessage ?? error.message;
      }
      return defaultMessage;
    };

  const getUserFriendlyErrorName: UseHttpErrorsHook['getUserFriendlyErrorName'] =
    (error, defaultName = 'API Error') => {
      if (isHttpError(error)) {
        if (isConflictHttpStatus(error.statusCode)) {
          return 'Found Conflict';
        }

        if (isBadRequestHttpStatus(error.statusCode)) {
          return 'Invalid Data';
        }

        if (isUnauthorizedHttpStatus(error.statusCode)) {
          return 'Unauthenticated';
        }

        if (isForbiddenHttpStatus(error.statusCode)) {
          return 'Unauthorized';
        }

        return defaultName;
      }
      return defaultName;
    };

  return {
    isHttpError,
    isHttpErrorStatus,
    isNotFoundHttpStatus,
    isConflictHttpStatus,
    isBadRequestHttpStatus,
    isUnauthorizedHttpStatus,
    isForbiddenHttpStatus,
    isFatalHttpStatus,
    getUserFriendlyErrorName,
    getUserFriendlyErrorMessage,
    notFound(errorCode, message) {
      return createError(404, errorCode ?? 'UNK_000', message);
    },
    badRequest(errorCode, message) {
      return createError(400, errorCode ?? 'UNK_000', message);
    },
    unauthorized(errorCode, message) {
      return createError(401, errorCode ?? 'UNK_000', message);
    },
    forbidden(errorCode, message) {
      return createError(403, errorCode ?? 'UNK_000', message);
    },
    conflict(errorCode, message) {
      return createError(409, errorCode ?? 'UNK_000', message);
    },
    fatal(errorCode, message) {
      return createError(500, errorCode ?? 'UNK_000', message);
    },
    createError(statusCode, errorCode, message) {
      return createError(statusCode ?? 500, errorCode ?? 'UNK_000', message);
    },
  };
};
