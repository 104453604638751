import _ from 'lodash';
import React from 'react';

import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  RikerIcon,
  TextField,
  type TextFieldProps,
  useTheme,
} from '@joggrdocs/riker';
import { generateComponentClasses } from '@stargate/theme';

import type {
  DirectoryIconType,
  DirectoryTree,
  DirectoryTreeDirectoryNode,
} from '../../types';
import { DirectoryBreadCrumbs } from '../DirectoryBreadcrumbs';

export const directorySelectClasses = generateComponentClasses(
  'DirectorySelect',
  ['root', 'select', 'selectMenuDivider', 'selectTextFilter'] as const
);

export interface DirectorySelectItem {
  id: string;
  title: string;
  summary?: string;
  icon?: string;
  iconType?: DirectoryIconType;
  parentId?: string;
}

export interface DirectorySelectItemWithMetadata extends DirectorySelectItem {
  ancestors: DirectoryTreeDirectoryNode[];
  children: DirectoryTreeDirectoryNode[];
  fullTitle: string;
}

export interface DirectorySelectProps {
  /**
   * The size of the component
   */
  size?: 'small' | 'medium';

  /**
   * The style of the component.
   */
  sx?: TextFieldProps['sx'];

  /**
   * The directory tree.
   */
  directoryTree: DirectoryTree;

  /**
   * The directory that is being edited.
   */
  defaultDirectoryId?: string | null;

  /**
   * The label for the select.
   */
  label?: React.ReactNode;

  /**
   * Callback for when the directory is changed.
   *
   * @param directoryItem A directory or null if the root directory is selected.
   */
  onChangeDirectory: (directoryItem: DirectorySelectItem | null) => void;
}

/**
 * Select a directory from a directory tree.
 */
export const DirectorySelect = React.forwardRef<
  HTMLDivElement,
  DirectorySelectProps
>(
  (
    {
      size = 'small',
      sx,
      onChangeDirectory,
      directoryTree,
      defaultDirectoryId,
      label = 'Directory',
    },
    ref
  ) => {
    const theme = useTheme();
    const [textFilterValue, setTextFilterValue] = React.useState('');

    /*
    |------------------
    | Computed
    |------------------
    */

    const directoryNodes = React.useMemo(() => {
      if (directoryTree) {
        return directoryTree.filter(
          (node) => node.nodeType === 'directory'
        ) as DirectoryTreeDirectoryNode[];
      }
      return [];
    }, [directoryTree]);

    const directoryOptions = React.useMemo(() => {
      return formatDirectoryOptions(directoryNodes);
    }, [directoryNodes]);

    const directoryOptionsFiltered = React.useMemo(() => {
      return directoryOptions.filter((dirOption) => {
        if (textFilterValue) {
          return dirOption.fullTitle
            .toLowerCase()
            .includes(textFilterValue.toLowerCase());
        }
        return true;
      });
    }, [directoryOptions, textFilterValue]);

    /*
    |------------------
    | Callbacks
    |------------------
    */

    const handleDirectorySearch = React.useCallback<
      Required<TextFieldProps>['onChange']
    >((event) => {
      setTextFilterValue(event.target.value);
    }, []);

    const handleDirectoryChange = React.useCallback<
      Required<TextFieldProps>['onChange']
    >(
      (event) => {
        if (event.target.value === 'root') {
          onChangeDirectory(null);
        } else {
          const foundParent = directoryOptions.find(
            (directory) => directory.id === event.target.value
          );
          if (foundParent) {
            onChangeDirectory(foundParent);
          }
        }
      },
      [onChangeDirectory, directoryOptions]
    );

    return (
      <TextField
        classes={{
          root: directorySelectClasses.root,
        }}
        ref={ref}
        tabIndex={-1}
        label={label}
        select
        autoFocus={false}
        defaultValue={defaultDirectoryId}
        onChange={handleDirectoryChange}
        size={size}
        sx={{
          ...sx,
          [`.${directorySelectClasses.selectMenuDivider}`]: {
            mt: 1,
            mb: 1,
          },
        }}
        fullWidth
        SelectProps={{
          classes: {
            root: directorySelectClasses.select,
          },
          onClose: () => {
            setTextFilterValue('');
          },
          MenuProps: {
            slotProps: {
              paper: {
                sx: {
                  maxHeight: '600px',
                  '::-webkit-scrollbar': {
                    width: '8px !important',
                  },
                },
              },
            },
            MenuListProps: {
              sx: {
                pt: '0 !important',
                pb: 2,
              },
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          },
          autoFocus: false,
        }}
      >
        <TextField
          classes={{
            root: directorySelectClasses.selectTextFilter,
          }}
          autoFocus={false}
          placeholder='Filter directories, e.g. "Backend/Guides"'
          size={size}
          onKeyDown={(event) => {
            event.stopPropagation();
          }}
          sx={{
            pt: 2,
            pb: 1,
            px: 2,
            mb: 1,
            width: '100%',
            position: 'sticky',
            top: '0',
            left: '0',
            right: '0',
            borderBottom: `1px solid ${theme.palette.divider}`,
            bgcolor: theme.palette.background.paper,
            zIndex: 1,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <RikerIcon name='filter-search' />
              </InputAdornment>
            ),
            endAdornment:
              textFilterValue.length > 0 ? (
                <InputAdornment position='end'>
                  <IconButton
                    size='small'
                    onClick={() => {
                      setTextFilterValue('');
                    }}
                  >
                    <RikerIcon name='x' />
                  </IconButton>
                </InputAdornment>
              ) : null,
          }}
          onChange={handleDirectorySearch}
          fullWidth
          type='text'
          helperText='Filter directories in the dropdown by using the format "directory/subdirectory", e.g. "Backend/Guides"'
        />
        {directoryOptionsFiltered.length === 0 && (
          <Box
            sx={{
              p: 2,
            }}
          >
            Sorry no directories match this text filter, please try another.
          </Box>
        )}
        {_.chain(directoryOptionsFiltered)
          .map((dirOption) => {
            return (
              <MenuItem
                autoFocus={false}
                key={dirOption.id}
                value={dirOption.id}
                onKeyDown={(event) => {
                  event.stopPropagation();
                }}
              >
                <DirectoryBreadCrumbs
                  directoryBreadCrumbs={[dirOption, ...dirOption.ancestors]}
                />
              </MenuItem>
            );
          })
          .value()}
        <Divider className={directorySelectClasses.selectMenuDivider} />
        <MenuItem
          value='root'
          onKeyDown={(event) => {
            event.stopPropagation();
          }}
        >
          <DirectoryBreadCrumbs
            directoryBreadCrumbs={[
              {
                id: 'root',
                title: '<root>',
                icon: 'home',
                iconType: 'riker',
                parentId: undefined,
              },
            ]}
          />
        </MenuItem>
      </TextField>
    );
  }
);

/*
|------------------
| Utils
|------------------
*/

/**
 * Get the directory select items with metadata & sorted by ancestors.
 *
 * @param directoryNodes A list of directory nodes.
 * @returns A list of directory select items with metadata, sorted by ancestors.
 */
const formatDirectoryOptions = (
  directoryNodes: DirectoryTreeDirectoryNode[]
): DirectorySelectItemWithMetadata[] => {
  return _.chain(directoryNodes)
    .map((dir) => ({
      id: dir.id,
      title: dir.title,
      icon: dir.icon,
      iconType: dir.iconType,
      parentId: dir.parentId,
      summary: dir.summary,
      ancestors: directoryNodes
        .filter((node) => dir.ancestors.map((anc) => anc.id).includes(node.id))
        .sort((a, b) => {
          if (a.parentId === b.id) {
            return -1;
          }

          if (b.parentId === a.id) {
            return 1;
          }

          // MUST always be first
          if (!b.parentId) {
            return 100;
          }

          return 0;
        })
        .reverse(),
      children: directoryNodes.filter((node) => node.parentId === dir.id),
    }))
    .map((dir) => {
      return {
        ...dir,
        fullTitle: [...dir.ancestors.map((anc) => anc.title), dir.title].join(
          '/'
        ),
      };
    })
    .sortBy((dir) => dir.fullTitle)
    .value();
};
