import * as Frigade from '@frigade/react';
import React from 'react';

import { useConfig } from '@stargate/hooks';

export type FrigadeCollectionId = keyof typeof collections;

export interface FrigadeCollectionProps {
  /**
   * The id of the collection, that will be used to display the collection.
   */
  collectionSlug: FrigadeCollectionId;
}

/**
 * Component for rendering a Frigade collection.
 */
export const FrigadeCollection: React.FC<FrigadeCollectionProps> = ({
  collectionSlug,
}) => {
  const config = useConfig();

  const env = React.useMemo(
    () => (config.environment === 'production' ? 'production' : 'development'),
    [config.environment]
  );

  return <Frigade.Collection collectionId={collections[collectionSlug][env]} />;
};

/*
|------------------
| Config
|------------------
*/

/**
 * The collections that are available in the Frigade, we do this
 * to avoid hardcoding the collection ids in the components.
 */
const collections = {
  productTours: {
    production: 'collection_VQeyKAB2',
    development: 'collection_H9IxzVyS',
  },
};
