import type {
  SuggestionKeyDownProps,
  SuggestionProps,
} from '@tiptap/suggestion';
import React from 'react';

import type { SlashCommandItem } from '../types';
import { SlashMenuCommands } from './SlashMenuCommands';

export type SlashMenuProps = SuggestionProps<SlashCommandItem>;

/**
 * A list of commands in the Editor accessible via typing `/` in the Editor.
 */
export const SlashMenu = React.forwardRef<unknown, SlashMenuProps>(
  ({ command, items }, ref) => {
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    /*
  |------------------
  | Handlers
  |------------------
  */

    const handleAction = React.useCallback(
      (item: SlashCommandItem) => {
        command(item);
      },
      [command]
    );

    const handleSelectItem = React.useCallback(
      (index: number) => {
        const item = items[index];
        if (item) {
          handleAction(item);
        }
      },
      [items, handleAction]
    );

    const handleArrowUp = React.useCallback(() => {
      setSelectedIndex((selectedIndex + items.length - 1) % items.length);
    }, [items, selectedIndex]);

    const handleArrowDown = React.useCallback(() => {
      setSelectedIndex((selectedIndex + 1) % items.length);
    }, [items, selectedIndex]);

    const handleEnter = React.useCallback(() => {
      handleSelectItem(selectedIndex);
    }, [handleSelectItem, selectedIndex]);

    React.useImperativeHandle(
      ref,
      () => {
        return {
          onKeyDown: (x: SuggestionKeyDownProps) => {
            if (x.event.key === 'ArrowUp') {
              handleArrowUp();
              return true;
            }

            if (x.event.key === 'ArrowDown') {
              handleArrowDown();
              return true;
            }

            if (x.event.key === 'Enter') {
              handleEnter();
              return true;
            }

            return false;
          },
        };
      },
      [handleArrowUp, handleArrowDown, handleEnter]
    );

    /*
    |------------------
    | Effects
    |------------------
    */

    // biome-ignore lint/correctness/useExhaustiveDependencies: we want to reset if the items change
    React.useEffect(() => {
      setSelectedIndex(0);
    }, [items]);

    return (
      <SlashMenuCommands
        items={items}
        selectedIndex={selectedIndex}
        onClickCommand={handleAction}
      />
    );
  }
);
