import {
  IconButton,
  type IconButtonProps,
  RikerIcon,
  Skeleton,
  useTheme,
} from '@joggrdocs/riker';
import React from 'react';

import { generateComponentClasses } from '@stargate/theme';

export const actionsButtonClasses = generateComponentClasses('ActionsButton', [
  'root',
  'icon',
] as const);

export interface ActionsButtonProps extends IconButtonProps {
  loading?: boolean;
  onClick: Exclude<IconButtonProps['onClick'], undefined>;
}

/**
 * The ActionsButton component is a button that opens the ActionsMenu.
 */
export const ActionsButton = React.forwardRef<
  HTMLButtonElement,
  ActionsButtonProps
>(({ onClick, size = 'small', loading = false, ...props }, ref) => {
  const theme = useTheme();
  if (loading) {
    return (
      <Skeleton variant='rounded' ref={ref}>
        <IconButton size={size}>
          <ActionsButtonIcon size={size} />
        </IconButton>
      </Skeleton>
    );
  }

  return (
    <IconButton
      {...props}
      className={theme.utils.classNames([
        actionsButtonClasses.root,
        props.className,
      ])}
      ref={ref}
      size={size}
      onClick={onClick}
    >
      <ActionsButtonIcon size={size} />
    </IconButton>
  );
});
ActionsButton.displayName = 'ActionsButton';
ActionsButton.muiName = 'IconButton';

/*
|------------------
| Utils
|------------------
*/

const ActionsButtonIcon = ({ size }: { size?: ActionsButtonProps['size'] }) => {
  const theme = useTheme();
  return (
    <RikerIcon
      name='dots'
      className={actionsButtonClasses.icon}
      size={size === 'small' ? 18 : undefined}
      color={
        theme.palette.mode === 'light' ? undefined : theme.palette.common.white
      }
    />
  );
};
