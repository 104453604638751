import type { NodeViewProps } from '@tiptap/react';
import React from 'react';

import type { CodeMirrorProps } from '@stargate/lib/codemirror';

/*
|==========================================================================
| useCodeBlockExit
|==========================================================================
|
| Used to track when a user should exit a code block (top or bottom with N number up/down arrows)
|
*/

export type CodeBlockExitProps = Pick<NodeViewProps, 'editor' | 'getPos'> & {
  size: number;
};

export const useCodeBlockExit = (nodeViewProps: CodeBlockExitProps) => {
  const { editor, getPos, size } = nodeViewProps;
  const [arrowCount, setArrowCount] = React.useState(0);

  const handleKeyUp = React.useCallback<Required<CodeMirrorProps>['onKeyUp']>(
    (keyboardEvent, cmEditorView) => {
      if (['ArrowUp', 'ArrowDown'].includes(keyboardEvent.key) && editor) {
        const { state } = cmEditorView;
        const lineNumber = state.doc.lineAt(state.selection.main.head).number;

        // If not over the "count" we can continue to increment
        if (keyboardEvent.key === 'ArrowUp' && lineNumber === 1) {
          if (arrowCount >= 2) {
            editor
              .chain()
              .focus(getPos() - 1)
              .run();
            setArrowCount(0);
          } else {
            setArrowCount((c) => c + 1);
          }
        } else if (
          keyboardEvent.key === 'ArrowDown' &&
          lineNumber === state.doc.lines
        ) {
          if (arrowCount >= 2) {
            editor
              .chain()
              .focus(getPos() + size + 1)
              .run();
            setArrowCount(0);
          } else {
            setArrowCount((c) => c + 1);
          }
        } else {
          setArrowCount(0);
        }
      }
    },
    [editor, getPos, size, arrowCount]
  );

  return React.useMemo(
    () => ({
      onKeyUp: handleKeyUp,
    }),
    [handleKeyUp]
  );
};

export default useCodeBlockExit;
