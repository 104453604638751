import { useTheme } from '@joggrdocs/riker';
import type { Measures } from '@react-hookz/web';
import { useSideBar } from '@stargate/layouts';
import { produce } from 'immer';
import React from 'react';
import type * as TypeFest from 'type-fest';
import { create } from 'zustand';

/**
 * The layout for the Joggr document editor content.
 */
export type JDocLayoutMeasure = Measures;

export type UseJDocLayoutHook = [State, Actions];

/**
 * Manage the layout of the Joggr document editor content.
 *
 * @returns A tuple containing the state and actions of the store.
 */
export const useJDocLayout = (): UseJDocLayoutHook => {
  const theme = useTheme();
  const [sidebarState] = useSideBar();

  const [baseState, actions] = useStore((store) => [
    {
      containerSize: store.containerSize,
      contentSize: store.contentSize,
      headerSize: store.headerSize,
    },
    {
      setContainerSize: store.setContainerSize,
      setContentSize: store.setContentSize,
      setHeaderSize: store.setHeaderSize,
    },
  ]);

  const headerPosition = React.useMemo(() => {
    if (sidebarState.drawerOpen) {
      return {
        top: theme.measurements.topBar.height,
        left: theme.measurements.sideBar.width + sidebarState.drawerWidth,
      };
    }
    return {
      top: theme.measurements.topBar.height,
      left: theme.measurements.sideBar.width,
    };
  }, [sidebarState, theme]);

  const state = React.useMemo(() => {
    return {
      ...baseState,
      headerPosition,
      // copied from /src/lib/dashdraft/styles => --dashdraft-content-margin
      contentPadding: 64,
    };
  }, [baseState, headerPosition]);

  return [state, actions];
};

/*
|------------------
| Utils
|------------------
*/

interface State extends StoreState {
  headerPosition: {
    top: number;
    left: number;
  };
  /**
   * The padding around the content in pixels, that matches the padding of the editor (--dashdraft-content-margin).
   *
   * @default 64
   */
  contentPadding: number;
}

type UseJoggrDocLayoutStore = TypeFest.Simplify<StoreState & Actions>;

const useStore = create<UseJoggrDocLayoutStore>((set) => ({
  containerSize: {
    width: 0,
    height: 0,
  },
  headerSize: {
    width: 0,
    height: 0,
  },
  contentSize: {
    width: 0,
    height: 0,
  },
  setContainerSize: (size) => {
    set(
      produce<StoreState>((draft) => {
        draft.containerSize = size;
      })
    );
  },
  setContentSize: (size) => {
    set(
      produce<StoreState>((draft) => {
        draft.contentSize = size;
      })
    );
  },
  setHeaderSize: (size) => {
    set(
      produce<StoreState>((draft) => {
        draft.headerSize = size;
      })
    );
  },
}));

interface StoreState {
  /**
   * The size of the container.
   */
  containerSize: JDocLayoutMeasure;

  /**
   * The size of the header.
   */
  headerSize: JDocLayoutMeasure;

  /**
   * The size of the content.
   */
  contentSize: JDocLayoutMeasure;
}

interface Actions {
  /**
   * Set the size of the container.
   *
   * @param size A `JoggrDocLayoutMeasure` object.
   */
  setContainerSize: (size: JDocLayoutMeasure) => void;

  /**
   * Set the size of the content.
   *
   * @param size A `JoggrDocLayoutMeasure` object.
   */
  setHeaderSize: (size: JDocLayoutMeasure) => void;

  /**
   * Set the size of the content.
   *
   * @param size A `JoggrDocLayoutMeasure` object.
   */
  setContentSize: (size: JDocLayoutMeasure) => void;
}
