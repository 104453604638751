import api, { createClientCacheStore, type APIContract } from '@stargate/api';
import type { HttpError } from '@stargate/lib/http';
import React from 'react';

export type User = APIContract<'GET /user'>['response'];

export type UserRequestPayload = {
  /**
   * Skip the server cache. Will force the API to fetch the data from the database, Auth0 and other sources directly.
   */
  clearCache?: boolean;
};

export type UseUserHook = {
  /**
   * Load the state from the API.
   *
   * @param payload The request payload.
   */
  load: (payload?: UserRequestPayload) => Promise<User | null>;

  /**
   * Refresh the state from the API.
   *
   * @param payload The request payload.
   */
  refresh: (payload?: UserRequestPayload) => Promise<User | null>;

  /**
   * Reset the state.
   */
  reset: () => void;

  /**
   * The loading state.
   */
  loading: boolean;

  /**
   * The error state.
   */
  error: HttpError | Error | null;

  /**
   * The data from the API.
   */
  data: User | null;
};

export const useUser = (): UseUserHook => {
  const apiStore = useApiClientStore();
  const [, apiActions] = api.useRequestClient('GET /user');

  const refresh = React.useCallback<UseUserHook['refresh']>(
    async (payload) => {
      try {
        const { clearCache = false } = payload ?? {};
        const result = await apiActions.execute({
          clearCache: clearCache,
        });
        apiStore.setData(result);
        return result;
      } catch (error) {
        apiStore.setError(
          error instanceof Error ? error : new Error('Unknown error')
        );
        throw error;
      }
    },
    [apiActions, apiStore]
  );

  const load = React.useCallback<UseUserHook['load']>(
    async (payload) => {
      try {
        apiStore.setStatus('loading');
        const { clearCache = false } = payload ?? {};
        const result = await apiActions.execute({
          clearCache: clearCache,
        });
        apiStore.setData(result);
        return result;
      } catch (error) {
        apiStore.setError(
          error instanceof Error ? error : new Error('Unknown error')
        );
        throw error;
      }
    },
    [apiActions, apiStore]
  );

  const reset = React.useCallback(() => {
    apiStore.clearData();
  }, [apiStore]);

  return React.useMemo(
    () => ({
      data: apiStore.data ?? null,
      error: apiStore.error,
      loading: apiStore.status === 'loading',
      load,
      refresh,
      reset,
    }),
    [apiStore.data, apiStore.error, apiStore.status, load, refresh, reset]
  );
};

const useApiClientStore = createClientCacheStore('GET /user');
