import React from 'react';

import { langDefinitions } from '../config/langs';
import {
  findLangByFileExtension,
  findLangDefinition,
  findLangDefinitionByFileExtension,
} from '../lib/langs';

/**
 * React wrapper around the supported languages lib.
 */
export const useCodeMirrorLangs = () => {
  return React.useMemo(() => {
    return {
      langs: langDefinitions,
      findDefinition: findLangDefinition,
      findDefinitionByFileExtension: findLangDefinitionByFileExtension,
      findByFileExtension: findLangByFileExtension,
    };
  }, []);
};
