import React from 'react';
import type * as TypeFest from 'type-fest';

import { GitHubAccountChip } from './GitHubAccountChip';
import type { GitHubChipProps } from './GitHubChip';

export type GitHubRepositoryOwnerChipProps<
  RootComponent extends React.ElementType = 'div',
> = TypeFest.Simplify<
  Omit<GitHubChipProps<RootComponent>, 'label' | 'startIcon'> & {
    owner: string;
  }
>;

export const GitHubRepositoryOwnerChip = React.forwardRef<
  HTMLDivElement,
  GitHubRepositoryOwnerChipProps
>(({ owner, ...props }, ref) => {
  return <GitHubAccountChip {...props} account={owner} />;
});
GitHubRepositoryOwnerChip.displayName = 'GitHubRepositoryOwnerChip';
GitHubRepositoryOwnerChip.muiName = 'Chip';
