import _ from 'lodash';

import type {
  APIHeaders,
  APIParams,
  APIPayload,
  APIRoute,
  APIRouteMethod,
  APIRouteURL,
} from '@stargate/api/types';

/**
 * Get the HTTP method from a schema name for the Fetch API.
 *
 * @param route A schema name.
 * @returns The method from the schema name.
 */
export const getAPIRequestMethod = <R extends APIRoute>(
  route: R
): APIRouteMethod<R> => {
  const [method] = route.split(' ');
  return method as APIRouteMethod<R>;
};

/**
 * Get the URL from a schema name for the Fetch API.
 *
 * @param route The schema name.
 * @returns A URL from the schema name and payload.
 */
export const getAPIRequestURL = <R extends APIRoute>(
  route: R
): APIRouteURL<R> => {
  const [, url] = route.split(' ');
  return url as APIRouteURL<R>;
};

/**
 * Extract the URL & build the path using the passed in params.
 *
 * @param route The schema name.
 * @param payload The API request  payload.
 * @returns A URL from the schema name and payload.
 */
export const buildAPIRequestURL = <R extends APIRoute>(
  route: R,
  params?: APIParams<R>
) => {
  const url = getAPIRequestURL(route);
  if (!_.isNil(params)) {
    let urlWithParams = `${url}`;

    for (const [key, value] of Object.entries(params)) {
      urlWithParams = urlWithParams.replace(`:${key}`, value);
    }

    return urlWithParams;
  }
  return url;
};

/**
 * Build the headers from a API payload.
 *
 * @param payload A API request  payload.
 * @returns The headers from the API request  payload.
 */
export const buildAPIRequestHeaders = <R extends APIRoute>(
  payload?: APIPayload<R>
): APIHeaders | undefined => {
  if (payload?.clearCache === true) {
    return {
      ...payload.headers,
      'x-jgr-options': 'clear-cache',
    } satisfies APIHeaders<true>;
  }

  if (!_.isNil(payload?.headers)) {
    return payload.headers satisfies APIHeaders<false>;
  }
};

/**
 * Get the data from a schema name for the Fetch API.
 *
 * @param payload A API request  payload.
 * @returns The data from the API request  payload or undefined.
 */
export const buildAPIRequestData = <R extends APIRoute>(
  payload?: APIPayload<R>
) => {
  const body = _.get(payload, 'body') as Record<string, unknown> | undefined;
  const querystring = _.get(payload, 'querystring') as
    | Record<string, unknown>
    | undefined;

  if (!_.isNil(body)) {
    return body;
  }

  if (!_.isNil(querystring)) {
    return querystring;
  }

  return undefined;
};
