import _ from 'lodash';
import React from 'react';

import {
  Alert,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@joggrdocs/riker';

import { isValidLoomUrl } from '@joggrdocs/bumblebee';

export interface LoomFormProps {
  defaultSrc?: string;
  onSave: (payload: { url: string }) => void;
  onRemove?: () => void;
}

export const LoomForm: React.FC<LoomFormProps> = ({ defaultSrc, onSave }) => {
  const [currentSrc, setCurrentSrc] = React.useState(defaultSrc ?? '');
  const [error, setError] = React.useState(false);

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // We have to do this because onChange fires on render
      // @todo remove once fixed in 2.5.0
      // @see https://github.com/ueberdosis/tiptap/commit/aa4389883aead90fa16e804c672dc269b9a1292a
      queueMicrotask(() => {
        if (error) {
          setError(false);
        }
        setCurrentSrc(e.target.value);
      });
    },
    [error]
  );

  const handleSave = React.useCallback(() => {
    if (isValidLoomUrl(currentSrc)) {
      onSave({ url: currentSrc });
      setCurrentSrc('');
    } else {
      setError(true);
    }
  }, [currentSrc, onSave]);

  return (
    <Stack className='dashdraft-media-form' spacing={1}>
      <TextField
        label='Loom Share URL'
        placeholder='Add a url i.e. "loom.com/7bd6f47720f4473cae48822100640251"'
        size='small'
        type='url'
        defaultValue={currentSrc}
        onChange={handleChange}
        fullWidth
      />
      {error && <Alert severity='error'>Invalid Loom url.</Alert>}
      <Divider flexItem />
      <Stack direction='column' spacing={1} sx={{ mt: 1 }} alignItems='center'>
        <Button
          size='small'
          variant='outlined'
          fullWidth
          disabled={_.isNil(currentSrc)}
          onClick={handleSave}
        >
          Insert Video
        </Button>
        <Typography variant='caption' color='text.secondary'>
          Insert a Loom video by pasting a url
        </Typography>
      </Stack>
    </Stack>
  );
};
