import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import React from 'react';

import { apiClient } from '@stargate/api';
import { useUser } from '@stargate/features/user';
import { useConfig } from '@stargate/hooks';
import { DashDraftProvider } from '@stargate/lib/dashdraft';
import { Outlet } from '@stargate/routes';

import { useJDocContext } from '../hooks/use-jdoc-ctx';

/**
 * Provider the DashDraftProvider to the page to allow the page to use the DashDraftContent & Hooks
 */
export const JoggrDocProvider: React.FC = () => {
  const jdocParams = useJDocContext();
  const user = useUser();
  const config = useConfig();
  const query = useQuery({
    queryKey: ['user-auth-tiptap'],
    queryFn: async () => await apiClient.request('POST /user/auth/tiptap', {}),
    enabled: !!user.data?.id,
  });

  if (!query.data?.ai) {
    return null;
  }

  return (
    <DashDraftProvider
      editorOptions={{
        editable: jdocParams.mode !== 'view',
        editorProps: {
          scrollThreshold: 100,
          scrollMargin: 100,
        },
      }}
      extensionsConfig={{
        ai: {
          enabled: true,
          options: {
            appId: config.tiptap.ai.appId,
            token: query.data.ai,
          },
        },
      }}
    >
      <Outlet />
    </DashDraftProvider>
  );
};
