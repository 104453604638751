import _ from 'lodash';
import React from 'react';

export interface GitHubRepoFullName {
  owner: string;
  repo: string;
}

export interface GitHubFile {
  owner: string;
  repo: string;
  branch: string;
  path: string;
}

export interface GitHubUrl {
  /**
   * Generates a URL to a GitHub homepage.
   *
   * @param path A path to a GitHub page.
   * @returns A URL to a GitHub homepage.
   */
  root: (path: string) => string;

  /**
   * Generates a URL to an organization's GitHub page.
   *
   * @param org An organization name, i.e. `joggrdocs`.
   * @returns A URL to an organization's GitHub page.
   */
  org: (org: string) => string;

  /**
   * Generates a URL to a user's GitHub page.
   *
   * @param username A GitHub user name, i.e. `zrosenbauer`.
   * @returns A URL to a user's GitHub page.
   */
  user: (username: string) => string;

  /**
   * Generates a URL to a user's GitHub image.
   *
   * @param username A GitHub user name, i.e. `zrosenbauer`.
   * @returns A URL to a user's GitHub image.
   */
  avatar: (username: string) => string;

  /**
   * Generates a URL to a repository's GitHub page.
   *
   * @param repo A object representing a GitHub repository's full name.
   * @returns A URL to a repository's GitHub page.
   */
  repo: (repo: GitHubRepoFullName) => string;

  /**
   * Generates a URL to a file's GitHub page.
   *
   * @param payload A object representing a file in GitHub.
   * @returns A URL to a file's GitHub page.
   */
  file: (payload: GitHubFile) => string;

  /**
   * Generates a URL to a pull request's GitHub page.
   *
   * @param payload A object representing a pull request in GitHub.
   * @returns A URL to a pull request's GitHub page.
   */
  pull: (repo: GitHubRepoFullName & { pull: number }) => string;

  /**
   * Generates a URL to authorize access to GitHub.
   *
   * @param payload A object representing a pull request in GitHub.
   * @param payload.clientId The client ID of the GitHub app.
   * @returns A URL to authorize access to GitHub.
   */
  authorize: (payload: { clientId: string }) => URL;
}

/**
 * This hook provides a set of functions to create URLs to https://github.com.
 */
export const useGitHubUrls = (): GitHubUrl => {
  const root: GitHubUrl['root'] = (path: string) => {
    return `https://github.com/${_.trimStart(path, '/')}`;
  };

  const org: GitHubUrl['org'] = (organizationName: string) => {
    return root(organizationName);
  };

  const user: GitHubUrl['user'] = (userName: string) => {
    return root(userName);
  };

  const avatar: GitHubUrl['avatar'] = (userName: string) => {
    return `${user(userName)}.png`;
  };

  const repo: GitHubUrl['repo'] = (payload) => {
    const { owner: repositoryOwner, repo: repository } = payload;
    return root([repositoryOwner, repository].join('/'));
  };

  const pull: GitHubUrl['pull'] = (payload) => {
    return [repo(payload), 'pull', payload.pull].join('/');
  };

  const file: GitHubUrl['file'] = (payload: GitHubFile) => {
    const {
      owner: repositoryOwner,
      repo: repository,
      branch,
      path: filePath,
    } = payload;
    return root(
      [
        repositoryOwner,
        repository,
        'blob',
        branch,
        _.trimStart(filePath, '/'),
      ].join('/')
    );
  };

  const authorize: GitHubUrl['authorize'] = (payload) => {
    const url = new URL('https://github.com/login/oauth/authorize');
    url.searchParams.append('client_id', payload.clientId);
    return url;
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  return React.useMemo(() => {
    return {
      root,
      org,
      user,
      avatar,
      repo,
      file,
      pull,
      authorize,
    };
  }, []);
};
