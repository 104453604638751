import type { MermaidGraphType } from './graphTypes';

/*
|==========================================================================
| examples
|==========================================================================
|
| This file contains examples of mermaid diagrams.
|
*/

const flowchart = `
graph TD;
    question(You have a programming question?);
    question2(Have you Googled it?);
    question3(Have you checked StackOverflow?);
    duck(RubberDucky);
    question --> question2;
    question --> question3;
    question2 --> duck;
    question3 --> duck;
`.trim();

const sequenceDiagram = `
sequenceDiagram
    Developer->>RubberDucky: Can I explain this code to you?
    RubberDucky-->>Developer: Quack!
`.trim();

const gantt = `
gantt
    title A Gantt Diagram
    dateFormat YYYY-MM-DD
    section Section
        A task          :a1, 2014-01-01, 30d
        Another task    :after a1, 20d
    section Another
        Task in Another :2014-01-12, 12d
        another task    :24d
`.trim();

const classDiagram = `
---
title: Animal example
---
classDiagram
    note "From Duck till Zebra"
    Animal <|-- Duck
    note for Duck "can fly\ncan swim\ncan dive\ncan help in debugging"
    Animal <|-- Fish
    Animal <|-- Zebra
    Animal : +int age
    Animal : +String gender
    Animal: +isMammal()
    Animal: +mate()
    class Duck{
        +String beakColor
        +swim()
        +quack()
    }
    class Fish{
        -int sizeInFeet
        -canEat()
    }
    class Zebra{
        +bool is_wild
        +run()
    }
`.trim();

const stateDiagram = `
stateDiagram-v2
    state if_state <<choice>>
    [*] --> IsPositive
    IsPositive --> if_state
    if_state --> False: if n < 0
    if_state --> True : if n >= 0
`.trim();

const requirementDiagram = `
requirementDiagram

    requirement test_req {
    id: 1
    text: the test text.
    risk: high
    verifymethod: test
    }

    element test_entity {
    type: simulation
    }

    test_entity - satisfies -> test_req
`.trim();

const erDiagram = `
erDiagram
    CUSTOMER ||--o{ ORDER : places
    CUSTOMER {
        string name
        string custNumber
        string sector
    }
    ORDER ||--|{ LINE-ITEM : contains
    ORDER {
        int orderNumber
        string deliveryAddress
    }
    LINE-ITEM {
        string productCode
        int quantity
        float pricePerUnit
    }
`.trim();

const pie = `
pie title Pets good at debugging
    "Dogs" : 386
    "Cats" : 13
    "Ducks" : 1433
`.trim();

const journey = `
journey
    title My working day
    section Go to work
      Make tea: 5: Me
      Go upstairs: 3: Me
      Do work: 1: Me, 🐥
    section Go home
      Go downstairs: 5: Me
      Sit down: 5: Me
`.trim();

const gitGraph = `
gitGraph
   commit
   commit
   branch develop
   checkout develop
   commit
   commit
   checkout main
   merge develop
   commit
   commit
`.trim();

const quadrantChart = `
quadrantChart
    title Reach and engagement of campaigns
    x-axis Low Reach --> High Reach
    y-axis Low Engagement --> High Engagement
    quadrant-1 We should expand
    quadrant-2 Need to promote
    quadrant-3 Re-evaluate
    quadrant-4 May be improved
    Campaign A: [0.3, 0.6]
    Campaign B: [0.45, 0.23]
    Campaign C: [0.57, 0.69]
    Campaign D: [0.78, 0.34]
    Campaign E: [0.40, 0.34]
    Campaign F: [0.35, 0.78]
`.trim();

/**
 * Example definitions.
 */
export const exampleDefinitions = [
  { type: 'flowchart', name: 'Flowchart' },
  { type: 'sequenceDiagram', name: 'Sequence Diagram' },
  { type: 'gantt', name: 'Gantt' },
  { type: 'classDiagram', name: 'Class Diagram' },
  { type: 'stateDiagram', name: 'State Diagram' },
  { type: 'requirementDiagram', name: 'Requirement Diagram' },
  { type: 'erDiagram', name: 'Entity Relationship Diagram' },
  { type: 'pie', name: 'Pie' },
  { type: 'journey', name: 'Journey' },
  { type: 'gitGraph', name: 'Git Graph' },
  { type: 'quadrantChart', name: 'Quadrant Chart' },
] as const;

export const getExample = (type: MermaidGraphType) => {
  switch (type) {
    case 'sequenceDiagram':
      return sequenceDiagram;
    case 'gantt':
      return gantt;
    case 'classDiagram':
      return classDiagram;
    case 'stateDiagram':
    case 'stateDiagram-v2':
      return stateDiagram;
    case 'requirementDiagram':
      return requirementDiagram;
    case 'erDiagram':
      return erDiagram;
    case 'pie':
      return pie;
    case 'journey':
      return journey;
    case 'git':
    case 'gitGraph':
      return gitGraph;
    case 'quadrantChart':
      return quadrantChart;
    default:
      return flowchart;
  }
};

export const getExampleDefinition = (type: MermaidGraphType) => {
  return exampleDefinitions.find((definition) => definition.type === type)!;
};
