import type { EmojiItem } from '@tiptap-pro/extension-emoji';
import type {
  SuggestionKeyDownProps,
  SuggestionProps,
} from '@tiptap/suggestion';
import React from 'react';

import {
  IconMoodSad,
  MenuItem,
  MenuList,
  Paper,
  Stack,
  Typography,
  lighten,
  useTheme,
} from '@joggrdocs/riker';

/*
|==========================================================================
| EmojiList
|==========================================================================
|
| This component is used to display a list of emojis to the user.
|
*/

export type EmojiMenuProps = SuggestionProps<Partial<EmojiItem>>;

export const EmojiMenu: React.ForwardRefRenderFunction<
  unknown,
  EmojiMenuProps
> = ({ command, ...props }, ref) => {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const theme = useTheme();

  /*
  |------------------
  | Handlers
  |------------------
  */

  const handleSelectItem = React.useCallback(
    (index: number) => {
      const item = props.items[index];
      if (item) {
        command({ name: item.name });
      }
    },
    [command, props.items]
  );

  const createSelectItemHandler = React.useCallback(
    (index: number) => {
      return () => {
        handleSelectItem(index);
      };
    },
    [handleSelectItem]
  );

  const handleArrowUp = React.useCallback(() => {
    setSelectedIndex(
      (selectedIndex + props.items.length - 1) % props.items.length
    );
  }, [props.items, selectedIndex]);

  const handleArrowDown = React.useCallback(() => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  }, [props.items, selectedIndex]);

  const handleEnter = React.useCallback(() => {
    handleSelectItem(selectedIndex);
  }, [handleSelectItem, selectedIndex]);

  React.useImperativeHandle(
    ref,
    () => {
      return {
        onKeyDown: (x: SuggestionKeyDownProps) => {
          if (x.event.key === 'ArrowUp') {
            handleArrowUp();
            return true;
          }

          if (x.event.key === 'ArrowDown') {
            handleArrowDown();
            return true;
          }

          if (x.event.key === 'Enter') {
            handleEnter();
            return true;
          }

          return false;
        },
      };
    },
    [handleArrowUp, handleArrowDown, handleEnter]
  );

  /*
  |------------------
  | Effects
  |------------------
  */

  React.useEffect(() => {
    setSelectedIndex(0);
  }, [props.items]);

  return (
    <Paper
      sx={{
        border: (theme) => `0.5px solid ${theme.palette.divider}`,
      }}
      elevation={2}
    >
      <MenuList variant='selectedMenu'>
        {props.items.length === 0 && (
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='center'
            spacing={1}
            sx={{
              p: 1,
            }}
          >
            <IconMoodSad
              fill={lighten(theme.palette.primary.dark, 0.5)}
              color={theme.palette.primary.dark}
              stroke={2}
              size={24}
            />
            <Typography variant='body1'>Sorry no emojis found.</Typography>
          </Stack>
        )}
        {props.items.map((item, index) => (
          <MenuItem
            selected={index === selectedIndex}
            key={item.emoji}
            sx={{
              fontSize: '1rem',
            }}
            onClick={createSelectItemHandler(index)}
          >
            {item.fallbackImage ? (
              <img
                style={{ height: '1rem', width: '1rem' }}
                src={item.fallbackImage}
                alt={`Emoji: ${item.name}`}
              />
            ) : (
              item.emoji
            )}
            &nbsp;:{item.name}:
          </MenuItem>
        ))}
      </MenuList>
    </Paper>
  );
};

const EmojiMenuWrapped = React.forwardRef(EmojiMenu);
EmojiMenuWrapped.displayName = 'EmojiMenu';

export default EmojiMenuWrapped;
