import { Box, Divider, FormHelperText, Typography } from '@joggrdocs/riker';
import React from 'react';

import { ComingSoonChip } from '@stargate/components/Chips';
import { GitHubOrganizationSelect } from '@stargate/features/github';

function Analyze() {
  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant='h3' gutterBottom>
        Analyze with AI <ComingSoonChip />
      </Typography>
      <Typography variant='body1' gutterBottom>
        Utilize AI to analyze your code base to find gaps in your documentation
        and <br />
        generate documentation for those gaps.
      </Typography>
      <Divider sx={{ my: 1 }} />
      <Typography variant='body1' sx={{ mb: 2 }}>
        Select a GitHub Organization to get started.
      </Typography>
      <GitHubOrganizationSelect
        id={'analyze-github-org-select'}
        options={[]}
        onChange={() => {}}
        size='small'
        fullWidth={false}
        disabled
      />
      <FormHelperText>
        Currently only the default branch is supported.
      </FormHelperText>
    </Box>
  );
}

export default Analyze;
