import * as Sentry from '@sentry/react';

/**
 * Hook to access the Sentry SDK.
 *
 * @returns The Sentry SDK.
 */
export const useSentry = (): typeof Sentry => {
  return Sentry;
};
