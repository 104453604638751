import React from 'react';

import { createCachedClient } from '@stargate/api/_deprecated/cached-client-factory';
import type { HttpError } from '@stargate/lib/http';
import type { GitHubOrganization } from '../types';

export type UseGitHubOrganizationsHook = {
  /**
   * Load the state from the API.
   */
  load: (payload?: Payload) => Promise<GitHubOrganization[]>;

  /**
   * Refresh the state from the API.
   */
  refresh: (payload?: Payload) => Promise<GitHubOrganization[]>;

  /**
   * Reset the state.
   */
  reset: () => void;

  /**
   * The loading state.
   */
  loading: boolean;

  /**
   * The error state.
   */
  error: HttpError | Error | null;

  /**
   * The data from the API.
   */
  data: GitHubOrganization[];
};

export const useGitHubOrganizations = (): UseGitHubOrganizationsHook => {
  const client = useApiClient();

  const load = React.useCallback<UseGitHubOrganizationsHook['load']>(
    async (payload) => {
      const result = await client.execute({
        clearCache: payload?.clearCache === true,
      });
      return result;
    },
    [client.execute]
  );

  const refresh = React.useCallback<UseGitHubOrganizationsHook['refresh']>(
    async (payload) => {
      const result = await client.refresh({
        clearCache: payload?.clearCache === true,
      });
      return result;
    },
    [client.refresh]
  );

  return {
    data: client.data ?? [],
    error: client.error,
    loading: client.status === 'loading',
    load,
    refresh,
    reset: client.reset,
  };
};

/*
|------------------
| Utils
|------------------
*/

type Payload = {
  /**
   * Skip the server cache. Will force the API to fetch the data from the database, Auth0 and other sources directly.
   */
  clearCache?: boolean;
};

const useApiClient = createCachedClient({
  route: 'GET /github/organizations',
  defaultValue: [],
});
