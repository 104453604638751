import type { ViewUpdate } from '@codemirror/view';

/**
 * CodeMirror's selections are based on the cursor position, not the top-down order of the selection,
 * this function returns the start and end line of the selection in a top-down order.
 *
 * @param updateView A codemirror ViewUpdate
 * @returns The start and end line of the selection, in a top-down order
 */
export const getTopDownSelectionLines = (updateView: ViewUpdate) => {
  let start = updateView.state.doc.lineAt(updateView.state.selection.main.head);
  let end = updateView.state.doc.lineAt(updateView.state.selection.main.anchor);

  // Swap start and end if needed to make sure start is always smaller than end
  // This is needed because the selection can be made in both directions but primarily
  // when double clicking a word, the selection is made from the end of the word to the start
  if (start.number > end.number) {
    const temp = start;
    start = end;
    end = temp;
  }

  return { start, end };
};
