import { classname } from '@uiw/codemirror-extensions-classname';

/*
|==========================================================================
| lineNumberClassNames
|==========================================================================
|
| This extension adds a class name to the line number, which can be used
| to style the line number.
|
*/

export default classname({
  add: (lineNumber) => {
    return `cm-lineNumber-${lineNumber}`;
  },
});
