import { findParentNode, isTextSelection } from '@tiptap/core';
import { BubbleMenu, type Editor } from '@tiptap/react';
import _ from 'lodash';
import React from 'react';

import {
  ButtonGroup,
  IconBlockquote,
  IconBold,
  IconCode,
  IconH1,
  IconH2,
  IconH3,
  IconH4,
  IconH5,
  IconItalic,
  IconLink,
  IconList,
  IconListNumbers,
  IconStrikethrough,
  useTheme,
} from '@joggrdocs/riker';

import LinkDialogMemo from '../../extensions/link/components/LinkDialog';
import { BubbleMenuIconButton } from './helpers/BubbleMenuIconButton';

/*
|----------------------------------
| TextBubbleMenu
|----------------------------------
|
| When hovering over text, this menu will appear.
|
*/

export interface TextMenuProps {
  editor: Editor;
}

const TextMenu: React.FC<TextMenuProps> = (props) => {
  const { editor } = props;
  const [openLinkDialog, setOpenLinkDialog] = React.useState(false);
  const [defaultLinkText, setDefaultLinkText] = React.useState('');
  const [defaultLinkUrl, setDefaultLinkUrl] = React.useState('');
  const theme = useTheme();

  /*
  |------------------
  | Computed
  |------------------
  */
  const iconSize = 20;

  const showHeaderBtn = _.every([
    !editor.isActive('blockquote'),
    !editor.isActive('orderedList'),
    !editor.isActive('bulletList'),
    !editor.isActive('link'),
    !editor.isActive('tableHeader'),
  ]);

  const showBaseMarks = editor.isActive('tableHeader');

  const hideBlockQuote = _.some([
    editor.isActive('blockquote'),
    editor.isActive('alerts'),
    editor.isActive('tableHeader'),
    editor.isActive('bulletList'),
    editor.isActive('orderedList'),
  ]);

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const handleClose = React.useCallback(() => {
    setOpenLinkDialog(false);
    setDefaultLinkText('');
    setDefaultLinkUrl('');
  }, []);

  const handleLink = React.useCallback(() => {
    const existingHref = editor.isActive('link')
      ? editor.getAttributes('link').href
      : '';
    const defaultLinkUrl = existingHref;
    const { from, to, empty } = editor.state.selection;
    const selectedText = empty
      ? ''
      : editor.state.doc.textBetween(from, to, ' ');
    const defaultLinkText = selectedText;

    setDefaultLinkText(defaultLinkText);
    setDefaultLinkUrl(defaultLinkUrl);
    setOpenLinkDialog(true);
  }, [editor]);

  return (
    <React.Fragment>
      <LinkDialogMemo
        href={defaultLinkUrl}
        title={defaultLinkText}
        onClose={handleClose}
        open={openLinkDialog}
      />
      <BubbleMenu
        editor={editor}
        tippyOptions={{
          zIndex: theme.zIndex.tooltip,
          onHide: () => {
            setDefaultLinkText('');
            setDefaultLinkUrl('');
          },
        }}
        shouldShow={(x) => {
          if (!x.editor.isEditable) {
            return false;
          }

          const isEmptyTextBlock =
            !x.state.doc.textBetween(x.from, x.to).length &&
            isTextSelection(x.state.selection);

          // Check if the selection is within a table, is a table, or has the class 'dashdraft-table', because
          // we ONLY want to target the table itself and not anywhere else in the editor
          const node = x.view.domAtPos(x.state.selection.$from.pos).node;
          const isInTable =
            node.nodeName.toUpperCase() === 'TABLE' ||
            node.nodeName.toUpperCase() === 'TH' ||
            node.nodeName.toUpperCase() === 'TR' ||
            node.nodeName.toUpperCase() === 'TD';

          return (
            !x.view.dragging &&
            !isEmptyTextBlock &&
            !x.editor.isActive('image') &&
            !x.editor.isActive('youtube') &&
            !isInTable &&
            (x.editor.isActive('bold') ||
              x.editor.isActive('italic') ||
              x.editor.isActive('link') ||
              x.editor.isActive('paragraph') ||
              x.editor.isActive('heading'))
          );
        }}
      >
        {!defaultLinkText && (
          <ButtonGroup
            sx={{
              bgcolor: theme.palette.background.paper,
            }}
          >
            {showHeaderBtn && !showBaseMarks && (
              <BubbleMenuIconButton
                selected={editor.isActive('heading', { level: 1 })}
                icon={<IconH1 size={iconSize} />}
                onClick={() =>
                  editor.chain().focus().toggleHeading({ level: 1 }).run()
                }
                description='H1'
              />
            )}
            {showHeaderBtn && !showBaseMarks && (
              <BubbleMenuIconButton
                selected={editor.isActive('heading', { level: 2 })}
                icon={<IconH2 size={iconSize} />}
                onClick={() =>
                  editor.chain().focus().toggleHeading({ level: 2 }).run()
                }
                description='H2'
              />
            )}
            {showHeaderBtn && !showBaseMarks && (
              <BubbleMenuIconButton
                selected={editor.isActive('heading', { level: 3 })}
                icon={<IconH3 size={iconSize} />}
                onClick={() =>
                  editor.chain().focus().toggleHeading({ level: 3 }).run()
                }
                description='H3'
              />
            )}
            {showHeaderBtn && !showBaseMarks && (
              <BubbleMenuIconButton
                selected={editor.isActive('heading', { level: 4 })}
                icon={<IconH4 size={iconSize} />}
                onClick={() =>
                  editor.chain().focus().toggleHeading({ level: 4 }).run()
                }
                description='H4'
              />
            )}
            {showHeaderBtn && !showBaseMarks && (
              <BubbleMenuIconButton
                selected={editor.isActive('heading', { level: 5 })}
                icon={<IconH5 size={iconSize} />}
                onClick={() =>
                  editor.chain().focus().toggleHeading({ level: 5 }).run()
                }
                description='H5'
              />
            )}
            <BubbleMenuIconButton
              selected={editor.isActive('bold')}
              icon={<IconBold size={iconSize} />}
              onClick={() => editor.chain().focus().toggleBold().run()}
              description='Bold'
            />
            <BubbleMenuIconButton
              selected={editor.isActive('italic')}
              icon={<IconItalic size={iconSize} />}
              onClick={() => editor.chain().focus().toggleItalic().run()}
              description='Italic'
            />
            <BubbleMenuIconButton
              selected={editor.isActive('strike')}
              icon={<IconStrikethrough size={iconSize} />}
              onClick={() => editor.chain().focus().toggleStrike().run()}
              description='Strike'
            />
            {!showBaseMarks && (
              <BubbleMenuIconButton
                selected={editor.isActive('orderedList')}
                icon={<IconListNumbers size={iconSize} />}
                onClick={() => editor.chain().focus().toggleOrderedList().run()}
                description='Bullet list'
              />
            )}
            {!showBaseMarks && (
              <BubbleMenuIconButton
                selected={editor.isActive('bulletList')}
                icon={<IconList size={iconSize} />}
                onClick={() => editor.chain().focus().toggleBulletList().run()}
                description='Unordered list'
              />
            )}
            {!hideBlockQuote && (
              <BubbleMenuIconButton
                selected={editor.isActive('blockquote')}
                icon={<IconBlockquote size={iconSize} />}
                onClick={() => editor.chain().focus().toggleBlockquote().run()}
                description='Blockquote'
              />
            )}
            <BubbleMenuIconButton
              selected={editor.isActive('code')}
              icon={<IconCode size={iconSize} />}
              onClick={() => editor.chain().focus().toggleCode().run()}
              description='Code'
            />
            <BubbleMenuIconButton
              selected={editor.isActive('link')}
              icon={<IconLink size={iconSize} />}
              onClick={handleLink}
              description='Link'
            />
          </ButtonGroup>
        )}
      </BubbleMenu>
    </React.Fragment>
  );
};

export default TextMenu;
