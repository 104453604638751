import React from 'react';

import { Button, Divider, Stack, Typography } from '@joggrdocs/riker';

import FileDropZone from '@stargate/components/Utils/FileDropZone';
import { ImageAltTextField } from './ImageAltTextField';

/**
 * This component is used to add an image that will be committed to the repo.
 *
 * @note This component is not yet implemented & is just to show user future state.
 */
export const ImageFormGitCommit: React.FC = () => {
  const stub = (): void => {
    // Stub
  };

  return (
    <Stack
      spacing={1.5}
      justifyContent='center'
      sx={{
        textAlign: 'center',
      }}
    >
      <ImageAltTextField onChange={stub} />
      <FileDropZone
        onDrop={stub}
        onError={stub}
        size='small'
        dragActiveLabel='Drop image here'
        dragInactiveLabel='Drag and drop image here'
        icon={'git-commit'}
      />
      <Divider flexItem />
      <Button sx={{ mt: 1 }} size='small' variant='outlined' fullWidth disabled>
        Add Image to Commit
      </Button>
      <Typography variant='caption' color='text.secondary'>
        Upload size limits, restricted by GitHub
      </Typography>
    </Stack>
  );
};
ImageFormGitCommit.displayName = 'ImageFormGitCommit';
