import {
  Box,
  CardContent,
  CardHeader,
  Stack,
  useTheme,
} from '@joggrdocs/riker';
import React from 'react';

import { useResizeObserver } from '@stargate/hooks';
import { generateComponentClasses } from '@stargate/theme';
import { useJDocLayout } from '../hooks/use-jdoc-layout';

export const joggrDocContentLayoutClasses = generateComponentClasses(
  'JoggrDocContentLayout',
  ['root', 'header', 'title', 'summary', 'metadata', 'content'] as const
);

export interface JoggrDocContentLayoutProps {
  /**
   * The title of the document.
   */
  title: React.ReactNode;

  /**
   * The summary of the document.
   */
  summary: React.ReactNode;

  /**
   * The metadata of the document content.
   */
  metadata: React.ReactNode;

  /**
   * The main content of the document.
   */
  content: React.ReactNode;

  /**
   * Optional click handler for the section headers.
   *
   * @param event The click event.
   * @param section The section that was clicked.
   */
  onClick?: (event: React.SyntheticEvent, section: 'title' | 'content') => void;
}

/**
 * The layout for the Joggr document editor content.
 */
export const JoggrDocContentLayout: React.FC<JoggrDocContentLayoutProps> =
  React.forwardRef<HTMLDivElement, JoggrDocContentLayoutProps>(
    (props, fwdRef) => {
      const theme = useTheme();
      const [docLayoutState, docLayoutActions] = useJDocLayout();

      const offsetTop = React.useMemo(() => {
        return (
          docLayoutState.headerSize.height + theme.measurements.topBar.height
        );
      }, [theme.measurements.topBar.height, docLayoutState.headerSize.height]);

      const ref = useResizeObserver<HTMLDivElement>({
        ref: fwdRef,
        onResize: (size) => {
          docLayoutActions.setContentSize({
            width: size.width ?? 0,
            height: size.height ?? 0,
          });
        },
        debounce: 100,
      });

      return (
        <Box
          ref={ref}
          className={joggrDocContentLayoutClasses.root}
          sx={{
            [`&.${joggrDocContentLayoutClasses.root}`]: {
              minHeight: `calc(100vh - ${offsetTop}px)`,
              display: 'flex',
              flexDirection: 'column',
            },
          }}
        >
          <CardHeader
            sx={{
              px: `${docLayoutState.contentPadding}px`,
            }}
            classes={{
              root: joggrDocContentLayoutClasses.header,
            }}
            title={
              <Box
                component='span'
                className={joggrDocContentLayoutClasses.title}
                onClick={(e) => props.onClick?.(e, 'title')}
              >
                {props.title}
              </Box>
            }
            disableTypography
            subheader={
              <Stack direction='column' spacing={1} sx={{ mt: 1 }}>
                <Box className={joggrDocContentLayoutClasses.summary}>
                  {props.summary}
                </Box>
                <Stack
                  direction='row'
                  spacing={0.5}
                  alignItems='center'
                  className={joggrDocContentLayoutClasses.metadata}
                >
                  {props.metadata}
                </Stack>
              </Stack>
            }
          />
          <CardContent
            classes={{
              root: joggrDocContentLayoutClasses.content,
            }}
            sx={{
              flex: '1 1 auto',
              mt: 0,
              py: 1,
              px: 0,
              height: '100%',
            }}
            onClick={(e) => props.onClick?.(e, 'content')}
          >
            {props.content}
          </CardContent>
        </Box>
      );
    }
  );
