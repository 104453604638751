import * as hookz from '@react-hookz/web';
import React from 'react';
import { Helmet } from 'react-helmet-async';

import { useApp } from '@stargate/app';
import { useDebugSession } from '@stargate/lib/debug-session';
import { useNightingale } from '@stargate/lib/nightingale';
import { useLogger } from '@stargate/logger';
import { useAuth0 } from '@stargate/vendors/auth0';

export const LogoutPage: React.FC = () => {
  const auth0 = useAuth0();
  const logger = useLogger();
  const nightingale = useNightingale();
  const debugSession = useDebugSession();
  const app = useApp();

  hookz.useMountEffect(() => {
    app.setLoading(true);
    logger.info('Logging out');
    nightingale.logout();
    debugSession.clear();
    auth0.logout();
  });

  return (
    <React.Fragment>
      <Helmet>
        <title>Logging out</title>
      </Helmet>
    </React.Fragment>
  );
};
