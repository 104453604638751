import React from 'react';
import type * as TypeFest from 'type-fest';

import {
  IconFileCode,
  IconFileTypeCss,
  IconFileTypeCsv,
  IconFileTypeDoc,
  IconFileTypeHtml,
  IconFileTypeJpg,
  IconFileTypeJs,
  IconFileTypeJsx,
  IconFileTypePdf,
  IconFileTypePhp,
  IconFileTypePng,
  IconFileTypeRs,
  IconFileTypeSql,
  IconFileTypeSvg,
  IconFileTypeTs,
  IconFileTypeTsx,
  IconFileTypeTxt,
  IconFileTypeVue,
  IconFileTypeXml,
  IconFileTypeZip,
  type RikerNamedIcon,
  type RikerNamedIconProps,
} from '@joggrdocs/riker';

import {
  type CodeMirrorLang,
  useCodeMirrorLangs,
} from '@stargate/lib/codemirror';

export type GitHubFileIconLanguage = TypeFest.Simplify<CodeMirrorLang | 'csv'>;

export interface GitHubFileIconProps extends RikerNamedIconProps {
  fileExtension?: string;
}

/**
 * A component that displays a file icon based on the file extension.
 */
export const GitHubFileIcon = React.forwardRef<
  RikerNamedIcon,
  GitHubFileIconProps
>(({ fileExtension = '', ...props }, ref) => {
  const supportedLanguages = useCodeMirrorLangs();

  const Icon = React.useMemo(() => {
    const cleanFileExtension = fileExtension.replace('.', '');

    // Support Special Cases
    if (cleanFileExtension === 'csv') {
      return IconFileTypeCsv;
    }
    if (cleanFileExtension === 'zip') {
      return IconFileTypeZip;
    }
    if (cleanFileExtension === 'png') {
      return IconFileTypePng;
    }
    if (cleanFileExtension === 'jpg') {
      return IconFileTypeJpg;
    }
    if (cleanFileExtension === 'svg') {
      return IconFileTypeSvg;
    }
    if (cleanFileExtension === 'pdf') {
      return IconFileTypePdf;
    }

    // Support Core Languages
    const language = supportedLanguages.findByFileExtension(fileExtension);
    switch (language) {
      case 'css':
        return IconFileTypeCss;
      case 'rust':
        return IconFileTypeRs;
      case 'typescript':
        return IconFileTypeTs;
      case 'tsx':
        return IconFileTypeTsx;
      case 'javascript':
        return IconFileTypeJs;
      case 'jsx':
        return IconFileTypeJsx;
      case 'html':
        return IconFileTypeHtml;
      case 'xml':
        return IconFileTypeXml;
      case 'php':
        return IconFileTypePhp;
      case 'sql':
        return IconFileTypeSql;
      case 'markdown':
        return IconFileTypeDoc;
      case 'plaintext':
        return IconFileTypeTxt;
      case 'vue':
        return IconFileTypeVue;
      default:
        return IconFileCode;
    }
  }, [fileExtension, supportedLanguages]);

  return <Icon ref={ref} {...props} />;
});
GitHubFileIcon.displayName = 'GitHubFileIcon';
