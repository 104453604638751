import { Extension } from '@tiptap/core';

import { Emoji } from './extensions/emoji';
import { Mention } from './extensions/mention';
import { SlashCommands } from './extensions/slash-commands';

export default Extension.create({
  name: 'dashdraft-suggestions',

  addExtensions() {
    return [Emoji, SlashCommands, Mention];
  },
});
