import {
  generateHTML as generateTipTapHTML,
  generateJSON as generateTipTapJSON,
  generateText as generateTipTapText,
} from '@tiptap/core';
import _ from 'lodash';

import {
  transformHtmlToMarkdown,
  transformMarkdownToHtml,
} from '@joggrdocs/bumblebee';

import { DashDraftExtensions } from '@dashdraft/extensions';
import type { DashDraftJSONContent } from '@dashdraft/types';

/**
 * Generate a DashDraft (TipTap) JSON representation of the given HTML
 *
 * @param html A string of HTML to convert to JSON
 * @returns A DashDraft (TipTap) JSON representation of the HTML
 */
export const generateJSON = (html: string): DashDraftJSONContent => {
  return generateTipTapJSON(html, DashDraftExtensions.list());
};

/**
 * Generate an HTML representation of the given DashDraft (TipTap) JSON
 *
 * @param json A DashDraft (TipTap) JSON representation
 * @returns A string of HTML
 */
export const generateHTML = (json: DashDraftJSONContent): string => {
  return generateTipTapHTML(json, DashDraftExtensions.list());
};

/**
 * Generate a text representation of the given DashDraft (TipTap) JSON
 *
 * @param json A DashDraft (TipTap) JSON representation
 * @returns A string of text
 */
export const generateText = (json: DashDraftJSONContent): string => {
  return generateTipTapText(json, DashDraftExtensions.list());
};

/**
 * Convert the given DashDraft (TipTap) JSON to Markdown
 *
 * @param json A DashDraft (TipTap) JSON representation
 * @returns Markdown representation of the given DashDraft (TipTap) JSON
 */
export const convertJSONToMarkdown = async (
  json: DashDraftJSONContent
): Promise<string> => {
  const html = generateHTML(json);
  return await transformHtmlToMarkdown(html);
};

/**
 * Convert the given Markdown to DashDraft (TipTap) JSON
 *
 * @param markdown A string of Markdown
 * @returns A DashDraft (TipTap) JSON representation of the Markdown
 */
export const convertMarkdownToJSON = async (
  markdown: string
): Promise<DashDraftJSONContent> => {
  const html = await transformMarkdownToHtml(markdown);
  return generateJSON(html);
};
