import mermaid from 'mermaid';

import type { MermaidGraphType } from './graphTypes';

/*
|==========================================================================
| general
|==========================================================================
|
| This file contains general utilities for mermaid.
|
*/

/**
 * Returns the type of the graph (graph key)
 *
 * @param graph A mermaid graph string
 * @returns the type of the graph
 */
export const detectGraphType = (graph: string): MermaidGraphType => {
  return mermaid.detectType(graph) as MermaidGraphType;
};
