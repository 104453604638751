import { Extension } from '@tiptap/core';

import blockquote from './extensions/blockquote';
import bold from './extensions/bold';
import { Heading } from './extensions/heading';
import horizontalRule from './extensions/horizontal-rule';
import italic from './extensions/italic';
import { Paragraph } from './extensions/paragraph';
import strikethrough from './extensions/strike';
import underline from './extensions/underline';

export default Extension.create({
  name: 'dashdraft-typography',

  addExtensions() {
    return [
      blockquote,
      bold,
      horizontalRule,
      italic,
      strikethrough,
      underline,
      Paragraph,
      Heading,
    ];
  },
});
