import type { QuackrDevtoolConfigItemValue } from '../types';
import { useQuackrConfig } from './use-quackr-config';

/**
 * Get the configuration value for a Quackr tool.
 */
export const useQuackrConfigValue = <V extends QuackrDevtoolConfigItemValue>(
  toolKey: string,
  configKey: string
): V | null => {
  const config = useQuackrConfig(toolKey);

  if (config === null) {
    return null;
  }

  return config.configs?.[configKey as keyof typeof config.configs] as V;
};
