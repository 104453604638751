import _ from 'lodash';
import React from 'react';

import {
  Box,
  IconMoodSad,
  Paper,
  Stack,
  Typography,
  lighten,
  useTheme,
} from '@joggrdocs/riker';

import type { SlashCommandItem } from '../types';
import { SlashMenuCommandsList } from './SlashMenuCommandsList';

export interface SlashMenuCommandsProps {
  /**
   * The list of commands to display.
   */
  items: SlashCommandItem[];

  /**
   * The index of the selected command.
   */
  selectedIndex?: number;

  /**
   * Callback when a command is clicked.
   *
   * @param item A command item that was clicked.
   * @returns A callback that is called when a command is clicked.
   */
  onClickCommand: (item: SlashCommandItem) => void;
}

/**
 * A menu for selecting commands in the Editor.
 */
export const SlashMenuCommands = React.memo<SlashMenuCommandsProps>(
  ({ onClickCommand, items, selectedIndex }) => {
    const boxRef = React.useRef<HTMLDivElement>(null);
    const theme = useTheme();

    /*
    |------------------
    | Computed
    |------------------
    */

    const grouped = React.useMemo(() => {
      return _.groupBy(items, 'group');
    }, [items]);

    const selected = React.useMemo(() => {
      if (selectedIndex === undefined) {
        return undefined;
      }

      return items[selectedIndex]?.id;
    }, [selectedIndex, items]);

    /*
    |------------------
    | Callbacks
    |------------------
    */

    const handleClick = React.useCallback(
      (item: SlashCommandItem) => {
        onClickCommand(item);
      },
      [onClickCommand]
    );

    /*
    |------------------
    | Effects
    |------------------
    */

    /**
     * This is a hacky implementation that uses the DOM directly so we can manipulate the scroll position. This allows
     * a user to scroll as they keydown + handles filtered items.
     */
    React.useEffect(() => {
      if (!selected) return;

      const container = boxRef.current;
      const selectedElement = boxRef.current?.querySelector('.selected');

      if (container && selectedElement) {
        if (selectedIndex === 0) {
          container.scrollTo({
            behavior: 'smooth',
            top: 0,
            left: 0,
          });
        } else {
          if (!isElementVisible(selectedElement, container)) {
            const selectedElementRect = selectedElement.getBoundingClientRect();
            const containerRect = container.getBoundingClientRect();
            let top =
              container.scrollTop + selectedElementRect.top - containerRect.top;

            // If first in the list, we have to add some padding
            if (
              grouped.basic?.findIndex((item) => item.id === selected) === 0 ||
              grouped.code?.findIndex((item) => item.id === selected) === 0 ||
              grouped.media?.findIndex((item) => item.id === selected) === 0 ||
              grouped.advanced?.findIndex((item) => item.id === selected) === 0
            ) {
              top -= 48;
            }

            container.scrollTo({
              behavior: 'smooth',
              top,
              left: 0,
            });
          }
        }
      }
    }, [
      selected,
      selectedIndex,
      grouped.basic,
      grouped.code,
      grouped.media,
      grouped.advanced,
    ]);

    return (
      <Paper
        sx={{
          overflow: 'auto',
          border: `0.5px solid ${theme.palette.divider}`,
        }}
        elevation={2}
      >
        <Box
          ref={boxRef}
          className='dashdraft-slash-menu-commands'
          sx={{
            maxHeight: '480px',
            width: '324px',
            overflow: 'hidden',
            overflowY: items.length > 1 ? 'scroll' : 'hidden',
            overflowX: 'hidden',
            overscrollBehavior: 'auto',
            '&::-webkit-scrollbar': {
              width: '8px',
            },
          }}
        >
          {items.length === 0 && (
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='center'
              spacing={1}
              sx={{
                p: 2,
              }}
            >
              <IconMoodSad
                fill={lighten(theme.palette.primary.dark, 0.5)}
                color={theme.palette.primary.dark}
                stroke={2}
                size={36}
              />
              <Typography variant='body1'>Sorry no commands found.</Typography>
            </Stack>
          )}
          <SlashMenuCommandsList
            title='Basic'
            items={grouped.basic ?? []}
            selected={selected}
            onClick={handleClick}
          />
          <SlashMenuCommandsList
            title='Code'
            items={grouped.code ?? []}
            selected={selected}
            onClick={handleClick}
          />
          <SlashMenuCommandsList
            title='Media'
            items={grouped.media ?? []}
            selected={selected}
            onClick={handleClick}
          />
          <SlashMenuCommandsList
            title='Advanced'
            items={grouped.advanced ?? []}
            selected={selected}
            onClick={handleClick}
          />
        </Box>
      </Paper>
    );
  }
);
SlashMenuCommands.displayName = 'SlashMenuCommands';

/*
|------------------
| Utils
|------------------
*/

const isElementVisible = (element: Element, container: Element) => {
  const { bottom, height, top } = element.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  return top <= containerRect.top
    ? containerRect.top - top <= height
    : bottom - containerRect.bottom <= height;
};
