import * as hookz from '@react-hookz/web';
import React from 'react';

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconTags,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@joggrdocs/riker';

import TagChip from '@stargate/components/Chips/components/TagChip';
import NoResultsFeedback from '@stargate/components/Feedback/components/NoResultsFeedback';
import { Page } from '@stargate/components/Utils';

import api from '@stargate/api';
import {
  GitHubRepositoryOwnerChip,
  useGitHubOrganizations,
} from '@stargate/features/github';
import {
  TagSaveDialog,
  type TagSaveDialogProps,
} from '@stargate/features/tags/components/TagSaveDialog';
import { useUser } from '@stargate/features/user';
import { useNotify } from '@stargate/lib/notify';

function ListTagsPage() {
  const authenticatedUser = useUser();
  const ghOrgs = useGitHubOrganizations();
  const [documentTagsState, documentTagsActions] =
    api.useRequestClient('GET /tags');
  const [, createTagActions] = api.useRequestClient('POST /tags');
  const notify = useNotify();
  const [open, setOpen] = React.useState(false);
  const [saving, setSaving] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    setSaving(false);
  };

  const handleSave: TagSaveDialogProps['onSave'] = (documentTag) => {
    if (
      documentTagsState.result?.find(
        (docTag) => docTag.name === documentTag.name
      )
    ) {
      notify.error('Document tag already exists.');
      return;
    }

    setSaving(true);
    createTagActions
      .execute({
        body: documentTag,
      })
      .then(() => {
        setSaving(false);
        setOpen(false);
        documentTagsActions.execute();
      })
      .catch(() => {
        setSaving(false);
        setOpen(false);
        notify.error('Error creating document tag. Please try again.');
      });
  };

  hookz.useMountEffect(() => {
    void ghOrgs.load();
    void documentTagsActions.execute();
  });

  return (
    <Page
      id='document-tags'
      gutter
      loading={
        documentTagsState.status !== 'success' || authenticatedUser.loading
      }
      renderProps={{
        docTags: documentTagsState.result,
        user: authenticatedUser.data,
      }}
      render={({ docTags, user }) => (
        <React.Fragment>
          <TagSaveDialog
            open={open}
            saving={saving}
            onSave={handleSave}
            onClose={handleClose}
          />
          <Card>
            <CardHeader
              avatar={<IconTags size={24} />}
              title='Document Tags'
              titleTypographyProps={{
                variant: 'h5',
              }}
              action={
                <Button
                  color='success'
                  size='small'
                  variant='contained'
                  text='Create Tag'
                  startIcon={<IconTags size={16} />}
                  onClick={() => {
                    setOpen(true);
                  }}
                  disabled={ghOrgs.data.length === 0}
                />
              }
            />
            <CardContent>
              {docTags.length === 0 && <NoResultsFeedback />}
              {docTags.length > 0 && (
                <TableContainer>
                  <Table>
                    {docTags.map((docTag) => {
                      return (
                        <TableRow key={docTag.name}>
                          <TableCell>
                            <TagChip tag={docTag} size='medium' />
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant='body1'
                              color={
                                !docTag.description
                                  ? 'text.disabled'
                                  : undefined
                              }
                            >
                              {docTag.description ?? 'No description provided'}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <GitHubRepositoryOwnerChip
                              owner={
                                // biome-ignore lint/style/noNonNullAssertion: this SHOULD be safe
                                ghOrgs.data.find(
                                  (x) =>
                                    x.id.toString() === docTag.repositoryOwnerId
                                )?.name!
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <Stack
                              direction='row'
                              spacing={1}
                              justifyContent='end'
                            >
                              <Button
                                color='warning'
                                size='medium'
                                text='Edit'
                                disabled
                              />
                              <Button
                                color='warning'
                                size='medium'
                                text='Delete'
                                disabled
                              />
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </Table>
                </TableContainer>
              )}
            </CardContent>
          </Card>
        </React.Fragment>
      )}
    />
  );
}

export default ListTagsPage;
