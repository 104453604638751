import { useGroup } from '@frigade/react';
import React from 'react';

/*
|==========================================================================
| useFrigadeGroup
|==========================================================================
|
| A hook to control Frigade Group,
|
*/

export interface useFrigadeGroupHook {
  /**
   * The group id.
   */
  groupId: string | null;

  /**
   * Set the group id, with properties.
   * @param id The group id.
   * @param properties (optional) The properties to set.
   */
  identify: (id: string, properties?: Record<string, unknown>) => Promise<void>;

  /**
   * Capture an event for a group.
   */
  track: (event: string, properties: Record<string, unknown>) => Promise<void>;
}

/**
 * A wrapper around the Frigade group hook, to prevent re-rendering and tracking null groups.
 */
export const useFrigadeGroup = (): useFrigadeGroupHook => {
  const {
    groupId: frigadeGroupId,
    setGroupId: setFrigadeGroupId,
    track: trackEventForFrigadeGroup,
  } = useGroup();
  /*
  |------------------
  | Data
  |------------------
  */

  const groupId = React.useMemo(() => {
    // If its a guest user, we don't want to track them.
    if (frigadeGroupId?.includes('guest')) {
      return null;
    }
    return frigadeGroupId;
  }, [frigadeGroupId]);

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const identify = React.useCallback(
    async (id: string, properties?: Record<string, unknown>) => {
      await setFrigadeGroupId(id, properties);
    },
    [setFrigadeGroupId]
  );

  const track = React.useCallback(
    async (event: string, properties: Record<string, unknown>) => {
      if (groupId) {
        trackEventForFrigadeGroup(event, properties);
      }
    },
    [trackEventForFrigadeGroup, groupId]
  );

  return {
    groupId,
    identify,
    track,
  };
};
