import { Button, type ButtonProps, IconArrowForward } from '@joggrdocs/riker';
import { FormattedMessage } from '@stargate/localization';
import React from 'react';

export type ViewButtonProps = ButtonProps;

export const ViewButton = React.forwardRef<HTMLButtonElement, ViewButtonProps>(
  (props, ref) => {
    const {
      color = 'primary',
      variant = 'contained',
      children,
      ...restProps
    } = props;
    return (
      <Button
        {...restProps}
        ref={ref}
        startIcon={<IconArrowForward />}
        color={color}
        variant={variant}
      >
        {children || <FormattedMessage id='generic.view' />}
      </Button>
    );
  }
);
ViewButton.displayName = 'ViewButton';
