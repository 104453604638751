import {
  Box,
  Chip,
  FormControl,
  type FormControlProps,
  FormHelperText,
  InputLabel,
  Select,
  type SelectChangeEvent,
  generateComponentClasses,
} from '@joggrdocs/riker';
import _ from 'lodash';
import React from 'react';

import { SelectMenuItem } from '@stargate/components/FormFields';

import type { GitHubRepository } from '../../types';
import { GitHubRepositoryIcon } from '../Icons';

export const githubRepositoryMultiSelectClasses = generateComponentClasses(
  'GitHubRepositoryMultiSelect',
  ['root', 'select']
);

export interface GitHubRepositoryMultiSelectProps extends BaseProps {
  /**
   * The id for the label association
   */
  id: string;

  /**
   * Size of the select
   *
   * @default 'medium'
   */
  size?: 'small' | 'medium';

  /**
   * Label for the select
   */
  label?: React.ReactNode;

  /**
   * Placeholder for the select
   */
  placeholder?: string;

  /**
   * Options to display in the select
   */
  options: GitHubRepository[];

  /**
   * Default value for the select
   */
  defaultValue?: GitHubRepository[];

  /**
   * Text to display below the select
   */
  helperText?: React.ReactNode;

  /**
   * Flag to disable showing the owner name
   */
  hideOwner?: boolean;

  /**
   * Flag to disable the chip icon
   */
  hideIcon?: boolean;

  /**
   * Callback when the select value changes
   *
   * @param data The selected repository(s)
   * @returns A callback when the select value changes
   */
  onChange: (data: GitHubRepository[]) => void;

  /**
   * Callback when the select is closed
   */
  onClose?: () => void;

  /**
   * Callback when the select is opened
   */
  onOpen?: () => void;
}

export const GitHubRepositoryMultiSelect: React.FC<
  GitHubRepositoryMultiSelectProps
> = ({
  id,
  size = 'medium',
  fullWidth = true,
  label = 'GitHub Repositories',
  placeholder = 'Select GitHub Repositories',
  options,
  defaultValue = [],
  onChange,
  onClose,
  onOpen,
  helperText,
  disabled = false,
  hideOwner = false,
  hideIcon = false,
  ...props
}) => {
  const [repoIds, setRepoIds] = React.useState<string[]>(
    _.map(defaultValue, (v) => v.id.toString())
  );

  /*
  |------------------
  | Computed
  |------------------
  */

  const opts = React.useMemo(() => {
    return options.map((repository) =>
      transformToOption(repository, hideOwner)
    );
  }, [options, hideOwner]);

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const handleChange = (repoIds: string[]) => {
    setRepoIds(repoIds);
    onChange(
      _.chain(repoIds)
        .map((id) => options.find((r) => r.id.toString() === id))
        .compact()
        .value()
    );
  };

  /*
  |------------------
  | Effects
  |------------------
  */

  return (
    <FormControl
      {...props}
      size={size}
      classes={{
        root: githubRepositoryMultiSelectClasses.root,
      }}
      fullWidth={fullWidth}
      disabled={disabled}
    >
      <InputLabel id={id}>{label}</InputLabel>
      <Select<string[]>
        classes={{
          root: githubRepositoryMultiSelectClasses.select,
        }}
        labelId={id}
        size={size}
        label={label}
        placeholder={placeholder}
        value={repoIds}
        disabled={disabled}
        onOpen={onOpen}
        onClose={onClose}
        onChange={(event) => {
          const {
            target: { value },
          } = event;
          const repoIds = typeof value === 'string' ? value.split(',') : value;
          handleChange(repoIds);
        }}
        renderValue={(selected) => {
          return _.chain(selected)
            .map((value) => {
              const selectedValue = opts.find((r) => r.value === value);

              if (!_.isNil(selectedValue)) {
                return (
                  <Chip
                    icon={hideIcon ? undefined : <GitHubRepositoryIcon />}
                    label={selectedValue.label}
                    size={size}
                    onMouseDown={(event) => {
                      event.stopPropagation();
                    }}
                    onDelete={() => {
                      const filtered = repoIds.filter((v) => v !== value);
                      handleChange(filtered);
                    }}
                  />
                );
              }
              return null;
            })
            .compact()
            .thru((chips) => {
              return (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {chips}
                </Box>
              );
            })
            .value();
        }}
        multiple
      >
        {opts.map((options) => (
          <SelectMenuItem
            key={options.value}
            value={options.value}
            icon={<GitHubRepositoryIcon />}
            label={options.label}
          />
        ))}
      </Select>
      {!_.isNil(helperText) && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
GitHubRepositoryMultiSelect.displayName = 'GitHubRepositoryMultiSelect';

/*
|------------------
| Utils
|------------------
*/

type BaseProps = Omit<FormControlProps, 'onChange' | 'defaultValue'>;

/**
 * Transform a GitHub repository to an option.
 *
 * @param repository The GitHub repository to transform
 * @param hideOwner Flag to hide the owner name
 * @returns The transformed option
 */
const transformToOption = (
  repository: GitHubRepository,
  hideOwner: boolean
) => ({
  value: repository.id.toString(),
  label: hideOwner
    ? repository.name
    : `${repository.owner.name}/${repository.name}`,
});
