import React from 'react';

/*
|==========================================================================
| useDelayedState
|==========================================================================
|
| This hook is used to delay the state update of a component.
|
*/

/**
 * Delay a state change based on a delay in milliseconds. Primarily used for UI updates,
 * that we want to show a user.
 *
 * @param delay Delay in milliseconds
 * @param defaultValue A default value for the state
 * @returns A "useState" hook with a "setState" function that is delayed by `delay` milliseconds
 */
const useDelayedState = <T>(defaultValue: T) => {
  const [state, setState] = React.useState<T>(defaultValue);

  const handleSetDelayedState = React.useCallback((value: T, delay = 0) => {
    setTimeout(() => {
      setState(value);
    }, delay);
  }, []);

  return [state, setState, handleSetDelayedState] as const;
};

export default useDelayedState;
