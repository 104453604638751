import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  type RikerIconName,
  Typography,
  useTheme,
} from '@joggrdocs/riker';
import React from 'react';
import type * as TypeFest from 'type-fest';

import { IconAvatar } from '@stargate/components/Avatars';
import { generateComponentClasses } from '@stargate/theme';

/*
|==========================================================================
| OnboardingStep
|==========================================================================
|
| Component for displaying the content of a step in the onboarding stepper,
| with a predefined layout.
|
*/

const onboardingStepClasses = generateComponentClasses('OnboardingStep', [
  'root',
  'header',
  'headerTitle',
  'headerIcon',
  'contentTitle',
  'contentDescription',
  'content',
  'action',
  'footer',
] as const);

export interface OnboardingStepItem {
  /**
   * Is the step active.
   */
  active: boolean;

  /**
   * Is the step completed.
   */
  completed: boolean;

  /**
   * Continue to the next step in the onboarding process.
   */
  onContinue: () => Promise<void>;

  /**
   * Skip the onboarding process.
   */
  onSkip?: () => Promise<void>;
}

export type OnboardingContentNode<Props> =
  | string
  | React.ReactNode
  | ((props: Props) => React.ReactNode);

export type OnboardingStepProps = TypeFest.Simplify<
  OnboardingStepItem &
    TypeFest.RequireExactlyOne<
      {
        /**
         * The icon to display in the onboarding step.
         */
        icon?: RikerIconName;

        /**
         * The avatar to display in the onboarding step.
         */
        avatar?: React.ReactNode;

        /**
         * The title of the onboarding step.
         */
        title: string;

        /**
         * The title of the onboarding step.
         */
        contentTitle: string;

        /**
         * The text content of the onboarding step.
         */
        contentDescription: string;

        /**
         * Additional content to display in the onboarding step.
         */
        content?: OnboardingContentNode<OnboardingStepProps>;

        /**
         * Action to take in the onboarding step.
         */
        action?: OnboardingContentNode<OnboardingStepProps>;

        /**
         * Footer content to display in the onboarding step.
         */
        footer?: string | React.ReactNode;
      },
      'icon' | 'avatar'
    >
>;

const renderNode = (
  props: OnboardingStepProps,
  node: OnboardingContentNode<OnboardingStepProps>
) => {
  if (typeof node === 'string') {
    return node;
  }

  if (typeof node === 'function') {
    return node(props);
  }

  return node;
};

export const OnboardingStep: React.FC<OnboardingStepProps> = (props) => {
  const theme = useTheme();
  return (
    <Card
      classes={onboardingStepClasses}
      sx={{
        display: props.active ? 'block' : 'none',

        /*
        |------------------
        | Header
        |------------------
        */

        [`& .${onboardingStepClasses.header}`]: {
          borderBottom: '1px solid',
          borderColor: 'divider',
          mb: 1,
        },
        [`& .${onboardingStepClasses.headerTitle}`]: {
          fontSize: theme.typography.fontSize * 1.75,
          fontWeight: 'bold',
        },
        [`& .${onboardingStepClasses.headerIcon}`]: {
          width: 40,
          height: 40,
        },

        /*
        |------------------
        | Content
        |------------------
        */

        [`& .${onboardingStepClasses.contentTitle}`]: {
          mb: 1,
        },
        [`& .${onboardingStepClasses.contentDescription}`]: {
          mb: 2,
        },

        /*
        |------------------
        | Action
        |------------------
        */

        [`& .${onboardingStepClasses.action}`]: {
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          pb: props.footer ? 0 : 3,
          pt: 0,
        },

        /*
        |------------------
        | Footer
        |------------------
        */

        [`& .${onboardingStepClasses.footer}`]: {
          textAlign: 'center',
          px: 2,
          pt: 1,
          pb: 2,
        },
      }}
    >
      <CardHeader
        classes={{
          root: onboardingStepClasses.header,
          title: onboardingStepClasses.headerTitle,
          avatar: onboardingStepClasses.headerIcon,
        }}
        title={props.title}
        avatar={props.icon ? <IconAvatar icon={props.icon} /> : props.avatar}
        action={
          props.onSkip && (
            <Button
              variant='text'
              onClick={() => {
                props.onSkip?.();
              }}
            >
              Skip
            </Button>
          )
        }
      />
      <CardContent>
        <Typography
          variant='body1'
          fontWeight='bold'
          fontSize={theme.typography.fontSize * 1.25}
          className={onboardingStepClasses.contentTitle}
        >
          {props.contentTitle}
        </Typography>
        <Typography
          variant='body1'
          className={onboardingStepClasses.contentDescription}
        >
          {props.contentDescription}
        </Typography>
        {props.content && (
          <Box component='span' className={onboardingStepClasses.content}>
            {renderNode(props, props.content)}
          </Box>
        )}
      </CardContent>
      {props.action && (
        <CardActions
          classes={{
            root: onboardingStepClasses.action,
          }}
        >
          {renderNode(props, props.action)}
        </CardActions>
      )}
      {props.footer && (
        <Box className={onboardingStepClasses.footer}>
          <Typography variant='caption'>{props.footer}</Typography>
        </Box>
      )}
    </Card>
  );
};
