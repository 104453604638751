import React from 'react';

import { Box, Tooltip } from '@joggrdocs/riker';

export interface FilePathTruncateProps {
  /**
   * The file path to display.
   */
  filePath: string | string[];

  /**
   * The maximum number of characters to display.
   */
  maxCharacters?: number;

  /**
   * Disables the hint when the file path is truncated.
   */
  disableHint?: boolean;
}

export const FilePathTruncate: React.FC<FilePathTruncateProps> = (props) => {
  const filePathParts = getFilePathParts(props.filePath);
  const maxCharacters = props.maxCharacters ?? CHARACTER_MAX_DEFAULT;

  if (shouldTruncate(filePathParts, maxCharacters)) {
    if (props.disableHint === true) {
      return truncateFilePath(filePathParts, maxCharacters);
    }
    return (
      <Tooltip title={filePathParts.join('/')}>
        <Box component='span' sx={{ cursor: 'pointer' }}>
          {truncateFilePath(filePathParts, maxCharacters)}
        </Box>
      </Tooltip>
    );
  }
  return filePathParts.join('/');
};

export default FilePathTruncate;

const CHARACTER_MAX_DEFAULT = 60;

/*
|------------------
| Utils
|------------------
*/

function shouldTruncate(
  filePathParts: string[],
  maxCharacters: number
): boolean {
  return filePathParts.join('/').length > maxCharacters;
}

function getFilePathParts(filePath: string | string[]): string[] {
  if (typeof filePath === 'string') {
    return filePath.split('/');
  }
  return filePath;
}

function truncateFilePath(
  filePathParts: string[],
  maxCharacters: number
): string {
  const firstPart = filePathParts[0];
  const lastPart = filePathParts[filePathParts.length - 1];

  const value = `${firstPart}/.../${lastPart}`;

  if (value.length > maxCharacters) {
    if (firstPart.length > lastPart.length) {
      return `${firstPart.slice(0, 5)}.../${lastPart}`;
    }
    return `${firstPart}/.../...${lastPart.slice(lastPart.length - 5, lastPart.length)}`;
  }
  return value;
}
