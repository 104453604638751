import React from 'react';

import { Box } from '@joggrdocs/riker';

import SirenaHeader from './SirenaHeader';
import { SirenaSplitEditor } from './SirenaSplitEditor';

export interface SirenaEditorProps {
  defaultGraphCode?: string;
  onGraphCodeChange: (graphCode: string) => void;
  rightAction?: React.ReactNode;
}

export const SirenaEditor: React.FC<SirenaEditorProps> = (props) => {
  return (
    <Box>
      <SirenaHeader rightAction={props.rightAction} />
      <SirenaSplitEditor
        defaultGraphCode={props.defaultGraphCode}
        onGraphCodeChange={props.onGraphCodeChange}
      />
    </Box>
  );
};

const MemoizedSirenaEditor = React.memo(SirenaEditor);
MemoizedSirenaEditor.displayName = 'SirenaEditor';

export default MemoizedSirenaEditor;
