import React from 'react';

import { Box, LinearProgress, Typography } from '@joggrdocs/riker';

import { generateComponentClasses } from '@stargate/theme';

/*
|==========================================================================
| LinearProgressWithLabel
|==========================================================================
|
| Component for Linear Progress With Label (i.e. %)
|
*/

export const linearProgressWithLabelClasses = generateComponentClasses(
  'LinearProgressWithLabel',
  ['root'] as const
);

export interface LinearProgressWithLabelProps {
  percent: number;
}

/**
 * Component for Linear  (i.e. %)
 */
export const LinearProgressWithLabel: React.FC<LinearProgressWithLabelProps> = (
  props
) => {
  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center' }}
      className={linearProgressWithLabelClasses.root}
    >
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          {...props}
          variant='determinate'
          value={props.percent}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant='body2'
          color='text.secondary'
        >{`${Math.round(props.percent)}%`}</Typography>
      </Box>
    </Box>
  );
};
