import _ from 'lodash';
import React from 'react';
import * as ReactRouter from 'react-router-dom';

import { type RouteNames, useRoute } from './use-route';

/*
|==========================================================================
| useParams
|==========================================================================
|
| Type-safe version of React Router's useParams hook.
|
*/

/**
 * Get typed params from a route string.
 *
 * ```tsx
 * const { id } = useParams('app.users.view');
 * ```
 */
export const useParams = <Name extends RouteNames>(routeName: Name) => {
  const route = useRoute(routeName);
  const params = ReactRouter.useParams();

  const result = React.useMemo(() => {
    return route.params(params);
  }, [params, route]);

  return result;
};
