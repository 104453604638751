import qs from 'qs';
import React from 'react';
import * as ReactRouter from 'react-router-dom';

import {
  type RouteNames,
  type RouteParams,
  type RouteSearch,
  getUrl,
} from './use-route';

/*
|==========================================================================
| useLocation 
|==========================================================================
|
| Type-safe wrapper around react-router's useLocation hook.
|
*/

export interface Location<N extends RouteNames> extends ReactRouter.Location {
  active: <R extends RouteNames = N>(
    routeName: R,
    params?: RouteParams<R>,
    search?: RouteSearch<R>
  ) => boolean;
  query: RouteSearch<N>;
}

export const useLocation = (): Location<RouteNames> => {
  const location = ReactRouter.useLocation();

  /**
   * Check if the current location is active using the route name.
   */
  const active = React.useCallback(
    <R extends RouteNames>(
      routeName: R,
      params?: RouteParams<R>,
      search?: RouteSearch<R>
    ) => {
      if (!routeName) {
        return false;
      }
      const url = getUrl(routeName, params, search);
      return location.pathname === url;
    },
    [location]
  );

  return React.useMemo(
    () => ({
      ...location,
      active,
      query: (location.search
        ? qs.parse(location.search)
        : {}) as unknown as RouteSearch<RouteNames>,
    }),
    [location, active]
  );
};
