import React from 'react';
import {
  TransformComponent,
  TransformWrapper,
  useControls,
} from 'react-zoom-pan-pinch';

import {
  Box,
  Button,
  type ButtonProps,
  Divider,
  IconFocusCentered,
  IconMinus,
  IconPlus,
  Stack,
} from '@joggrdocs/riker';

/*
|==========================================================================
| PanZoom
|==========================================================================
|
| Pan & Zoom images, SVGs and other items. 
|
*/

/*
|------------------
| Internal Components
|------------------
*/

const PanZoomDefaultControls = () => {
  const { zoomIn, zoomOut, centerView, resetTransform } = useControls();

  /*
  |------------------
  | Computed
  |------------------
  */

  const buttonIconProps = {
    size: 16,
  };

  const buttonProps: ButtonProps = {
    variant: 'contained',
    color: 'info',
    size: 'small',
    sx: {
      pl: 1,
      pr: 1,
      minWidth: 0,
    },
  };

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const handleZoomIn = React.useCallback(() => {
    zoomIn();
  }, [zoomIn]);

  const handleZoomOut = React.useCallback(() => {
    zoomOut();
  }, [zoomOut]);

  const handleCenter = React.useCallback(() => {
    centerView();
    resetTransform();
  }, [centerView, resetTransform]);

  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: (theme) => theme.spacing(1),
        right: (theme) => theme.spacing(1),
        zIndex: 1,
        p: 0.75,
        bgcolor: (theme) =>
          theme.palette.mode === 'dark'
            ? theme.palette.grey[800]
            : theme.palette.grey[100],
        border: (theme) => `1px solid ${theme.palette.divider}`,
        borderRadius: '4px',
      }}
    >
      <Stack direction='column' spacing={1}>
        <Button {...buttonProps} onClick={handleCenter}>
          <IconFocusCentered {...buttonIconProps} />
        </Button>
        <Divider flexItem />
        <Button {...buttonProps} onClick={handleZoomIn}>
          <IconPlus {...buttonIconProps} />
        </Button>
        <Button {...buttonProps} onClick={handleZoomOut}>
          <IconMinus {...buttonIconProps} />
        </Button>
      </Stack>
    </Box>
  );
};

/*
|------------------
| Public API 
|------------------
*/

export interface Props extends React.PropsWithChildren {
  wrapperStyle?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  controls?: React.ReactNode;
}

export const PanZoom: React.FC<Props> = (props) => {
  return (
    <TransformWrapper
      initialScale={1}
      initialPositionX={0}
      initialPositionY={0}
      limitToBounds={true}
      centerOnInit
    >
      {props.controls || <PanZoomDefaultControls />}
      <TransformComponent
        wrapperStyle={props.wrapperStyle}
        contentStyle={{
          ...props.contentStyle,
          cursor: 'grab',
        }}
      >
        {props.children}
      </TransformComponent>
    </TransformWrapper>
  );
};

export default PanZoom;
