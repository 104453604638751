import type { Extension } from '@codemirror/state';
import { hyperLink } from '@uiw/codemirror-extensions-hyper-link';
import { EditorView } from 'codemirror';

import color from './colors';
import lineNumberClassNames from './line-number-classnames';

/*
|------------------
| Types
|------------------
*/
export type { EventHandlers } from './events';

/*
|------------------
| Extensions
|------------------
*/

export { default as lineNumberOffset } from './line-number-offset';
export { default as highlightEntireLine } from './highlight-entire-line';
export { default as language } from './lang';
export const core: Extension[] = [
  EditorView.lineWrapping,
  hyperLink,
  lineNumberClassNames,
  color,
];
export { default as events } from './events';
