import React from 'react';

/*
|==========================================================================
| useStaticUrls
|==========================================================================
|
| Static URLs for use in the app (in future we can load from Server OR integrate with Configs)
|
*/

export const useStaticUrls = () => {
  return React.useMemo(
    () => ({
      marketingSite: 'https://joggr.io',
      statusPageSite: 'https://status.joggr.io',
      privacyPolicy: 'https://www.joggr.io/legal/privacy',
      termsOfService: 'https://www.joggr.io/legal/terms-conditions',
      supportEmail: 'mailto:support@joggr.io',
      securityVulnerabilityForm: 'https://forms.gle/thoRgXDCRY2Miz1E6',
    }),
    []
  );
};

export default useStaticUrls;
