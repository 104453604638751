import { Extension } from '@tiptap/core';

import codeBlock from './extensions/code-block';

/*
|------------------
| Types
|------------------
*/
export type {
  CodeBlockType,
  CodeBlockBasicAttributes,
  CodeBlockSnippetAttributes,
} from './types';

/*
|------------------
| Extensions
|------------------
*/

export default Extension.create({
  name: 'dashdraft-codeblock',
  addExtensions() {
    return [codeBlock];
  },
});
