import { Skeleton, Tooltip } from '@joggrdocs/riker';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import React from 'react';

import { jdocVersionsQueryOptions } from '@stargate/api';

import type { JDocComponentProps } from '../../types';
import { JoggrDocVersionChip } from './JoggrDocVersionChip';
import { JoggrDocVersionSelect } from './JoggrDocVersionSelect';

export type JoggrDocVersionDetailsProps = JDocComponentProps;

export const JoggrDocVersionDetails: React.FC<JoggrDocVersionDetailsProps> = ({
  doc,
  draft,
  mode,
}) => {
  const docId = React.useMemo(() => doc?.baseDocumentId ?? doc?.id, [doc]);

  const jdocVersionQuery = useQuery({
    ...jdocVersionsQueryOptions(docId),
    initialData: [],
  });

  if (mode === 'create') {
    return (
      <Tooltip
        title='You are creating a JoggrDoc, the version is not available yet, and will be set when you commit the doc.'
        arrow
        placement='bottom'
      >
        <JoggrDocVersionChip
          version={doc?.github?.branch ?? 'latest'}
          sx={{
            px: 1,
            height: '36px',
          }}
        />
      </Tooltip>
    );
  }

  if (
    (jdocVersionQuery.isLoading || jdocVersionQuery.isPending) &&
    !jdocVersionQuery.isPlaceholderData
  ) {
    return (
      <Skeleton
        variant='rounded'
        sx={{
          height: '36px',
          width: '200px',
        }}
      />
    );
  }

  return (
    <JoggrDocVersionSelect
      doc={doc}
      draft={draft}
      mode={mode}
      versions={jdocVersionQuery.data}
    />
  );
};
