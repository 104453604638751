import {
  type CustomContentProps,
  SnackbarContent,
  useSnackbar,
} from 'notistack';
import React from 'react';

import { Alert, AlertTitle } from '@joggrdocs/riker';

export type NotificationProps = CustomContentProps;

export const Notification = React.forwardRef<HTMLDivElement, NotificationProps>(
  (props, ref) => {
    const { closeSnackbar } = useSnackbar();

    const handleClose = React.useCallback(() => {
      closeSnackbar(props.id);
    }, [props.id, closeSnackbar]);

    return (
      <SnackbarContent ref={ref}>
        <Alert
          severity={props.variant === 'default' ? 'info' : props.variant}
          elevation={1}
          sx={{
            maxWidth: '400px',
          }}
          onClose={handleClose}
        >
          {props.title && <AlertTitle>{props.title}</AlertTitle>}
          {props.message}
        </Alert>
      </SnackbarContent>
    );
  }
);
