import { createLocale } from '../../lib/locale-factory';

export default createLocale('generic', {
  save: 'Save',
  confirm: 'Confirm',
  cancel: 'Cancel',
  delete: 'Delete',
  edit: 'Edit',
  next: 'Next',
  previous: 'Previous',
  search: 'Search',
  close: 'Close',
  view: 'View',
  add: 'Add',
  remove: 'Remove',
  create: 'Create',
});
