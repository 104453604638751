import React from 'react';

import {
  Box,
  IconFilePlus,
  Stack,
  Typography,
  useTheme,
} from '@joggrdocs/riker';

import { ButtonLink } from '@stargate/components/Buttons';
import { Img } from '@stargate/components/Images';

import type { ShortcutItem } from '../types';

export type BlankDocumentProps = ShortcutItem;

const BlankDocument: React.FC<BlankDocumentProps> = (props) => {
  const theme = useTheme();

  return (
    <Stack spacing={2} direction='row' alignItems='flex-start'>
      <Box sx={{ minWidth: '364px' }}>
        <Typography variant='h3' gutterBottom>
          Create a Blank Document
        </Typography>
        <Typography variant='body1' gutterBottom>
          Create a new document by starting from scratch.
        </Typography>
        <Box sx={{ mb: 2 }} />
        <ButtonLink
          startIcon={<IconFilePlus />}
          variant='contained'
          onClick={props.onClose}
          to='app.documents.create'
          search={{
            blank: true,
            dir: props.meta?.directoryId ?? 'root',
          }}
        >
          Create a new doc
        </ButtonLink>
      </Box>
      <Box sx={{ width: '100%' }}>
        <Img
          src={`/features/shortcuts/examples-blank-${theme.palette.mode}.png`}
          alt='Blank Doc'
        />
      </Box>
    </Stack>
  );
};

export default BlankDocument;
