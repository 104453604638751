import _ from 'lodash';
import React from 'react';

import { useStorage } from './use-storage';

export interface UseStorageItemHook<V> {
  /**
   * The key of the storage item.
   */
  key: string;

  /**
   * The current value of the storage item.
   */
  data: V | null;

  /**
   * The timestamp of the last update.
   */
  updatedAt: Date | null;

  /**
   * The timestamp of the first update.
   */
  createdAt: Date | null;

  /**
   * Check if the storage item has expired.
   *
   * @param ttl The time-to-live in milliseconds.
   */
  expired: (ttl: number) => boolean;

  /**
   * Set the value of the storage item.
   */
  set: (value: Value<V>) => void;

  /**
   * Remove the storage item.
   */
  remove: () => void;
}

/**
 * A hook for saving data to browser storage and accessing it via a hook interface.
 *
 * @param key The key to use for the item.
 * @param defaultValue The default value to use if the storage item is not set.
 */
export const useStorageItem = <V = unknown>(
  key: string,
  defaultValue?: V
): UseStorageItemHook<V> => {
  const storage = useStorage<V>();

  // biome-ignore lint/correctness/useExhaustiveDependencies: we only want to update the state when the key changes or defaultValue changes
  const state = React.useMemo(() => {
    const k = storage.createKey(key);
    return {
      key: k,
      data: storage.data[k]?.value ?? defaultValue ?? null,
      updatedAt: storage.data[k]?.updatedAt ?? null,
      createdAt: storage.data[k]?.createdAt ?? null,
    };
  }, [storage.data, key, defaultValue]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: we only want to update the actions when the key changes or defaultValue changes
  const actions = React.useMemo<Actions<V>>(() => {
    return {
      set: (value): void => {
        const calculatedValue = getValue(
          value,
          storage.get(key) ?? defaultValue ?? null
        );
        storage.set(key, calculatedValue);
      },
      remove: (): void => {
        storage.remove(key);
      },
      expired: (ttl: number) => {
        return storage.expired(key, ttl);
      },
    };
  }, [defaultValue, key]);

  return React.useMemo(() => ({ ...state, ...actions }), [actions, state]);
};

/*
|------------------
| Utils
|------------------
*/

type Actions<V> = Omit<
  UseStorageItemHook<V>,
  'data' | 'createdAt' | 'updatedAt' | 'key'
>;

type Value<V> = V | ((previousValue: V | null) => V);

/**
 * Get a value from a value or a function that returns a value.
 *
 * @param getter A value or a function that returns a value.
 * @param previousValue A previous value to pass to the getter function.
 * @returns A value.
 */
const getValue = <V>(getter: Value<V>, previousValue: V | null): V => {
  if (_.isFunction(getter)) {
    return getter(previousValue);
  }

  return getter;
};
