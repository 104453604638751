import _ from 'lodash';
import type * as TypeFest from 'type-fest';

/**
 * Create a locale object with a prefix and configuration.
 *
 * @param prefix The prefix for the locale.
 * @param config The configuration for the locale.
 * @returns The locale object.
 */
export const createLocale = <
  Prefix extends string,
  Config extends Record<string, string>,
>(
  prefix: Prefix,
  config: Config
): Locale<Prefix, Config> => {
  return _.mapKeys(
    config,
    (_value, key) => `${prefix}.${key}`
  ) as unknown as Locale<Prefix, Config>;
};

export type Locale<
  Prefix extends string,
  Config extends Record<string, string>,
> = Record<`${Prefix}.${TypeFest.StringKeyOf<Config>}`, string>;
