import React from 'react';

import { Chip } from '@joggrdocs/riker';

import type { MermaidGraphType } from '../lib/mermaidUtils';

/*
|==========================================================================
| SirenaGraphTypeChip
|==========================================================================
|
| Simply chip to display the graph type.
|
*/

export interface Props {
  graphType: MermaidGraphType;
}

export const SirenaGraphTypeChip: React.FC<Props> = (props) => {
  return (
    <Chip
      size='small'
      label={
        <React.Fragment>
          <strong>type:</strong> {props.graphType}
        </React.Fragment>
      }
    />
  );
};

const MemoizedSirenaGraphTypeChip = React.memo(SirenaGraphTypeChip);
MemoizedSirenaGraphTypeChip.displayName = 'SirenaGraphTypeChip';

export default MemoizedSirenaGraphTypeChip;
