import Placeholder from '@tiptap/extension-placeholder';

export const placeholderClasses = {
  editor: 'dashdraft-editor-placeholder',
  node: 'dashdraft-node-placeholder',
};

export default Placeholder.configure({
  emptyEditorClass: `is-editor-empty ${placeholderClasses.editor}`,
  emptyNodeClass: `is-empty ${placeholderClasses.node}`,
  showOnlyWhenEditable: true,
  showOnlyCurrent: false,
  includeChildren: false,
  placeholder: 'Start typing...',
});
