import { useDashdraftContext } from './use-dashdraft-context';

export const useDashDraftEditor = () => {
  const { editor } = useDashdraftContext();

  if (!editor) {
    throw new Error(
      'DashDraft editor not found. Make sure you are using the `DashDraftProvider`.'
    );
  }

  return editor;
};
