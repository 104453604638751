import { createLocale } from '@stargate/localization/lib/locale-factory';

export default createLocale('settings', {
  title: 'Account Settings',
  'profile.title': 'User Profile',
  'profile.description': 'Manage your profile information',
  'config.title': 'Configuration',
  'config.description': 'Configure how Joggr works for you',
  'appearance.title': 'Appearance',
  'appearance.description': 'Customize how the app looks to you',
  'workspaces.title': 'Workspaces',
  'workspaces.description': 'Manage your workspace settings',
  'security.title': 'Security',
  'security.description': 'Manage your security settings',
});
