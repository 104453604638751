import React from 'react';
import {
  type HotkeyCallback,
  type Options,
  useHotkeys as useBaseHotKeysHook,
} from 'react-hotkeys-hook';

import useDetectOS, { type OperatingSystem } from './use-detect-os';

/*
|==========================================================================
| useHotkeys
|==========================================================================
|
| This hook is a wrapper around react-hotkeys-hook that adds a hint to the
| hotkey string that is OS-aware.
|
*/

const mapping = (os: OperatingSystem) => ({
  enter: 'Enter',
  escape: 'Esc',
  alt: os === 'mac' ? '⌥' : 'Alt',
  shift: 'Shift',
  mod: os === 'mac' ? '⌘' : 'Ctrl',
});

export const useHotkeys = <El extends HTMLElement = HTMLElement>(
  keys: string,
  cb: HotkeyCallback,
  optionsOrDeps?: React.DependencyList | Options,
  deps?: React.DependencyList
) => {
  const ref = useBaseHotKeysHook<El>(keys, cb, optionsOrDeps, deps);
  const os = useDetectOS();

  /*
  |------------------
  | Computed
  |------------------
  */

  const keyMap = React.useMemo(() => mapping(os), [os]);

  const hint = React.useMemo(() => {
    const splitKeys = keys.toLowerCase().split('+');
    const outputKeys: string[] = [];
    const includesShift = splitKeys.includes('shift');

    for (const splitKey of splitKeys) {
      const cleanedKey = splitKey.toLowerCase().trim();
      const foundKey = keyMap[cleanedKey as keyof typeof keyMap];
      if (foundKey) {
        outputKeys.push(foundKey);
      } else {
        outputKeys.push(
          // We force other keys that include "shift" to uppercase, i.e. "⇧+E"
          includesShift && !!splitKey.match(/[a-z]+/g)
            ? splitKey.toUpperCase()
            : splitKey
        );
      }
    }

    return outputKeys.join('+');
  }, [keyMap, keys]);

  return React.useMemo(() => ({ ref, hint }), [ref, hint]);
};

export default useHotkeys;
