import { LocalStorage } from '@/lib/storage';

export const auth0JwtCache = {
  /**
   * Get the JWT token from browser storage.
   *
   * @returns The JWT token
   */
  get() {
    return LocalStorage.get<string>(key);
  },

  /**
   * Set the JWT token in browser storage.
   *
   * @param token A valid access token
   */
  set(token: string) {
    LocalStorage.set(key, token);
  },

  /**
   * Remove the JWT token from browser storage.
   */
  remove() {
    LocalStorage.remove(key);
  },
};

const key = 'auth0:token';
