import React from 'react';

import {
  type MermaidGraphType,
  detectGraphType,
  getExampleGraphDefinition,
} from '../lib/mermaidUtils';

/*
|==========================================================================
| useMermaidDocs
|==========================================================================
|
| A hook that returns the documentation url for a given mermaid graph type.
|
*/

const getDocumentationUrlFromGraphType = (type: MermaidGraphType) => {
  switch (type) {
    case 'flowchart':
    case 'flowchart-v2':
      return 'https://mermaid.js.org/syntax/flowchart.html';
    case 'sequenceDiagram':
    case 'sequence':
      return 'https://mermaid.js.org/syntax/sequenceDiagram.html';
    case 'gantt':
      return 'https://mermaid.js.org/syntax/gantt.html';
    case 'classDiagram':
    case 'class':
      return 'https://mermaid.js.org/syntax/classDiagram.html';
    case 'stateDiagram-v2':
    case 'stateDiagram':
    case 'state':
      return 'https://mermaid.js.org/syntax/stateDiagram.html';
    case 'quadrantChart':
      return 'https://mermaid.js.org/syntax/quadrantChart.html';
    case 'pie':
      return 'https://mermaid.js.org/syntax/pie.html';
    case 'erDiagram':
    case 'er':
      return 'https://mermaid.js.org/syntax/entityRelationshipDiagram.html';
    case 'journey':
      return 'https://mermaid.js.org/syntax/userJourney.html';
    case 'requirementDiagram':
    case 'requirement':
      return 'https://mermaid.js.org/syntax/requirementDiagram.html';
    case 'git':
    case 'gitGraph':
      return 'https://mermaid.js.org/syntax/gitgraph.html';
    default:
      return 'https://mermaid.js.org/intro/n00b-syntaxReference.html';
  }
};

export const useMermaidDocs = (defaultType?: MermaidGraphType) => {
  const [type, setType] = React.useState<MermaidGraphType | null>(
    defaultType ?? null
  );

  /*
  |------------------
  | Computed
  |------------------
  */

  const typeName = React.useMemo(() => {
    if (!type) return null;
    const exampleDefinition = getExampleGraphDefinition(type);

    if (exampleDefinition) {
      return exampleDefinition.name;
    }
    return null;
  }, [type]);

  const documentationUrl = React.useMemo(() => {
    return type ? getDocumentationUrlFromGraphType(type) : null;
  }, [type]);

  /*
  |------------------
  | Handlers
  |------------------
  */

  const getUrlFromType = React.useCallback((newType: MermaidGraphType) => {
    return getDocumentationUrlFromGraphType(newType);
  }, []);

  const updateType = React.useCallback((newType: MermaidGraphType) => {
    setType(newType);
  }, []);

  const updateTypeFromGraph = React.useCallback((graph: string) => {
    const graphType = detectGraphType(graph);
    setType(graphType);
  }, []);

  return {
    update: updateType,
    updateFromGraphCode: updateTypeFromGraph,
    type,
    getUrlFromType,
    name: typeName,
    url: documentationUrl,
  };
};

export default useMermaidDocs;
