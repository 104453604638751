import React from 'react';
import type * as TypeFest from 'type-fest';

import {
  Box,
  RikerIcon,
  Stack,
  Tooltip,
  Typography,
  generateComponentClasses,
  useTheme,
} from '@joggrdocs/riker';

import { useNavigate } from '@stargate/routes';

import { useSideBar } from '@stargate/layouts';
import type { DirectoryTreeNode } from '../../types';
import {
  ActionButton,
  DirectoryCreateAction,
  DocumentAction,
} from '../Actions';
import { DirectoryActions } from '../Actions/DirectoryActions';

/*
|==========================================================================
| DirectoryTreeItemLabel
|==========================================================================
|
| A tree view item label of the directory structure, used for navigation. 
|
*/

/*
|------------------
| Utils: Components
|------------------
*/

const RepositoryChip = React.memo<{
  repositoryName: string;
  repositoryOwnerName: string;
}>(({ repositoryName, repositoryOwnerName }) => {
  const theme = useTheme();
  const [sideBar] = useSideBar();

  if (sideBar.drawerWidth < 300) {
    return null;
  }
  return (
    <Stack
      direction='row'
      spacing={0.25}
      alignItems='center'
      sx={{
        fontSize: '10px',
        padding: '0px 4px',
        mt: '2px',
        mb: '2px',
        border: '1px solid',
        mr: 0.5,
        borderColor: theme.utils.modeValue({
          dark: theme.palette.grey[700],
          light: theme.palette.grey[300],
        }),
        borderRadius: '12px',
        bgcolor: theme.utils.modeValue({
          dark: theme.palette.grey[800],
          light: theme.palette.grey[200],
        }),
        color: theme.palette.text.primary,
      }}
    >
      <RikerIcon name='notebook' size={16} />
      <Box component='span'>{repositoryName}</Box>
    </Stack>
  );
});
RepositoryChip.displayName = 'RepositoryChip';

/*
|------------------
| Public API
|------------------
*/

export const directoryTreeItemLabelClasses = generateComponentClasses(
  'DirectoryTreeItemLabel',
  ['root', 'actions'] as const
);

export type DirectoryTreeItemLabelProps = TypeFest.Simplify<{
  node: DirectoryTreeNode;
  hintDisabled?: boolean;
  showActions?: boolean;
  showRepositoryInfo?: boolean;
}>;

export const DirectoryTreeItemLabel: React.FC<DirectoryTreeItemLabelProps> = ({
  node,
  hintDisabled = false,
  showActions = false,
  showRepositoryInfo = false,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const handleClickEdit = React.useCallback(() => {
    navigate('app.documents.edit', {
      params: {
        id: node.id,
      },
    });
  }, [navigate, node.id]);

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpen = React.useCallback(() => {
    if (!hintDisabled) {
      setOpen(true);
    }
  }, [hintDisabled]);

  const handlePreventActionBubbling = React.useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault();
      event.stopPropagation();
      setOpen(false);
    },
    []
  );

  return (
    <Box className={directoryTreeItemLabelClasses.root}>
      <Tooltip
        title={
          <Stack direction='column'>
            <Stack direction='row' spacing={0.5}>
              <Typography
                variant='h6'
                sx={{
                  color: 'inherit',
                }}
              >
                {node.title}
              </Typography>
            </Stack>
            {node.summary && (
              <Typography
                variant='subtitle2'
                sx={{
                  color: 'inherit',
                }}
              >
                {node.summary}
              </Typography>
            )}
          </Stack>
        }
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        enterDelay={800}
        enterNextDelay={1000}
        placement={'right'}
        disableInteractive
        arrow
      >
        <Stack
          direction='row'
          alignItems='center'
          justifyItems='flex-start'
          spacing={0}
        >
          <Box
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              width: '100%',
            }}
          >
            {node.title}
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Stack
            className={directoryTreeItemLabelClasses.actions}
            direction='row'
            spacing={0}
            onClick={handlePreventActionBubbling}
            onMouseOver={handlePreventActionBubbling}
            onMouseOut={handlePreventActionBubbling}
            onFocus={handlePreventActionBubbling}
          >
            {node.nodeType === 'directory' && (
              <React.Fragment>
                <DirectoryCreateAction directory={node} size='xsmall' />
                <DocumentAction directory={node} size='xsmall' />
                <DirectoryActions directory={node} />
              </React.Fragment>
            )}
            {node.nodeType === 'document' && showRepositoryInfo && (
              <RepositoryChip
                repositoryName={node.repositoryName}
                repositoryOwnerName={node.repositoryOwnerName}
              />
            )}
            {node.nodeType === 'document' && (
              <React.Fragment>
                <ActionButton
                  size='xsmall'
                  hint='Edit document'
                  icon='edit'
                  onClick={handleClickEdit}
                />
              </React.Fragment>
            )}
          </Stack>
        </Stack>
      </Tooltip>
    </Box>
  );
};
