import { type AsyncStatus, useAsyncAbortable } from '@react-hookz/web';
import _ from 'lodash';

import {
  buildAPIRequestData,
  buildAPIRequestHeaders,
  buildAPIRequestURL,
  getAPIRequestMethod,
} from '@stargate/api/lib/utils';
import type {
  APIParams,
  APIPayload,
  APIResponse,
  APIRoute,
} from '@stargate/api/types';
import { useHTTPClient } from '@stargate/lib/http';
import type { HasRequiredKeys } from 'type-fest';

/**
 * API Client Hook for the Joggr API.
 *
 * Example:
 *
 * ```ts
 * const [readDocsState, readDocsActions] = useJoggrAPIClient('GET /documents/:id');
 * const [listDocsState, listDocsActions] = useJoggrAPIClient('GET /documents');
 * const [createDocsState, createDocsActions] = useJoggrAPIClient('POST /documents');
 *
 * hookz.useMountEffect(() => {
 *   readDocsActions.execute({
 *     params: {
 *     id: 'd383393d-fe39-4a3b-a063-e66c3382b6b7'
 *   });
 *   // OR
 *   listDocsActions.execute({
 *     query: {
 *       filter: 'name="My Document"',
 *     },
 *   });
 *   // OR
 *   createDocsActions.execute({
 *     body: {
 *       name: 'My Document',
 *     },
 *   });
 * });
 * ```
 *
 * @deprecated Use 'tanstack/react-query' instead.
 * @param schemaName A schema name, in the format of `METHOD /path/to/resource`, e.g. `GET /documents`.
 * @returns A built API client.
 */
export const useAPIRequestClient = <R extends APIRoute>(schemaName: R) => {
  const { fetchAuthorized } = useHTTPClient();
  return useAsyncAbortable<APIResponse<R>, [Payload<R>]>(
    async (_signal, payload) => {
      return await fetchAuthorized<APIResponse<R>>(
        buildAPIRequestURL<R>(
          schemaName,
          _.get(payload, 'params') as APIParams<R>
        ),
        {
          method: getAPIRequestMethod<R>(schemaName),
          data: buildAPIRequestData<R>(payload as APIPayload<R>),
          headers: buildAPIRequestHeaders<R>(payload as APIPayload<R>),
        }
      );
    }
  );
};

/**
 * The status of the API Client Hook for the Joggr API.
 *
 * @deprecated Use `useQuery` instead.
 */
export type UseApiClientHookStatus = AsyncStatus;

/*
|------------------
| Utils
|------------------
*/

type Payload<R extends APIRoute> = HasRequiredKeys<APIPayload<R>> extends true
  ? APIPayload<R>
  : // biome-ignore lint/suspicious/noConfusingVoidType: We need this for the API client.
    APIPayload<R> | void;
