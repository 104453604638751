import { create } from 'zustand';

/*
|==========================================================================
| Search Hook
|==========================================================================
|
| A global store, used to manage the search components.
|
*/

/**
 * Manage the search dialog state.
 */
export const useSearch = (): [SearchStoreState, SearchStoreActions] => {
  return useStore(({ state, actions }) => [state, actions]);
};

interface SearchStoreState {
  dialogOpen: boolean;
}

interface SearchStoreActions {
  closeDialog: () => void;
  openDialog: () => void;
}

interface SearchStore {
  state: SearchStoreState;
  actions: SearchStoreActions;
}

const useStore = create<SearchStore>((set) => ({
  state: {
    dialogOpen: false,
    filter: null,
  },
  actions: {
    closeDialog: () => {
      set(({ state }) => ({
        state: {
          ...state,
          showReward: false,
          dialogOpen: false,
        },
      }));
    },
    openDialog: () => {
      set(({ state }) => ({
        state: {
          ...state,
          dialogOpen: true,
        },
      }));
    },
  },
}));
