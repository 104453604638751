import React from 'react';

import {
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  RikerIcon,
  menuClasses,
  useTheme,
} from '@joggrdocs/riker';

import { componentClassFactory } from '@stargate/theme';

import type { ActionItem } from '../types';

/*
|==========================================================================
| ActionsMenuProps
|==========================================================================
|
| Display a menu of actions.
|
*/

const cn = componentClassFactory('ActionsMenu');
export const actionsMenuClasses = {
  ...menuClasses,
  root: cn('root'),
  actionButton: cn('item-button'),
  actionDivider: cn('item-divider'),
  item: cn('item'),
  itemIcon: cn('item-icon'),
  itemContent: cn('item-content'),
} as const;

export interface ActionsMenuProps<A extends string> {
  open: boolean;
  anchorEl: null | HTMLElement;
  size?: 'small' | 'medium';
  onClose: () => void;
  onAction: (action: A) => void;
  items: Array<ActionItem<A>>;
}

export const ActionsMenu = <A extends string>(props: ActionsMenuProps<A>) => {
  const theme = useTheme();

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const createActionHandler = (action: A) => () => {
    props.onAction(action);
    props.onClose();
  };

  return (
    <Menu
      anchorEl={props.anchorEl}
      open={props.open}
      onClose={props.onClose}
      className={actionsMenuClasses.root}
      disablePortal
      disableScrollLock
      slotProps={{
        paper: {
          sx: {
            minWidth: props.size === 'small' ? undefined : '280px',
          },
        },
      }}
      disableRestoreFocus
      MenuListProps={{
        dense: props.size === 'small',
      }}
    >
      {props.items.map((item, index) => {
        if (item.type === 'divider') {
          return (
            <Divider
              // biome-ignore lint/suspicious/noArrayIndexKey: This is a divider and does not need a key to re-render
              key={index}
              flexItem
              className={theme.utils.classNames([
                actionsMenuClasses.actionDivider,
                actionsMenuClasses.item,
              ])}
            />
          );
        }
        return (
          <MenuItem
            dense={props.size === 'small'}
            key={item.action}
            onClick={createActionHandler(item.action)}
            disabled={item.disabled ?? false}
            className={theme.utils.classNames([
              actionsMenuClasses.actionButton,
              actionsMenuClasses.item,
            ])}
          >
            <ListItemIcon className={actionsMenuClasses.itemIcon}>
              {typeof item.icon === 'string' ? (
                <RikerIcon
                  name={item.icon}
                  size={props.size === 'small' ? 18 : undefined}
                />
              ) : (
                <item.icon size={props.size === 'small' ? 18 : undefined} />
              )}
            </ListItemIcon>
            <ListItemText
              className={actionsMenuClasses.itemContent}
              primary={item.primaryLabel}
              secondary={item.secondaryLabel}
            />
          </MenuItem>
        );
      })}
    </Menu>
  );
};
