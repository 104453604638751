import React from 'react';

import type { DashDraftEditor } from '../types';

export interface DashDraftContextState {
  editor: DashDraftEditor | null;
}

/**
 * The DashDraftContext is a React Context that is used to store the editor and other information that is needed to render the editor.
 */
const DashDraftContext = React.createContext<DashDraftContextState>({
  editor: null,
});

export default DashDraftContext;
