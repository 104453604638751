import api, { createClientCacheStore } from '@stargate/api';
import type { HttpError } from '@stargate/lib/http';
import React from 'react';

import type { Workspaces } from '../types';

export type UseWorkspacesHook = {
  /**
   * Load the state from the API.
   */
  load: () => Promise<Workspaces>;

  /**
   * Refresh the state from the API.
   */
  refresh: () => Promise<Workspaces>;

  /**
   * Reset the state.
   */
  reset: () => void;

  /**
   * The loading state.
   */
  loading: boolean;

  /**
   * The error state.
   */
  error: HttpError | Error | null;

  /**
   * The data from the API.
   */
  data: Workspaces;
};

export const useWorkspaces = (): UseWorkspacesHook => {
  const apiStore = useApiClientStore();
  const [, apiActions] = api.useRequestClient('GET /workspaces');

  const refresh = React.useCallback(async () => {
    try {
      const result = await apiActions.execute();
      apiStore.setData(result);
      return result ?? [];
    } catch (error) {
      apiStore.setError(
        error instanceof Error ? error : new Error('Unknown error')
      );
      throw error;
    }
  }, [apiActions, apiStore]);

  const load = React.useCallback(async () => {
    if (apiStore.data) {
      return apiStore.data;
    }

    try {
      apiStore.setStatus('loading');
      const result = await apiActions.execute();
      apiStore.setData(result);
      return result ?? [];
    } catch (error) {
      apiStore.setError(
        error instanceof Error ? error : new Error('Unknown error')
      );
      throw error;
    }
  }, [apiActions, apiStore]);

  const reset = React.useCallback(() => {
    apiStore.clearData();
  }, [apiStore]);

  return React.useMemo(
    () => ({
      data: apiStore.data ?? [],
      error: apiStore.error,
      loading: apiStore.status === 'loading',
      load,
      refresh,
      reset,
    }),
    [apiStore.data, apiStore.error, apiStore.status, load, refresh, reset]
  );
};

const useApiClientStore = createClientCacheStore('GET /workspaces');
