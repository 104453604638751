import React from 'react';

import { AnchorLink, type SxProps, useTheme } from '@joggrdocs/riker';

import { ImgLogo } from '@stargate/components/Images';

import { Link } from '@stargate/routes';
import { createComponentClass } from '@stargate/theme';

/*
|==========================================================================
| LogoAnchor
|==========================================================================
|
| Joggr Logo, wrapped in an AnchorLink.
|
*/

export const logoAnchorClasses = {
  root: createComponentClass('LogoAnchor'),
  img: createComponentClass('LogoAnchor', 'img'),
};

export interface LogoAnchorProps {
  sx?: SxProps;
}

const LogoAnchor: React.FC<LogoAnchorProps> = (props) => {
  const theme = useTheme();
  return (
    <AnchorLink
      className={logoAnchorClasses.root}
      component={Link}
      to='app.root'
      sx={props.sx}
    >
      <ImgLogo
        className={logoAnchorClasses.img}
        logo='full'
        contrast={theme.palette.mode === 'dark'}
        responsive
      />
    </AnchorLink>
  );
};

export default LogoAnchor;
