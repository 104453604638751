import { useTheme } from '@joggrdocs/riker';
import _ from 'lodash';
import React from 'react';

import { generateComponentClasses } from '@stargate/theme';

import { Img, type ImgProps } from './Img';

export const imgLogoClasses = generateComponentClasses('ImgLogo');

/**
 * The Joggr logo in various forms.
 */
export interface ImgLogoProps extends Omit<ImgProps, 'src' | 'alt'> {
  /**
   * The type of logo to display.
   *
   * @default 'full'
   */
  logo?: 'full' | 'icon';

  /**
   * The shape of the icon to display.
   *
   * @default 'circle'
   */
  shape?: 'circle';

  /**
   * Should the contrast version of the logo be displayed?
   *
   * @default false
   */
  contrast?: boolean;
}

export const ImgLogo: React.FC<ImgLogoProps> = React.forwardRef<
  HTMLImageElement,
  ImgLogoProps
>(
  (
    { logo = 'full', shape = 'circle', contrast = false, className, ...props },
    ref
  ) => {
    const theme = useTheme();
    return (
      <Img
        {...props}
        ref={ref}
        className={theme.utils.classNames([imgLogoClasses.root, className])}
        alt={'Joggr Logo'}
        src={`/brand/logo-${_.compact([shape, logo, contrast ? 'contrast' : null, theme.palette.mode]).join('-')}.png`}
      />
    );
  }
);
ImgLogo.displayName = 'ImgLogo';
