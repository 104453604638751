import * as hookz from '@react-hookz/web';
import React from 'react';

import { useUser } from '../hooks/use-user';
import { UserProfileActionsMenu } from './UserProfileActionsMenu';

/*
|==========================================================================
| UserProfileActions
|==========================================================================
|
| Actions for the user profile.
|
*/

export const UserProfileActions: React.FC = () => {
  const authenticatedUser = useUser();
  return <UserProfileActionsMenu user={authenticatedUser.data ?? undefined} />;
};
