import {
  BubbleMenu as BaseBubbleMenu,
  type BubbleMenuProps as BaseBubbleMenuProps,
  type Editor,
} from '@tiptap/react';
import _ from 'lodash';
import React from 'react';

import {
  IconRowInsertBottom,
  IconRowInsertTop,
  IconTrash,
} from '@joggrdocs/riker';

import {
  BubbleMenuIconButtonGroup,
  type BubbleMenuIconButtonGroupProps,
} from '../helpers/BubbleMenuIconButtonGroup';
import type { ShouldShowBubbleMenuFunc } from '../types';
import { isMultipleRowsSelected, isRowGripSelected } from './utils';

export const TableRowMenu = React.memo(
  ({ editor }: { editor: Editor }): JSX.Element => {
    /*
    |------------------
    | Handlers
    |------------------
   */

    const showShowSingleRow: ShouldShowBubbleMenuFunc = ({
      view,
      state,
      from,
    }) => {
      if (_.isNil(state) || _.isNil(from)) {
        return false;
      }

      return _.every([
        isRowGripSelected({
          editor,
          view,
          state,
          from,
        }),
        !isMultipleRowsSelected({
          editor,
          view,
          state,
          from,
        }),
      ]);
    };

    const shouldShowMultipleRows: ShouldShowBubbleMenuFunc = ({
      view,
      state,
      from,
    }) => {
      if (_.isNil(state) || _.isNil(from)) {
        return false;
      }

      return isMultipleRowsSelected({
        editor,
        view,
        state,
        from,
      });
    };

    /*
    |------------------
    | Computed
    |------------------
    */

    const baseBubbleMenuProps: Omit<BaseBubbleMenuProps, 'children'> = {
      editor,
      updateDelay: 0,
      tippyOptions: {
        placement: 'left',
        sticky: true,
        offset: [6, 24],
        interactive: true,
        // MUST be inside the React application or it will not work, fallback to body if not found (e.g. in SSR)
        appendTo: () => document.querySelector('#root') ?? document.body,
      },
    };

    const singleRowButtons = React.useMemo<
      BubbleMenuIconButtonGroupProps['buttons']
    >(() => {
      return [
        {
          description: 'Insert row above',
          icon: <IconRowInsertTop />,
          onClick: () => {
            editor.chain().focus().addRowBefore().run();
          },
        },
        {
          description: 'Insert row below',
          icon: <IconRowInsertBottom />,
          onClick: () => editor.chain().focus().addRowAfter().run(),
        },
        {
          description: 'Delete row',
          icon: <IconTrash />,
          onClick: () => editor.chain().focus().deleteRow().run(),
        },
      ];
    }, [editor]);

    const multiRowButtons = React.useMemo<
      BubbleMenuIconButtonGroupProps['buttons']
    >(() => {
      return [
        {
          description: 'Delete rows',
          icon: <IconTrash />,
          onClick: () => editor.chain().focus().deleteRow().run(),
        },
      ];
    }, [editor]);

    return (
      <>
        <BaseBubbleMenu
          pluginKey='dashdraft-table-row-menu-single'
          shouldShow={showShowSingleRow}
          {...baseBubbleMenuProps}
        >
          <BubbleMenuIconButtonGroup buttons={singleRowButtons} />
        </BaseBubbleMenu>
        <BaseBubbleMenu
          pluginKey='dashdraft-table-row-menu-multi'
          shouldShow={shouldShowMultipleRows}
          {...baseBubbleMenuProps}
          tippyOptions={{
            ...baseBubbleMenuProps.tippyOptions,
            offset: [0, 24],
          }}
        >
          <BubbleMenuIconButtonGroup buttons={multiRowButtons} />
        </BaseBubbleMenu>
      </>
    );
  }
);
TableRowMenu.displayName = 'TableRowMenu';

export default TableRowMenu;
