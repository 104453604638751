import {
  BubbleMenu as BaseBubbleMenu,
  type BubbleMenuProps as BaseBubbleMenuProps,
  type Editor,
} from '@tiptap/react';
import _ from 'lodash';
import React from 'react';

import {
  IconColumnInsertLeft,
  IconColumnInsertRight,
  IconTrash,
} from '@joggrdocs/riker';

import {
  BubbleMenuIconButtonGroup,
  type BubbleMenuIconButtonGroupProps,
} from '../helpers/BubbleMenuIconButtonGroup';
import type { ShouldShowBubbleMenuFunc } from '../types';
import { isColumnGripSelected, isMultipleColumnsSelected } from './utils';

export const TableColumnMenu = React.memo(
  ({ editor }: { editor: Editor }): JSX.Element => {
    /*
    |------------------
    | Handlers
    |------------------
   */

    const showShowSingleColumn: ShouldShowBubbleMenuFunc = ({
      view,
      state,
      from,
    }) => {
      if (_.isNil(state) || _.isNil(from)) {
        return false;
      }

      return _.every([
        isColumnGripSelected({
          editor,
          view,
          state,
          from,
        }),
        !isMultipleColumnsSelected({
          editor,
          view,
          state,
          from,
        }),
      ]);
    };

    const shouldShowMultipleColumns: ShouldShowBubbleMenuFunc = ({
      view,
      state,
      from,
    }) => {
      if (_.isNil(state) || _.isNil(from)) {
        return false;
      }

      return isMultipleColumnsSelected({
        editor,
        view,
        state,
        from,
      });
    };

    /*
    |------------------
    | Computed
    |------------------
    */

    const baseBubbleMenuProps: Omit<BaseBubbleMenuProps, 'children'> = {
      editor,
      updateDelay: 0,
      tippyOptions: {
        placement: 'top',
      },
    };

    const singleColumnButtons = React.useMemo<
      BubbleMenuIconButtonGroupProps['buttons']
    >(() => {
      return [
        {
          description: 'Insert column before',
          icon: <IconColumnInsertLeft />,
          onClick: () => editor.chain().focus().addColumnBefore().run(),
        },
        {
          description: 'Insert column after',
          icon: <IconColumnInsertRight />,
          onClick: () => editor.chain().focus().addColumnAfter().run(),
        },
        {
          description: 'Delete column',
          icon: <IconTrash />,
          onClick: () => editor.chain().focus().deleteColumn().run(),
        },
      ];
    }, [editor]);

    const multiColumnButtons = React.useMemo<
      BubbleMenuIconButtonGroupProps['buttons']
    >(() => {
      return [
        {
          description: 'Delete columns',
          icon: <IconTrash />,
          onClick: () => editor.chain().focus().deleteColumn().run(),
        },
      ];
    }, [editor]);

    return (
      <>
        <BaseBubbleMenu
          pluginKey='dashdraft-table-column-menu-single'
          shouldShow={showShowSingleColumn}
          {...baseBubbleMenuProps}
        >
          <BubbleMenuIconButtonGroup buttons={singleColumnButtons} />
        </BaseBubbleMenu>
        <BaseBubbleMenu
          pluginKey='dashdraft-table-column-menu-multi'
          shouldShow={shouldShowMultipleColumns}
          {...baseBubbleMenuProps}
        >
          <BubbleMenuIconButtonGroup buttons={multiColumnButtons} />
        </BaseBubbleMenu>
      </>
    );
  }
);
TableColumnMenu.displayName = 'TableColumnMenu';

export default TableColumnMenu;
