import {
  BadgePulsing,
  type BadgePulsingProps,
  Tooltip,
  type TooltipProps,
  badgePulsingClasses,
  useTheme,
} from '@joggrdocs/riker';
import { generateComponentClasses } from '@stargate/theme';
import React from 'react';

export const hintBadgeClasses = generateComponentClasses('HintBadge', [
  'tooltip',
  'badge',
]);

export interface HintBadgeProps extends BadgePulsingProps {
  /**
   * Optional hint tooltip to display when hovering over the badge
   * @default undefined
   */
  hint?: React.ReactNode;

  /**
   * Color for the badge
   * @default 'primary'
   */
  color?:
    | 'primary'
    | 'secondary'
    | 'default'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';

  /**
   * Optional size for the dot
   * @default 'medium'
   */
  dotSize?: 'small' | 'medium' | 'large';

  /**
   * Optional color for the badge
   * @default 'dot'
   */
  variant?: 'dot' | 'standard';

  /**
   * Optional tooltip title
   * @default 'top'
   */
  tooltipPlacement?: TooltipProps['placement'];
}

/**
 * Component for
 */
export const HintBadge: React.FC<HintBadgeProps> = ({
  pulsing = true,
  hint,
  tooltipPlacement = 'top',
  color = 'primary',
  variant = 'dot',
  dotSize = 'medium',
  children,
  ...props
}) => {
  const theme = useTheme();
  const [hovered, setHovered] = React.useState(false);

  return (
    <Tooltip
      className={hintBadgeClasses.tooltip}
      title={hint}
      placement={tooltipPlacement}
      arrow
      enterDelay={500}
      leaveDelay={200}
    >
      <BadgePulsing
        {...props}
        classes={{ root: hintBadgeClasses.badge }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        variant={variant}
        dotSize={dotSize}
        color={color}
        pulsing={pulsing && !hovered}
        sx={{
          zIndex: theme.zIndex.tooltip,
          [`.${badgePulsingClasses.dot} .${badgePulsingClasses.badge}`]: {
            width: dotSize,
            height: dotSize,
            minWidth: dotSize,
          },
        }}
      >
        {children}
      </BadgePulsing>
    </Tooltip>
  );
};
