import {
  Alert,
  AlertTitle,
  Box,
  Divider,
  IconLock,
  Typography,
} from '@joggrdocs/riker';
import _ from 'lodash';
import React from 'react';

import { ComingSoonChip } from '@stargate/components/Chips';
import { GitHubRepositorySelect } from '@stargate/features/github';

export interface Props {
  onAction: () => void;
}

function Generate(props: Props) {
  return (
    <Box>
      <Typography variant='h3' gutterBottom>
        Generate with AI <ComingSoonChip />
      </Typography>
      <Typography variant='body1' gutterBottom>
        Utilize AI to generate documentation based on your code base.
      </Typography>
      <Divider sx={{ my: 1 }} />
      <React.Fragment>
        <Alert color='warning' icon={<IconLock />}>
          <AlertTitle>
            <Typography variant='h4'>Sorry you don't have access</Typography>
          </AlertTitle>
          <Typography variant='body1'>
            This feature is in alpha, if you want access to this feature please
            reach out the Joggr team or submit an email to &nbsp;
            <a href='mailto:support@joggr.io'>support@joggr.io</a>.
          </Typography>
        </Alert>
        <React.Fragment>
          <Typography sx={{ mb: 2 }}>
            Select a GitHub repository below to get started.
          </Typography>
          <GitHubRepositorySelect
            id='generate-repo-select'
            disabled
            size='small'
            options={[]}
            onChange={() => {}}
            sx={{ minWidth: 200 }}
            fullWidth={false}
            helperText='Currently only the default branch is supported.'
          />
          <Box sx={{ mt: 2 }} />
        </React.Fragment>
      </React.Fragment>
    </Box>
  );
}

export default Generate;
