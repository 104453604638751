import React from 'react';

import { Button, type ButtonProps, IconGitCommit } from '@joggrdocs/riker';

export type GitHubCommitButtonProps = Omit<ButtonProps, 'startIcon'>;

/**
 * Button to represent an Git Commit action.
 */
export const GitHubCommitButton = React.forwardRef<
  HTMLButtonElement,
  GitHubCommitButtonProps
>((props, ref) => {
  const {
    color = 'success',
    children = 'Commit',
    size = 'small',
    variant = 'contained',
    ...restProps
  } = props;
  return (
    <Button
      {...restProps}
      ref={ref}
      startIcon={<IconGitCommit />}
      variant={variant}
      color={color}
      size={size}
    >
      {children}
    </Button>
  );
});
