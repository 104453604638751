import React from 'react';

import type { QuackrStoreDevtoolState } from '../store';
import { useQuackr } from './use-quackr';

export type UseQuackrConfigHook = QuackrStoreDevtoolState;

/**
 * Get the configuration for a Quackr tool.
 */
export const useQuackrConfig = (toolKey: string) => {
  const [state] = useQuackr();

  const devtool = React.useMemo(() => {
    return state.devtools?.[toolKey] ?? null;
  }, [state.devtools, toolKey]);

  return {
    ...devtool,
    enabled: devtool?.enabled ?? false,
  };
};
