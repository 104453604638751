import { Box, Typography, useTheme } from '@joggrdocs/riker';
import React from 'react';

import { Img } from '@stargate/components/Images';

/**
 * A component that displays an example of a code snippet.
 */
export const CodeSnippetExample: React.FC = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        textAlign: 'center',
        p: 1,
        maxWidth: '600px',
        mr: 'auto',
        ml: 'auto',
      }}
    >
      <Img
        src={`/features/code-sources/code-snippet-example-${theme.palette.mode}.png`}
        alt='highlight a piece of code example'
        sx={{
          maxWidth: '400px',
          mb: 2,
        }}
      />
      <Typography
        variant='h4'
        sx={{
          mb: 1,
        }}
      >
        Highlight code snippets from <br /> across your entire codebase
      </Typography>
      <Typography
        variant='body1'
        sx={{
          fontSize: (theme) => theme.typography.pxToRem(14),
        }}
      >
        Every time you inject a code snippet into a JoggrDoc, Joggr tracks it
        and auto-updates it when you make changes to your codebase.
      </Typography>
    </Box>
  );
};
