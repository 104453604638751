import type { Session } from './session';

/*
|==========================================================================
| expiration
|==========================================================================
|
| This file contains functions for calculating expiration times and
| determining if a given expiration time has passed.
|
*/

/**
 * Gets the expiration time for a session (5 minutes from now).
 *
 * @returns An epoch timestamp.
 */
export const getExpiration = () => Date.now() + EXPIRATION_DELTA;

/**
 * Checks if a given expiration time has passed.
 *
 * @param expiration A epoch timestamp.
 * @returns A boolean indicating if the expiration time has passed.
 */
export const shouldExpire = (expiration: number) => expiration <= Date.now();

/**
 * Checks if a session has expired.
 *
 * @param session A session object.
 * @returns A boolean indicating if the session has expired.
 */
export const isExpired = (session?: Session | null) => {
  if (session?.expiresAt) {
    return shouldExpire(session.expiresAt);
  }
  // If no expiration its definitely expired (aka not set)
  return true;
};

/**
 * 5 minutes (in milliseconds) of inactivity
 */
const EXPIRATION_DELTA = 1000 * 60 * 5;
