import {
  type Theme,
  type ThemePaletteColor,
  colors,
  darken,
} from '@joggrdocs/riker';

/*
|==========================================================================
| Aspects: Measurements
|==========================================================================
|
| Provide Global height/width and other measurements for components. 
|
*/

export interface ThemeBackground {
  dashboardTopBar: string;
  dashboardSideBar: string;
  dashboardMain: string;
  paper: string;
  default: string;
}

export interface ThemeCodeEditorColorPalette {
  background: string;
  textSelection: string;
}

export interface ThemeCustomPalette {
  generate: ThemePaletteColor;
  codeEditor: ThemeCodeEditorColorPalette;
  background: {
    dashboardTopBar: string;
    dashboardSideBar: string;
    dashboardMain: string;
  };
}

/*
|------------------
| Theme Type Overloads
|------------------
*/

declare module '@joggrdocs/riker' {
  interface ThemePalette {
    generate: ThemePaletteColor;
    codeEditor: ThemeCodeEditorColorPalette;
    background: ThemeBackground;
  }
}

/*
|------------------
| Public API
|------------------
*/

export const palette = (baseTheme: Theme) => ({
  dark: {
    generate: {
      light: '#ED5FE3',
      main: '#e937dc',
      dark: '#A3269A',
      200: '#f180e7',
      800: '#9800bb',
      contrastText: baseTheme.palette.primary.contrastText,
    },
    codeEditor: {
      background: colors.blueGrey[900],
      textSelection: colors.blueGrey[700],
    },
    background: {
      dashboardTopBar: '#111936',
      dashboardSideBar: '#111936',
      dashboardMain: baseTheme.palette.background.paper,
    },
  },
  light: {
    generate: {
      light: '#E733DE',
      main: '#e100d6',
      dark: '#9D0095',
      200: '#f08de7',
      800: '#a000c1',
      contrastText: baseTheme.palette.background.paper,
    },
    codeEditor: {
      background: baseTheme.palette.background.paper,
      textSelection: baseTheme.palette.grey[200],
    },
    background: {
      dashboardTopBar: darken(baseTheme.palette.grey[50], 0.025),
      dashboardSideBar: darken(baseTheme.palette.grey[50], 0.025),
      dashboardMain: baseTheme.palette.background.paper,
    },
  },
});

const createPalette = (baseTheme: Theme): ThemeCustomPalette => {
  return palette(baseTheme)[baseTheme.palette.mode];
};

export default createPalette;
