import { Auth0Client } from '@auth0/auth0-spa-js';

import config from '@stargate/config';

export const auth0Client = new Auth0Client({
  domain: config.auth0.url,
  clientId: config.auth0.clientId,
  authorizationParams: {
    audience: config.auth0.audience,
    redirect_uri: `${window.location.origin}/authorize`,
  },
});
