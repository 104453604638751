import _ from 'lodash';

import type { IconDefinition } from '../types';
import emojiJson from './emoji.json';

export const emojiIcons: IconDefinition[] = _.chain(emojiJson)
  .filter((value) => {
    // We only use the emoji icons that are not skin tone variations, since GitHub
    // does not support skin tone variations, in future we can add support for
    // skin tone variations if GitHub and other platforms support it.
    return !value.description.includes('skin tone');
  })
  .map((value, key) => ({
    id: value.id,
    name: value.description,
    value: value.emoji,
    category: value.generalCategory,
    tags: value.tags,
  }))
  .value();

export const emojiIconsByCategory = _.chain(emojiIcons)
  .groupBy('category')
  .map((value, key) => ({
    category: key,
    icons: value,
  }))
  .value();
