/**
 * Coalesces a list of arguments, returning the first non-null argument or null.
 *
 * @param args A list of arguments.
 * @returns The first non-null argument or null.
 */
export function coalesce<T>(...args: T[]): T | null {
  for (const arg of args) {
    if (arg !== null && arg !== undefined) {
      return arg;
    }
  }

  return null;
}
