import _ from 'lodash';
import React, { type IframeHTMLAttributes } from 'react';

import { Alert, AlertTitle, Box, type BoxProps } from '@joggrdocs/riker';

import { LoadingLogo } from '@stargate/components/Loading';
import { generateComponentClasses } from '@stargate/theme';

import { type LoomOembed, useLoomOembed } from '../hooks/use-loom-oembed';

const loomVideoClasses = generateComponentClasses('LoomVideo', [
  'root',
  'video',
  'overlay',
] as const);

export interface LoomVideoProps extends BoxProps {
  loom: string | LoomOembed;
  width: number;
  gifThumbnail?: boolean;
  ratio?: number;
  autoPlay?: boolean;
}

export const LoomVideo: React.FC<LoomVideoProps> = ({
  loom,
  width,
  ratio = 4 / 3,
  dangerouslySetInnerHTML,
  gifThumbnail,
  autoPlay = false,
  ...restProps
}) => {
  const [loadingIframe, setLoadingIframe] = React.useState<boolean>(true);
  const [isError, setIsError] = React.useState<boolean>(false);
  const [loomOembed, setLoomOembed] = React.useState<LoomOembed | null>(null);
  const [, loomOembedActions] = useLoomOembed();

  /*
  |------------------
  | Computed
  |------------------
  */

  const ratioSize = React.useMemo(() => {
    return {
      width,
      height: width / ratio,
    };
  }, [width, ratio]);

  const showVideo = React.useMemo(() => {
    return !_.isNil(loom) && !loadingIframe;
  }, [loom, loadingIframe]);

  const iframeStyles = React.useMemo<React.CSSProperties | null>(() => {
    if (!showVideo) {
      return {
        height: 0,
        width: 0,
      };
    }
    return null;
  }, [showVideo]);

  /*
   |------------------
   | Compute
   |------------------
   */

  /**
   * Get the iframe id from the LoomOembedResult object
   *
   * @param loomOembed A LoomOembedResult object
   * @returns A string that is a valid HTML id, in snake case, i.e. "loom video title" -> "loom_video_title"
   */
  const getIframeId = (loomOembed: LoomOembed): string => {
    return _.snakeCase(`loom video ${loomOembed.title}`);
  };

  /**
   * Get the iframe properties from the LoomOembedResult object
   *
   * @param loomOembed A LoomOembedResult object
   * @param size A VideoSize object
   * @returns A IframeHTMLAttributes object or null
   */
  const getIframeProperties = (
    loomOembed: LoomOembed
  ): IframeHTMLAttributes<unknown> | null => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(loomOembed.html, 'text/html');
    const iframe = doc.querySelector('iframe');

    if (_.isNil(iframe)) return null;

    const src = iframe.getAttribute('src')!;

    if (_.isNil(src)) return null;

    return {
      src: autoPlay ? `${src}?autoplay=1` : src,
      frameBorder: '0',
      allowFullScreen: true,

      // For some reason, the iframe is not responsive
      // the Loom API is broken, we are patching here..
      width: ratioSize.width,
      height: ratioSize.height,
    };
  };

  /*
  |------------------
  | Handlers
  |------------------
  */

  const handleIframeLoad = (): void => {
    setLoadingIframe(false);
  };

  const handleIframeError = (): void => {
    setLoadingIframe(false);
    setIsError(true);
  };

  /*
  |------------------
  | Effects
  |------------------
  */

  React.useEffect(() => {
    if (_.isString(loom) && _.isNil(loomOembed)) {
      void loomOembedActions
        .execute(loom, {
          height: ratioSize.height,
          width: ratioSize.width,
          gifThumbnail: gifThumbnail ?? false,
        })
        .then((result) => {
          setLoomOembed(result);
        });
    } else if (!_.isString(loom) && _.isNil(loomOembed)) {
      setLoomOembed(loom);
    }
  }, [
    loom,
    gifThumbnail,
    loomOembed,
    loomOembedActions,
    ratioSize.height,
    ratioSize.width,
  ]);

  if (_.isNil(loomOembed)) {
    return null;
  }

  return (
    <Box className={loomVideoClasses.root}>
      <Box
        {...restProps}
        className={loomVideoClasses.overlay}
        sx={{
          ...restProps.sx,
          width: ratioSize.width,
          height: ratioSize.height,
          display: showVideo ? 'none' : 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isError ? (
          <Alert severity='error'>
            <AlertTitle>Video Playback Error</AlertTitle>
            Something went wrong while loading the video.
          </Alert>
        ) : (
          <LoadingLogo loading />
        )}
      </Box>
      {!_.isNil(loom) && (
        <iframe
          {...getIframeProperties(loomOembed)}
          className={loomVideoClasses.video}
          id={getIframeId(loomOembed)}
          title={loomOembed.title}
          style={{
            ...iframeStyles,
          }}
          onLoad={handleIframeLoad}
          onError={handleIframeError}
        />
      )}
    </Box>
  );
};
