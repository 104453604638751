import { useUIState } from '@stargate/stores';

export interface UserInvitesState {
  dialogOpen: boolean;
}

export interface UserInvitesActions {
  closeDialog: () => void;
  openDialog: () => void;
}

const useUserInvites = (): [UserInvitesState, UserInvitesActions] => {
  const [state, setState] = useUIState((state) => [
    state.userInvitesDialog,
    state.setUserInvitesDialog,
  ]);

  return [
    {
      dialogOpen: state.open,
    },
    {
      closeDialog: () => {
        setState({ open: false });
      },
      openDialog: () => {
        setState({ open: true });
      },
    },
  ];
};

export default useUserInvites;
