import React from 'react';
import { useNavigate } from 'react-router-dom';

import type * as Typist from '@/lib/typist';

import { useAuth0Store } from '../hooks/use-auth0-store';
import { auth0Client } from '../lib/auth0-client';

export const Auth0Provider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const navigate = useNavigate();
  const auth0Store = useAuth0Store();

  const onRedirectCallback = React.useCallback(
    (appState?: Auth0AppState) => {
      navigate(appState?.returnTo || window.location.pathname);
    },
    [navigate]
  );

  const init = React.useRef(false);
  React.useEffect(() => {
    // If init is true, then we've already initialized the Auth0 client.
    if (init.current === true) {
      return;
    }
    init.current = true;
    (async (): Promise<void> => {
      try {
        if (hasAuthParams()) {
          const { appState } = await auth0Client.handleRedirectCallback();
          const user = await auth0Client.getUser();
          auth0Store.setUser(user ?? null);
          onRedirectCallback(appState);
        } else {
          await auth0Client.checkSession();
          const user = await auth0Client.getUser();
          auth0Store.setUser(user ?? null);
        }
      } catch (error) {
        const ex = error instanceof Error ? error : new Error('Unknown error');
        auth0Store.setError(ex);
      }
    })();
  }, [onRedirectCallback, auth0Store]);

  return <>{children}</>;
};

/**
 * The state of the application before the user was redirected to the login page.
 */
type Auth0AppState = {
  returnTo?: string;
  [key: string]: Typist.AllowedAny;
};

/**
 * Check if the search params contain the necessary auth parameters.
 *
 * @param searchParams If not provided, it will use the current window location search params.
 * @returns A boolean indicating if the search params contain the necessary auth parameters.
 */
const hasAuthParams = (searchParams = window.location.search): boolean =>
  (CODE_RE.test(searchParams) || ERROR_RE.test(searchParams)) &&
  STATE_RE.test(searchParams);

/*
|------------------
| Utils/Internals
|------------------
*/

const CODE_RE = /[?&]code=[^&]+/;
const STATE_RE = /[?&]state=[^&]+/;
const ERROR_RE = /[?&]error=[^&]+/;
