import * as hookz from '@react-hookz/web';
import React from 'react';

import { Page } from '@stargate/components/Utils';
import {
  OnboardingStepper,
  useUserOnboarding,
} from '@stargate/features/user-onboarding';

const OnboardingPage: React.FC = () => {
  const userOnboarding = useUserOnboarding();

  hookz.useMountEffect(() => {
    void userOnboarding.load();
  });

  return (
    <Page
      id='onboarding'
      loading={userOnboarding.loading}
      render={() => (
        <OnboardingStepper
          activeStepId={userOnboarding.activeStepId}
          completedStepIds={userOnboarding.completedStepIds}
          onContinue={userOnboarding.nextStep}
          onSkip={userOnboarding.skipStep}
        />
      )}
    />
  );
};

export default OnboardingPage;
