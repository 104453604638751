import _ from 'lodash';

import type { JDocCodeLink, JDocCodeSnippet, JDocCodeSource } from './types';

/**
 * Filter code sources to only include code snippets.
 *
 * @param codeSources Code sources.
 */
export const filterCodeSnippets = (
  codeSources: JDocCodeSource[]
): JDocCodeSnippet[] => {
  return _.chain(codeSources)
    .thru((result) => (_.isNil(result) ? [] : result))
    .filter(isCodeSnippet)
    .value();
};

/**
 * Filter code sources to only include code links.
 *
 * @param codeSources Code sources.
 */
export const filterCodeLinks = (
  codeSources: JDocCodeSource[]
): JDocCodeLink[] => {
  return _.chain(codeSources)
    .thru((result) => (_.isNil(result) ? [] : result))
    .filter(isCodeLink)
    .value();
};

/**
 * Assert that a Code Source is a Code Link.
 *
 * @param codeSource A Code Source.
 */
export const isCodeLink = (
  codeSource: JDocCodeSource
): codeSource is JDocCodeLink => {
  return (
    _.isNil(codeSource.lineNumberStart) &&
    _.isNil(codeSource.lineNumberEnd) &&
    codeSource.type === 'file'
  );
};

/**
 * Assert that a Code Source is a Code Snippet.
 *
 * @param codeSource A Code Source.
 */
export const isCodeSnippet = (
  codeSource: JDocCodeSource
): codeSource is JDocCodeSnippet => {
  return (
    !_.isNil(codeSource.lineNumberStart) &&
    !_.isNil(codeSource.lineNumberEnd) &&
    codeSource.type === 'file'
  );
};
