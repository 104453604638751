import _ from 'lodash';
import type { MermaidConfig } from 'mermaid';
import React from 'react';

/*
|==========================================================================
| useMermaidConfig
|==========================================================================
|
| This hook is used to configure mermaid diagrams, including a pre-baked default.
|
*/

export const useMermaidConfig = (config?: MermaidConfig): MermaidConfig => {
  return React.useMemo(() => {
    return _.merge(config ?? {}, {
      startOnLoad: false,
      securityLevel: 'strict',
      arrowMarkerAbsolute: false,
      flowchart: {
        htmlLabels: true,
        curve: 'linear',
      },
      sequence: {
        diagramMarginX: 50,
        diagramMarginY: 10,
        actorMargin: 50,
        width: 120,
        height: 30,
        boxMargin: 10,
        boxTextMargin: 5,
        noteMargin: 10,
        messageMargin: 35,
        mirrorActors: true,
        bottomMarginAdj: 1,
        useMaxWidth: true,
        rightAngles: false,
        showSequenceNumbers: false,
      },
      gantt: {
        titleTopMargin: 25,
        barHeight: 16,
        barGap: 4,
        topPadding: 50,
        leftPadding: 75,
        gridLineStartPadding: 35,
        fontSize: 11,
        numberSectionStyles: 4,
        axisFormat: '%Y-%m-%d',
      },
    });
  }, [config]);
};

export default useMermaidConfig;
