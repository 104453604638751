import React from 'react';

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  IconPlayerPlay,
  Typography,
  darken,
  lighten,
  useTheme,
} from '@joggrdocs/riker';
import { useNightingale } from '@stargate/lib/nightingale';
import { LoomDialog, useLoomOembed } from '@stargate/vendors/loom';

export interface CallToActionCardProps {
  id: string;
  flowId: string;
  title: string;
  description: string;
  icon: React.ReactNode;
  loom: string;
  action: string;
  onClick: () => void;
}

export const CallToActionCard: React.FC<CallToActionCardProps> = (props) => {
  const { loom } = props;
  const theme = useTheme();
  const nightingale = useNightingale();
  const [showLoomDialog, setShowLoomDialog] = React.useState(false);
  const [loomOembedState, loomOembedActions] = useLoomOembed();

  const isLoomSuccess = loomOembedState.status === 'success';
  const isLoomLoading = loomOembedState.status === 'loading';
  const isLoomError = loomOembedState.status === 'error';

  /*
  |------------------
  | Handlers
  |------------------
  */
  const handleOpenVideo = (): void => {
    setShowLoomDialog(true);
    void nightingale.capture(['view', 'homepage_action_card'], {
      type: 'video',
      id: props.id,
      frigadeFlowId: props.flowId,
      title: props.title,
      loom: props.loom,
      action: props.action,
      description: props.description,
    });
  };

  const handleCloseVideo = (): void => {
    setShowLoomDialog(false);
  };

  /*
  |------------------
  | Effects
  |------------------
  */

  React.useEffect(() => {
    void loomOembedActions.execute(loom);
  }, [loom, loomOembedActions]);

  return (
    <React.Fragment>
      {showLoomDialog && (
        <LoomDialog
          title={props.title}
          loom={loom}
          size='lg'
          onClose={handleCloseVideo}
        />
      )}
      <Card
        elevation={0}
        sx={{
          border: (theme) =>
            `1px solid ${
              theme.palette.mode === 'dark'
                ? theme.palette.grey[600]
                : theme.palette.grey[300]
            }`,
          bgcolor: (theme) =>
            theme.palette.mode === 'dark'
              ? darken(theme.palette.background.paper, 0.05)
              : lighten(theme.palette.grey[100], 0.4),
          position: 'relative',
          p: 1,
          [theme.breakpoints.up('md')]: {
            pt: 0,
            px: 0,
          },
          paddingBottom: '64px', // Handle "absolute" Actions
          width: '100%',
        }}
      >
        <CardHeader
          title={props.title}
          titleTypographyProps={{
            variant: 'h4',
          }}
          subheader={props.description}
          subheaderTypographyProps={{
            color: theme.palette.text.secondary,
            fontSize: '14px',
          }}
          avatar={props.icon}
        />
        <CardContent
          sx={{
            marginInline: 1,
            marginBlockEnd: 2,
            height: '200px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            cursor: isLoomSuccess ? 'pointer' : 'default',
            backgroundImage: `url(${loomOembedState.result?.thumbnail_url})`,
            position: 'relative',
            borderRadius: '4px',
            overflow: 'hidden',
          }}
          onClick={isLoomSuccess ? handleOpenVideo : undefined}
        >
          {isLoomLoading && <CircularProgress size={24} />}
          {isLoomSuccess && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor:
                  theme.palette.mode === 'dark'
                    ? 'rgba(0, 0, 0, 0.4)'
                    : 'rgba(255, 255, 255, 0.6)',
                '&:hover': {
                  backgroundColor:
                    theme.palette.mode === 'dark'
                      ? 'rgba(0, 0, 0, 0.6)'
                      : 'rgba(255, 255, 255, 0.4)',
                },
              }}
            >
              <IconPlayerPlay />
              <Typography variant='h5' sx={{ ml: 1 }}>
                Click to view video
              </Typography>
            </Box>
          )}
          {isLoomError && (
            <Typography variant='h5' sx={{ textAlign: 'center' }}>
              Whoops! Failed to load video.
            </Typography>
          )}
        </CardContent>
        <CardActions
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            p: 2,
          }}
        >
          <Button
            variant='contained'
            color='secondary'
            onClick={props.onClick}
            fullWidth
          >
            {props.action}
          </Button>
        </CardActions>
      </Card>
    </React.Fragment>
  );
};

export default CallToActionCard;
