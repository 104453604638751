import _ from 'lodash';
import React from 'react';

import { Box, RikerIcon, Stack, Typography, useTheme } from '@joggrdocs/riker';

import type { ImageNodeAttributes } from '../types';

export interface MediaImageProps extends ImageNodeAttributes {
  style?: React.CSSProperties;
  onError?: () => void;
}

export const MediaImage: React.FC<MediaImageProps> = ({
  alt,
  src,
  style,
  onError,
}) => {
  const [error, setError] = React.useState<boolean>(false);
  const theme = useTheme();

  /*
  |------------------
  | Computed
  |------------------
  */

  const imgStyle = React.useMemo(() => {
    if (!_.isNil(style) && !_.isEmpty(style)) {
      return style;
    }
    return {
      maxWidth: '100%',
      height: 'auto',
    };
  }, [style]);

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const handleError = React.useCallback(() => {
    setError(true);
    onError?.();
  }, [onError]);

  // If Error, we render a predefined error component instead of the image
  // (it looks like and image + we have a real image we can insert when saving)
  if (error) {
    return (
      <Box
        className='dashdraft-media-image-error'
        sx={{
          minWidth: '480px',
          minHeight: '400px',
          bgcolor:
            theme.palette.grey[theme.palette.mode === 'light' ? 100 : 800],
          color:
            theme.palette.mode === 'light'
              ? theme.palette.text.primary
              : theme.palette.background.paper,
          border: `1px solid ${theme.palette.divider}`,
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Stack
          direction={'column'}
          spacing={1}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <RikerIcon
            name='photo-exclamation'
            size={48}
            color={theme.palette.error.main}
          />
          <Typography>This image could not be loaded.</Typography>
        </Stack>
      </Box>
    );
  }
  if (_.isEmpty(src)) {
    return null;
  }
  return (
    <img
      className='dashdraft-media-image-img'
      alt={alt}
      onError={handleError}
      src={src}
      style={imgStyle}
    />
  );
};
MediaImage.displayName = 'MediaImage';
