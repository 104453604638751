import * as Sentry from '@sentry/react';
import * as Spotlight from '@spotlightjs/spotlight';
import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import config from '@stargate/config';
import { posthogInstance } from '@stargate/vendors/posthog';

const tracePropagationTargets = [/^\/api/, config.api.url];

if (config.environment === 'local' || config.environment === 'test') {
  tracePropagationTargets.push('localhost');
}

Sentry.init({
  dsn: config.sentry.dsn,
  tunnel: '/api/prx/sen',
  release: `stargate@${config.releaseVersion.replace('v', '')}`,
  environment: config.environment,
  enabled: config.sentry.enabled,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    Sentry.replayCanvasIntegration(),
    posthogInstance.sentryIntegration({
      organization: config.sentry.org,
      projectId: config.sentry.projectId,
    }),
  ],
  tracePropagationTargets: tracePropagationTargets,
  tracesSampleRate: config.sentry.traceSampleRate,
  profilesSampleRate: config.sentry.profileSampleRate,
  replaysSessionSampleRate: config.sentry.replaysSessionSampleRate,
  replaysOnErrorSampleRate: config.sentry.replaysErrorSampleRate,
});

if (config.environment === 'local') {
  Spotlight.init({
    // Hosted in docker-compose in launchpad
    sidecarUrl: 'http://localhost:9898/stream',
    showTriggerButton: false,
  });
}

export default Sentry;
