import TipTapMention, {
  type MentionOptions as TipTapMentionOptions,
} from '@tiptap/extension-mention';
import { PluginKey } from '@tiptap/pm/state';
import { ReactRenderer } from '@tiptap/react';
import tippy, { type GetReferenceClientRect } from 'tippy.js';

import {
  MentionMenu,
  type MentionSuggestionItem,
  isMemberSuggestionItem,
  isMentionSuggestionDocItem,
  isMentionSuggestionRepoItem,
} from '../components/MentionMenu';

export const MENTION_PLUGIN_NAME = 'dashdraft-mention';
export const MENTION_PLUGIN_KEY = new PluginKey(MENTION_PLUGIN_NAME);

type MentionOptions = TipTapMentionOptions<
  MentionSuggestionItem,
  MentionSuggestionItem
>;

type MentionSuggestion = Required<MentionOptions>['suggestion'];

const suggestion: MentionSuggestion = {
  char: '@',
  pluginKey: MENTION_PLUGIN_KEY,
  allowSpaces: true,
  // startOfLine: false,

  render: () => {
    let component: ReactRenderer | null = null;
    let popup: ReturnType<typeof tippy> | null = null;

    return {
      onStart(props) {
        component = new ReactRenderer(MentionMenu, {
          props,
          editor: props.editor,
        });

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect as GetReferenceClientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        });
      },

      onUpdate(props) {
        component?.updateProps(props);

        if (!props.clientRect) {
          return;
        }

        popup?.[0].setProps({
          getReferenceClientRect: props.clientRect as GetReferenceClientRect,
        });
      },

      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          popup?.[0].hide();
          // component?.destroy();
          return true;
        }

        const ref = component?.ref as {
          onKeyDown: (props: unknown) => boolean;
        } | null;
        return ref?.onKeyDown(props) ?? false;
      },

      onExit() {
        popup?.[0].destroy();
        component?.destroy();
      },
    };
  },

  command: ({ editor, range, props }) => {
    // increase range.to by one when the next node is of type "text"
    // and starts with a space character
    const nodeAfter = editor.view.state.selection.$to.nodeAfter;
    const overrideSpace = nodeAfter?.text?.startsWith(' ');

    if (overrideSpace) {
      range.to += 1;
    }

    const link = getLink(props);
    if (link) {
      editor
        .chain()
        .focus()
        .insertContentAt(range, [
          {
            type: 'text',
            marks: [
              {
                type: 'link',
                attrs: {
                  href: link.href,
                  target: '_blank',
                  rel: 'noopener noreferrer',
                  class: 'dashdraft-link',
                },
              },
            ],
            text: link.title,
          },
          {
            type: 'text',
            text: ' ',
          },
        ])
        .run();

      // get reference to `window` object from editor element, to support cross-frame JS usage
      editor.view.dom.ownerDocument.defaultView
        ?.getSelection()
        ?.collapseToEnd();
    }
  },
  allow: ({ state, range, editor }) => {
    return (
      !state.doc.rangeHasMark(range.from, range.to, state.schema.marks.link) &&
      !editor.isActive('code')
    );
  },
};

/*
|------------------
| Utils
|------------------
*/

const getLink = (item: MentionSuggestionItem) => {
  if (isMentionSuggestionRepoItem(item)) {
    return {
      href: item.data.url,
      title: `@${item.data.owner}/${item.data.name}`,
    };
  }

  if (isMentionSuggestionDocItem(item)) {
    return {
      href: `${window.location.origin}/app/documents/${item.data.id}`,
      title: `📖 ${item.data.title}`,
    };
  }

  if (isMemberSuggestionItem(item)) {
    return {
      href: item.data.githubUrl,
      title: `@${item.data.githubUsername}`,
    };
  }

  return null;
};

export const Mention = TipTapMention.configure({
  HTMLAttributes: {
    class: 'dashdraft-mention',
  },
  // @todo in future we can use the mention node to render something special instead of a "link"
  suggestion: suggestion as TipTapMentionOptions['suggestion'],
});
