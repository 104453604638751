import _ from 'lodash';
import React from 'react';

import {
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@joggrdocs/riker';

import {
  ImageAltTextField,
  type ImageAltTextFieldProps,
} from './ImageAltTextField';

export interface ImageFormExternalProps {
  src?: string;
  alt?: string;
  onSave: (img: { src: string; alt: string }) => void;
}

/**
 * This component is used to add an image from the web.
 */
export const ImageFormExternal: React.FC<ImageFormExternalProps> = ({
  onSave,
  ...props
}) => {
  const [imageSrc, setImageSrc] = React.useState(props.src ?? '');
  const [altText, setAltText] = React.useState(props.alt ?? '');

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const handleChangeAltText = React.useCallback<
    ImageAltTextFieldProps['onChange']
  >((alt) => {
    setAltText(alt);
  }, []);

  const handleAddLinkUrl = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setImageSrc(e.target.value);
    },
    []
  );

  const handleSave = React.useCallback(() => {
    if (_.every([imageSrc, altText], (v) => !_.isEmpty(v))) {
      onSave({
        src: imageSrc,
        alt: altText,
      });
      setImageSrc('');
    }
  }, [imageSrc, altText, onSave]);

  return (
    <Stack spacing={1.5}>
      <ImageAltTextField alt={altText} onChange={handleChangeAltText} />
      <TextField
        label='External Image URL'
        placeholder='Add a public url (e.g. "https://example.com/example.png")'
        size='small'
        type='url'
        defaultValue={props.src}
        onChange={handleAddLinkUrl}
        fullWidth
      />
      <Divider flexItem />
      <Stack direction='column' spacing={1} alignItems='center'>
        <Button
          size='small'
          variant='outlined'
          fullWidth
          disabled={!_.every([imageSrc, altText], (v) => !_.isEmpty(v))}
          onClick={handleSave}
        >
          Insert External Image
        </Button>
        <Typography variant='caption'>
          Link to any image from the web
        </Typography>
      </Stack>
    </Stack>
  );
};
ImageFormExternal.displayName = 'ImageFormExternal';
