import React from 'react';

import {
  Box,
  rikerIconClasses,
  typographyClasses,
  useTheme,
} from '@joggrdocs/riker';

import {
  Actions,
  type ActionsProps,
  actionsButtonClasses,
  actionsClasses,
  actionsMenuClasses,
} from '@stargate/components/Actions';

import { useShortcuts } from '@stargate/features/shortcuts';
import { componentClassFactory } from '@stargate/theme';

import type { DirectoryTreeNode } from '../../types';
import {
  directoryCreateIconName,
  directoryDeleteIconName,
  directoryEditIconName,
  directoryMoveIconName,
  documentCreateIconName,
} from '../Icons';
import { DirectoryActionDeleteDialog } from './DirectoryActionDeleteDialog';
import { DirectoryActionDialog } from './DirectoryActionDialog';

/*
|==========================================================================
| DirectoryActions
|==========================================================================
|
| A set of actions for a directory.
|
*/

const cn = componentClassFactory('DirectoryActions');

export const directoryActionsClasses = {
  root: cn('root'),
} as const;

export interface DirectoryActionsProps {
  directory: DirectoryTreeNode;
  size?: 'xsmall' | 'small';
}

export const DirectoryActions: React.FC<DirectoryActionsProps> = ({
  directory,
  size = 'xsmall',
}) => {
  const [, shortcutActions] = useShortcuts();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [action, setAction] = React.useState<
    'edit' | 'create' | 'delete' | null
  >(null);
  const theme = useTheme();

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const handleClose = React.useCallback(() => {
    setOpenDialog(false);
    setAction(null);
  }, []);

  const handleAction = React.useCallback<
    ActionsProps<'edit' | 'create' | 'delete' | 'move' | 'add-doc'>['onAction']
  >(
    (action) => {
      if (action === 'add-doc') {
        shortcutActions.onOpen('blank', {
          directoryId: directory.id,
        });
      } else {
        // Handle the action state
        switch (action) {
          case 'edit':
          case 'create':
          case 'delete':
            setAction(action);
            break;
          case 'move':
            setAction('edit');
            break;
        }

        setOpenDialog(true);
      }
    },

    [directory.id]
  );

  return (
    <Box
      className={directoryActionsClasses.root}
      component='span'
      sx={{
        [`&.${directoryActionsClasses.root} .${actionsClasses.root}`]: {
          [`& .${actionsButtonClasses.root}`]: {
            p: 0.5,
            [`& .${actionsButtonClasses.icon}`]: {
              height: size === 'small' ? 24 : 16,
              width: size === 'small' ? 24 : 16,
            },
          },
          [`& .${actionsMenuClasses.itemIcon}`]: {
            minWidth: size === 'small' ? undefined : 24,
            [`& .${rikerIconClasses.root}`]: {
              color: theme.palette.text.primary,
              height: size === 'small' ? 24 : 18,
              width: size === 'small' ? 24 : 18,
            },
          },
          [`& .${actionsMenuClasses.itemContent} .${typographyClasses.root}`]: {
            fontSize: size === 'small' ? '16px' : '14px',
          },
        },
      }}
    >
      {openDialog && (action === 'edit' || action === 'create') && (
        <DirectoryActionDialog
          action={action}
          directory={directory}
          onClose={handleClose}
        />
      )}
      {openDialog && action === 'delete' && (
        <DirectoryActionDeleteDialog
          directory={directory}
          onClose={handleClose}
        />
      )}
      <Actions
        onAction={handleAction}
        actions={
          [
            {
              type: 'button',
              primaryLabel: 'Edit settings',
              action: 'edit',
              icon: directoryEditIconName,
            },
            {
              type: 'button',
              primaryLabel: 'Create a directory inside',
              action: 'create',
              icon: directoryCreateIconName,
            },
            {
              type: 'button',
              primaryLabel: 'Create a document inside',
              action: 'add-doc',
              icon: documentCreateIconName,
            },
            {
              type: 'divider',
            },
            {
              type: 'button',
              primaryLabel: 'Delete',
              action: 'delete',
              icon: directoryDeleteIconName,
            },
          ] as const
        }
      />
    </Box>
  );
};
