import { Extension } from '@tiptap/core';
import type { Node, NodeType } from '@tiptap/pm/model';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import _ from 'lodash';

/*
|==========================================================================
| trailingNode
|==========================================================================
|
| Adds a trailing node to the end of the document, so you don't add a Node such as a CodeBlock and
| then have to press enter to get out of it OR have no way to get out of it.
| 
| Extension based on:
| @link https://github.com/ueberdosis/tiptap/blob/v1/packages/tiptap-extensions/src/extensions/TrailingNode.js
| @link https://github.com/remirror/remirror/blob/e0f1bec4a1e8073ce8f5500d62193e52321155b9/packages/prosemirror-trailing-node/src/trailing-node-plugin.ts
|
*/

/**
 * Checks if a ProseMirror Node is of a certain type
 *
 * @param node A ProseMirror Node
 * @param types A ProseMirror NodeType or an array of ProseMirror NodeTypes
 * @returns
 */
const doesNodeEqualsType = (
  node: Node | null,
  types: NodeType[] | NodeType
) => {
  return _.some([
    Array.isArray(types) && node && types.includes(node.type),
    node && node.type === types,
  ]);
};

/*
|------------------
| Public API
|------------------
*/

export interface TrailingNodeOptions {
  node: string;
  notAfter: string[];
}

export const TrailingNode = Extension.create<TrailingNodeOptions>({
  name: 'trailingNode',

  addOptions() {
    return {
      node: 'paragraph',
      notAfter: ['paragraph'],
    };
  },

  addProseMirrorPlugins() {
    const plugin = new PluginKey(this.name);
    const disabledNodes = Object.entries(this.editor.schema.nodes)
      .map(([, value]) => value)
      .filter((node) => this.options.notAfter.includes(node.name));

    return [
      new Plugin({
        key: plugin,
        appendTransaction: (_, __, state) => {
          const { doc, tr, schema } = state;
          const shouldInsertNodeAtEnd = plugin.getState(state);
          const endPosition = doc.content.size;
          const type = schema.nodes[this.options.node];

          if (!shouldInsertNodeAtEnd) {
            return;
          }

          return tr.insert(endPosition, type.create());
        },
        state: {
          init: (_, state) => {
            const lastNode = state.tr.doc.lastChild;

            return !doesNodeEqualsType(lastNode, disabledNodes);
          },
          apply: (tr, value) => {
            if (!tr.docChanged) {
              return value;
            }

            const lastNode = tr.doc.lastChild;

            return !doesNodeEqualsType(lastNode, disabledNodes);
          },
        },
      }),
    ];
  },
});

export default TrailingNode.configure();
