import { Button, type ButtonProps } from '@joggrdocs/riker';
import React from 'react';

import { generateComponentClasses } from '@stargate/theme';
import { CodeSnippetIcon } from './CodeSnippetIcon';

export const codeSnippetButtonClasses = generateComponentClasses(
  'CodeSnippetButton',
  ['root'] as const
);

export interface CodeSnippetButtonProps extends ButtonProps {}

export const CodeSnippetButton = React.forwardRef<
  HTMLButtonElement,
  CodeSnippetButtonProps
>(({ children, ...props }, ref) => {
  return (
    <Button
      {...props}
      ref={ref}
      classes={codeSnippetButtonClasses}
      startIcon={<CodeSnippetIcon />}
    >
      {children}
    </Button>
  );
});
CodeSnippetButton.displayName = 'CodeSnippetButton';
