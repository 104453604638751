import React from 'react';
import * as ReactRouter from 'react-router-dom';

import {
  type RouteNames,
  type RouteParams,
  type RouteSearch,
  getUrl,
} from '../hooks/use-route';

export interface LinkProps<R extends RouteNames = RouteNames>
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> {
  to: R;
  params?: RouteParams<R>;
  search?: Partial<RouteSearch<R>>;
  replace?: boolean;
  preventScrollReset?: boolean;
  reloadDocument?: boolean;
}

/**
 * Type-safe wrapper around React Router's Link component.
 */
export const Link = React.forwardRef(
  <R extends RouteNames = RouteNames>(
    props: LinkProps<R>,
    ref: React.Ref<HTMLAnchorElement>
  ): React.ReactNode => {
    const { to, params, search, ...restProps } = props;
    return (
      <ReactRouter.Link
        {...restProps}
        ref={ref}
        to={getUrl(to, params, search)}
      />
    );
  }
);
Link.displayName = 'Link';
