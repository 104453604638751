import { useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import React from 'react';

import { jdocTagsQueryOptions } from '@stargate/api';
import {
  type ActionItem,
  Actions,
  type ActionsProps,
} from '@stargate/components/Actions';
import { useGitHubUrls } from '@stargate/features/github';
import { useShortcuts } from '@stargate/features/shortcuts';
import type { Tag } from '@stargate/features/tags';
import { useConfig } from '@stargate/hooks';
import { useLocalization } from '@stargate/localization';
import { useNavigate, useRoute } from '@stargate/routes';

import type {
  JDoc,
  JDocComponentProps,
  JDocDraft,
  JDocMode,
} from '@/features/docs/types';
import { JoggrDocDraftRevertDialog } from './drafts/JoggrDocDraftRevertDialog';
import { JoggrDocDeleteDialog } from './mutate/JoggrDocDeleteDialog';
import { JoggrDocTagsDialog } from './mutate/JoggrDocTagsDialog';
import {
  JoggrDocShareDialog as JoggrDocShareDialogBase,
  type JoggrDocShareDialogProps,
} from './share/JoggrDocShareDialog';

export type JoggrDocAction =
  | 'view'
  | 'edit'
  | 'delete'
  | 'revert'
  | 'create-template'
  | 'create-exit'
  | 'view-github'
  | 'share'
  | 'tags';

export type JoggrDocActionPayload<Action extends JoggrDocAction> =
  Action extends 'tags'
    ? {
        action: Action;
        data: Tag[];
      }
    : {
        action: Action;
        data?: never;
      };

export type JoggrDocActionsProps = JDocComponentProps<{
  /**
   * Whether the document is in a modified state.
   */
  modified: boolean;

  /**
   * Whether the document is currently loading.
   *
   * @default false
   */
  loading: boolean;

  /**
   * Callback triggered when an action is performed.
   *
   * @param action Action to perform
   * @returns Promise when action is complete
   */
  onAction: <Action extends JoggrDocAction>(
    payload: JoggrDocActionPayload<Action>
  ) => Promise<void>;
}>;

export const JoggrDocActions: React.FC<JoggrDocActionsProps> = ({
  doc,
  draft,
  mode,
  loading,
  modified,
  onAction,
}) => {
  const navigate = useNavigate();
  const ghUrls = useGitHubUrls();
  const [, shortcutActions] = useShortcuts();
  const actions = useActions({
    doc,
    draft: draft?.doc,
    mode,
    modified,
  });

  /*
  |------------------
  | Queries & Mutations
  |------------------
  */

  const queryClient = useQueryClient();
  const jdocTagQuery = useQuery({
    ...jdocTagsQueryOptions(doc?.id),
  });

  /*
  |------------------
  | State Management
  |------------------
  */

  const [shareDialogOpen, setShareDialogOpen] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [tagsDialogOpen, setTagsDialogOpen] = React.useState(false);
  const [confirmRevertDialogOpen, setConfirmRevertDialogOpen] =
    React.useState(false);

  /*
  |------------------
  | Computed
  |------------------
  */

  const tags = React.useMemo(() => {
    if (mode === 'create') {
      return draft?.tags ?? [];
    }

    return jdocTagQuery.data;
  }, [jdocTagQuery.data, draft?.tags, mode]);

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const handleAction = React.useCallback(
    (action: JoggrDocAction) => {
      switch (action) {
        case 'delete':
          setDeleteDialogOpen(true);
          break;
        case 'view-github':
          if (
            !_.isNil(doc) &&
            !_.isNil(doc.github) &&
            !_.isNil(doc.github.repository) &&
            !_.isNil(doc.github.filePath) &&
            !_.isNil(doc.github.branch)
          ) {
            window.open(
              ghUrls.file({
                repo: doc.github.repository.name,
                owner: doc.github.repository.owner.login,
                path: doc.github.filePath,
                branch: doc.github.branch,
              }),
              '_blank'
            );
          }
          break;
        case 'create-template':
          shortcutActions.onOpen('templates');
          break;
        case 'create-exit':
          navigate('app.root');
          break;
        case 'share':
          setShareDialogOpen(true);
          break;
        case 'tags':
          setTagsDialogOpen(true);
          break;
        case 'revert':
          setConfirmRevertDialogOpen(true);
          break;
      }
    },
    [navigate, shortcutActions, ghUrls, doc]
  );

  return (
    <React.Fragment>
      {actions.length > 0 && (
        <Actions<JoggrDocAction>
          loading={loading}
          size='medium'
          actions={actions}
          onAction={handleAction}
        />
      )}
      <JoggrDocTagsDialog
        doc={doc}
        draft={draft}
        mode={mode}
        open={tagsDialogOpen}
        tags={tags}
        onClose={() => {
          setTagsDialogOpen(false);
        }}
        onChange={async (tags) => {
          await onAction({
            action: 'tags',
            data: tags,
          });

          if (doc?.id) {
            queryClient.invalidateQueries({
              queryKey: jdocTagsQueryOptions(doc.id).queryKey,
            });
          }
        }}
      />
      <JoggrDocDeleteDialog
        doc={doc}
        draft={draft}
        mode={mode}
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
        }}
        onDelete={() => {
          void onAction({ action: 'delete' })
            .then(() => {
              setDeleteDialogOpen(false);
            })
            .catch(() => {
              setDeleteDialogOpen(false);
            });
        }}
      />
      <JoggrDocShareDialog
        docId={doc?.id}
        open={shareDialogOpen}
        onClose={() => {
          setShareDialogOpen(false);
        }}
      />
      <JoggrDocDraftRevertDialog
        mode={mode}
        open={confirmRevertDialogOpen}
        onClose={() => {
          setConfirmRevertDialogOpen(false);
        }}
        onConfirm={() => {
          onAction({ action: 'revert' }).then(() => {
            setConfirmRevertDialogOpen(false);
          });
        }}
      />
    </React.Fragment>
  );
};

/*
|------------------
| Utils
|------------------
*/

const JoggrDocShareDialog: React.FC<
  { docId?: string } & Omit<JoggrDocShareDialogProps, 'shareUrl'>
> = ({ docId, open, onClose }) => {
  const config = useConfig();
  const route = useRoute('app.documents.view');

  const shareUrl = React.useMemo(() => {
    if (!docId) return null;

    return `${config.url}${route.url({ id: docId })}`;
  }, [docId, route, config]);

  if (!shareUrl) {
    return null;
  }

  return (
    <JoggrDocShareDialogBase
      shareUrl={shareUrl}
      open={open}
      onClose={onClose}
    />
  );
};

const useActions = (params: {
  doc: JDoc | null;
  draft?: JDocDraft | null;
  modified: boolean;
  mode: JDocMode;
}) => {
  const { doc, mode, modified } = params;
  const localz = useLocalization();
  return React.useMemo<ActionsProps<JoggrDocAction>['actions']>(() => {
    /*
    |------------------
    | Action Configs
    |------------------
    */

    const shareAction = {
      action: 'share',
      type: 'button',
      primaryLabel: 'Share',
      icon: 'share',
    } satisfies ActionItem<'share'>;

    const viewGithubAction = {
      action: 'view-github',
      type: 'button',
      primaryLabel: 'View on GitHub',
      icon: 'brand-github',
    } satisfies ActionItem<'view-github'>;

    const deleteAction = {
      action: 'delete',
      type: 'button',
      primaryLabel: 'Delete',
      icon: 'trash',
    } satisfies ActionItem<'delete'>;

    const tagsAction = {
      action: 'tags',
      type: 'button',
      primaryLabel: 'Tags',
      icon: 'tag',
    } satisfies ActionItem<'tags'>;

    const dividerAction = { type: 'divider' } satisfies ActionItem;

    /*
    |------------------
    | Logic
    |------------------
    */

    const mutateActions = (m: 'edit' | 'create') => {
      if (!modified) {
        return [];
      }

      return [
        {
          action: 'revert',
          type: 'button',
          primaryLabel: localz.formatMessage(
            `features.docs.${m}.drafts.revert.title`
          ),
          icon: 'arrow-back-up',
        } satisfies ActionItem<'revert'>,
      ];
    };

    const deleteActions = () =>
      !_.isNil(doc) ? [dividerAction, deleteAction] : [];

    if (mode === 'view') {
      return [
        shareAction,
        tagsAction,
        dividerAction,
        viewGithubAction,
        ...deleteActions(),
      ] as const;
    }

    if (mode === 'edit') {
      return [
        shareAction,
        tagsAction,
        dividerAction,
        viewGithubAction,
        ...mutateActions(mode),
        ...deleteActions(),
      ] as const;
    }

    if (mode === 'create') {
      return [
        {
          type: 'button',
          action: 'create-template',
          primaryLabel: 'Use a new Template',
          icon: 'template',
        },
        tagsAction,
        dividerAction,
        ...mutateActions(mode),
        {
          type: 'button',
          action: 'create-exit',
          primaryLabel: 'Exit',
          icon: 'x',
        },
      ] as const;
    }

    return [];
  }, [mode, doc, modified, localz]);
};
