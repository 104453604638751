import {
  Skeleton,
  Stack,
  TextField,
  Typography,
  inputClasses,
  useTheme,
} from '@joggrdocs/riker';
import React from 'react';
import * as usehooks from 'usehooks-ts';

import { useJDocDraftMutate } from '@/features/docs/hooks/use-jdoc-draft-mutate';
import type { JDocComponentProps } from '@/features/docs/types';

export type JoggrDocTitleProps = JDocComponentProps<{
  /**
   * Loading state of the component.
   * @default false
   */
  loading?: boolean;
}>;

/**
 * Display or edit the title of a JoggrDoc.
 */
export const JoggrDocTitle = React.memo<JoggrDocTitleProps>(
  ({ doc, draft, mode, loading = false }) => {
    const theme = useTheme();
    const jdocMutateDraft = useJDocDraftMutate();

    const handleChange = usehooks.useDebounceCallback((title: string) => {
      jdocMutateDraft.update({
        title,
      });
    }, 300);

    if (loading) {
      return (
        <Skeleton variant='text'>
          <Typography
            variant='h1'
            fontSize={TITLE_FONT_SIZE}
            sx={TYPOGRAPHY_SX}
          >
            Loading...
          </Typography>
        </Skeleton>
      );
    }

    if (mode !== 'view') {
      return (
        <Stack direction='row' alignItems='center' spacing={0.5}>
          <TextField
            defaultValue={draft?.doc?.title}
            placeholder='Untitled'
            multiline
            maxRows={3}
            onChange={(e) => handleChange(e.target.value)}
            fullWidth
            sx={{
              fontSize: TITLE_FONT_SIZE,
              [`& .${inputClasses.root}`]: {
                ...theme.typography.h1,
                fontSize: TITLE_FONT_SIZE,
                '&:before, :after, :hover:not(.Mui-disabled):before': {
                  borderBottom: 0,
                },
                '& input::placeholder': {
                  fontSize: TITLE_FONT_SIZE,
                },
                // We force View and Edit to have the same height
                '& input': {
                  height: 'auto',
                },
              },
            }}
            variant='standard'
          />
        </Stack>
      );
    }

    if (!doc) {
      throw new Error('Unable to render JoggrDoc title');
    }

    return (
      <Typography variant='h1' fontSize={TITLE_FONT_SIZE} sx={TYPOGRAPHY_SX}>
        {doc.title}
      </Typography>
    );
  }
);

const TITLE_FONT_SIZE = '3.65rem';

// Match the TextAreas padding/margin
const TYPOGRAPHY_SX = { padding: '4px 0 5px' };
