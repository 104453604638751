import { QueryClientProvider as ReactQueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';

import { useQuackrConfigValue } from '@stargate/lib/quackr';
import { queryClient } from '@stargate/lib/query/query-client';

/**
 * Pre-configured QueryClientProvider for `@tanstack/react-query`.
 */
export const QueryClientProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const devtoolEnabled = useQuackrConfigValue('tanstack', 'query');
  return (
    <ReactQueryClientProvider client={queryClient}>
      {children}
      {devtoolEnabled && (
        <ReactQueryDevtools initialIsOpen={true} buttonPosition='bottom-left' />
      )}
    </ReactQueryClientProvider>
  );
};
