import { Extension } from '@tiptap/core';

// We directly import so we get a quasi-native TipTap extension, in future we will use a less tightly-coupled solution
import { useStore } from '@stargate/features/code/hooks/use-code-explorer';

export interface CodeExplorerCommandPayload {
  /**
   * The ID of the code source to open in the code explorer.
   */
  codeSourceId: string;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    'dashdraft-code-explorer': {
      /**
       * Open the code explorer.
       */
      openCodeExplorer: (payload?: CodeExplorerCommandPayload) => ReturnType;

      /**
       * Close the code explorer.
       */
      closeCodeExplorer: () => ReturnType;

      /**
       * Toggle the code explorer.
       */
      toggleCodeExplorer: (payload?: CodeExplorerCommandPayload) => ReturnType;
    };
  }
}

/**
 * A Tiptap extension that adds commands for opening and closing the code explorer by
 * interacting with the code explorer store (in future, this will be replaced with a less tightly-coupled solution).
 */
export default Extension.create({
  name: 'dashdraft-code-explorer',

  addCommands() {
    return {
      openCodeExplorer: (payload) => () => {
        if (payload?.codeSourceId) {
          useStore.getState().mergeQuery({
            code: {
              id: payload.codeSourceId,
            },
          });
        }
        useStore.getState().openExplorer();
        return true;
      },
      closeCodeExplorer: () => () => {
        useStore.getState().closeExplorer();
        return true;
      },
      toggleCodeExplorer: (payload) => () => {
        if (useStore.getState().explorerOpen) {
          useStore.getState().closeExplorer();
        } else {
          if (payload?.codeSourceId) {
            useStore.getState().mergeQuery({
              code: {
                id: payload.codeSourceId,
              },
            });
          }
          useStore.getState().openExplorer();
        }
        return true;
      },
    };
  },
});
