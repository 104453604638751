import { SnackbarProvider } from 'notistack';
import React from 'react';

import { Notification } from './Notification';

/*
|==========================================================================
| NotifyProvider
|==========================================================================
|
| A provider for the notification system.
|
*/

declare module 'notistack' {
  interface InternalSnack {
    title?: string;
    showCloseButton?: boolean;
  }

  interface SharedProps {
    title?: string;
    showCloseButton?: boolean;
  }
}

export interface NotifyProviderProps {
  children: React.ReactNode;
}

/**
 * Provides the notification system, need to include at the Top (i.e. App.tsx) level.
 *
 * @param props
 * @returns A provider for the notification system.
 */
export const NotifyProvider: React.FC<NotifyProviderProps> = (props) => {
  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={5000}
      Components={{
        default: Notification,
        success: Notification,
        error: Notification,
        warning: Notification,
        info: Notification,
      }}
    >
      {props.children}
    </SnackbarProvider>
  );
};
