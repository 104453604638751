import React from 'react';

import { Chip, type ChipProps, chipClasses, useTheme } from '@joggrdocs/riker';
import type { Tag } from '../types';

export interface TagChipProps extends Omit<ChipProps, 'label'> {
  /**
   * The Tag to display
   */
  tag: Tag;
}

export const TagChip = React.forwardRef<HTMLDivElement, TagChipProps>(
  (props, ref) => {
    const { tag, size = 'small', ...rest } = props;
    const theme = useTheme();

    const sx = React.useMemo(() => {
      if (props.clickable) {
        const hoverSx = {
          opacity: 0.8,
          bgcolor: tag.color,
        };
        return {
          cursor: 'pointer',
          '&:hover': hoverSx,
          '&:focus': hoverSx,
          '&:active': hoverSx,
        };
      }
      return {};
    }, [tag.color, props.clickable]);

    return (
      <Chip
        {...rest}
        ref={ref}
        sx={{
          ...rest.sx,
          ...sx,
          color: theme.palette.getContrastText(tag.color),
          fontWeight: 'bold',
          bgcolor: tag.color,
          [`& .${chipClasses.deleteIcon}`]: {
            color: theme.palette.getContrastText(tag.color),
            '&:hover': {
              color: theme.palette.getContrastText(tag.color),
              opacity: 0.8,
            },
          },
        }}
        size={size}
        label={tag.name}
      />
    );
  }
);
TagChip.displayName = 'TagChip';
TagChip.muiName = 'Chip';
