import React from 'react';
import { IntlProvider } from 'react-intl';

import localesEn from './locales/en';

/**
 * Provider for localization.
 *
 * @todo support switching locales (when we have more than one).
 */
export const LocalizationProvider: React.FC<React.PropsWithChildren> = (
  props
) => {
  return (
    <IntlProvider locale='en' messages={localesEn}>
      {props.children}
    </IntlProvider>
  );
};
