import { Extension } from '@tiptap/core';

import codeExplorer from './extensions/code-explorer';
import dropcursor from './extensions/dropcursor';
import gapcursor from './extensions/gapcursor';
import history from './extensions/history';
import placeholder from './extensions/placeholder';
// import uniqueId from './extensions/unique-id';

export default Extension.create({
  name: 'dashdraft-utilities',

  addExtensions() {
    return [dropcursor, gapcursor, history, codeExplorer, placeholder];
  },
}).configure();
