import { Avatar } from '@joggrdocs/riker';
import React from 'react';

import { IconAvatar } from '@stargate/components/Avatars';

import type { QuackrAvatar as TQuackrAvatar } from '../types';

export interface QuackrAvatarProps {
  /**
   * The size of the avatar.
   */
  size?: number;

  /**
   * The avatar to display.
   */
  avatar: TQuackrAvatar;
}

export const QuackrAvatar: React.FC<QuackrAvatarProps> = (props) => {
  const iconAvatar =
    props.avatar && 'icon' in props.avatar ? (
      <IconAvatar icon={props.avatar.icon} size={props.size} />
    ) : (
      <IconAvatar icon='link' size={props.size} />
    );
  const srcAvatar =
    props.avatar && 'src' in props.avatar ? (
      <Avatar
        src={props.avatar.src}
        variant='rounded'
        sx={{
          width: props.size,
          height: props.size,
        }}
      />
    ) : null;
  return srcAvatar ?? iconAvatar;
};
