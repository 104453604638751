import {
  Divider,
  FormControl,
  type FormControlProps,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Stack,
} from '@joggrdocs/riker';
import * as hookz from '@react-hookz/web';
import _ from 'lodash';
import React from 'react';

import type { Tag } from '../types';
import { TagChip } from './TagChip';

export interface TagSelectProps extends Omit<FormControlProps, 'onChange'> {
  /**
   * The tags to display in the select.
   */
  options: Tag[];

  /**
   * The default selected tags.
   */
  defaultSelected?: Tag[];

  /**
   * Callback fired when the value changes.
   */
  onChange: (tags: Tag[]) => void;

  /**
   * Callback fired when the user clicks the create new tag button.
   */
  onCreateAction?: () => void;

  /**
   * Override the default Label
   */
  label?: React.ReactNode;

  /**
   * Override the default Icon
   */
  icon?: React.ReactNode;
}

export const TagSelect = React.forwardRef<HTMLDivElement, TagSelectProps>(
  (
    {
      disabled = false,
      label = 'Tags',
      defaultSelected = [],
      icon,
      options,
      onChange,
      onCreateAction,
      ...props
    },
    ref
  ) => {
    const [selected, setSelected] = React.useState<string[]>(
      _.chain(defaultSelected)
        .map((v) => v.name)
        .value()
    );

    /*
    |------------------
    | Utils
    |------------------
    */

    /**
     * Get a tag object by name.
     *
     * @param name A tag name
     * @returns A tag object
     */
    const getTagByName = (name: string): Tag | null => {
      const foundTag = options.find((x) => x.name === name);
      if (foundTag) {
        return foundTag;
      }

      return null;
    };

    /*
    |------------------
    | Handlers
    |------------------
    */

    const handleChange = (event: SelectChangeEvent<string[]>) => {
      const {
        target: { value },
      } = event;
      // On autofill we get a stringified value.
      const v = typeof value === 'string' ? value.split(',') : value;
      // We don't want to trigger a save when clicking, create new tag.
      if (v.length > 0 && v[0] !== '___create-tag') {
        const tags = _.chain(v).map(getTagByName).compact().value();
        setSelected(tags.map((x) => x.name));
        onChange(tags);
      }
    };

    /*
    |------------------
    | Effects
    |------------------
    */

    hookz.useDeepCompareEffect(() => {
      if (!_.isEmpty(defaultSelected)) {
        setSelected(_.map(defaultSelected, 'name'));
      }
    }, [defaultSelected]);

    return (
      <FormControl ref={ref} {...props}>
        <InputLabel id={LABEL_ID}>{label}</InputLabel>
        <Select
          labelId={LABEL_ID}
          id={`${LABEL_ID}-select`}
          label={label}
          multiple
          placeholder='Select a single tag or multiple tags'
          startAdornment={
            icon ? (
              <InputAdornment position='start'>{icon}</InputAdornment>
            ) : undefined
          }
          value={selected}
          MenuProps={{
            disablePortal: true,
          }}
          sx={{
            width: '100%',
          }}
          disabled={(options.length === 0 && !onCreateAction) || disabled}
          onChange={handleChange}
          renderValue={(selected) => (
            <Stack direction='row' spacing={0.5}>
              {selected.map((tagName) => {
                if (typeof tagName !== 'string' || !tagName) {
                  return null;
                }

                const tag = options.find((x) => x.name === tagName);
                if (!tag) {
                  return null;
                }
                return (
                  <TagChip
                    tag={tag}
                    key={tagName}
                    onMouseDown={(event) => {
                      event.stopPropagation();
                    }}
                    onDelete={() => {
                      const updatedSelected = selected.filter(
                        (x) => x !== tagName
                      );
                      setSelected(updatedSelected);
                      onChange(
                        _.chain(updatedSelected)
                          .map((name) => getTagByName(name))
                          .compact()
                          .value()
                      );
                    }}
                  />
                );
              })}
            </Stack>
          )}
        >
          {options.map((tag) => (
            <MenuItem key={tag.name} value={tag.name}>
              <TagChip tag={tag} />
            </MenuItem>
          ))}
          {onCreateAction && options.length > 0 && <Divider sx={{ my: 0.5 }} />}
          {onCreateAction && (
            <MenuItem value='___create-tag' onClick={onCreateAction}>
              Create new tag
            </MenuItem>
          )}
        </Select>
      </FormControl>
    );
  }
);

/*
|------------------
| Utils & Components
|------------------
*/

const LABEL_ID = 'document-tag';
