import React from 'react';

import { Button, Divider, Stack, TextField } from '@joggrdocs/riker';

/*
|==========================================================================
| LinkEditForm
|==========================================================================
|
| Form for editing links.
|
*/

export interface Props {
  defaultText?: string;
  defaultUrl?: string;
  onSave: (link: { text: string; url: string }) => void;
  onRemove?: () => void;
}

const LinkForm: React.FC<Props> = (props) => {
  const [currentLinkText, setCurrentLinkText] = React.useState(
    props.defaultText ?? ''
  );
  const [currentLinkUrl, setCurrentLinkUrl] = React.useState(
    props.defaultUrl ?? ''
  );

  return (
    <Stack spacing={1}>
      <TextField
        label='URL'
        placeholder='Add a url i.e. "https://google.com"'
        size='small'
        type='url'
        defaultValue={currentLinkUrl}
        onChange={(e) => {
          setCurrentLinkUrl(e.target.value);
        }}
        fullWidth
      />
      <TextField
        label='Title'
        placeholder='Name the url i.e. "Google"'
        size='small'
        onChange={(e) => {
          setCurrentLinkText(e.target.value);
        }}
        defaultValue={currentLinkText}
        fullWidth
      />
      <Divider flexItem />
      <Stack direction='row' spacing={0.5} sx={{ mt: 1 }}>
        <Button
          text={props.defaultUrl ? 'Update' : 'Insert'}
          size='small'
          variant='outlined'
          fullWidth
          disabled={!currentLinkUrl || !currentLinkText}
          onClick={() => {
            props.onSave({ text: currentLinkText, url: currentLinkUrl });
            setCurrentLinkUrl('');
            setCurrentLinkText('');
          }}
        />
        {props.defaultUrl && props.onRemove && (
          <Button
            text='Remove'
            color='error'
            size='small'
            fullWidth
            variant='outlined'
            onClick={props.onRemove}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default LinkForm;
