import { ButtonAnchor, IconBrandGithub, Link } from '@joggrdocs/riker';
import React from 'react';

import config from '@stargate/config';

import { OnboardingStep, type OnboardingStepItem } from '../OnboardingStep';

/*
|==========================================================================
| GithubAppStep
|==========================================================================
|
| Tells the user to install the Github app, and shares helpful information
| about what to expect & to encourage them it's safe to continue.
|
*/

export type OnboardingStepGithubAppProps = OnboardingStepItem;

export const OnboardingStepGithubApp: React.FC<OnboardingStepGithubAppProps> = (
  props
) => {
  return (
    <OnboardingStep
      {...props}
      title='GitHub App'
      icon='apps'
      contentTitle='Enable access to your GitHub & start creating docs!'
      contentDescription='Our JoggrBot app enables you to create and maintain documentation for your GitHub repositories. We will need access to read & write Markdown files to your codebase.'
      action={
        <ButtonAnchor
          size='large'
          href={config.githubApp.installUrl}
          variant='contained'
          color='primary'
          startIcon={<IconBrandGithub />}
          sx={{ mb: 2 }}
        >
          Install JoggrBot
        </ButtonAnchor>
      }
      footer={
        <React.Fragment>
          Can't access the repository you want? You might need to reach out to
          your GitHub Organization Admin or you can email us at{' '}
          <Link href='mailto:support@joggr.io'>support@joggr.io</Link>.
        </React.Fragment>
      }
    />
  );
};
