import { Extension } from '@tiptap/core';

import table from './extensions/table';
import tableCell from './extensions/table-cell';
import tableHeader from './extensions/table-header';
import tableRow from './extensions/table-row';

export default Extension.create({
  name: 'dashdraft-table',

  addExtensions() {
    return [table, tableCell, tableHeader, tableRow];
  },
});
