import {
  Autocomplete,
  FormControl,
  type FormControlProps,
  InputAdornment,
  RikerIcon,
  TextField,
} from '@joggrdocs/riker';
import * as hookz from '@react-hookz/web';
import _ from 'lodash';
import React from 'react';

import apiClient from '@stargate/api';
import { SelectMenuItem } from '@stargate/components/FormFields';

/*
|==========================================================================
| GitHubRepositoryBranchAutocomplete
|==========================================================================
|
| Select a branch from a list of branches for a given repository.
|
*/

const formatValue = (value: string | null): string | null =>
  _.isNil(value) ? null : _.trim(value);

/*
|------------------
| Public API
|------------------
*/

export interface GitHubRepositoryBranchAutocompleteProps
  extends Omit<FormControlProps, 'onChange'> {
  /**
   * The ID of the repository to fetch branches for.
   */
  repositoryId?: string | number;

  /**
   * Force the open state of the autocomplete.
   */
  forceOpenState?: boolean;

  /**
   * If the autocomplete is disabled.
   */
  disabled?: boolean;

  /**
   * If the autocomplete should show pull requests.
   */
  pullRequests?: boolean;

  /**
   * The default branch to select.
   */
  defaultBranch?: string;

  /**
   * Callback fired when the autocomplete is closed, except when the forceOpenState is true.
   */
  onClose?: () => void;

  /**
   * Callback fired when the autocomplete is opened, except when the forceOpenState is false.
   */
  onOpen?: () => void;

  /**
   * Callback fired when the selected value changes.
   */
  onChange: (branch: string | null) => void;
}

export const GitHubRepositoryBranchAutocomplete = React.forwardRef<
  HTMLDivElement,
  GitHubRepositoryBranchAutocompleteProps
>(
  (
    {
      repositoryId,
      fullWidth = true,
      forceOpenState,
      defaultBranch,
      disabled = false,
      pullRequests,
      onChange,
      onOpen,
      onClose,
      ...props
    },
    ref
  ) => {
    const [ghRepoBranchesState, ghRepoBranchesActions] =
      apiClient.useRequestClient(
        'GET /github/repositories/:repositoryId/branches'
      );

    /*
    |------------------
    | State
    |------------------
    */

    const [searchValue, setSearchValue] = React.useState('');
    const previousSearchValue = hookz.usePrevious(searchValue);
    const [selected, setSelected] = React.useState<string | null>(
      defaultBranch ?? null
    );
    const previousDefaultBranch = hookz.usePrevious(defaultBranch);
    const [open, setOpen] = React.useState<boolean>(false);
    const previousRepositoryId = hookz.usePrevious(repositoryId);

    /*
    |------------------
    | Computed
    |------------------
    */

    const isDisabled = disabled || _.isNil(repositoryId);

    // At times the default branch may not be in the list of branches
    // as its a random set thats returned from the API
    const options = _.chain([
      defaultBranch,
      ...(ghRepoBranchesState.result?.map(({ name }) => name) ?? []),
    ])
      .compact()
      .uniq()
      .value();

    /*
    |------------------
    | Handlers
    |------------------
    */

    const handleOpen = React.useCallback(() => {
      onOpen?.();
      setOpen(true);
    }, [onOpen]);

    const handleClose = React.useCallback(() => {
      onClose?.();
      setOpen(false);
    }, [onClose]);

    const handleLoadData = (search: string | null): void => {
      if (_.isNil(repositoryId)) {
        return;
      }

      void ghRepoBranchesActions.execute({
        params: {
          repositoryId: repositoryId.toString(),
        },
        querystring: {
          name: search ?? undefined,
          pullRequest: _.isBoolean(pullRequests) ? pullRequests : undefined,
        },
      });
    };

    const handleChange = React.useCallback(
      (newValue: string | null): void => {
        setSelected(formatValue(newValue));
        onChange(formatValue(newValue));
        setSearchValue('');
        handleLoadData(null);
      },

      [onChange]
    );

    const handleInputChange = React.useCallback(
      (
        _e: React.SyntheticEvent<Element, Event>,
        newInputValue: string,
        reason: string
      ): void => {
        const formattedValue = formatValue(newInputValue);
        if (
          (reason === 'input' && !_.isEmpty(formattedValue)) ||
          reason === 'reset'
        ) {
          setSearchValue(formattedValue!);
        } else if (reason === 'clear' || _.isEmpty(formattedValue)) {
          setSearchValue('');
          handleLoadData(null);
        }
      },
      []
    );

    /*
    |------------------
    | Effects
    |------------------
    */

    // If the default branch changes, we need to update the selected value
    React.useEffect(() => {
      if (!_.isNil(defaultBranch) && defaultBranch !== previousDefaultBranch) {
        handleChange(defaultBranch);
      }
    }, [defaultBranch, previousDefaultBranch, handleChange]);

    // Allow us to force the open state change
    React.useEffect(() => {
      if (forceOpenState === true && !open) {
        setOpen(true);
      } else if (forceOpenState === false && open) {
        setOpen(false);
      }
    }, [forceOpenState, open]);

    // Listen to changes to the search and load the data
    // from the API
    hookz.useDebouncedEffect(
      () => {
        if (
          ghRepoBranchesState.status !== 'loading' &&
          !_.isNil(searchValue) &&
          searchValue.length > 1 &&
          searchValue !== previousSearchValue
        ) {
          handleLoadData(searchValue);
        }
      },
      [searchValue, ghRepoBranchesState.status],
      200,
      500
    );

    React.useEffect(() => {
      // The repository ID has changed, so we need to fetch the branches
      if (
        repositoryId !== previousRepositoryId &&
        !_.isNil(previousRepositoryId)
      ) {
        handleLoadData(null);
        return;
      }

      // If we have a repository ID and we haven't fetched the branches yet
      if (_.isNil(previousRepositoryId) && !_.isNil(repositoryId)) {
        handleLoadData(null);
      }
    }, [repositoryId, previousRepositoryId]);

    return (
      <FormControl
        {...props}
        fullWidth={fullWidth}
        disabled={isDisabled}
        ref={ref}
      >
        <Autocomplete
          size='small'
          ref={ref}
          open={open}
          value={disabled && _.isNil(defaultBranch) ? null : selected}
          defaultValue={defaultBranch}
          disabled={isDisabled}
          options={options}
          loading={ghRepoBranchesState.status === 'loading'}
          slotProps={{
            paper: {
              elevation: 1,
            },
          }}
          noOptionsText={
            searchValue === ''
              ? 'Start typing to select a branch'
              : 'No branches found'
          }
          // We have to disable so we can run the async fetch
          filterOptions={(x) => x}
          isOptionEqualToValue={(option, value) => option === value}
          getOptionLabel={(option) => option}
          onOpen={handleOpen}
          onClose={handleClose}
          onChange={(_e, value) => handleChange(value)}
          onInputChange={handleInputChange}
          renderOption={(props, option) => (
            <SelectMenuItem
              {...props}
              key={option}
              value={option}
              icon={<RikerIcon name='git-branch' />}
              label={option}
            />
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Branch'
              placeholder='Start typing to select a branch'
              InputProps={{
                ...params.InputProps,
                startAdornment: !isDisabled ? (
                  <InputAdornment position='start'>
                    <RikerIcon name='git-branch' />
                  </InputAdornment>
                ) : null,
              }}
            />
          )}
          autoHighlight
          autoComplete
          includeInputInList
        />
      </FormControl>
    );
  }
);
GitHubRepositoryBranchAutocomplete.displayName =
  'GitHubRepositoryBranchAutocomplete';
