import _ from 'lodash';
import { useSearchParams as useReactRouterSearchParams } from 'react-router-dom';

/*
|==========================================================================
| useSearchParams Wrapper for React Router
|==========================================================================
|
| Wraps the useSearchParams hook from react-router-dom to provide a
|
*/

export function transformSearchParamsToPlainObject<
  T extends Record<string, string>,
>(searchParams: URLSearchParams) {
  const searchObject: Record<string, string> = {};

  searchParams.forEach((value, key) => {
    searchObject[key] = value;
  });

  return searchObject as T;
}

/** @deprecated */
export default function useSearchParams<T extends Record<string, string>>(): [
  T,
  (
    update: Record<string, string | string[]>,
    options?: { merge: boolean }
  ) => void,
  (keys: string[]) => void,
] {
  const [searchParams, setSearchParams] = useReactRouterSearchParams();

  function setSearchParamsWithMerge(
    searchParamsPlainObject: Record<string, string | string[]>,
    options: { merge: boolean } = { merge: true }
  ) {
    if (options.merge) {
      setSearchParams({
        ...transformSearchParamsToPlainObject(searchParams),
        ..._.omitBy(searchParamsPlainObject, (x) => _.isNil(x)),
      });
    } else {
      setSearchParams(searchParamsPlainObject);
    }
  }

  function removeSearchParams(keys: string[]) {
    setSearchParams(
      _.omit(transformSearchParamsToPlainObject(searchParams), keys)
    );
  }

  return [
    transformSearchParamsToPlainObject<T>(searchParams),
    setSearchParamsWithMerge,
    removeSearchParams,
  ];
}
