import { createLocale } from '@stargate/localization/lib/locale-factory';

export default createLocale('home', {
  'title.no-user': 'Welcome back!',
  'title.user': 'Welcome back {{name}}!',
  description:
    'Get started by creating a JoggrDoc, viewing the JoggrDocs you & your team already created, or importing existing markdown into Joggr.',
  'action-cards.create-doc.title': 'Create a Doc',
  'action-cards.create-doc.description':
    'Create a new JoggrDoc using a template or from scratch',
  'action-cards.create-doc.cta': 'Create a Doc',
  'action-cards.create-directory.title': 'Create a Directory',
  'action-cards.create-directory.description':
    'Create a directory to organize your docs',
  'action-cards.create-directory.cta': 'Create a Directory',
  'action-cards.import-docs.title': 'Import your Docs',
  'action-cards.import-docs.description':
    'Import your existing markdown to Joggr',
  'action-cards.import-docs.cta': 'Import your Docs',
  'faqs.title': 'FAQs',
  'faqs.description': 'Get answers to your questions',
  'faqs.more':
    'Missing a question & answer? Checkout the FAQ page on our Public Docs Site for more information, or let us know what you need help with.',
  'faqs.cta': 'Visit our Docs Site',
});
