import type { ReactCodeMirrorProps } from '@uiw/react-codemirror';
import React from 'react';

/**
 * Base defaults for the CodeMirror component.
 */
export const useCodeMirrorDefaults = (
  props?: ReactCodeMirrorProps
): ReactCodeMirrorProps => {
  const basicSetup = useCodeMirrorBasicSetup(props?.basicSetup);

  return React.useMemo(() => {
    return {
      ...props,
      minHeight: props?.minHeight ?? '200px',
      autoFocus: props?.autoFocus ?? false,
      readOnly: props?.readOnly ?? false,
      basicSetup,
    };
  }, [props, basicSetup]);
};

/*
|------------------
| Utils
|------------------
*/

/**
 * Automatically sets basics to true if they are not set.
 */
const useCodeMirrorBasicSetup = (
  basicSetup: ReactCodeMirrorProps['basicSetup']
): ReactCodeMirrorProps['basicSetup'] => {
  return React.useMemo(() => {
    if (basicSetup) {
      return basicSetup;
    }

    return {
      bracketMatching: true,
      closeBrackets: true,
      closeBracketsKeymap: true,
      dropCursor: true,
      defaultKeymap: true,
      drawSelection: true,
      foldGutter: true,
      foldKeymap: true,
      indentOnInput: true,
      lineNumbers: true,
      lintKeymap: true,
      history: true,
      historyKeymap: true,
      highlightSelectionMatches: true,
      highlightSpecialChars: true,
      rectangularSelection: true,
      tabSize: 2,

      // Disabled by default
      autocompletion: false,
      completionKeymap: false,
      allowMultipleSelections: false,
      crosshairCursor: false,
      highlightActiveLine: false,
      highlightActiveLineGutter: false,
      searchKeymap: false,
      syntaxHighlighting: false,
    };
  }, [basicSetup]);
};
