import React from 'react';
import { useState } from 'react';

import {
  Box,
  Divider,
  IconButton,
  IconCategory,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@joggrdocs/riker';

import {
  type ActiveWorkspace,
  WorkspaceIcon,
} from '@stargate/features/workspaces';

/*
|==========================================================================
| DocumentDirectoryExplorerHeader
|==========================================================================
|
| The header for the DocumentDirectoryExplorer
|
*/

export interface DocumentDirectoryExplorerHeaderProps {
  workspace: ActiveWorkspace;
  width: number;
  actionLeft?: React.ReactNode;
  actionRight?: React.ReactNode;
}

export const DocumentDirectoryExplorerHeader: React.FC<
  DocumentDirectoryExplorerHeaderProps
> = ({ workspace, actionLeft, actionRight, width }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (): void => {
    setAnchorEl(null);
  };

  const actionsNode = (
    <>
      {actionLeft}
      {Boolean(actionRight) && Boolean(actionLeft) && (
        <Divider
          flexItem
          orientation='vertical'
          sx={{ mx: '4px !important' }}
        />
      )}
      {actionRight}
    </>
  );

  return (
    <Stack
      direction='row'
      spacing={0.1}
      alignItems='center'
      sx={{
        borderBottom: '1px solid',
        borderColor: 'divider',
        pb: 0.5,
        mb: 1,
      }}
    >
      <Box
        sx={{
          textOverflow: 'ellipsis',
          textWrap: 'nowrap',
          overflow: 'hidden',
        }}
      >
        <WorkspaceIcon
          size={20}
          style={{
            display: 'inline',
            verticalAlign: 'middle',
            clear: 'left',
            right: 'left',
          }}
        />
        <Tooltip
          placement='top-end'
          title={
            <Stack>
              <Typography variant='h6' color='inherit'>
                {workspace.name} (active workspace)
              </Typography>
              {workspace.description != null && (
                <Typography variant='subtitle2' color='inherit'>
                  {workspace.description}
                </Typography>
              )}
            </Stack>
          }
          arrow
        >
          <Typography
            variant='h6'
            sx={{
              display: 'inline',
              ml: 0.5,
            }}
          >
            {workspace.name}
          </Typography>
        </Tooltip>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      {width >= 250 ? (
        actionsNode
      ) : (
        <>
          <IconButton
            size='small'
            onClick={handleMenuOpen}
            aria-controls='action-menu'
            aria-haspopup='true'
            aria-expanded={anchorEl != null ? 'true' : undefined}
          >
            <IconCategory />
          </IconButton>
          <Menu
            id='action-menu'
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            disableAutoFocusItem
          >
            <MenuItem
              sx={{
                backgroundColor: 'transparent',
                '&.MuiMenuItem-root:hover': {
                  backgroundColor: 'transparent',
                  cursor: 'default',
                },
              }}
              disableRipple
            >
              {actionsNode}
            </MenuItem>
          </Menu>
        </>
      )}
    </Stack>
  );
};
