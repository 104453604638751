import type { Extension } from '@codemirror/state';
import { lineNumbers } from '@codemirror/view';

/*
|==========================================================================
| lineNumberOffset
|==========================================================================
|
| Adds a line number offset to the line numbers. This is useful when
| displaying a subset of a file.
|
*/

function lineNumberOffset(offset: number): Extension {
  return lineNumbers({
    formatNumber: (n) => (n + offset).toString(),
  });
}

export default lineNumberOffset;
