/**
 * Field definitions for the JoggrDoc fields lib
 */
export const fields = {
  /**
   * The required fields to save a JoggrDoc
   */
  required: [
    'title',
    'content',
    'github.filePath',
    'github.repository.id',
  ] as const,

  /**
   * The fields that can be modified on a JoggrDoc
   */
  modifiable: [
    'title',
    'summary',
    'content',
    'github.filePath',
    'github.repository.id',
  ],
};
