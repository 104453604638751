import React from 'react';

import type { GitHubRepository } from '../types';
import { useGitHubRepositories } from './use-github-repositories';

export interface UseGitHubRepositoryHook {
  /**
   * The GitHub Repository data.
   *
   * @default null
   */
  data: GitHubRepository | null;

  /**
   * Whether the GitHub Repository is loading.
   */
  loading: boolean;
}

/**
 * Get a GitHub Repository by ID.
 *
 * @param repoId A GitHub Repository ID
 * @returns A GitHub Repository object
 */
export const useGithubRepository = (
  repoId?: string | number | null
): UseGitHubRepositoryHook => {
  const ghRepos = useGitHubRepositories();
  return React.useMemo(() => {
    return {
      data:
        ghRepos.data.find(
          (repo) => repo.id.toString() === repoId?.toString()
        ) ?? null,
      loading: ghRepos.loading,
    };
  }, [ghRepos.data, ghRepos.loading, repoId]);
};
