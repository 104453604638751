import { Box, Skeleton, Stack } from '@joggrdocs/riker';
import React from 'react';

import { generateComponentClasses } from '@stargate/theme';

export const codeExplorerFileTreeSkeletonClasses = generateComponentClasses(
  'CodeExplorerFileTreeSkeleton',
  ['root'] as const
);

/**
 * A skeleton for the file tree, to use when loading the file tree.
 */
export const CodeExplorerFileTreeSkeleton = React.memo(() => {
  return (
    <Stack
      classes={{
        root: codeExplorerFileTreeSkeletonClasses.root,
      }}
    >
      {[1, 2, 3, 4, 5, 6, 7, 8].map((n) => (
        <Box
          key={n}
          sx={{
            display: 'flex',
            alignItems: 'center',
            minWidth: '148px',
            mb: 1,
          }}
        >
          <Box sx={{ ml: 2, mr: 1 }}>
            <Skeleton variant='rectangular' width={20} height={20} />
          </Box>
          <Box sx={{ width: '100%' }}>
            <Skeleton width='100%' height={20} />
          </Box>
        </Box>
      ))}
    </Stack>
  );
});
