import {
  IconInfoCircle,
  Link,
  type LinkProps,
  Tooltip,
  type TooltipProps,
} from '@joggrdocs/riker';
import React from 'react';

export interface InfoIconProps {
  /**
   * Href to open when icon is clicked
   */
  href: LinkProps['href'];

  /**
   * Tooltip title
   */
  tooltip?: TooltipProps['title'];

  /**
   * Tooltip placement
   */
  placement?: TooltipProps['placement'];

  /**
   * Info icon size
   */
  size?: string | number | undefined;
}

export const InfoIcon: React.FC<InfoIconProps> = ({
  href,
  tooltip,
  size,
  placement = 'top',
}) => {
  return (
    <Tooltip title={tooltip} placement={placement} arrow>
      <Link
        href={href}
        target='_blank'
        rel='noopener noreferrer'
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <IconInfoCircle size={size} />
      </Link>
    </Tooltip>
  );
};
