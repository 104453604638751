import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconChevronDown,
  Typography,
  useTheme,
} from '@joggrdocs/riker';
import { MarkdownPreview } from '@stargate/components/Utils';
import { useNightingale } from '@stargate/lib/nightingale';
import React from 'react';

export interface FAQAccordionProps {
  id: string;
  question: string;
  answer: string;
}

export const FAQAccordion: React.FC<FAQAccordionProps> = (props) => {
  const theme = useTheme();
  // Used only for user event tracking
  const [expanded, setExpanded] = React.useState(false);

  const nightingale = useNightingale();

  /*
  |------------------
  | Handlers
  |------------------
  */

  const handleClick = (): void => {
    setExpanded((prev) => !prev);
    void nightingale.capture(['view', 'homepage_faq'], {
      id: props.id,
      question: props.question,
      answer: props.answer,
      expanded: !expanded,
    });
  };

  /*
  |------------------
  | Effects
  |------------------
  */

  return (
    <Accordion>
      <AccordionSummary
        id={props.id}
        aria-controls={`${props.id}-content`}
        expandIcon={<IconChevronDown />}
        onClick={handleClick}
      >
        <Typography
          variant='h5'
          component='p'
          sx={{
            [theme.breakpoints.down('md')]: {
              fontSize: '0.975rem',
            },
          }}
        >
          {props.question}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          '.dashdraft > .dashdraft-content > .ProseMirror > * ': {
            mr: '0px !important',
            ml: '0px !important',
          },
        }}
      >
        <MarkdownPreview markdown={props.answer} />
      </AccordionDetails>
    </Accordion>
  );
};

export default FAQAccordion;
