import _ from 'lodash';
import { PostHogProvider as BasePostHogProvider } from 'posthog-js/react';
import React from 'react';

import posthogInstance from '../initialize';

/**
 * A pre-configured PostHog provider, that includes filtering for sensitive data.
 */
export const PostHogProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <BasePostHogProvider client={posthogInstance}>
      {children}
    </BasePostHogProvider>
  );
};
