import {
  Box,
  Button,
  type ButtonProps,
  IconSquareChevronLeft,
  IconSquareChevronUp,
  Tooltip,
  useTheme,
} from '@joggrdocs/riker';
import React from 'react';
import type * as tf from 'type-fest';

import { useLocalization } from '@stargate/localization';
import { generateComponentClasses } from '@stargate/theme';

import { FrigadeTarget } from '@stargate/vendors/frigade/components/FrigadeTarget';
import type { CodeExplorerPlacement } from '../../types';

export const codeExplorerButtonClasses = generateComponentClasses(
  'CodeExplorerButton',
  ['root', 'button', 'placement-right', 'placement-bottom']
);

export interface CodeExplorerButtonProps extends BaseProps {
  /**
   * The placement of the button.
   */
  placement: CodeExplorerPlacement;
}

export const CodeExplorerButton: React.FC<CodeExplorerButtonProps> = ({
  placement = 'right',
  className,
  ...props
}) => {
  const theme = useTheme();
  const locale = useLocalization();
  return (
    <Box
      component='span'
      className={theme.utils.classNames([
        codeExplorerButtonClasses.root,
        className,
        codeExplorerButtonClasses[`placement-${placement}`],
      ])}
      sx={{
        [`&.${codeExplorerButtonClasses.root}`]: {
          [`& .${codeExplorerButtonClasses.button}`]: {
            border: 0,
            p: 0,
            minWidth: 0,
          },

          [`&.${codeExplorerButtonClasses['placement-right']} .${codeExplorerButtonClasses.button}`]:
            {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              height: SIZE_LONG,
              width: SIZE_SHORT,
            },
          [`&.${codeExplorerButtonClasses['placement-bottom']} .${codeExplorerButtonClasses.button}`]:
            {
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              height: SIZE_SHORT,
              width: SIZE_LONG,
            },
        },
      }}
    >
      <FrigadeTarget targetId='jdoc-code-explorer-btn'>
        <Tooltip
          title={locale.formatMessage('features.code.explorer.btn.tooltip')}
          placement={placement === 'right' ? 'left' : 'top'}
          arrow
        >
          <Button
            {...props}
            classes={{
              root: codeExplorerButtonClasses.button,
            }}
          >
            {placement === 'right' ? (
              <IconSquareChevronLeft />
            ) : (
              <IconSquareChevronUp />
            )}
          </Button>
        </Tooltip>
      </FrigadeTarget>
    </Box>
  );
};

/*
|------------------
| Utils
|------------------
*/

type BaseProps = Omit<
  tf.SetRequired<ButtonProps, 'onClick'>,
  'children' | 'component'
>;

const SIZE_LONG = '84px';

const SIZE_SHORT = '36px';
