import clsx from 'classnames';
import _ from 'lodash';
import React from 'react';

import { Popover } from '@joggrdocs/riker';

import {
  Block,
  type BlockProps,
} from '@stargate/lib/dashdraft/components/Block';

export interface MediaProps {
  /**
   * The type of media block to render, outputs the class `dashdraft-media-${mediaType}`,
   * such as `dashdraft-media-image` or `dashdraft-media-iframe`.
   */
  mediaType: 'image' | 'iframe';

  /**
   * The category of the media block, i.e. 'image', 'loom' etc. Outputs
   * the class `dashdraft-media-${mediaCategory}`, such as `dashdraft-media-loom`.
   */
  mediaCategory?: string;

  /**
   * The content to render.
   */
  children: React.ReactNode;

  /**
   * The form to render.
   */
  popoverContent: React.ReactNode;

  /**
   * The Actions to display in the block.
   */
  actions?: BlockProps['actions'];

  /**
   * Default the popover to open.
   */
  open?: boolean;

  /**
   * Exit editing mode.
   */
  onClose: () => void;
}

/**
 * Component for rendering a media block in the editor.
 */
export const MediaBlock: React.FC<MediaProps> = ({
  mediaType,
  mediaCategory = null,
  children,
  popoverContent,
  actions,
  open = false,
  onClose,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <div
      className={clsx('dashdraft-media', `dashdraft-media-${mediaType}`, {
        [`dashdraft-media-${mediaCategory}`]: !_.isEmpty(mediaCategory),
      })}
      contentEditable={false}
    >
      <Block className='dashdraft-media-block' ref={ref} actions={actions}>
        {children}
      </Block>
      {!_.isNil(ref.current) && (
        <Popover
          className='dashdraft-media-popover'
          anchorEl={ref.current}
          open={open}
          disableRestoreFocus
          disableScrollLock
          disablePortal
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={onClose}
          sx={{
            p: 0,
          }}
          slotProps={{
            paper: {
              sx: {
                p: 0,
                minWidth: '320px',
              },
            },
          }}
        >
          <div className='dashdraft-media-popover-content'>
            {popoverContent}
          </div>
        </Popover>
      )}
    </div>
  );
};
