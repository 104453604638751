import { Button, type ButtonProps } from '@joggrdocs/riker';
import React from 'react';

import { generateComponentClasses } from '@stargate/theme';
import { CodeLinkIcon } from './CodeLinkIcon';

export const codeLinkButtonClasses = generateComponentClasses(
  'CodeLinkButton',
  ['root'] as const
);

export interface CodeLinkButtonProps extends ButtonProps {}

export const CodeLinkButton = React.forwardRef<
  HTMLButtonElement,
  CodeLinkButtonProps
>(({ children, ...props }, ref) => {
  return (
    <Button
      {...props}
      ref={ref}
      classes={codeLinkButtonClasses}
      startIcon={<CodeLinkIcon />}
    >
      {children}
    </Button>
  );
});
CodeLinkButton.displayName = 'CodeLinkButton';
