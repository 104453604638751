import { useEditor as useTipTapEditor } from '@tiptap/react';
import _ from 'lodash';
import React from 'react';

import DashDraftContext from '@dashdraft/components/DashDraftContext';
import {
  DashDraftExtensions,
  type DashDraftExtensionsConfig,
} from '@dashdraft/extensions';
import type { DashDraftEditor, DashDraftEditorOptions } from '@dashdraft/types';

export interface DashDraftProviderProps {
  /**
   * The children of the DashDraftProvider, MUST include a DashDraftContent
   */
  children?: React.ReactNode;

  /**
   * The editor options to pass to the editor.
   */
  editorOptions?: DashDraftEditorOptions;

  /**
   * Configuration for the extensions
   */
  extensionsConfig?: DashDraftExtensionsConfig;
}

/**
 * DashDraftProvider is a wrapper component that provides the DashDraftContext,
 * that can be used to manage the state of the editor.
 */
export const DashDraftProvider: React.FC<DashDraftProviderProps> = ({
  editorOptions,
  children,
  extensionsConfig,
}) => {
  const extensions = React.useMemo(() => {
    return DashDraftExtensions.configure(extensionsConfig);
  }, [extensionsConfig]);

  const editor = useTipTapEditor({
    ...editorOptions,
    immediatelyRender: true,
    shouldRerenderOnTransaction: false,
    extensions,
  });

  if (_.isNil(editor)) {
    return null;
  }

  return (
    <DashDraftContext.Provider
      value={{
        editor: editor as DashDraftEditor,
      }}
    >
      {children}
    </DashDraftContext.Provider>
  );
};
