import _ from 'lodash';
import React from 'react';

import { Box, RikerIcon, Stack, Typography, useTheme } from '@joggrdocs/riker';

import { ButtonLink } from '@stargate/components/Buttons';
import { Img } from '@stargate/components/Images';

import type { ShortcutItem } from '../types';

export type ImportSectionProps = ShortcutItem;

const Import: React.FC<ImportSectionProps> = (props) => {
  const theme = useTheme();
  return (
    <Box>
      <Img
        src={`/features/shortcuts/examples-imports-${theme.palette.mode}.png`}
        alt='Examples of Imports'
        sx={{
          width: '100%',
          maxWidth: '600px',
          mx: 'auto',
          display: 'block',
        }}
      />
      <Stack
        direction='column'
        justifyContent='center'
        spacing={1}
        sx={{ mt: 2, textAlign: 'center' }}
      >
        <Typography variant='h3' gutterBottom>
          Import Documents
        </Typography>
        <Typography variant='body1' gutterBottom>
          Import documents from your GitHub repositories into the Joggr
          platform. This will allow you to view and search your documents in the
          Joggr platform and start to build a knowledge base that is always
          up-to-date.
        </Typography>
        <Stack
          direction='row'
          spacing={1}
          alignItems='center'
          justifyContent='center'
          sx={{ pt: 1 }}
        >
          <ButtonLink
            to='app.document-imports.create'
            startIcon={<RikerIcon name='file-import' />}
            search={
              props.meta?.directoryId
                ? { rootDirId: props.meta.directoryId }
                : undefined
            }
            onClick={() => props.onClose?.()}
          >
            Create an Import
          </ButtonLink>
          <ButtonLink
            to='app.document-imports.list'
            startIcon={<RikerIcon name='list' />}
            variant='outlined'
            onClick={() => props.onClose?.()}
          >
            View existing Imports
          </ButtonLink>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Import;
