import {
  Box,
  Button,
  ButtonAnchor,
  Link,
  RikerIcon,
  Stack,
  Typography,
  useTheme,
} from '@joggrdocs/riker';
import React from 'react';

import { ImgError } from '@stargate/components/Images';
import { DebugId } from '@stargate/features/user-support';
import { FormattedMessage } from '@stargate/localization';

import { Feedback, type FeedbackProps } from './Feedback';

/*
|==========================================================================
| ErrorFeedback
|==========================================================================
|
| Provide Feedback to Users for Errors.
|
*/

export interface ErrorFeedbackProps extends Partial<FeedbackProps> {
  /**
   * Type of error.
   */
  type:
    | 'unknown'
    | 'not-found'
    | 'bad-request'
    | 'unauthorized'
    | 'forbidden'
    | 'internal-server-error';

  /**
   * URL to reload the page.
   */
  reloadUrl?: string;

  /**
   * Callback to reload the page.
   */
  onReload?: (e: React.SyntheticEvent) => void;
}

function ErrorFeedback(
  props: ErrorFeedbackProps,
  ref: React.Ref<HTMLDivElement>
) {
  const { type = 'unknown', reloadUrl, onReload, ...restProps } = props;
  const theme = useTheme();

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const handleBackClick = React.useCallback(() => {
    window.history.back();
  }, []);

  return (
    <Feedback
      ref={ref}
      title={<FormattedMessage id={`error.type.${type}.title`} />}
      message={
        props.message ?? (
          <FormattedMessage id={`error.type.${type}.description`} />
        )
      }
      imagery={
        <ImgError type={type} sx={{ width: '100%', maxWidth: '400px' }} />
      }
      actions={
        <Box>
          <Stack direction='row' spacing={1} justifyContent='center'>
            <ButtonAnchor
              size='large'
              variant='contained'
              startIcon={<RikerIcon name='home' />}
              sx={{ marginLeft: '8px' }}
              href={`${window.location.origin}/app/home`}
              fullWidth
            >
              Go to Home Page
            </ButtonAnchor>
            {reloadUrl && (
              <ButtonAnchor
                size='large'
                variant='outlined'
                startIcon={<RikerIcon name='reload' />}
                href={reloadUrl}
                onClick={onReload}
                fullWidth
              >
                Reload Page
              </ButtonAnchor>
            )}
            {!reloadUrl && window.history.length > 0 && (
              <Button
                size='large'
                variant='outlined'
                startIcon={<RikerIcon name='arrow-back' />}
                onClick={handleBackClick}
                fullWidth
              >
                Go Back
              </Button>
            )}
          </Stack>
          <Typography
            variant='caption'
            textAlign='center'
            sx={{
              mt: 2,
              color: theme.palette.text.primary,
            }}
          >
            If the problem persists, please contact support at{' '}
            <Link href='mailto:support@joggr.io'>support@joggr.io</Link> & share
            your Session ID (below).
          </Typography>
          <Box
            sx={{
              px: 1,
              mt: 1,
              mr: 'auto',
              ml: 'auto',
              width: 'fit-content',
              borderRadius: '4px',
              bgcolor: theme.utils.modeValue({
                light: theme.palette.grey[100],
                dark: theme.palette.grey[900],
              }),
            }}
          >
            <DebugId />
          </Box>
        </Box>
      }
      {...restProps}
    />
  );
}

export default React.forwardRef(ErrorFeedback);
