import {
  Box,
  Card,
  CardContent,
  type CardContentProps,
  CardHeader,
  type CardProps,
  Divider,
  IconChartDots3,
  IconCodeCircle2,
  Stack,
  Typography,
  cardContentClasses,
  useTheme,
} from '@joggrdocs/riker';
import * as hookz from '@react-hookz/web';
import React from 'react';

import useMermaidRender from '../hooks/useMermaidRender';
import SirenaCodeEditor, {
  type SirenaCodeEditorProps,
} from './SirenaCodeEditor';
import SirenaDocsButton from './SirenaDocsButton';
import { SirenaDownloadButton } from './SirenaDownloadButton';
import SirenaExampleSelect, {
  type SirenaExamplesSelectProps,
} from './SirenaExamplesSelect';
import SirenaGraphTypeChip from './SirenaGraphTypeChip';
import SirenaViewer, { type SirenaViewerProps } from './SirenaViewer';

/*
|==========================================================================
| SirenaSplitEditor
|==========================================================================
|
| This component renders a split editor for mermaid graphs.
|
*/

/*
|------------------
| Internal Components
|------------------
*/

const SplitCard: React.FC<CardProps> = (props) => {
  const theme = useTheme();
  return (
    <Card
      {...props}
      sx={{
        ...props.sx,
        padding: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        border: `1px solid ${theme.palette.divider}`,
      }}
    />
  );
};

const SplitCardContent: React.FC<CardContentProps> = (props) => {
  const theme = useTheme();
  return (
    <CardContent
      {...props}
      sx={{
        ...props.sx,
        padding: theme.spacing(1),
        position: 'relative',
        flexGrow: 1,
        display: 'box',
        width: '100%',
      }}
    />
  );
};

const SplitCardHeader: React.FC<{
  title: React.ReactNode;
  titleIcon: React.ReactNode;
  action?: React.ReactNode;
}> = ({ title, titleIcon, action }) => {
  return (
    <CardHeader
      sx={{
        py: 1,
        px: 2,
        bgcolor: (theme) =>
          theme.palette.mode === 'dark'
            ? theme.palette.background.default
            : theme.palette.grey[100],
        minHeight: '56px',
      }}
      title={
        <Stack direction='row' alignItems='center' spacing={1}>
          <Stack direction='row' alignItems='center' spacing={1}>
            {titleIcon}
            <Typography variant='h4'>{title}</Typography>
          </Stack>
          <Box sx={{ flexGrow: 1 }} />
          {action}
        </Stack>
      }
    />
  );
};

/*
|------------------
| Public API
|------------------
*/

export interface SirenaSplitEditorProps {
  onGraphCodeChange: (graphCode: string) => void;
  defaultGraphCode?: string;
  editorProps?: Partial<SirenaCodeEditorProps>;
  viewerProps?: Partial<SirenaViewerProps>;
}

export const SirenaSplitEditor: React.FC<SirenaSplitEditorProps> = ({
  onGraphCodeChange,
  ...props
}) => {
  const theme = useTheme();

  /*
  |------------------
  | Mermaid
  |------------------
  */

  const mermaidRender = useMermaidRender({
    defaultGraphCode: props.defaultGraphCode,
  });

  /*
  |------------------
  | State
  |------------------
  */

  const [graphCodeExample, setGraphCodeExample] = React.useState('');

  /*
  |------------------
  | Handlers
  |------------------
  */

  const handleCodeChange = hookz.useDebouncedCallback<
    Required<SirenaCodeEditorProps>['onCodeChange']
  >(
    (code) => {
      onGraphCodeChange(code);
      mermaidRender.update(code);
    },
    [onGraphCodeChange],
    500
  );

  const handleChangeExample = React.useCallback<
    SirenaExamplesSelectProps['onChangeExample']
  >(
    (example) => {
      setGraphCodeExample(example.graphCode);
      handleCodeChange(example.graphCode);
      mermaidRender.update(example.graphCode);
    },

    [handleCodeChange]
  );

  return (
    <Stack
      direction='row'
      spacing={1}
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <SplitCard
        sx={{
          bgcolor: theme.palette.codeEditor.background,
          [theme.breakpoints.up('md')]: {
            width: '90%',
          },
          [theme.breakpoints.up('lg')]: {
            width: '70%',
          },
        }}
      >
        <SplitCardHeader
          title='Editor'
          titleIcon={<IconCodeCircle2 />}
          action={
            <React.Fragment>
              <SirenaExampleSelect onChangeExample={handleChangeExample} />
              <Divider flexItem orientation='vertical' />
              <SirenaDocsButton
                graphType={mermaidRender.graphType ?? undefined}
              />
            </React.Fragment>
          }
        />
        <SplitCardContent
          className='sirena-split-editor-content'
          sx={{
            [`&.${cardContentClasses.root}:last-child`]: {
              paddingBottom: `${theme.spacing(6)} !important`,
            },
          }}
        >
          {mermaidRender.graphType ? (
            <Box
              sx={{
                position: 'absolute',
                bottom: '8px',
                right: '8px',
              }}
            >
              <SirenaGraphTypeChip graphType={mermaidRender.graphType} />
            </Box>
          ) : undefined}
          <SirenaCodeEditor
            {...props.editorProps}
            graphType={mermaidRender.graphType ?? 'flowchart'}
            graphCode={graphCodeExample || props.defaultGraphCode}
            onCodeChange={handleCodeChange}
          />
        </SplitCardContent>
      </SplitCard>
      <SplitCard>
        <SplitCardHeader
          title='Diagram'
          titleIcon={<IconChartDots3 />}
          action={
            <SirenaDownloadButton
              svg={mermaidRender.graphSvg ?? undefined}
              graphType={mermaidRender.graphType ?? undefined}
              disabled={!!mermaidRender.error}
            />
          }
        />
        <SplitCardContent
          className='sirena-split-editor-content'
          sx={{
            position: 'relative',
            // override base CardContent padding
            padding: '0 !important',
          }}
        >
          <SirenaViewer
            {...props.viewerProps}
            error={mermaidRender.error}
            loading={
              mermaidRender.status === 'loading' ||
              mermaidRender.status === 'idle'
            }
            svg={mermaidRender.graphSvg}
            graphType={mermaidRender.graphType ?? 'flowchart'}
          />
        </SplitCardContent>
      </SplitCard>
    </Stack>
  );
};

const MemoizedSirenaSplitEditor = React.memo(SirenaSplitEditor);
MemoizedSirenaSplitEditor.displayName = 'SirenaSplitEditor';

export default MemoizedSirenaSplitEditor;
