import React from 'react';
import type * as TypeFest from 'type-fest';

import {
  Box,
  type RikerIconName,
  type RikerIconProps,
  type SxProps,
  type Theme,
  createComponentRootProps,
  useTheme,
} from '@joggrdocs/riker';

import { generateComponentClasses } from '@stargate/theme';

import type { IconType } from '../../types';
import { IconEmoji } from './IconEmoji';
import { IconRiker } from './IconRiker';

/*
|==========================================================================
| IconPickerIcon
|==========================================================================
|
| Component for a IconPickerIcon that switches between icons based on the data passed to it.
|
*/

export const iconPickerIconClasses = generateComponentClasses(
  'IconPickerIcon',
  ['root', 'emoji', 'riker', 'unknown'] as const
);

export type IconPickerIconProps = TypeFest.Simplify<
  Pick<RikerIconProps, 'sx' | 'className' | 'size'> & {
    iconType: IconType;
    icon: string;
  }
>;

/**
 * Component for a IconPickerIcon that switches between icons based on the data passed to it.
 */
export const IconPickerIcon = React.forwardRef<any, IconPickerIconProps>(
  ({ icon, iconType, ...props }, ref) => {
    const theme = useTheme();
    const rootProps = createComponentRootProps(props, iconPickerIconClasses);
    if (iconType === 'riker') {
      return (
        <IconRiker
          {...rootProps}
          className={theme.utils.classNames([
            iconPickerIconClasses.root,
            iconPickerIconClasses.riker,
            props.className,
          ])}
          ref={ref}
          icon={icon as RikerIconName}
        />
      );
    }
    if (iconType === 'emoji') {
      return (
        <IconEmoji
          {...rootProps}
          className={theme.utils.classNames([
            iconPickerIconClasses.root,
            iconPickerIconClasses.emoji,
            props.className,
          ])}
          ref={ref}
          emoji={icon}
        />
      );
    }
    return (
      <Box
        {...rootProps}
        className={theme.utils.classNames([
          iconPickerIconClasses.root,
          iconPickerIconClasses.unknown,
          props.className,
        ])}
        component='span'
        ref={ref}
      >
        {icon}
      </Box>
    );
  }
);
