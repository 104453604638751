import React from 'react';

import {
  Box,
  IconMoonStars,
  IconSunFilled,
  type SxProps,
  lighten,
  useTheme,
} from '@joggrdocs/riker';

/*
|==========================================================================
| ModeIcon
|==========================================================================
|
| Display an icon representing the current mode.
|
*/

/*
|------------------
| Utilities
|------------------
*/

const getSizeBase = (size: number) => {
  return {
    height: `${size}px`,
    width: `${size}px`,
    padding: `${size * 0.15}px`,
  };
};

/*
|------------------
| Public API
|------------------
*/

export interface ModeIconProps {
  mode: 'light' | 'dark';
  size?: number;
  sx?: SxProps;
}

export const ModeIcon: React.FC<ModeIconProps> = (props) => {
  const { size = 36, mode, sx } = props;
  const theme = useTheme();

  const colorSx = React.useMemo(() => {
    if (theme.palette.mode === 'light') {
      return {
        color: theme.palette.grey[100],
        bgcolor: theme.palette.grey[600],
      };
    }
    return {
      color: theme.palette.grey[100],
      bgcolor: theme.palette.grey[900],
      border: `1px solid ${lighten(theme.palette.grey[900], 0.25)}`,
    };
  }, [theme]);

  const sizeSx = React.useMemo(() => {
    return getSizeBase(size);
  }, [size]);

  const icon = React.useMemo(() => {
    if (mode === 'light') {
      return <IconSunFilled />;
    }
    return <IconMoonStars />;
  }, [mode]);

  return (
    <Box
      sx={{
        ...colorSx,
        ...sizeSx,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg.tabler-icon': {
          height: '100%',
          width: '100%',
        },
        ...sx,
      }}
    >
      {icon}
    </Box>
  );
};
