import {
  IconButton,
  IconCopy,
  IconTrash,
  Typography,
  useTheme,
} from '@joggrdocs/riker';
import React from 'react';
import type * as TF from 'type-fest';

import { type ActionItem, Actions } from '@stargate/components/Actions';
import { useDelayedState } from '@stargate/hooks';
import Codemirror, { type CodeMirrorProps } from '@stargate/lib/codemirror';
import { useNotify } from '@stargate/lib/notify';

import {
  CodeBlockCard,
  CodeBlockCardContent,
  CodeBlockCardContentActions,
  CodeBlockCardHeader,
  codeBlockCardContentClasses,
} from '@dashdraft/components/CodeBlockCards';
import {
  CodeLanguageSelect,
  type Language,
} from '@dashdraft/extensions/code-block/components/shared/CodeLanguageSelect';
import type {
  CodeBlockBasicAttributes,
  CodeBlockTypeNodeViewProps,
} from '@dashdraft/extensions/code-block/types';

/**
 * The base props for the CodeBlockBasic or CodeBlockMermaid NodeView
 */
export type CodeBlockBasicProps = TF.SetRequired<
  CodeBlockTypeNodeViewProps<
    Omit<CodeBlockBasicAttributes, 'codeSnippetId' | 'placeholder'>
  >,
  'code' | 'language'
>;

/**
 * A basic code block component, for custom code blocks (not snippets).
 */
export const CodeBlockBasic = React.memo<CodeBlockBasicProps>(
  ({
    readonly = false,
    code,
    language,
    onChangeCode,
    onChangeLanguage,
    onDelete,
    onKeyUp,
  }) => {
    const notify = useNotify();
    const theme = useTheme();

    /*
    |------------------
    | State
    |------------------
    */

    const [hover, setHover] = React.useState<boolean>(false);
    const [isTyping, setIsTyping, setDelayedIsTyping] =
      useDelayedState<boolean>(false);
    const [focused, setFocused] = React.useState<boolean>(false);

    /*
    |------------------
    | Computed
    |------------------
    */

    const autoFocus = React.useMemo(() => !readonly, [readonly]);

    const actions = React.useMemo<CodeBlockActionItem[]>(() => {
      if (readonly) {
        return [
          {
            type: 'button',
            action: 'copy',
            primaryLabel: 'Copy',
            icon: IconCopy,
          },
        ];
      }
      return [
        {
          type: 'button',
          action: 'copy',
          primaryLabel: 'Copy',
          icon: IconCopy,
        },
        { type: 'divider' },
        {
          type: 'button',
          action: 'delete',
          primaryLabel: 'Delete',
          icon: IconTrash,
        },
      ];
    }, [readonly]);

    /*
    |------------------
    | Callbacks
    |------------------
    */

    const handleFocus = React.useCallback(() => {
      setFocused(true);
    }, []);

    const handleBlur = React.useCallback(() => {
      setFocused(false);
    }, []);

    // biome-ignore lint/correctness/useExhaustiveDependencies: state setters should not be a dependency
    const handleKeyUp = React.useCallback<Required<CodeMirrorProps>['onKeyUp']>(
      (event, view) => {
        onKeyUp?.(event, view);
        if (
          event.key !== 'ArrowDown' &&
          event.key !== 'ArrowUp' &&
          event.key !== 'Enter' &&
          event.key !== 'Escape'
        ) {
          setIsTyping(true);
          setDelayedIsTyping(false, 6000);
        } else {
          setIsTyping(false);
        }
      },
      [onKeyUp]
    );

    const handleCopyCode = React.useCallback(() => {
      navigator.clipboard.writeText(code);
      notify.send({
        message: 'Copied code to clipboard',
        severity: 'success',
      });
    }, [code, notify]);

    const handleAction = React.useCallback(
      (action: string) => {
        switch (action) {
          case 'copy':
            handleCopyCode();
            break;
          case 'delete':
            onDelete();
            break;
          default:
            break;
        }
      },
      [handleCopyCode, onDelete]
    );

    const handleLanguageChange = React.useCallback(
      (language: Language) => {
        onChangeLanguage(language);
      },
      [onChangeLanguage]
    );

    const handleCodeChange = React.useCallback(
      (code: string) => {
        onChangeCode(code);
      },
      [onChangeCode]
    );

    const handleMouseLeave = React.useCallback(() => {
      setHover(false);
    }, []);

    const handleMouseEnter = React.useCallback(() => {
      setHover(true);
    }, []);

    return (
      <CodeBlockCard
        sx={{
          [`& .${codeBlockCardContentClasses.root}:last-child`]: {
            pb: `${theme.spacing(1)} !important`,
          },
          '& .cm-cursorLayer': {
            display: readonly ? 'none !important' : undefined,
          },
        }}
      >
        <CodeBlockCardHeader
          leftAction={
            <CodeLanguageSelect
              disabled={readonly}
              language={language}
              onChangeLanguage={handleLanguageChange}
            />
          }
          rightAction={
            <Actions<CodeBlockAction>
              size='small'
              actions={actions}
              onAction={handleAction}
            />
          }
        />
        <CodeBlockCardContent
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Codemirror
            code={code}
            lang={language}
            onCodeChange={handleCodeChange}
            onKeyUp={handleKeyUp}
            readonly={readonly}
            ReactCodeMirrorProps={{
              minHeight: '48px',
              onFocus: handleFocus,
              onBlur: handleBlur,
              autoFocus,
            }}
          />
          {!readonly && (
            <Typography
              variant='caption'
              sx={{
                ml: 1,
                visibility: !isTyping && focused ? 'visible' : 'hidden',
              }}
            >
              <strong>Pro Tip:</strong> Hit the ↓ down or ↑ up arrow to exit the
              code snippet.
            </Typography>
          )}
          <CodeBlockCardContentActions
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{
              display: hover ? 'block' : 'none',
            }}
          >
            <IconButton size='small' onClick={handleCopyCode}>
              <IconCopy />
            </IconButton>
          </CodeBlockCardContentActions>
        </CodeBlockCardContent>
      </CodeBlockCard>
    );
  }
);
CodeBlockBasic.displayName = 'CodeBlockBasic';

/*
|------------------
| Utils
|------------------
*/

type CodeBlockAction = 'delete' | 'copy';

type CodeBlockActionItem = ActionItem<CodeBlockAction>;
