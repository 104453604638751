import { useMountEffect, usePrevious } from '@react-hookz/web';
import _ from 'lodash';
import React from 'react';
import * as usehooks from 'usehooks-ts';

import {
  DashDraftContent,
  type DashDraftEventListenerHandler,
  useDashDraftEditor,
  useDashDraftEventListener,
} from '@stargate/dashdraft';
import { generateComponentClasses } from '@stargate/theme';

import { useJDocDraftMutate } from '@/features/docs/hooks/use-jdoc-draft-mutate';
import { useJDocTableOfContents } from '@/features/docs/hooks/use-jdoc-toc';
import type { JDocComponentProps, JDocMode } from '@/features/docs/types';

import { JoggrDocContentSkeleton } from './JoggrDocContentSkeleton';

export const jdocContentClasses = generateComponentClasses('JoggrDocContent', [
  'root',
] as const);

export type JDocContentProps = JDocComponentProps<{
  /**
   * Whether the JoggrDoc is currently loading.
   *
   * @default false
   */
  loading?: boolean;
}>;

/**
 * Renders & allows editing of the content of a JoggrDoc.
 */
export const JDocContent: React.FC<JDocContentProps> = ({
  mode,
  doc,
  draft,
  loading = false,
}) => {
  const jdocMutateDraft = useJDocDraftMutate();
  const docTOC = useJDocTableOfContents();
  const editor = useDashDraftEditor();

  const content = React.useMemo(() => {
    if (mode === 'edit' || mode === 'create') {
      return draft?.doc?.content;
    }

    return doc?.content;
  }, [mode, doc?.content, draft?.doc?.content]);

  /*
  |------------------
  | Effects
  |------------------
  */

  /**
   * Handle syncing the editor content with the draft content.
   */
  const handleDraftSync = usehooks.useDebounceCallback<
    DashDraftEventListenerHandler<'update'>
  >(({ editor, transaction }) => {
    if ((mode === 'edit' || mode === 'create') && transaction.docChanged) {
      jdocMutateDraft.update({
        content: editor.getJSON(),
      });
    }
  }, 200);
  useDashDraftEventListener('update', handleDraftSync);

  // Handle changes in the loading state, due to the content being fetched or
  // saved, or other upstream changes.
  const previousLoading = usePrevious(loading);
  // biome-ignore lint/correctness/useExhaustiveDependencies: only want to run this effect when loading changes
  React.useEffect(() => {
    if (loading === false && previousLoading === true && !_.isNil(content)) {
      docTOC.clear();
      editor.commands.setContent(content, false);
    }
  }, [loading, previousLoading, content]);

  useMountEffect(() => {
    if (!_.isNil(content)) {
      docTOC.clear();
      editor.commands.setContent(content, false);
    }
  });

  if (loading || (mode !== 'create' && _.isNil(content))) {
    return <JoggrDocContentSkeleton />;
  }

  return (
    <DashDraftContent
      readonly={mode === 'view'}
      defaultContent={content ?? undefined}
    />
  );
};
