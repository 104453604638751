import React from 'react';

import CodeMirror, {
  type CodeMirrorProps,
  type CodeMirrorLang,
} from '@stargate/lib/codemirror';
import type { MermaidGraphType } from '../lib/mermaidUtils';

/*
|==========================================================================
| SirenaCodeEditor
|==========================================================================
|
| A CodeMirror editor for Mermaid.
|
*/

export type SirenaCodeEditorProps = Omit<CodeMirrorProps, 'lang' | 'code'> & {
  graphType?: MermaidGraphType;
  graphCode?: string;
};

export const SirenaCodeEditor: React.FC<SirenaCodeEditorProps> = (props) => {
  const language = React.useMemo<CodeMirrorLang>(() => {
    switch (props.graphType) {
      // Only certain graph types are supported by this extension
      // @see https://github.com/inspirnathan/codemirror-lang-mermaid?tab=readme-ov-file#supported-diagrams
      case 'pie':
      case 'journey':
      case 'sequenceDiagram':
      case 'sequence':
      case 'flowchart':
      case 'flowchart-v2':
      case 'requirementDiagram':
      case 'requirement':
      case 'gantt':
        return 'mermaid';

      // We provide custom defaults based on the best-case match (we eyeballed these)
      case 'er':
      case 'erDiagram':
        return 'java';
      case 'quadrantChart':
        return 'go';
      case 'state':
      case 'stateDiagram':
      case 'stateDiagram-v2':
        return 'stylus';
      case 'class':
      case 'classDiagram':
        return 'ruby';
      case 'git':
      case 'gitGraph':
        return 'python';

      // Default to JavaScript
      default:
        return 'javascript';
    }
  }, [props.graphType]);

  return <CodeMirror {...props} code={props.graphCode ?? ''} lang={language} />;
};

const MemoizedSirenaCodeEditor = React.memo(SirenaCodeEditor);
MemoizedSirenaCodeEditor.displayName = 'SirenaCodeEditor';

export default MemoizedSirenaCodeEditor;
