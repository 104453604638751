import type { APIResponse } from '@stargate/api';

/**
 * The default content for a code snippet, that is injected
 * when a new code snippet is created as a placeholder.
 */
export const PLACEHOLDER_DEFAULT_CONTENT = '__editor__placeholder__content__';

/**
 * Check if the code has a placeholder code.
 *
 * @param code The code to check.
 * @returns True if the code is a placeholder.
 */
export const hasPlaceholderCode = (code: string) =>
  code.includes(PLACEHOLDER_DEFAULT_CONTENT);

/**
 * Filter the placeholder content from a code string.
 *
 * @param code A code string.
 * @returns A code string without the placeholder content.
 */
export const filterPlaceholderCode = (code: string) => {
  return code.replaceAll(PLACEHOLDER_DEFAULT_CONTENT, '');
};

/**
 * Get placeholder data for a code snippet.
 *
 * @returns Placeholder data for a code snippet.
 */
export const getPlaceholderData = () => {
  return {
    ...placeholderCodeSnippet,
    language: 'typescript' as const,
    code: placeholderCode,
  };
};

/**
 * Placeholder used to provide a "PlaceHolder Code Snippet" when inserting a new code snippet.
 */
const placeholderCodeSnippet = {
  id: 'placeholder',
  path: 'src/we-love-docs.ts',
  repository: 'rtfm',
  repositoryId: '24243234',
  branchName: 'main',
  repositoryOwner: 'joggr',
  repositoryOwnerId: '53264235',
  type: 'file',
  access: 'none',
  createdAt: '2042-09-01T00:00:00Z',
  updatedAt: '2042-09-01T00:00:00Z',
  lineNumberStart: 1,
  lineNumberEnd: 17,
} satisfies APIResponse<'GET /code-sources/:codeSourceId'>;

/**
 * Placeholder code snippet data.
 */
const placeholderCode = `
import joggrdoc, { type Code, type JoggrDoc } from '@joggr/doc';

const doc = joggrdoc.create({
  name: 'joggr',
  description: 'A code snippet manager for developers',
  autoUpdateDocs: true
});

export default doc;
        `.trim();
