import React from 'react';

import {
  IconBook2,
  type RikerNamedIcon,
  type RikerNamedIconProps,
  useTheme,
} from '@joggrdocs/riker';

export type GitHubRepositoryIconProps = RikerNamedIconProps;

/**
 * A component that displays a repository icon.
 */
export const GitHubRepositoryIcon = React.forwardRef<
  RikerNamedIcon,
  GitHubRepositoryIconProps
>((props, ref) => {
  const theme = useTheme();
  return (
    <IconBook2
      {...props}
      ref={ref}
      fill={props.fill ?? theme.palette.primary.light}
      color={props.color ?? theme.palette.primary.dark}
    />
  );
});
GitHubRepositoryIcon.displayName = 'GitHubRepositoryIcon';
