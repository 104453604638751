import { type langNames, loadLanguage } from '@uiw/codemirror-extensions-langs';

/*
|==========================================================================
| languages
|==========================================================================
|
| Support syntax highlighting for any given language.
|
*/

export type Language = (typeof langNames)[number];

export type LanguagesResult = Exclude<ReturnType<typeof loadLanguage>, null>;

const languages = (language: Language): LanguagesResult => {
  // Default to C as one of the most common language ancestor
  const loadedLanguage = loadLanguage(language) ?? loadLanguage('c');

  if (loadedLanguage !== null) {
    return loadedLanguage;
  }
  throw new Error(`Language "${language}" is not supported.`);
};

export default languages;
