import React from 'react';

import useDirectoryTree from '../../hooks/use-directory-tree';
import type { DirectoryTreeNode } from '../../types';
import { directoryCreateIconName } from '../Icons';
import { ActionButton, type ActionButtonProps } from './ActionButton';
import { DirectoryActionDialog } from './DirectoryActionDialog';

/*
|==========================================================================
| DirectoryCreateAction
|==========================================================================
|
| Create a directory from the current selection or at the top level.
|
*/

export interface DirectoryCreateActionProps
  extends Pick<ActionButtonProps, 'size'> {
  directory?: DirectoryTreeNode;
}

export const DirectoryCreateAction: React.FC<DirectoryCreateActionProps> = ({
  directory,
  size = 'small',
}) => {
  const [open, setOpen] = React.useState(false);
  const directoryTree = useDirectoryTree();

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  return (
    <React.Fragment>
      <ActionButton
        hint='Create a new directory'
        icon={directoryCreateIconName}
        size={size}
        onClick={handleOpen}
      />
      {open && directoryTree.tree && (
        <DirectoryActionDialog
          action='create'
          directory={directory}
          onClose={handleClose}
        />
      )}
    </React.Fragment>
  );
};
