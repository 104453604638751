import React from 'react';
import type * as TypeFest from 'type-fest';

import {
  RikerIcon,
  type RikerIconName,
  type RikerIconProps,
  useTheme,
} from '@joggrdocs/riker';

import { generateComponentClasses } from '@stargate/theme';

/*
|==========================================================================
| IconRiker
|==========================================================================
|
| Component for a Riker Icon
|
*/

export const iconRikerClasses = generateComponentClasses('IconRiker', [
  'root',
] as const);

export type IconRikerProps = TypeFest.Simplify<
  Omit<RikerIconProps, 'name'> & {
    icon: RikerIconName;
  }
>;

/**
 * Component for a Riker Icon
 */
export const IconRiker = React.forwardRef<any, IconRikerProps>(
  ({ icon, ...props }, ref) => {
    const theme = useTheme();
    return (
      <RikerIcon
        {...props}
        classes={iconRikerClasses}
        ref={ref}
        stroke={2.1}
        name={icon}
        color={theme.utils.modeValue({
          light: theme.palette.primary.dark,
          dark: theme.palette.primary.light,
        })}
      />
    );
  }
);
IconRiker.displayName = 'IconRiker';
