import { NodeViewContent, NodeViewWrapper } from '@tiptap/react';
import _ from 'lodash';
import React from 'react';

import { RikerIcon } from '@joggrdocs/riker';

import BlockEmpty from '@dashdraft/components/BlockEmpty';
import { type ActionItem, Actions } from '@stargate/components/Actions';
import { downloadImage } from '@stargate/lib/dashdraft/lib/media';
import type { DashDraftNodeViewProps } from '@stargate/lib/dashdraft/types';

import type { SetImageOptions, SetImagePayload } from '../../extensions/image';
import { MediaBlock } from '../MediaBlock';
import { MediaFullScreenDialog } from '../MediaFullScreenDialog';
import { MediaImage } from '../MediaImage';
import { ImageForm, type ImageFormProps } from './ImageForm';

export type ImageAction =
  | 'edit'
  | 'delete'
  | 'fullscreen'
  | 'open'
  | 'download';

export type ImageNodeViewProps = DashDraftNodeViewProps<
  SetImagePayload & { options: SetImageOptions }
>;

/**
 * This component is responsible for rendering the image node in the editor.
 */
export const ImageNodeView = React.memo<ImageNodeViewProps>(
  ({ editor, deleteNode, node, updateAttributes }) => {
    const { src, alt, options } = node.attrs;
    const [popoverOpen, setPopoverOpen] = React.useState(
      options?.forceOpen ?? false
    );
    const [fullScreenOpen, setFullScreenOpen] = React.useState(false);

    /*
    |------------------
    | Computed
    |------------------
    */
    const actions = React.useMemo(() => {
      const actions: Array<ActionItem<ImageAction>> = [];
      if (editor.isEditable) {
        actions.push({
          icon: 'edit',
          primaryLabel: 'Edit',
          action: 'edit',
          type: 'button',
        });
      }

      if (!_.isNil(src)) {
        actions.push(
          {
            icon: 'arrow-up-right',
            primaryLabel: 'View original',
            action: 'open',
            type: 'button',
          },
          {
            icon: 'arrows-maximize',
            primaryLabel: 'Fullscreen',
            action: 'fullscreen',
            type: 'button',
          },
          {
            icon: 'download',
            primaryLabel: 'Download',
            action: 'download',
            type: 'button',
          }
        );
      }

      if (editor.isEditable) {
        actions.push(
          {
            type: 'divider',
          },
          {
            icon: 'trash',
            primaryLabel: 'Delete',
            action: 'delete',
            type: 'button',
          }
        );
      }

      return actions;
    }, [src, editor.isEditable]);

    /*
    |------------------
    | Handlers
    |------------------
    */

    const handleOpen = React.useCallback(() => {
      setPopoverOpen(true);
    }, []);

    const handleClose = React.useCallback(() => {
      setPopoverOpen(false);
    }, []);

    const handleSave = React.useCallback<ImageFormProps['onSave']>(
      (payload) => {
        updateAttributes({
          src: payload.src,
          alt: payload.alt,
        });
        setPopoverOpen(false);
      },
      [updateAttributes]
    );

    /*
    |------------------
    | Handlers: Actions
    |------------------
    */

    const handleCloseFullscreen = React.useCallback(() => {
      setFullScreenOpen(false);
    }, []);

    const handleAction = React.useCallback(
      (action: ImageAction) => {
        switch (action) {
          case 'delete':
            deleteNode();
            break;
          case 'edit':
            handleOpen();
            break;
          case 'fullscreen':
            setFullScreenOpen(true);
            break;
          case 'download':
            if (!_.isNil(src)) {
              void downloadImage(src, 'joggr-img-download');
            }
            break;
          case 'open':
            if (!_.isNil(src)) {
              window.open(src, '_blank');
            }
            break;
          default:
            break;
        }
      },
      [src, handleOpen, deleteNode]
    );

    return (
      <NodeViewWrapper>
        <MediaBlock
          mediaType='image'
          open={popoverOpen}
          onClose={handleClose}
          actions={
            <React.Fragment>
              <Actions<ImageAction>
                size='small'
                iconColor='dark'
                onAction={handleAction}
                actions={actions}
              />
              <MediaFullScreenDialog
                open={fullScreenOpen}
                onClose={handleCloseFullscreen}
              >
                <MediaImage
                  src={src}
                  alt={alt}
                  style={{
                    width: '100%',
                    height: 'auto',
                  }}
                />
              </MediaFullScreenDialog>
            </React.Fragment>
          }
          popoverContent={
            <ImageForm
              defaultSrc={src}
              defaultAlt={alt}
              onSave={handleSave}
              onClose={handleClose}
            />
          }
        >
          {!_.isEmpty(src) && <MediaImage src={src} alt={alt} />}
          {_.isEmpty(src) && (
            <BlockEmpty
              helperIcon={<RikerIcon name='photo' />}
              helperText={'Click to add an image'}
              onClick={handleOpen}
            />
          )}
        </MediaBlock>
        <div style={{ display: 'none' }}>
          <NodeViewContent />
        </div>
      </NodeViewWrapper>
    );
  }
);
ImageNodeView.displayName = 'ImageNodeView';
