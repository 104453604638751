import React from 'react';
import type * as TypeFest from 'type-fest';

import { Box, IconCopy, Stack } from '@joggrdocs/riker';

import { useDebugSession } from '@stargate/lib/debug-session';
import { useNotify } from '@stargate/lib/notify';
import { generateComponentClasses } from '@stargate/theme';

/*
|==========================================================================
| DebugId
|==========================================================================
|
| A viewer for the debug id, that a user can share with support.
|
*/

export const debugIdClasses = generateComponentClasses('DebugId');

export type DebugIdProps = TypeFest.Simplify<{
  size?: 'small' | 'medium';
}>;

export const DebugId: React.FC<DebugIdProps> = ({ size = 'small' }) => {
  const debugSession = useDebugSession();
  const notify = useNotify();

  /*
  |------------------
  | Computed
  |------------------
  */

  const sizing = React.useMemo(() => {
    switch (size) {
      case 'medium':
        return {
          fontSize: '14px',
          iconSize: 16,
        };
      default:
        return {
          fontSize: '12px',
          iconSize: 14,
        };
    }
  }, [size]);

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const handleSessionIdClick = React.useCallback(() => {
    navigator.clipboard.writeText(debugSession.id);
    notify.send({
      message: 'Copied `session_id` to clipboard',
      severity: 'success',
    });
  }, [debugSession.id, notify]);

  return (
    <Stack
      spacing={1}
      className={debugIdClasses.root}
      direction='row'
      alignContent={'center'}
      justifyContent={'center'}
      alignItems={'center'}
      onClick={handleSessionIdClick}
      sx={{
        textAlign: 'center',
        fontSize: sizing.fontSize,
        color: (theme) => theme.palette.text.secondary,
        cursor: 'pointer',
        width: '100%',
        [`&.${debugIdClasses.root}:hover`]: {
          color: (theme) => theme.palette.text.primary,
        },
      }}
    >
      <Box>Copy debug session ID</Box>
      <IconCopy size={sizing.iconSize} />
    </Stack>
  );
};
