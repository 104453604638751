import React from 'react';

import { Button, IconDownload } from '@joggrdocs/riker';

import useMermaidDownload from '../hooks/useMermaidDownload';

/*
|==========================================================================
| SirenaDownloadButton
|==========================================================================
|
| This component renders a download button for mermaid graphs.
|
*/

export interface SirenaDownloadButtonProps {
  svg?: string;
  graphType?: string;
  disabled?: boolean;
}

export const SirenaDownloadButton: React.FC<SirenaDownloadButtonProps> = (
  props
) => {
  const mermaidDownload = useMermaidDownload();

  const handleDownload = React.useCallback(() => {
    if (props.svg) {
      mermaidDownload(props.svg, props.graphType);
    }
  }, [mermaidDownload, props.graphType, props.svg]);

  return (
    <Button
      variant='contained'
      size='small'
      color='secondary'
      startIcon={<IconDownload />}
      text='Download'
      onClick={handleDownload}
      disabled={!props.svg || props.disabled}
    />
  );
};

const MemoizedSirenaDownloadButton = React.memo(SirenaDownloadButton);
MemoizedSirenaDownloadButton.displayName = 'SirenaDownloadButton';

export default MemoizedSirenaDownloadButton;
