import { LocalStorage } from '@stargate/lib/storage';
import _ from 'lodash';
import { create } from 'zustand';

/*
|==========================================================================
| SideBar
|==========================================================================
|
| A global store, used to manage the document share feature.
|
*/

export type DrawerContent = 'explorer';

export interface State {
  drawerOpen: boolean;
  drawerContent: DrawerContent | null;
  drawerWidth: number;
  drawerMinWidth: number;
  drawerMaxWidth: number;
}

export interface Actions {
  closeDrawer: () => void;
  openDrawer: (payload: { drawerContent: DrawerContent }) => void;
  setDrawerWidth: (payload: { width: number }) => void;
}

export interface Store {
  state: State;
  actions: Actions;
}

/**
 * The width of a 13-inch MacBook Pro in pixels.
 */
const MACBOOK_PRO_13_INCH_WIDTH = 1440;

const defaultDrawerWidth =
  window.innerWidth > MACBOOK_PRO_13_INCH_WIDTH ? 280 : 235;

const getDefaultDrawerOpen = (): boolean => {
  const storageValue = LocalStorage.get<boolean>('sidebar-drawer:open');

  if (_.isNil(storageValue)) {
    return window.innerWidth > MACBOOK_PRO_13_INCH_WIDTH;
  }
  try {
    return storageValue;
  } catch {
    return true;
  }
};

const useStore = create<Store>((set) => ({
  state: {
    drawerOpen: getDefaultDrawerOpen(),
    drawerContent: 'explorer',
    drawerWidth:
      LocalStorage.get<number>('sidebar-drawer:width') ?? defaultDrawerWidth,
    drawerMinWidth: 235,
    drawerMaxWidth: 700,
  },
  actions: {
    closeDrawer: () => {
      set(({ state }) => ({
        state: {
          ...state,
          drawerOpen: false,
        },
      }));
      LocalStorage.set('sidebar-drawer:open', false);
    },
    openDrawer: (payload) => {
      set(({ state }) => ({
        state: {
          ...state,
          drawerContent: payload.drawerContent,
          drawerOpen: true,
        },
      }));
      LocalStorage.set('sidebar-drawer:open', true);
    },
    setDrawerWidth: (payload) => {
      // New action implementation
      set(({ state }) => ({
        state: {
          ...state,
          drawerWidth: payload.width,
        },
      }));
      LocalStorage.set('sidebar-drawer:width', payload.width);
    },
  },
}));

const useSideBar = (): [State, Actions] => {
  return useStore(({ state, actions }) => [state, actions]);
};

export default useSideBar;
