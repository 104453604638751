import _ from 'lodash';
import React from 'react';

import {
  Box,
  Button,
  Chip,
  FormHelperText,
  InputAdornment,
  RikerIcon,
  Stack,
  TextField,
  Tooltip,
  Typography,
  generateComponentClasses,
  useTheme,
} from '@joggrdocs/riker';

import FilePathTruncate from '@stargate/components/Utils/FilePathTruncate';
import {
  GitHubDirectorySelector,
  type GitHubDirectorySelectorProps,
  GitHubRepositoryAutocomplete,
} from '@stargate/features/github';
import type { GitHubRepository } from '@stargate/features/github';

import { githubFileTreeItemClasses } from '@/features/github/components/Utils/GitHubFileTreeItem';

export const joggrDocFileLocationFormClassNames = generateComponentClasses(
  'JoggrDocFileLocationForm',
  [
    'directory-separator',
    'repository-select',
    'file-location',
    'file-name',
  ] as const
);

export interface JoggrDocFileLocationFormProps {
  /**
   * The file tree to display in the directory selector.
   */
  fileTree?: GitHubDirectorySelectorProps['fileTree'];

  /**
   * The GitHub repository to select.
   */
  githubRepository?: GitHubRepository;

  /**
   * The path of the file in the repository.
   */
  directoryPath?: string;

  /**
   * The file name to display in the form.
   */
  fileName?: string;

  /**
   * Error message to display when the file name is invalid.
   */
  fileNameError?: string | null;

  /**
   * Is the form in loading state.
   *
   * @default false
   */
  loading?: boolean;

  /**
   * Disable the repository select.
   *
   * @default false
   */
  disableRepositorySelect?: boolean;

  /**
   * Callback that triggers when the file name changes.
   *
   * @param fileName A string representing the new file name
   */
  onChangeFilename: (fileName: string) => void;

  /**
   * Callback that triggers when the directory changes.
   *
   * @param directoryPath A string representing the new directory
   */
  onChangeDirectory: (directoryPath: string) => void;

  /**
   * Callback that triggers when the repository changes.
   */
  onChangeRepository: (githubRepository: GitHubRepository | null) => void;
}

export const JoggrDocFileLocationForm: React.FC<
  JoggrDocFileLocationFormProps
> = ({
  githubRepository,
  fileTree,
  loading = false,
  directoryPath,
  disableRepositorySelect = false,
  fileName,
  fileNameError,
  onChangeRepository,
  onChangeDirectory,
  onChangeFilename,
}) => {
  const theme = useTheme();
  const [showHelper, setShowHelper] = React.useState(false);
  const repoSelectRef = React.useRef<HTMLDivElement | null>(null);

  /*
  |------------------
  | Computed
  |------------------
  */

  const rootFilePath = React.useMemo(
    () => directoryPath === '/' || directoryPath === '',
    [directoryPath]
  );

  return (
    <Stack
      direction='column'
      spacing={1.5}
      alignContent='flex-start'
      alignItems='flex-start'
      classes={{
        root: joggrDocFileLocationFormClassNames.root,
      }}
      sx={{
        width: '100%',
      }}
      // We always want to close the helper when the user clicks on the form
      onClick={() => {
        setShowHelper(false);
      }}
    >
      <Stack
        direction='row'
        alignItems='center'
        spacing={1}
        divider={
          <span
            className={
              joggrDocFileLocationFormClassNames['directory-separator']
            }
          >
            /
          </span>
        }
        sx={{
          width: '100%',
          [`& .${joggrDocFileLocationFormClassNames['directory-separator']}`]: {
            fontSize: '2rem',
          },
        }}
      >
        <Box
          className={joggrDocFileLocationFormClassNames['repository-select']}
          sx={{
            minWidth: '236px',
          }}
        >
          <GitHubRepositoryAutocomplete
            inputRef={repoSelectRef}
            defaultValue={githubRepository}
            disabled={loading || disableRepositorySelect}
            onChange={onChangeRepository}
            disableClearable
            openOnFocus
          />
        </Box>
        <Chip
          className={joggrDocFileLocationFormClassNames['file-location']}
          icon={<RikerIcon name='folder-filled' />}
          sx={{
            px: 0.5,
            borderRadius: '4px',
          }}
          shape='rounded'
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setShowHelper(true);
          }}
          label={
            <Typography
              sx={{
                width: '100%',
                whiteSpace: 'nowrap',
                overflowX: 'hidden',
                textOverflow: 'ellipsis',
                textAlign: 'center',
              }}
            >
              {rootFilePath && '<root>'}
              {!rootFilePath && !_.isNil(directoryPath) && (
                <FilePathTruncate filePath={directoryPath} maxCharacters={40} />
              )}
            </Typography>
          }
          size='medium'
        />
        <TextField
          size='small'
          placeholder='name-of-my-doc'
          value={fileName}
          className={joggrDocFileLocationFormClassNames['file-name']}
          sx={{
            width: getWidthPercentFromFontSize(fileName?.length ?? 0, 16),
            minWidth: '184px',
            position: 'relative',
          }}
          onChange={(e) => {
            onChangeFilename(e.target.value);
          }}
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position='end'>
                  <Box
                    sx={{
                      position: 'absolute',
                      right: 0,
                      top: 0,
                      bottom: 0,
                    }}
                  >
                    <Stack
                      direction='row'
                      alignItems='center'
                      alignSelf='stretch'
                      sx={{
                        borderLeft: `1px solid ${theme.palette.divider}`,
                        bgcolor: theme.utils.modeValue({
                          light: theme.palette.grey[100],
                          dark: theme.palette.grey[800],
                        }),
                        color: theme.palette.text.secondary,
                        p: 1,
                      }}
                    >
                      <span>.md</span>
                    </Stack>
                  </Box>
                </InputAdornment>
              ),
            },
          }}
          error={!_.isNil(fileNameError)}
          fullWidth
        />
      </Stack>
      <FormHelperText error={!_.isNil(fileNameError)} variant='outlined'>
        {fileNameError ??
          'Name your file and select the GitHub repository & directory where you want to commit the document to in your codebase.'}
      </FormHelperText>
      <Tooltip
        title={
          showHelper
            ? 'Select the location in GitHub where you will save (commit) your document.'
            : undefined
        }
        open={showHelper}
        onClose={() => {
          setShowHelper(false);
        }}
        disableHoverListener
        disableFocusListener
        disableTouchListener
        placement='left-start'
        arrow
      >
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '100%',
            zIndex: theme.zIndex.tooltip + 1,
            [`& .${githubFileTreeItemClasses.root}`]: {
              pointerEvents: _.isNil(fileTree) ? 'none' : undefined,
            },
          }}
        >
          {fileTree?.tree && fileTree?.tree.length > 0 && (
            <GitHubDirectorySelector
              fileTree={fileTree}
              showRoot
              defaultDirectory={rootFilePath ? '/' : directoryPath}
              onChangeDirectory={onChangeDirectory}
              disabled={loading || _.isNil(githubRepository)}
              loading={loading}
              readonly={false}
            />
          )}
          {(_.isNil(fileTree?.tree) || fileTree.tree.length === 0) && (
            <React.Fragment>
              <Stack
                direction='column'
                spacing={2}
                justifyContent='center'
                alignItems='center'
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  zIndex: theme.zIndex.tooltip + 1,
                }}
              >
                <Typography variant='body1' fontSize='18px'>
                  You need to select a GitHub repository to view & select a
                  directory.
                </Typography>
                <Button
                  startIcon={<RikerIcon name='select' />}
                  onClick={() => {
                    repoSelectRef.current?.focus();
                  }}
                >
                  Select a GitHub Repository
                </Button>
                <Box
                  sx={{
                    bgcolor: theme.utils.modeValue({
                      light: theme.palette.grey[100],
                      dark: theme.palette.grey[800],
                    }),
                    opacity: 0.8,
                    position: 'absolute',
                    zIndex: -1,
                    inset: '-16px',
                  }}
                />
              </Stack>
              <GitHubDirectorySelector
                fileTree={{
                  tree: [
                    { path: 'docs', type: 'directory', sha: '-' },
                    { path: 'docs/guides', type: 'directory', sha: '-' },
                    { path: 'src', type: 'directory', sha: '-' },
                    { path: 'public', type: 'directory', sha: '-' },
                  ],
                  sha: '-',
                }}
                defaultDirectory={'docs'}
                showRoot
                onChangeDirectory={_.noop}
                loading={loading}
                readonly={false}
                disabled
              />
            </React.Fragment>
          )}
        </Box>
      </Tooltip>
    </Stack>
  );
};

/*
|------------------
| Utils
|------------------
*/

/**
 * Hacky way to calculate the width of the text field based on the font size,
 * probably not the best way to do this, and need a util in Riker or something.
 *
 * @param textLength How many characters are in the text
 * @param fontSize A number representing the font size
 * @returns A string representing the width in pixels
 */
const getWidthPercentFromFontSize = (
  textLength: number,
  fontSize: number
): string => {
  const width = Math.max(textLength + 12, fontSize);
  return `${Math.min(100, width)}%`;
};
