import { Box, type SxProps, Tooltip, useTheme } from '@joggrdocs/riker';
import React from 'react';

import { generateComponentClasses } from '@stargate/theme';

import type { ActionItem } from '../types';
import { ActionsButton, actionsButtonClasses } from './ActionsButton';
import { ActionsMenu } from './ActionsMenu';

export const actionsClasses = generateComponentClasses('Actions');

export interface ActionsProps<A extends string> {
  /**
   * Show a loading state
   */
  loading?: boolean;

  /**
   * The size of the Actions
   */
  size?: 'small' | 'medium';

  /**
   * The actions to render
   */
  actions: Array<ActionItem<A>>;

  /**
   *
   * @param action
   * @returns
   */
  onAction: (action: A) => void;

  /**
   * A hint to display when hovering over the Actions
   */
  hint?: string;

  /**
   * Additional styles
   */
  sx?: SxProps;

  /**
   * Override the icon color
   *
   * @default 'default'
   */
  iconColor?: 'default' | 'dark';
}

/**
 * Actions is a component that renders a button that opens a menu of actions.
 */
export const Actions = <A extends string>(props: ActionsProps<A>) => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const theme = useTheme();

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const handleMenuClose = React.useCallback(() => {
    setMenuOpen(false);
  }, []);

  const handleMenuOpen = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setMenuAnchorEl(event.currentTarget);
      setMenuOpen(true);
    },
    []
  );

  return (
    <Box
      className={actionsClasses.root}
      component='span'
      sx={{
        [`.${actionsClasses.root}`]: {
          ...props.sx,

          [`& .${actionsButtonClasses.icon}`]: {
            color:
              props.iconColor === 'dark'
                ? theme.palette.common.white
                : undefined,
          },
        },
      }}
    >
      <Tooltip title={props.hint ?? ''} placement='top' disableInteractive>
        <div>
          <ActionsButton
            size={props.size}
            loading={props.loading}
            onClick={handleMenuOpen}
          />
        </div>
      </Tooltip>
      <ActionsMenu
        open={menuOpen}
        anchorEl={menuAnchorEl}
        onClose={handleMenuClose}
        onAction={props.onAction}
        size={props.size}
        items={props.actions}
      />
    </Box>
  );
};
