import { IconSend } from '@joggrdocs/riker';
import React from 'react';

import { Button, type ButtonProps } from '@joggrdocs/riker';

export type Props = Partial<ButtonProps>;

export const SendInviteButton: React.FC<Props> = (props) => {
  return (
    <Button
      color='primary'
      startIcon={<IconSend />}
      variant='contained'
      {...props}
    >
      Send Invite
    </Button>
  );
};

export default SendInviteButton;
