import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@joggrdocs/riker';
import React from 'react';

import { IconAvatar } from '@stargate/components/Avatars';
import {
  DirectorySelect,
  type DirectoryTree,
} from '@stargate/features/directories';
import { useLocalization } from '@stargate/localization';

import type { JDocMode } from '../../types';

export interface JoggrDocBreadcrumbsDialogProps {
  /**
   * The JoggrDoc mode.
   */
  mode: Exclude<JDocMode, 'view'>;

  /**
   * Callback triggered when the dialog is closed.
   */
  open: boolean;

  /**
   * The directory tree.
   */
  tree?: DirectoryTree;

  /**
   * Id of the current document directory.
   */
  defaultDirectoryId?: string | null;

  /**
   * Callback triggered when the dialog is closed.
   */
  onClose: () => void;

  /**
   * Callback triggered when the dialog is confirmed.
   */
  onConfirm: (directoryId?: string | null) => void;
}

export const JoggrDocBreadcrumbsDialog: React.FC<
  JoggrDocBreadcrumbsDialogProps
> = ({ open, mode, tree, defaultDirectoryId, onClose, onConfirm }) => {
  const [directoryId, setDirectoryId] = React.useState(defaultDirectoryId);
  const localz = useLocalization();
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        startIcon={<IconAvatar icon='folder-open' size={44} />}
        title={localz.formatMessage(
          `features.docs.${mode}.breadcrumbs.dialog.title`
        )}
        titleTypographyProps={{ variant: 'h5' }}
        subTitle={localz.formatMessage(
          `features.docs.${mode}.breadcrumbs.dialog.subtitle`
        )}
        onClose={onClose}
      />
      <DialogContent>
        <DirectorySelect
          defaultDirectoryId={defaultDirectoryId}
          directoryTree={tree || []}
          onChangeDirectory={(directory) => setDirectoryId(directory?.id)}
        />
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={() => onConfirm(directoryId)}>
          {localz.formatMessage('generic.confirm')}
        </Button>
        <Button variant='outlined' onClick={onClose}>
          {localz.formatMessage('generic.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
