import type * as IntlMessageFmt from 'intl-messageformat';
import React from 'react';
import { useIntl as useReactIntl } from 'react-intl';

import type { LocaleId } from '../types';

export interface Localization extends Omit<ReactIntl, 'formatMessage'> {
  /**
   * Formats a message based on the available translations.
   * @param id The message id to format.
   * @param values The values to interpolate into the message.
   * @param opts Additional options for formatting the message.
   */
  formatMessage<V extends IntlMessageFmt.FormatXMLElementFn<any>>(
    // @todo Fix types
    id: LocaleId,
    values?: Record<string, IntlMessageFmt.PrimitiveType | V>,
    opts?: IntlMessageFmt.Options
  ): string;
}

/**
 * A thin wrapper around `useIntl` that provides types based on the available translations.
 */
export const useLocalization = () => {
  const intl = useReactIntl();

  const formatMessage = React.useCallback<Localization['formatMessage']>(
    (id, values, opts) => {
      return intl.formatMessage(
        { id, defaultMessage: 'Missing Translation' },
        values,
        opts
      ) as string;
    },
    [intl]
  );

  return {
    ...intl,
    formatMessage,
  };
};

/*
|------------------
| Utils
|------------------
*/

type ReactIntl = ReturnType<typeof useReactIntl>;

type ReactIntlFormatMessageFunc = ReactIntl['formatMessage'];
