import _ from 'lodash';
import React from 'react';
import type * as TF from 'type-fest';

import { useDashDraftEditor } from '@stargate/dashdraft';

import {
  type JDocModifiableField,
  type JDocRequiredField,
  getMissingFields,
  getModifiedFields,
} from '@/features/docs/lib/fields';
import type {
  JDoc,
  JDocDraft,
  JDocGitHub,
  JDocMode,
} from '@/features/docs/types';

export interface UseJdocDraftFieldsHook {
  /**
   * The fields that have been modified in the JoggrDoc Local Draft
   */
  modifiedFields: JDocModifiableField[];

  /**
   * The fields that are missing from the JoggrDoc Local Draft
   */
  missingFields: JDocRequiredField[];

  /**
   * Validate if the JoggrDoc Local Draft can be saved, and return the missing fields, data, and modified fields
   */
  validateCurrentDraft: () => JDocDraftValidResult;
}

export interface UseJDocDraftFieldsPayload {
  /**
   * The mode of the JoggrDoc
   */
  mode: JDocMode;

  /**
   * The JoggrDoc
   */
  doc?: JDocDraft | null;

  /**
   * The raw Local JoggrDocDraft
   */
  draft?: JDocDraft | null;
}

export const useJdocValidate = (
  payload: UseJDocDraftFieldsPayload
): UseJdocDraftFieldsHook => {
  const editor = useDashDraftEditor();

  /*
  |------------------
  | Computed
  |------------------
  */

  const combined = React.useMemo(() => {
    return combineDocAndDraft(payload.mode, payload.doc, payload.draft);
  }, [payload.mode, payload.doc, payload.draft]);

  const missing = React.useMemo(() => {
    return getMissingFields(combined);
  }, [combined]);

  const modified = React.useMemo(() => {
    return getModifiedFields(payload.mode, payload.doc, payload.draft).map(
      (field) => field.field
    );
  }, [payload.mode, payload.doc, payload.draft]);

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const validate = React.useCallback<() => JDocDraftValidResult>(() => {
    if (isDraftSaveable(combined)) {
      return {
        valid: true,
        draft: {
          ...combined,
          // We always want to save the latest version of the content in the editor
          content: editor.getJSON(),
        },
        missing: [],
        modified: modified,
      };
    }

    return {
      valid: false,
      draft: null,
      missing,
      modified,
    };
  }, [combined, editor.getJSON, missing, modified]);

  return {
    modifiedFields: modified,
    missingFields: missing,
    validateCurrentDraft: validate,
  };
};

/**
 * A saveable JoggrDocDraft
 */
export type JDocDraftSaveable = TF.Merge<
  TF.SetRequired<JDocDraft, 'title' | 'content'>,
  {
    github: JDocGitHub;
  }
>;

export interface JDocDraftValidSuccessResult {
  valid: true;
  draft: JDocDraftSaveable;
  missing: never[];
  modified: JDocModifiableField[];
}

export interface JDocDraftValidFailureResult {
  valid: false;
  draft: null;
  missing: JDocRequiredField[];
  modified: JDocModifiableField[];
}

export type JDocDraftValidResult =
  | JDocDraftValidSuccessResult
  | JDocDraftValidFailureResult;

/*
|------------------
| Utils
|------------------
*/

/**
 * Check if a JoggrDocDraft is saveable
 *
 * @param draft A JoggrDocDraft
 * @returns True if the draft is saveable
 */
const isDraftSaveable = (
  draft?: Partial<JDocDraft> | null
): draft is JDocDraftSaveable => {
  if (_.isNil(draft)) {
    return false;
  }

  return getMissingFields(draft).length === 0;
};

/**
 * Transform a JoggrDocDraft to a JoggrDoc by overriding the fields based on the draft
 *
 * @param doc The JoggrDoc
 * @param draft The JoggrDocDraft
 * @returns The JoggrDoc
 */
const combineDocAndDraft = (
  mode: JDocMode,
  doc?: JDocDraft | null,
  draft?: JDocDraft | null
): JDoc | JDocDraft => {
  const modifiedFields = getModifiedFields(mode, doc, draft);
  let docClone = _.cloneDeep({ ...doc });
  for (const { field, newValue } of modifiedFields) {
    docClone = _.set(docClone, field, newValue);
  }
  return docClone;
};
