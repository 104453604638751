import { produce } from 'immer';
import { create } from 'zustand';

import { LocalStorage } from '../lib/local-storage';

export type UseStorageHook = {
  /**
   * Key-value storage.
   */
  data: Record<string, string | null>;

  /**
   * Set a key-value pair in storage.
   */
  set: (key: string, value: string) => void;

  /**
   * Remove a key from storage.
   */
  remove: (key: string) => void;

  /**
   * Clear all keys from storage.
   */
  clear: () => void;
};

/**
 * A zustand store for listening to changes to the browser storage.
 */
export const useStorageStore = create<UseStorageHook>((set) => ({
  data: LocalStorage.export(),
  set: (key, value) => {
    set(
      produce((draft) => {
        draft.data[key] = value;
      })
    );
  },
  remove: (key) => {
    set(
      produce((draft) => {
        draft.data[key] = null;
      })
    );
  },
  clear: () => {
    set(
      produce((draft) => {
        draft.data = {};
      })
    );
  },
  // WITHOUT zimmer...?
  // set: (key, value) => {
  //   set((state) => {
  //     state.data[key] = value;
  //     return state;
  //   });
  // },
  // remove: (key) => {
  //   set((state) => {
  //     state.data[key] = null;
  //     return state;
  //   });
  // },
  // clear: () => {
  //   set((state) => {
  //     state.data = {};
  //     return state;
  //   });
  // },
}));
