import React from 'react';

import api from '@stargate/api';
import { useNightingale } from '@stargate/lib/nightingale';

export interface Invite {
  id: string;
  emails: string[];
}

const useInvite = () => {
  const [sendInviteState, sendInviteActions] =
    api.useRequestClient('POST /users/invite');
  const [errors, setErrors] = React.useState<string[]>([]);
  const nightingale = useNightingale();

  /*
  |------------------
  | Methods
  |------------------
  */

  const reset = () => {
    sendInviteActions.reset();
    setErrors([]);
  };

  const submit = async (invite: Invite) => {
    if (invite.emails.length === 0) {
      setErrors(['Please enter at least one email address']);
      return 'error';
    }

    try {
      const result = await sendInviteActions.execute({
        body: {
          emails: invite.emails as [string, ...string[]],
          id: invite.id,
        },
      });

      if (result.status !== 'success') {
        if (result.failedEmails.length > 0) {
          setErrors(
            result.failedEmails.map(
              (email) => `Failed to send invite to ${email}`
            )
          );
        } else {
          setErrors(['Failed to send invites']);
        }
        return 'error';
      }
      setErrors([]);

      nightingale.capture(['sent', 'invite'], {
        inviteId: invite.id,
        emailCount: invite.emails.length,
        emails: invite.emails,
      });

      return 'success';
    } catch (err) {
      if (err instanceof Error && Object.hasOwn(err, 'statusCode')) {
        setErrors([
          err.statusCode === 400 && err.message
            ? err.message
            : 'Something went wrong, please try again',
        ]);
      }

      return 'error';
    }
  };

  return {
    errors,
    submit,
    reset,
    status: errors.length > 0 ? 'error' : sendInviteState.status,
    result: sendInviteState.result,
  };
};

export default useInvite;
