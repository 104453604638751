import React from 'react';

import DashDraftContext from '../components/DashDraftContext';

/**
 * A hook to get the current Dashdraft context.
 */
export const useDashdraftContext = () => {
  return React.useContext(DashDraftContext);
};
