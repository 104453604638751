import * as hookz from '@react-hookz/web';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import useLocation from '../../../hooks/use-location';

/*
|==========================================================================
| Redirect
|==========================================================================
|
| Redirects to a new location.
|
*/

export interface Props {
  to: string;
  replace?: boolean;
  preventScrollReset?: boolean;
}

const Redirect: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  hookz.useMountEffect(() => {
    navigate(
      {
        pathname: props.to,
        search: location.search,
      },
      {
        replace: props.replace ?? false,
        preventScrollReset: props.preventScrollReset ?? false,
      }
    );
  });

  return null;
};

export default Redirect;
