import clsx from 'classnames';
import React from 'react';

export interface BlockActionsProps
  extends React.HTMLAttributes<HTMLDivElement> {
  active?: boolean;
  transparent?: boolean;
}

/**
 * Component for rendering a block actions in the editor.
 */
export const BlockActions: React.FC<BlockActionsProps> = ({
  active = false,
  transparent = false,
  children,
  className,
  ...props
}) => {
  return (
    <div
      {...props}
      className={clsx('dashdraft-block-actions', className, {
        active,
        transparent,
      })}
    >
      {children}
    </div>
  );
};
