import { createLocale } from '@stargate/localization/lib/locale-factory';

export default createLocale('code', {
  'content.path.tooltip': 'Click to view <code>{path}</code> in GitHub',
  'explorer.btn.tooltip':
    'Click to open the Code Explorer & add Code Snippets directly from your codebase.',

  /*
  |------------------
  | File Tree
  |------------------
  */

  'tree.tooltip.close': 'Open the file tree to link code to your docs',
  'tree.tooltip.open': 'Close the file tree',
  'tree.drawer.toggle.tooltip': 'Toggle the file tree',
  'tree.title': 'File Tree',

  /*
  |------------------
  | Code Snippet & Links
  |------------------
  */

  'links.action.tooltip.exists': 'This file is already linked to the JoggrDoc',
  'links.action.tooltip.description':
    'A Code Link is a link to file or piece of code that Joggr tracks. If you make changes in the file Joggr notifies you in a pull request that the changes will make the document out-of-date.',
  'links.action.label': 'Link entire file',
  'snippets.action.tooltip.description':
    'A Code Snippet is a piece of code that is injected into a JoggrDoc, that Joggr tracks and auto-updates when you make changes to your codebase.',
  'snippets.action.label': 'Insert Code Snippet',
  'snippets.action.tooltip.warning.no-insert':
    'You cannot insert a Code Snippet unless your cursor in the JoggrDoc is on an empty line or you inserted a Code Snippet using the "/codesnippet" command.',
});
