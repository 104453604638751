import { Card } from '@joggrdocs/riker';
import React from 'react';

import type { AppErrorType } from '@stargate/app/types';
import { ErrorFeedback } from '@stargate/components/Feedback';
import { Page } from '@stargate/components/Utils';
import { useSearchParams } from '@stargate/routes';

export interface ErrorPageProps {
  errorType?: AppErrorType;
}

export const ErrorPage: React.FC<ErrorPageProps> = (props) => {
  const [searchParams] = useSearchParams('root.error');

  const errorType = React.useMemo<AppErrorType>(() => {
    if (props.errorType) {
      return props.errorType;
    }
    return searchParams.errorType ?? 'unknown';
  }, [props.errorType, searchParams.errorType]);

  return (
    <Page
      id={errorType}
      render={() => (
        <Card
          sx={{
            maxWidth: '600px',
            margin: '0 auto',
          }}
        >
          <ErrorFeedback type={errorType} reloadUrl={searchParams.reloadUrl} />
        </Card>
      )}
    />
  );
};
