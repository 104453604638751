import React from 'react';

import { useConfig } from '@stargate/hooks';

import { createDevLogger } from '../lib/dev-logger';

/**
 * Dev logger hook, to log development messages, that you can safely leave in your codebase (they don't log in production).
 *
 * @param prefix - The prefix to use for the logger, to add to all messages.
 * @returns A hook to use the logger.
 */
export const useDevLogger = (prefix?: string) => {
  const config = useConfig();
  return React.useMemo(() => createDevLogger(config, prefix), [config, prefix]);
};
