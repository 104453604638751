import React from 'react';

import type { AppErrorType } from '@stargate/app';

import { Img, type ImgProps } from './Img';

export interface ImgErrorProps extends Omit<ImgProps, 'alt' | 'src'> {
  /**
   * The type of error to display.
   */
  type: AppErrorType;
}

/**
 * Display fun & contextual error images to a user.
 */
export const ImgError = React.forwardRef<HTMLImageElement, ImgErrorProps>(
  (props, ref) => {
    const { type, ...rest } = props;

    const imgName = React.useMemo(() => {
      switch (type) {
        case 'not-found':
          return '404';
        case 'internal-server-error':
          return '500';
        default:
          return 'unknown';
      }
    }, [type]);

    return (
      <Img
        {...rest}
        ref={ref}
        alt={`Error - ${type}`}
        src={`/errors/${imgName}.png`}
      />
    );
  }
);
