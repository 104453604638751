import React from 'react';

import { Box, Typography } from '@joggrdocs/riker';

import type { MermaidGraphType } from '../lib/mermaidUtils';
import SirenaViewerError from './SirenaViewerError';

/*
|==========================================================================
| SirenaViewerFeedback
|==========================================================================
|
| Provides feedback to the user when there is an error or no results.
|
*/

export interface Props {
  error: Error | null;
  svg: string | null;
  loading: boolean;
  graphType: MermaidGraphType | null;
}

const SirenaViewerFeedback: React.FC<Props> = (props) => {
  /*
  |------------------
  | Computed
  |------------------
  */

  const showErrorFeedback: boolean = props.error !== null;
  const showNoResultsFeedback: boolean = !props.svg && !showErrorFeedback;
  const showFeedback: boolean =
    (showErrorFeedback || showNoResultsFeedback) && !props.loading;

  if (!showFeedback) {
    return null;
  }

  return (
    <Box
      className='sirena-viewer-feedback'
      sx={{
        zIndex: (theme) => theme.zIndex.appBar + 1,
        minHeight: props.svg ? 'auto' : '224px',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            p: 2,
            borderRadius: 2,
            elevation: 1,
            border: (theme) => `1px solid ${theme.palette.divider}`,
            minHeight: 0,
            display: 'block',
            textAlign: 'center',
            mt: 2,
            ml: 'auto',
            mr: 'auto',
            width: 'fit-content',
            bgcolor: (theme) => theme.palette.background.paper,
          }}
        >
          {showErrorFeedback && (
            <SirenaViewerError graphType={props.graphType ?? undefined} />
          )}
          {showNoResultsFeedback && (
            <React.Fragment>
              <Typography variant='h4' component='div' gutterBottom>
                No graph to display
              </Typography>
            </React.Fragment>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          opacity: (theme) => (theme.palette.mode === 'dark' ? 0.5 : 0.1),
          position: 'absolute',
          bgcolor: (theme) => theme.palette.common.black,
          zIndex: 0,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      />
    </Box>
  );
};

const SirenaViewerFeedbackMemo = React.memo(SirenaViewerFeedback);
SirenaViewerFeedbackMemo.displayName = 'SirenaViewerFeedback';

export default SirenaViewerFeedbackMemo;
