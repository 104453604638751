import { Extension } from '@tiptap/core';

import { Alerts } from './extensions/alerts';

export { alertTypes } from '@joggrdocs/bumblebee';
export type * from './types';

export default Extension.create({
  name: 'dashdraft-alerts',

  addExtensions() {
    return [Alerts];
  },
});
