import _ from 'lodash';

import type { IconDefinition } from '../types';
import rikerJson from './riker.json';

export const rikerIcons: IconDefinition[] = _.chain(rikerJson)
  .reduce<IconDefinition[]>((result, value, key) => {
    result.push({
      id: value.name,
      name: value.name.replaceAll('-', ' '),
      value: key,
      category: value.category || 'General',
      tags: value.tags,
    });
    return result;
  }, [])
  .sortBy('name')
  .value();

export const rikerIconsByCategory = _.chain(rikerIcons)
  .groupBy('category')
  .map((value, key) => ({
    category: key,
    icons: value,
  }))
  .sortBy('category')
  .value();
