/**
 * Safely get an error.
 *
 * @param error A possible error.
 * @param fallback A fallback error.
 * @returns The error or the fallback error, or a new error with "Unknown Error" as the message.
 */
export const getErrorSafely = (
  error: unknown,
  fallback?: Error | string
): Error => {
  if (error instanceof Error) {
    return error;
  }

  if (typeof error === 'string') {
    return new Error(error);
  }

  if (fallback instanceof Error) {
    return fallback;
  }

  if (typeof fallback === 'string') {
    return new Error(fallback);
  }

  return new Error('Unknown Error');
};
