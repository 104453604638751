import { Extension } from '@tiptap/core';

import AiCore from './extensions/ai';
import type { DashDraftExtensionsAIConfig as TDashDraftExtensionsAIConfig } from './types';

export type DashDraftExtensionsAIConfig = TDashDraftExtensionsAIConfig;

export default (config: TDashDraftExtensionsAIConfig) =>
  Extension.create({
    name: 'dashdraft-ai',

    addExtensions() {
      return [AiCore(config)];
    },
  });
