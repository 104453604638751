import {
  Avatar,
  Box,
  Dialog,
  DialogTitle,
  IconAnalyze,
  IconFile,
  IconFileImport,
  IconGitPullRequest,
  IconSparkles,
  IconTemplate,
  Stack,
  Tab,
  TabContext,
  TabList,
  TabPanel,
  Typography,
  darken,
  dialogClasses,
  useMediaQuery,
  useTheme,
} from '@joggrdocs/riker';
import React from 'react';

import { IconAvatar } from '@stargate/components/Avatars';
import { ComingSoonChip } from '@stargate/components/Chips';

import useShortcuts from '../hooks/use-shortcuts';
import type { Shortcut } from '../types';
import Analyze from './ShortcutItemAnalyze';
import BlankDocument from './ShortcutItemBlankDoc';
import Generate from './ShortcutItemGenerate';
import Import from './ShortcutItemImport';
import PullRequest from './ShortcutItemPullRequest';
import Templates from './ShortcutItemTemplates';

/*
|==========================================================================
| ShortcutDialog
|==========================================================================
|
| Shortcuts for quickly completing common tasks, i.e. creating a new doc from a template.
|
*/

/*
|------------------
| Utility Components & Functions
|------------------
*/

const ComingSoonTabTitle = (props: { title: string }) => {
  return (
    <Stack direction='row' alignItems='center' spacing={0.5}>
      <Typography variant='h5' fontSize='18px'>
        {props.title}
      </Typography>
      <ComingSoonChip sx={{ fontSize: '12px' }} />
    </Stack>
  );
};

const TabIcon: React.FC<{
  icon: React.ReactNode;
  comingSoon?: boolean;
}> = (props) => {
  const theme = useTheme();
  return (
    <Avatar
      variant='rounded'
      sx={{
        width: theme.spacing(5),
        height: theme.spacing(5),
        mr: 2,
        bgcolor: props.comingSoon
          ? theme.palette.divider
          : theme.palette.primary.main,
        '& svg': {
          color: theme.palette.common.white,
        },
      }}
    >
      {props.icon}
    </Avatar>
  );
};

const TabLabel: React.FC<{
  title: React.ReactNode;
  description: React.ReactNode;
}> = (props) => {
  return (
    <Stack spacing={0}>
      <Typography variant='h5' fontSize='18px'>
        {props.title}
      </Typography>
      <Typography variant='body1' fontSize='12px'>
        {props.description}
      </Typography>
    </Stack>
  );
};

const ShortcutDialog: React.FC = () => {
  const theme = useTheme();
  const [shortcutState, shortcutActions] = useShortcuts();
  const [value, setValue] = React.useState(shortcutState.active ?? 'blank');
  const xlDown = useMediaQuery(theme.breakpoints.down('xl'));

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const tabProps = (shortcut: Shortcut) => {
    return {
      value: shortcut,
      'aria-controls': `shortcut-${shortcut}`,
      sx: {
        textAlign: 'left',
        textTransform: 'none',
        justifyContent: 'flex-start',
        borderBottom: '1px solid',
        borderColor: 'divider',
        bgcolor:
          value === shortcut
            ? darken(theme.palette.background.paper, 0.1)
            : 'transparent',
      },
    };
  };

  const handleChange = (event: React.SyntheticEvent, newValue: Shortcut) => {
    setValue(newValue);
  };

  const handleClose = () => {
    shortcutActions.onClose();
  };

  /*
  |------------------
  | Effects
  |------------------
  */

  React.useEffect(() => {
    if (shortcutState.active && shortcutState.active !== value) {
      setValue(shortcutState.active);
    }
  }, [shortcutState.active]);

  return (
    <Dialog
      open={shortcutState.open}
      onClose={handleClose}
      maxWidth='lg'
      sx={{
        [`.${dialogClasses.paper}`]: {
          minHeight: xlDown ? '80vh' : '65vh',
        },
      }}
      fullWidth
    >
      <DialogTitle
        startIcon={<IconAvatar icon={'bolt'} />}
        title='Commands'
        subTitle='Quickly complete common tasks, such as creating a new document from a template.'
        onClose={handleClose}
        sx={{
          px: 2,
          bgcolor: 'transparent',
          borderBottom: '1px solid',
          borderColor: 'divider',
        }}
      />
      <Box sx={{ flexGrow: 1, bgcolor: 'transparent', display: 'flex' }}>
        <TabContext value={value}>
          <TabList
            sx={{
              width: '300px',
              borderRight: '1px solid',
              borderColor: 'divider',
            }}
            onChange={handleChange}
            orientation='vertical'
            aria-label='Shortcuts'
          >
            <Tab
              icon={<TabIcon icon={<IconFile />} />}
              iconPosition='start'
              label={
                <TabLabel
                  title='Blank Document'
                  description='Start with a blank document.'
                />
              }
              {...tabProps('blank')}
            />
            <Tab
              icon={<TabIcon icon={<IconTemplate />} />}
              iconPosition='start'
              label={
                <TabLabel
                  title='Templates'
                  description='Choose from a variety of templates to get started.'
                />
              }
              {...tabProps('templates')}
            />
            <Tab
              icon={<TabIcon icon={<IconFileImport />} />}
              iconPosition='start'
              label={
                <TabLabel
                  title='Import'
                  description='Import your existing documents.'
                />
              }
              {...tabProps('import')}
            />
            <Tab
              icon={<TabIcon icon={<IconGitPullRequest />} comingSoon />}
              iconPosition='start'
              label={
                <TabLabel
                  title={<ComingSoonTabTitle title='Pull Request' />}
                  description='Create a doc from  a pull request.'
                />
              }
              {...tabProps('pull-request')}
            />
            <Tab
              icon={<TabIcon icon={<IconSparkles />} comingSoon />}
              iconPosition='start'
              label={
                <TabLabel
                  title={<ComingSoonTabTitle title='Generate' />}
                  description='Utilize AI to generate documentation based on your code base.'
                />
              }
              {...tabProps('generate')}
            />
            <Tab
              icon={<TabIcon icon={<IconAnalyze />} comingSoon />}
              iconPosition='start'
              label={
                <TabLabel
                  title={<ComingSoonTabTitle title='Analyze' />}
                  description='Utilize AI to analyze your codebase for documentation gaps.'
                />
              }
              {...tabProps('analyze')}
            />
          </TabList>
          <Box sx={{ width: 'calc(100% - 300px)', position: 'relative' }}>
            <TabPanel value='blank'>
              <BlankDocument onClose={handleClose} meta={shortcutState.meta} />
            </TabPanel>
            <TabPanel value='templates'>
              <Templates onClose={handleClose} meta={shortcutState.meta} />
            </TabPanel>
            <TabPanel value='import'>
              <Import onClose={handleClose} meta={shortcutState.meta} />
            </TabPanel>
            <TabPanel value='pull-request'>
              <PullRequest />
            </TabPanel>
            <TabPanel value='generate'>
              <Generate onAction={handleClose} />
            </TabPanel>
            <TabPanel value='analyze'>
              <Analyze />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </Dialog>
  );
};

export default ShortcutDialog;
