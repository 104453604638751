import _ from 'lodash';
import React from 'react';
import { type DropzoneOptions, useDropzone } from 'react-dropzone';

import {
  Box,
  RikerIcon,
  type RikerIconName,
  Typography,
} from '@joggrdocs/riker';

/*
|==========================================================================
| FileDropZone
|==========================================================================
|
| FileDropZone is a component that allows the user to upload a file or drag and drop a file.
|
*/

/*
|------------------
| Internal Types
|------------------
*/

type OnDropOriginalFunc = Required<DropzoneOptions>['onDrop'];

/*
|------------------
| Public API
|------------------
*/

export type OnDropFunc = (acceptedFiles: File[]) => void;

export type OnErrorFunc = (error: Error) => void;

export interface FileDropZoneProps {
  onDrop: OnDropFunc;
  onError: OnErrorFunc;

  accept?: Required<DropzoneOptions>['accept'];
  maxFiles?: number;
  maxSize?: number;
  size?: 'small' | 'medium' | 'large';
  dragActiveLabel?: React.ReactNode;
  dragInactiveLabel?: React.ReactNode;
  icon?: RikerIconName;
}

export const FileDropZone: React.FC<FileDropZoneProps> = ({
  onDrop,
  onError,
  ...props
}) => {
  const [errors, setErrors] = React.useState<string[]>([]);

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const handleDrop = React.useCallback<OnDropOriginalFunc>(
    (acceptedFiles, rejectedFiles, e) => {
      if (rejectedFiles.length > 0) {
        setErrors((prevErrors) =>
          _.uniq([
            ...prevErrors,
            ...rejectedFiles.flatMap((file) =>
              file.errors.map((err) => err.message)
            ),
          ])
        );
      } else {
        setErrors([]);
        onDrop(acceptedFiles);
      }
    },
    [onDrop]
  );

  const handleDropError = React.useCallback<OnErrorFunc>((error) => {
    setErrors((prevErrors) => _.uniq([...prevErrors, error.message]));
  }, []);

  const handleError = React.useCallback(
    (errs: string[]) => {
      if (errs.length > 0) {
        onError(new Error(`Unable to upload file: ${errs.join(', ')}`));
      }
    },
    [onError]
  );

  /*
  |------------------
  | React Dropzone
  |------------------
  */

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    onError: handleDropError,
    maxFiles: props.maxFiles,
    maxSize: props.maxSize,
    accept: props.accept,
  });

  /*
  |------------------
  | Computed
  |------------------
  */

  const sizeSx = React.useMemo(() => {
    switch (props.size) {
      case 'small':
        return {
          minHeight: '100px',
        };
      case 'large':
        return {
          minHeight: '300px',
        };
      default:
        return {
          minHeight: '200px',
        };
    }
  }, [props.size]);

  /*
  |------------------
  | Effects
  |------------------
  */

  React.useEffect(() => {
    handleError(errors);
  }, [errors]);

  return (
    <Box
      {...getRootProps()}
      sx={{
        border: (theme) => `1px dashed ${theme.palette.divider}`,
        borderRadius: (theme) => theme.shape.borderRadius,
        bgcolor: (theme) =>
          theme.palette.mode === 'dark'
            ? theme.palette.grey[900]
            : theme.palette.grey[100],
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        ...sizeSx,
      }}
    >
      <Box
        sx={{
          textAlign: 'center',
        }}
      >
        <RikerIcon
          name={props.icon ?? 'upload'}
          size={props.size === 'small' ? 24 : props.size === 'medium' ? 24 : 36}
        />
        <Typography
          variant='body1'
          sx={{
            mt: 1,
          }}
        >
          {isDragActive
            ? (props.dragActiveLabel ?? 'Drop the files here ...')
            : (props.dragInactiveLabel ??
              'Drag and drop a file here, or click to select a file.')}
        </Typography>
      </Box>
      <input {...getInputProps()} />
    </Box>
  );
};

export default FileDropZone;
