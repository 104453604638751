import { Box } from '@joggrdocs/riker';
import React from 'react';

import { ShortcutDialog } from '@stargate/features/shortcuts';
import { SendInviteDialog } from '@stargate/features/user-invites';
import { SupportWidget } from '@stargate/features/user-support';

import MainContent from './MainContent';
import Sidebar from './SideBar';
import TopBar from './TopBar';
import MobileWarningFeedback from './Utils/MobileWarningFeedback';

/**
 * The DashboardLayout is the main layout for the application.
 */
const DashboardLayout = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <TopBar />
      <Sidebar />
      <MainContent>
        <SupportWidget />
        <SendInviteDialog />
        <ShortcutDialog />
      </MainContent>
      <MobileWarningFeedback />
    </Box>
  );
};

export default DashboardLayout;
