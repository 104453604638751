import _ from 'lodash';
import React from 'react';

import { Box } from '@joggrdocs/riker';

import { LoadingLogo } from '@stargate/components/Loading';

/*
|==========================================================================
| LoadingGuard
|==========================================================================
|
| Component for displaying a loading spinner while a condition is true.
|
*/

function useMinimumLoadTime(ms: number) {
  const [wait, setWait] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setWait(false);
    }, ms);
  }, [ms]);

  return wait;
}

export interface LoadingGuardProps {
  loading: boolean;
  render: () => React.ReactNode;
  loaderElement?: React.ReactNode;
  minimumLoadTime?: number;
}

export function LoadingGuard(props: LoadingGuardProps) {
  const wait = useMinimumLoadTime(props.minimumLoadTime ?? 300);

  if (!props.loading && !wait) {
    return props.render();
  }

  return (
    props.loaderElement ?? (
      <Box
        sx={{
          mt: 4,
          width: '100%',
        }}
      >
        <LoadingLogo
          size='small'
          loading
          containerProps={{
            ml: 'auto',
            mr: 'auto',
          }}
        />
      </Box>
    )
  );
}

export default LoadingGuard;
