import TiptapTable from '@tiptap/extension-table';
import { mergeAttributes } from '@tiptap/react';

export default TiptapTable.extend({
  renderHTML({ HTMLAttributes }) {
    // We have to do this because the table extension doesn't render a tbody element & div when in "readOnly" aka `editor.isEditable === false` mode.
    return [
      'div',
      { class: 'tableWrapper' },
      [
        'table',
        mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
        ['tbody', 0],
      ],
    ];
  },
}).configure({
  resizable: true,
  lastColumnResizable: false,
  HTMLAttributes: {
    class: 'dashdraft-table',
  },
});
