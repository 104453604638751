import React from 'react';

import {
  Button,
  ButtonGroup,
  type ButtonProps,
  Tooltip,
  useTheme,
} from '@joggrdocs/riker';

export interface BubbleMenuIconButtonGroupProps {
  buttons: Array<{
    description: string;
    icon: JSX.Element;
    onClick: () => void;
    color?: ButtonProps['color'];
  }>;
}

/**
 * Create Menus for the Table
 */
export const BubbleMenuIconButtonGroup: React.FC<
  BubbleMenuIconButtonGroupProps
> = ({ buttons }) => {
  const theme = useTheme();
  return (
    <ButtonGroup
      sx={{
        bgcolor: theme.palette.background.paper,
      }}
    >
      {buttons.map(({ description, color = 'primary', icon, onClick }) => (
        <Tooltip title={description} key={description}>
          <Button
            size='small'
            variant='outlined'
            color={color}
            sx={{
              minWidth: '0 !important',
            }}
            onClick={onClick}
          >
            {icon}
          </Button>
        </Tooltip>
      ))}
    </ButtonGroup>
  );
};
BubbleMenuIconButtonGroup.displayName = 'BubbleMenuIconButtonGroup';
