import React from 'react';

import {
  IconFolder,
  IconFolderOpen,
  type RikerNamedIcon,
  type RikerNamedIconProps,
  useTheme,
} from '@joggrdocs/riker';

export interface GitHubDirectoryIconProps extends RikerNamedIconProps {
  open?: boolean;
}

/**
 * A component that displays a directory icon, allowing for an open or closed state.
 */
export const GitHubDirectoryIcon = React.forwardRef<
  RikerNamedIcon,
  GitHubDirectoryIconProps
>(({ open = false, ...props }, ref) => {
  const theme = useTheme();

  if (open) {
    return (
      <IconFolderOpen
        {...props}
        ref={ref}
        fill={theme.palette.primary.light}
        color={theme.palette.primary.dark}
      />
    );
  }

  return (
    <IconFolder
      {...props}
      ref={ref}
      fill={theme.palette.primary.light}
      color={theme.palette.primary.dark}
    />
  );
});
GitHubDirectoryIcon.displayName = 'GitHubDirectoryIcon';
