import _ from 'lodash';
import React from 'react';

import { useJDocContext } from '@/features/docs/hooks/use-jdoc-ctx';
import { useJdocDraftLocalCache } from '@/features/docs/hooks/use-jdoc-draft-cache';
import type { JDocDraftQueryResult } from '../types';

/**
 * Query the data for a JoggrDocDraft, based on the JoggrDocContext.
 *
 * @returns The JoggrDocDraft data
 */
export const useJDocDraftQuery = (): JDocDraftQueryResult => {
  const { mode } = useJDocContext();
  const cache = useJdocDraftLocalCache();

  /*
  |------------------
  | Computed
  |------------------
  */

  const loading = React.useMemo(() => {
    if (mode === 'view') {
      return false;
    }
    return cache.data?.loading ?? false;
  }, [cache.data, mode]);

  const doc = React.useMemo(() => {
    if (mode === 'view') {
      return null;
    }

    return {
      title: cache.data?.title,
      summary: cache.data?.summary,
      updatedAt: cache.data?.updatedAt,
      createdAt: cache.data?.createdAt,
      version: cache.data?.version,
      author: cache.data?.author,
      baseDocumentId: cache.data?.baseDocumentId,
      github: cache.data?.github,
      content: cache.data?.content ?? undefined,
    };
  }, [cache.data, mode]);

  const tags = React.useMemo(() => {
    return cache.data?.tags ?? [];
  }, [cache.data]);

  const codeSources = React.useMemo(() => {
    return cache.data?.codeSources ?? [];
  }, [cache.data]);

  const dirId = React.useMemo(() => {
    return cache.data?.dir ?? null;
  }, [cache.data]);

  return {
    loading,
    doc,
    tags,
    dirId,
    codeSources,
  };
};
