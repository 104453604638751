import {
  FormControl,
  type FormControlProps,
  InputLabel,
  Select,
  type SelectProps,
} from '@joggrdocs/riker';
import _ from 'lodash';
import React from 'react';

import {
  SelectMenuItem,
  SelectRenderValue,
} from '@stargate/components/FormFields';

import type { GitHubOrganization } from '../../types';
import { GitHubOrganizationIcon } from '../Icons';

export interface GitHubOrganizationSelectProps extends BaseProps {
  /**
   * The ID of the select used for label association
   */
  id: string;

  /**
   * A list of GitHub organizations to select from
   */
  options: GitHubOrganization[];

  /**
   * The default value to select
   */
  defaultValue?: GitHubOrganization;

  /**
   * Flag to hide the label
   */
  hideLabel?: boolean;

  /**
   * Callback triggered when the menu is closed
   */
  onClose?: () => void;

  /**
   * Callback triggered when the menu is opened
   */
  onOpen?: () => void;

  /**
   * Callback triggered when the value changes
   *
   * @param ghOrg The selected GitHub organization
   */
  onChange: (ghOrg: GitHubOrganization) => void;
}

export const GitHubOrganizationSelect = React.forwardRef<
  HTMLDivElement,
  GitHubOrganizationSelectProps
>(
  (
    {
      fullWidth = true,
      hideLabel = false,
      onChange,
      onClose,
      onOpen,
      defaultValue,
      options,
      disabled,
      ...props
    },
    ref
  ) => {
    const [value, setValue] = React.useState<GitHubOrganization | undefined>(
      defaultValue
    );

    /*
    |------------------
    | Computed
    |------------------
    */

    const disabledSelect = React.useMemo(() => {
      return disabled === true || options.length === 0;
    }, [disabled, options]);

    /*
    |------------------
    | Callbacks
    |------------------
    */

    const handleChange: Required<SelectProps>['onChange'] = (event) => {
      const val = event.target.value as string;
      const ghOrg = findGitHubOrganization(options, val);
      if (!_.isNil(ghOrg)) {
        setValue(ghOrg);
        onChange(ghOrg);
      }
    };

    /*
    |------------------
    | Effects
    |------------------
    */

    // Allow us to force reset the value if the defaultValue changes
    React.useEffect(() => {
      if (!_.isNil(defaultValue)) {
        setValue((v) => {
          if (v?.id !== defaultValue.id) {
            return defaultValue;
          }
          return v;
        });
      }
    }, [defaultValue]);

    return (
      <FormControl
        {...props}
        ref={ref}
        fullWidth={fullWidth}
        disabled={disabledSelect}
      >
        {!hideLabel && (
          <InputLabel id={props.id}>GitHub Organization</InputLabel>
        )}
        <Select
          labelId={hideLabel ? undefined : props.id}
          label={hideLabel ? undefined : 'GitHub Organization'}
          value={value?.id}
          defaultValue={defaultValue?.id}
          placeholder='Select a GitHub Organization'
          disabled={disabledSelect}
          displayEmpty={hideLabel}
          onChange={handleChange}
          onOpen={onOpen}
          onClose={onClose}
          renderValue={(selected) => {
            const foundOrg = findGitHubOrganization(options, selected);
            return (
              <SelectRenderValue
                placeholder='Select a GitHub Organization'
                label={foundOrg?.name}
                icon={<GitHubOrganizationIcon type='organization' />}
              />
            );
          }}
          sx={{ minWidth: '180px' }}
          multiple={false}
        >
          {options.map((ghOrg) => (
            <SelectMenuItem
              key={ghOrg.id}
              value={ghOrg.id.toString()}
              label={ghOrg.name}
              icon={<GitHubOrganizationIcon />}
            />
          ))}
        </Select>
      </FormControl>
    );
  }
);
GitHubOrganizationSelect.displayName = 'GitHubOrganizationSelect';

/*
|------------------
| Utils
|------------------
*/

type BaseProps = Omit<
  FormControlProps,
  'onChange' | 'options' | 'defaultValue'
>;

/**
 * Given a list of GitHub organizations and an ID, find the GitHub organization.
 *
 * @param ghOrgs A list of GitHub organizations
 * @param id A GitHub organization ID
 * @returns A GitHub organization or undefined if not found
 */
const findGitHubOrganization = (
  ghOrgs: GitHubOrganization[],
  id: string | number
): GitHubOrganization | undefined => {
  return ghOrgs.find((ghOrg) => ghOrg.id.toString() === id.toString());
};
