import { createLocale } from '@stargate/localization/lib/locale-factory';

/**
 * JoggrDoc `edit` mode translations.
 */
const edit = createLocale('edit', {
  /*
  |------------------
  | Code Links
  |------------------
  */

  'code-links.action.delete.tooltip': 'Click to delete this Code Link',

  /*
  |------------------
  | Drafts
  |------------------
  */

  'drafts.description':
    'This is a local draft with changes that need to be committed to GitHub. To commit these changes to your JoggrDoc, click the "Commit" button.',
  'drafts.chip.label': 'uncommitted changes',
  'drafts.action.label': 'Revert to previous version',
  'drafts.revert.title': 'Revert to previous version',
  'drafts.revert.confirmation':
    'This will wipe out any changes you have made and revert to the previous version by reloading the content.',

  /*
  |------------------
  | Breadcrumbs
  |------------------
  */

  'breadcrumbs.tooltip': 'Switch the JoggrDir, where your JoggrDoc is located.',
  'breadcrumbs.dialog.title': 'Switch the JoggrDir',
  'breadcrumbs.dialog.subtitle':
    'Switch the JoggrDir location for your JoggrDoc.',
});

/**
 * JoggrDoc `create` mode translations.
 */
const create = createLocale('create', {
  /*
  |------------------
  | Code Links
  |------------------
  */

  'code-links.action.delete.tooltip': 'Click to delete this Code Link',

  /*
  |------------------
  | Drafts
  |------------------
  */

  'drafts.description':
    'This is a local draft that has never been committed to GitHub. To create this JoggrDoc, click the "Commit" button.',
  'drafts.chip.label': 'local draft',
  'drafts.actions.label': 'Discard draft',
  'drafts.revert.title': 'Discard draft',
  'drafts.revert.confirmation':
    'This will wipe out any changes you have made and you will not be able to recover this draft.',

  /*
  |------------------
  | Breadcrumbs
  |------------------
  */

  'breadcrumbs.tooltip':
    'Switch the JoggrDir, where you will save the JoggrDoc.',
  'breadcrumbs.dialog.title': 'Switch JoggrDir',
  'breadcrumbs.dialog.subtitle':
    'Choose the destination JoggrDir for your JoggrDoc.',
});

/**
 * JoggrDoc `view` mode translations.
 */
const view = createLocale('view', {
  /*
  |------------------
  | Code Links
  |------------------
  */

  'code-links.action.delete.tooltip':
    'You can only delete code links in edit mode',
});

export default createLocale('docs', {
  ...edit,
  ...create,
  ...view,
  'versions.only': 'You are viewing the only version of this document.',
  'versions.multiple':
    'Are you sure you want to edit the latest version as this document has {count} version{s}?',

  /*
  |------------------
  | Mutations
  |------------------
  */

  'delete.error': 'Unable to delete JoggrDoc',
  'save.error': 'Unable to save JoggrDoc',
  'save.error.invalid-commit':
    'Unable to save JoggrDoc as the commit is malformed, please try again or contact support.',
  'save.error.missing':
    'Unable to save JoggrDoc as you are missing required data: {missingData}',
  'save.error.missing.title': 'Title',
  'save.error.missing.content': 'Content',
  'save.error.missing.github.repository.id': 'GitHub Repository',
  'save.error.missing.github.filePath': 'GitHub File Location',
  'save.success.version': 'Successfully created a new version of the JoggrDoc',
  'save.success.edit': 'Successfully updated the JoggrDoc',
  'save.success.create': 'Successfully created the JoggrDoc',
  'save.modified.none.tooltip': 'You have not made any changes to the JoggrDoc',

  /*
  |------------------
  | File Location
  |------------------
  */

  'file-location.title': 'File Location',
  'file-location.confirm.tooltip.missing-data':
    'You need to set the GitHub Repository, select a directory, and provide a filename.',
  'file-location.confirm.tooltip.not-modified':
    "You have not made any changes to the JoggrDoc's file location. Please make a change before clicking the 'Confirm' button.",
  'file-location.cancel.tooltip.warning':
    'You have unsaved changes. Are you sure you want to cancel? All changes will be lost.',

  /*
  |------------------
  | Code Links
  |------------------
  */

  'code-links.action.view.tooltip':
    'Click to view the Code Link in the Code Explorer',

  'code-links.chip.label': `
{numLinks, plural,
  =1 {1 Code Link}
  other {# Code Links}
}
  `,
  'code-links.delete.dialog.title': 'Delete Code Link',
  'code-links.delete.dialog.question':
    'Are you sure you want to delete the code link for file <code>{filePath}</code>? This action cannot be undone.',

  /*
  |------------------
  | Rewards
  |------------------
  */

  'rewards.create-first.title':
    'Congratulations on creating your first JoggrDoc!',
  'rewards.create-first.message':
    'Please share this with your team so they can use the doc that you just created.',
  'rewards.create-first.cancel': "Don't share with my team",
});
