import _ from 'lodash';

import config from '../config';
import type { FlowSlug } from '../types';

/*
|==========================================================================
| Mapper 
|==========================================================================
|
| Map IDs to flow names
|
*/

export type FlowIdMapping = {
  [key in FlowSlug]: string;
};

/**
 * Define the flows object, we use this to enforce the types of the flows
 * and in the future we might run different operations on the flows object
 *
 * @param flows The flows object
 * @returns The flow mapping object
 */
export const createFrigadeIdMapping = (flows: FlowIdMapping): FlowIdMapping => {
  if (
    _.some(
      Object.keys(flows),
      (key) => !config.flowSlugs.all.includes(key as any)
    )
  ) {
    throw new Error('Invalid flow name');
  }

  if (_.some(Object.values(flows), (value) => !value)) {
    throw new Error('Invalid flow ID');
  }

  return flows;
};
