import React from 'react';

import {
  Box,
  type BoxProps,
  RikerIcon,
  type RikerIconName,
  Tooltip,
  rikerIconClasses,
  useTheme,
} from '@joggrdocs/riker';

import { Link, type LinkProps, useLocation } from '@stargate/routes';
import { componentClassFactory } from '@stargate/theme';

const cn = componentClassFactory('SideBarNavItem');

export const sideBarNavItemClasses = {
  root: cn('root'),
  active: cn('active'),
} as const;

interface SideBarNavItemBaseProps {
  disabled?: boolean;
  active?: boolean;
  icon: RikerIconName;
  hint: string;
  hotKey?: string;
  showBorder?: boolean;
  classes?: Partial<typeof sideBarNavItemClasses>;
}

export interface SideBarNavItemLinkProps
  extends SideBarNavItemBaseProps,
    Omit<LinkProps, 'onClick'> {
  link: true;
  onClick?: Required<BoxProps>['onClick'];
}

export interface SideBarNavItemNonLinkProps extends SideBarNavItemBaseProps {
  link?: false;
  onClick: Required<BoxProps>['onClick'];
}

export type SideBarNavItemProps =
  | SideBarNavItemLinkProps
  | SideBarNavItemNonLinkProps;

export const SideBarNavItem: React.FC<SideBarNavItemProps> = ({
  disabled = false,
  showBorder = false,
  ...props
}) => {
  const theme = useTheme();
  const location = useLocation();

  /*
  |------------------
  | Computed
  |------------------
  */

  const linkProps = React.useMemo(() => {
    if (props.link) {
      return {
        to: props.to,
        params: props.params,
        search: props.search,
      };
    }
    return null;
  }, [props]);

  const classes = React.useMemo(() => {
    return {
      ...sideBarNavItemClasses,
      ...props.classes,
    };
  }, [props.classes]);

  return (
    <Tooltip
      title={[props.hint, props.hotKey ? `(${props.hotKey})` : ''].join(' ')}
      placement='right'
      disableFocusListener={disabled}
      disableHoverListener={disabled}
      disableTouchListener={disabled}
      disableInteractive={disabled}
      open={disabled ? false : undefined}
      sx={{
        fontSize: '20px',
      }}
    >
      <Box
        {...linkProps}
        component={props.link === true ? Link : 'div'}
        className={theme.utils.classNames([
          classes.root,
          theme.utils.classNames({
            [classes.active]: !!props.active,
            disabled:
              props.link === true
                ? location.active(props.to, props.params, props.search)
                : disabled,
          }),
        ])}
        sx={{
          width: '100%',
          borderRadius: '8px',
          px: 0,
          py: 1,
          color: theme.palette.text.primary,
          border: showBorder ? `1px solid ${theme.palette.divider}` : undefined,
          cursor: 'pointer',
          [`&:hover, &:active, &.${classes.active}`]: {
            bgcolor: theme.palette.action.hover,
          },
          '&.disabled': {
            pointerEvents: 'none',
            color: theme.palette.text.disabled,
            bgcolor: theme.palette.action.hover,
          },
          [`& .${rikerIconClasses.root}`]: {
            display: 'block',
            height: '32px',
            width: '32px',
            strokeWidth: '1.5px',
            mx: 'auto',
          },
        }}
        onClick={props.onClick}
      >
        <RikerIcon name={props.icon} />
      </Box>
    </Tooltip>
  );
};

export default SideBarNavItem;
