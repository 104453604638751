import type { RedirectLoginOptions } from '@auth0/auth0-spa-js';
import _ from 'lodash';
import React from 'react';

import type * as Typist from '@/lib/typist';

import { auth0Client } from '../lib/auth0-client';
import { isAuth0JwtExpired } from '../lib/auth0-jwt';
import type { Auth0Context } from '../types';
import { useAuth0Store } from './use-auth0-store';

/*
|==========================================================================
| useAuth0
|==========================================================================
|
| Thin wrapper around the Auth0 useAuth0 hook that allows us to add functionality, expose it Globally
| outside of the Auth0Provider & override certain methods as needed.
|
*/

export interface UseAuth0Hook extends Auth0Context {
  /**
   * Get the Auth0 token.
   *
   * @returns The refreshed token.
   */
  getAccessToken: () => Promise<string>;

  /**
   * Refresh the Auth0 token.
   *
   * @returns The refreshed token.
   */
  refreshAccessToken: () => Promise<string>;

  /**
   * Login the user (w/redirect).
   * @param opts The login options.
   */
  login: <AppState extends Typist.AllowedAny>(
    opts: RedirectLoginOptions<AppState>
  ) => Promise<void>;

  /**
   * Logout the user.
   */
  logout: () => Promise<void>;
}

/**
 * The `useAuth0` hook to be used in your components to access the auth state and methods.
 *
 * Example output:
 * ```js
 * const {
 *   error,
 *   isAuthenticated,
 *   isLoading,
 *   user,
 *   getAccessToken,
 *   logout,
 * } = useAuth0<TUser>();
 * ```
 */
export const useAuth0 = (): UseAuth0Hook => {
  const auth0Store = useAuth0Store();

  const refreshAccessToken = React.useCallback<
    UseAuth0Hook['refreshAccessToken']
  >(async () => {
    const refreshedToken = await auth0Client.getTokenSilently();
    auth0Store.setJwt(refreshedToken);
    return refreshedToken;
  }, [auth0Store.setJwt]);

  const getAccessToken = React.useCallback<
    UseAuth0Hook['getAccessToken']
  >(async () => {
    const token = auth0Store.jwt;

    if (_.isNil(token) || isAuth0JwtExpired(token)) {
      return refreshAccessToken();
    }

    return token;
  }, [refreshAccessToken, auth0Store.jwt]);

  const login = React.useCallback<UseAuth0Hook['login']>(async (opts) => {
    await auth0Client.loginWithRedirect(opts);
  }, []);

  const logout = React.useCallback<UseAuth0Hook['logout']>(async () => {
    auth0Store.clear();
    await auth0Client.logout();
  }, [auth0Store.clear]);

  return {
    user: auth0Store.user,
    isAuthenticated: auth0Store.isAuthenticated,
    isLoading: auth0Store.isLoading,
    isError: auth0Store.isError,
    error: auth0Store.error,
    getAccessToken,
    refreshAccessToken,
    login,
    logout,
  };
};
