import React from 'react';

import { useHttpErrors } from '@stargate/lib/http';
import { FormattedMessage, useLocalization } from '@stargate/localization';

import ErrorFeedback from './ErrorFeedback';
import type { FeedbackProps } from './Feedback';

/*
|==========================================================================
| HttpErrorFeedback
|==========================================================================
|
| Provide Error Feedback for HttpErrors.
|
*/

export interface HttpErrorFeedbackProps extends Partial<FeedbackProps> {
  /**
   * The Error or HTTP Error to display.
   */
  error: unknown;

  /**
   * Callback to reload the page.
   */
  onReload?: () => void;
}

/**
 * A feedback component for displaying HTTP errors.
 */
export const HttpErrorFeedback = React.forwardRef<
  HTMLDivElement,
  HttpErrorFeedbackProps
>(({ error, ...props }, ref) => {
  const httpErrors = useHttpErrors();
  const localz = useLocalization();

  const statusCode = React.useMemo(() => {
    if (httpErrors.isHttpError(error)) {
      return error.statusCode;
    }

    return undefined;
  }, [error, httpErrors]);

  const errorCode = React.useMemo(() => {
    if (httpErrors.isHttpError(error)) {
      return error.errorCode;
    }

    return undefined;
  }, [error, httpErrors]);

  const type = React.useMemo(() => {
    switch (statusCode) {
      case 400:
        return 'bad-request';
      case 401:
        return 'unauthorized';
      case 403:
        return 'forbidden';
      case 404:
        return 'not-found';
      case 500:
        return 'internal-server-error';
      default:
        return 'unknown';
    }
  }, [statusCode]);

  const message = React.useMemo(() => {
    if (errorCode) {
      const translation = localz.formatMessage(`error.codes.${errorCode}`);

      if (translation.toLowerCase() !== 'missing translation') {
        return translation;
      }
    }

    return undefined;
  }, [errorCode, localz]);

  return <ErrorFeedback {...props} ref={ref} message={message} type={type} />;
});
HttpErrorFeedback.displayName = 'HttpErrorFeedback';
