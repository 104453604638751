import { type DOMEventHandlers, EditorView } from '@codemirror/view';

/*
|==========================================================================
| events
|==========================================================================
|
| This extension allows you to add event handlers to CodeMirror.
|
*/

export type EventHandlers = DOMEventHandlers<any>;

export const events = (handlers: EventHandlers) => {
  return EditorView.domEventHandlers(handlers);
};

export default events;
