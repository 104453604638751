import { produce } from 'immer';
import _ from 'lodash';
import React from 'react';
import { create } from 'zustand';

import apiClient from '@stargate/api';
import { useUser } from '@stargate/features/user';
import { useNavigate } from '@stargate/routes';

export interface UseOnboardingHook {
  /**
   * Is the user onboarded?
   */
  onboarded: boolean;

  /**
   * Loading state.
   */
  loading: boolean;

  /**
   * Active step id.
   */
  activeStepId: OnboardingStepId;

  /**
   * List of completed step ids.
   */
  completedStepIds: OnboardingStepId[];

  /**
   * Load the user data.
   */
  load: () => Promise<void>;

  /**
   * Continue to the next onboarding step.
   */
  nextStep: () => Promise<void>;

  /**
   * Skip the current onboarding step.
   */
  skipStep: () => Promise<void>;

  /**
   * Mark a step as completed.
   */
  markStepCompleted: (stepId: OnboardingStepId) => Promise<void>;
}

/**
 * A hook to manage the user onboarding process.
 */
export const useUserOnboarding = (): UseOnboardingHook => {
  const authenticatedUser = useUser();
  const store = useMemoryStore();
  const navigate = useNavigate();
  const [, userOnboardingActions] = apiClient.useRequestClient(
    'PATCH /user/metadata'
  );

  /*
  |------------------
  | Data
  |------------------
  */

  const loading = React.useMemo(() => {
    return _.some([!authenticatedUser.data, authenticatedUser.loading]);
  }, [authenticatedUser.data, authenticatedUser.loading]);

  const activeStepId = React.useMemo<OnboardingStepId>(() => {
    const orderedSteps = _.chain(onboardingSteps)
      .sortBy('order')
      .map((step) => step.id)
      .value();

    for (const stepId of orderedSteps) {
      if (!store.completedStepIds.includes(stepId)) {
        return stepId;
      }
    }

    return 'complete';
  }, [store.completedStepIds]);

  const onboarded = React.useMemo<boolean>(() => {
    return (
      store.completedStepIds.includes('complete') ||
      authenticatedUser.data?.metadata.onboarded === true
    );
  }, [store.completedStepIds, authenticatedUser]);

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const load = React.useCallback<UseOnboardingHook['load']>(async () => {
    await authenticatedUser.load({
      clearCache: true,
    });
  }, [authenticatedUser]);

  const markStepCompleted = React.useCallback<
    UseOnboardingHook['markStepCompleted']
  >(
    async (stepId) => {
      // Only update when stepIds change
      if (['welcome', 'complete'].includes(stepId)) {
        await userOnboardingActions.execute({
          body: {
            onboarded: stepId === 'complete' ? true : undefined,
            acceptedTerms: stepId === 'welcome' ? true : undefined,
          },
        });
      }
      store.markStepCompleted(stepId);
    },
    [store.markStepCompleted, userOnboardingActions]
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const nextStep = React.useCallback(async () => {
    await markStepCompleted(activeStepId);
    if (activeStepId === 'complete') {
      await authenticatedUser.refresh({
        clearCache: true,
      });
      navigate('app.root');
    }
  }, [activeStepId, markStepCompleted, navigate]);

  const skipStep = React.useCallback(async () => {
    await markStepCompleted(activeStepId);
  }, [activeStepId, markStepCompleted]);

  /*
  |------------------
  | Effects
  |------------------
  */

  // Update the steps everytime the user data updates
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  React.useEffect(() => {
    if (!_.isNil(authenticatedUser.data)) {
      const { metadata } = authenticatedUser.data;
      if (
        metadata.acceptedTerms &&
        !store.completedStepIds.includes('welcome')
      ) {
        store.markStepCompleted('welcome');
      } else if (
        metadata.authorizedWithGithub &&
        !store.completedStepIds.includes('github-authorize')
      ) {
        store.markStepCompleted('github-authorize');
      } else if (
        metadata.githubAppInstalled &&
        !store.completedStepIds.includes('github-install-app')
      ) {
        store.markStepCompleted('github-install-app');
      } else if (
        metadata.onboarded &&
        !store.completedStepIds.includes('complete')
      ) {
        store.markStepCompleted('invite-team-members');
        store.markStepCompleted('complete');
      }
    }
  }, [authenticatedUser.data, store.completedStepIds]);

  return React.useMemo<UseOnboardingHook>(
    () => ({
      activeStepId,
      completedStepIds: store.completedStepIds,
      onboarded,
      loading,
      load,
      nextStep,
      skipStep,
      markStepCompleted,
    }),
    [
      activeStepId,
      store.completedStepIds,
      onboarded,
      loading,
      load,
      nextStep,
      skipStep,
      markStepCompleted,
    ]
  );
};

export type OnboardingStepId =
  | 'welcome'
  | 'github-authorize'
  | 'github-install-app'
  | 'invite-team-members'
  | 'complete';

export interface OnboardingStep {
  id: OnboardingStepId;
  order: number;
}

export const onboardingSteps = [
  { order: 1, id: 'welcome' },
  {
    order: 2,
    id: 'github-authorize',
  },
  {
    order: 3,
    id: 'github-install-app',
  },
  {
    order: 4,
    id: 'invite-team-members',
  },
  { order: 5, id: 'complete' },
] as const satisfies OnboardingStep[];

/*
|------------------
| Utils
|------------------
*/

const useMemoryStore = create<{
  completedStepIds: OnboardingStepId[];
  markStepCompleted: (stepId: OnboardingStepId) => void;
}>((set) => ({
  completedStepIds: [],
  markStepCompleted: (stepId) => {
    set(
      produce((draft) => {
        draft.completedStepIds = _.uniq([...draft.completedStepIds, stepId]);
      })
    );
  },
}));
