import { Extension } from '@tiptap/core';

import document from './extensions/document';
import focus from './extensions/focus';
import text from './extensions/text';
import trailingNode from './extensions/trailing-node';

export default Extension.create({
  name: 'dashdraft-core',

  addExtensions() {
    return [document, focus, text, trailingNode];
  },
});
