import { ButtonAnchor, Tooltip } from '@joggrdocs/riker';
import React from 'react';

import type { QuackrQuickLinkConfig } from '../types';
import { QuackrAvatar } from './quackr-avatar';

export type QuackrActionLinkProps = Required<QuackrQuickLinkConfig>;

export const QuackrActionLink: React.FC<QuackrActionLinkProps> = ({
  title,
  description,
  avatar,
  url,
}) => {
  return (
    <Tooltip title={description} placement='bottom-start' arrow>
      <ButtonAnchor
        startIcon={<QuackrAvatar avatar={avatar} size={24} />}
        href={url}
        variant='contained'
        color='primary'
        size='small'
        openInNewTab
      >
        {title}
      </ButtonAnchor>
    </Tooltip>
  );
};
