import React from 'react';

import {
  FormControl,
  type FormControlProps,
  IconGitBranch,
  InputLabel,
  Select,
} from '@joggrdocs/riker';

import {
  SelectMenuItem,
  SelectRenderValue,
} from '@stargate/components/FormFields';

/*
|==========================================================================
| GitHubRepositoryBranchSelect
|==========================================================================
|
| Select a branch from a list of branches.
|
*/

const LABEL_ID = 'repository-label';

export interface GitHubRepositoryBranchSelectProps
  extends Omit<FormControlProps, 'onChange'> {
  branches: string[];
  placeholder?: string;
  defaultBranch?: string;
  label?: string;
  open?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  onChange: (branch: string) => void;
}

export const GitHubRepositoryBranchSelect = React.forwardRef<
  HTMLDivElement,
  GitHubRepositoryBranchSelectProps
>((props, ref) => {
  const {
    fullWidth = true,
    branches,
    defaultBranch,
    onChange,
    disabled,
    onOpen,
    onClose,
    placeholder = 'Select a branch',
    label = 'Branch',
    ...restProps
  } = props;
  const [value, setValue] = React.useState<string>(defaultBranch ?? '');
  const [open, setOpen] = React.useState(props.open ?? false);

  React.useEffect(() => {
    setValue(defaultBranch ?? '');
  }, [defaultBranch]);

  // @todo fix type
  function handleChange(event: any) {
    setValue(event.target.value);
    onChange(event.target.value);
  }

  const handleOpen = () => {
    setOpen(true);
    onOpen?.();
  };

  const handleClose = () => {
    setOpen(false);
    onClose?.();
  };

  // Allows us to force open
  React.useEffect(() => {
    if (props.open !== open && typeof props.open === 'boolean') {
      setOpen(props.open);
    }
  }, [props.open]);

  return (
    <FormControl
      {...restProps}
      fullWidth={fullWidth}
      disabled={disabled || branches.length === 0}
      ref={ref}
    >
      <InputLabel id={LABEL_ID}>{label}</InputLabel>
      <Select
        labelId={LABEL_ID}
        label={label}
        id={`${LABEL_ID}-select`}
        value={value || defaultBranch || ''}
        placeholder={placeholder}
        open={open}
        disabled={disabled || branches.length === 0}
        onChange={handleChange}
        onOpen={handleOpen}
        onClose={handleClose}
        renderValue={(value) => {
          return (
            <SelectRenderValue
              placeholder='Select an branch'
              label={value}
              icon={<IconGitBranch />}
            />
          );
        }}
        multiple={false}
      >
        {branches.map((branch) => (
          <SelectMenuItem
            key={branch}
            value={branch}
            icon={<IconGitBranch />}
            label={branch}
          />
        ))}
      </Select>
    </FormControl>
  );
});
GitHubRepositoryBranchSelect.displayName = 'GitHubRepositoryBranchSelect';
