import { produce } from 'immer';
import _ from 'lodash';
import type * as TypeFest from 'type-fest';
import { create } from 'zustand';

export type UseFrigadeStoreHook = TypeFest.Merge<State, Actions>;

/**
 * Store the Frigade state for updating the provider & other actions as needed.
 *
 * @returns A tuple containing the state and actions of the store.
 */
export const useFrigadeStore = (): UseFrigadeStoreHook => {
  return useStore((store) => ({
    userId: store.userId,
    targetIds: store.targetIds,
    setUserId: store.setUserId,
    addTargetId: store.addTargetId,
  }));
};

/*
|------------------
| Utils
|------------------
*/

type UserStore = TypeFest.Simplify<State & Actions>;

const useStore = create<UserStore>((set) => ({
  /*
  |------------------
  | State
  |------------------
  */

  userId: null,
  targetIds: [],

  /*
  |------------------
  | Actions
  |------------------
  */

  setUserId: (id) => {
    set(
      produce((draft) => {
        draft.userId = id;
      })
    );
  },
  addTargetId: (id) => {
    set(
      produce((draft) => {
        draft.targetIds = _.uniq([...draft.targetIds, id]);
      })
    );
  },
}));

interface State {
  /**
   * The user id.
   */
  userId: string | null;

  /**
   * The target ids.
   */
  targetIds: string[];
}

interface Actions {
  /**
   * Set the user id.
   */
  setUserId: (id: string | null) => void;

  /**
   * Add a target id.
   */
  addTargetId: (id: string) => void;
}
