import _ from 'lodash';
import React from 'react';

import { transformFromAPIContent } from '@/api';
import { type UseStorageItemHook, useStorageItem } from '@/lib/storage';
import type * as Type from '@/lib/typist';
import { useLogger } from '@/logger';

import { useJDocContext } from '@/features/docs/hooks/use-jdoc-ctx';
import type { JDocDraftLocalCacheData, JDocMode } from '@/features/docs/types';
import type { DashDraftJSONContent } from '@dashdraft/types';
import { useSentry } from '@stargate/vendors/sentry';

/**
 * @returns A cache item based on the current active JoggrDoc (mode + template + doc)
 */
export const useJdocDraftLocalCache = () => {
  const ctx = useJDocContext();
  const cacheKey = React.useMemo(
    () =>
      getStorageKey({
        mode: ctx.mode,
        templateId: ctx.templateId,
        docId: ctx.docId,
      }),
    [ctx]
  );
  const cache = useStorageItem<JDocDraftLocalCacheData | null>(cacheKey, null);
  const data = useLocalCacheDraftConversion(cache);

  return React.useMemo(() => ({ ...cache, data }), [cache, data]);
};

/*
|------------------
| Utils
|------------------
*/

/**
 * Create a storage key for a JoggrDocDraft.
 *
 * @param payload a payload used to generate the storage key
 * @returns A JoggrDocDraft storage ID for use in LocalStorage
 */
const getStorageKey = (payload: {
  mode: JDocMode;
  templateId: string | null;
  docId: string | null;
}): string => {
  const id = payload.docId ?? payload.templateId ?? '-';
  return `joggrdoc:draft:${payload.mode === 'create' ? 'create' : 'edit'}:${id}`;
};

/**
 * A cache object that is using the legacy "string" content field.
 */
type JDocDraftLegacyLocalCacheData = Type.Simplify<
  Omit<JDocDraftLocalCacheData, 'content'> & {
    content: string;
  }
>;

/**
 * Is the cache a legacy cache?
 *
 * @param cacheData A JoggrDocDraft
 * @returns A boolean indicating whether the cache is a legacy cache
 */
const isJDocLegacyLocalCache = (
  cacheData?: object | null
): cacheData is JDocDraftLegacyLocalCacheData => {
  if (_.isNil(cacheData)) {
    return false;
  }

  return _.isString(_.get(cacheData, 'content', null));
};

/**
 * Convert a legacy cache to a new cache.
 *
 * @todo remove this as we don't need it anymore (once we hit 2-3 weeks)
 * @linear https://linear.app/joggr/issue/ENG-2223
 *
 * @param cache A JDocDraftLocalCacheData
 * @returns The data and loading state of the cache based on the legacy cache
 */
const useLocalCacheDraftConversion = (
  cache: UseStorageItemHook<JDocDraftLocalCacheData | null>
) => {
  const sentry = useSentry();
  const logger = useLogger();

  const merge = React.useCallback(
    ({
      content,
      loading,
    }: {
      content?: DashDraftJSONContent;
      loading: boolean;
    }) => {
      cache.set((prev) => ({
        ...prev,
        content: content ?? prev?.content,
        loading: loading ?? prev?.loading,
      }));
    },
    [cache]
  );

  React.useEffect(() => {
    if (isJDocLegacyLocalCache(cache.data) && cache.data?.loading !== true) {
      merge({ loading: true });
      void transformFromAPIContent(cache.data.content)
        .then((content) => {
          merge({ content, loading: false });
          sentry.captureMessage('Converted legacy cache (markdown) to JSON', {
            level: 'info',
            extra: {
              key: cache.key,
              content,
            },
          });
        })
        .catch((err) => {
          merge({ loading: false });
          logger.error(
            {
              error: err,
            },
            'Failed to convert legacy cache (markdown) to JSON'
          );
          sentry.captureException(err, {
            extra: {
              message: 'Failed to convert legacy cache (markdown) to JSON',
            },
          });
        });
    }
  }, [cache, logger.error, merge, sentry]);

  return React.useMemo(() => {
    if (isJDocLegacyLocalCache(cache.data)) {
      return {
        ...cache.data,
        loading: true,
        content: null,
      };
    }
    return cache.data;
  }, [cache.data]);
};
