import _ from 'lodash';
import type * as TypeFest from 'type-fest';

/*
|==========================================================================
| Routes
|==========================================================================
|
| Library for defining and working with routes.
|
*/

/*
|------------------
| Utility Types
|------------------
*/

export type StripSlash<Path extends string> = Path extends `/${infer Rest}`
  ? Rest
  : Path;

/*
|----------------------------------
| Schema
|----------------------------------
|
| A schema is provided to help the "type" inference of the route object. 
|
*/

/*
|------------------
| Schema: Base
|------------------
*/

export interface EnumSchema<T extends string> {
  type: 'string';
  enum: readonly T[];
  default?: T;
}

export interface StringSchema {
  type: 'string';
  default?: string;
}

export interface NumberSchema {
  type: 'number';
  default?: number;
}

export interface BooleanSchema {
  type: 'boolean';
  default?: boolean;
}

export type AllSchema =
  | EnumSchema<string>
  | StringSchema
  | NumberSchema
  | BooleanSchema;

export type Schema<T extends AllSchema> = T extends EnumSchema<infer U>
  ? U
  : T extends StringSchema
    ? string
    : T extends NumberSchema
      ? number
      : T extends BooleanSchema
        ? boolean
        : never;

/*
|------------------
| Schema: Routes
|------------------
*/

export type RouteDefinitionPartSchema<
  S extends Record<string, AllSchema> = Record<string, AllSchema>,
> = {
  [K in keyof S]: S[K];
};

export interface RoutesDefinitionSchema<
  Path extends string = string,
  Params extends RouteDefinitionPartSchema | undefined = undefined,
  Search extends RouteDefinitionPartSchema | undefined = undefined,
> {
  path: Path;
  params: Params extends RouteDefinitionPartSchema
    ? RouteDefinitionPartSchema<Params>
    : undefined;
  search: Search extends RouteDefinitionPartSchema
    ? RouteDefinitionPartSchema<Search>
    : undefined;
}

/*
|------------------
| Schema: Utils
|------------------
*/

export type RoutePartSchema<
  P extends RouteDefinitionPartSchema | undefined = undefined,
> = {
  [Key in keyof P]: P extends RouteDefinitionPartSchema
    ? Schema<P[Key]>
    : never;
};

/*
|------------------
| Route Utils & Helpers
|------------------
*/

/**
 * Coerce an unknown value to a type definition.e
 *
 * @param data An unknown value to coerce.
 * @param schema The route schema to coerce the value to.
 * @returns A value of the type defined by `typeDefinition`.
 */
export const coerceRouteData = <D, S extends AllSchema>(data: D, schema: S) => {
  if (_.isNil(data)) {
    return schema.default as Schema<S>;
  }
  if (schema.type === 'string') {
    return _.toString(data);
  }
  if (schema.type === 'number') {
    return _.toNumber(data);
  }
  if (schema.type === 'boolean') {
    if (data === 'true') {
      return true;
    }
    if (data === 'false') {
      return false;
    }
    return Boolean(data);
  }
  // We force to string when unknown type.
  return _.toString(data);
};

export const data = <S extends RouteDefinitionPartSchema>(
  data: unknown,
  schema: S
): TypeFest.Simplify<{ [K in keyof S]: Schema<S[K]> }> => {
  const result = _.mapValues(schema, (fieldSchema, key) => {
    const value = _.get(data, key);
    return coerceRouteData(value, fieldSchema);
  });
  return result as TypeFest.Simplify<{ [K in keyof S]: Schema<S[K]> }>;
};

const route = <
  Path extends string,
  Params extends RouteDefinitionPartSchema | undefined = undefined,
  Search extends RouteDefinitionPartSchema | undefined = undefined,
>(
  path: Path,
  schema?: {
    params?: Params;
    search?: Search;
  }
): RoutesDefinitionSchema<Path, Params, Search> => {
  return {
    path,
    search: schema?.search,
    params: schema?.params,
  } as RoutesDefinitionSchema<Path, Params, Search>;
};

export const rel = <Prefix extends string, Path extends string>(
  prefixPath: Prefix,
  path: Path
): StripSlash<TypeFest.Replace<Path, Prefix, ''>> => {
  const relativePath = path.replace(prefixPath, '');
  const result = relativePath[0] === '/' ? relativePath.slice(1) : relativePath;
  return result as StripSlash<TypeFest.Replace<Path, Prefix, ''>>;
};

/*
|------------------
| Public API  
|------------------
*/

/**
 * Create a type-safe route object & related utils.
 */
const typeSafeRoutes = {
  /**
   * Create a type-safe route object.
   *
   * @param path A route path string.
   * @param routeDefinition A route config object.
   * @returns A route object.
   */
  route,

  /**
   * Build a params object with proper data coercion and typing for a route.
   *
   * @param params An object of data to coerce.
   * @param schema The route schema to use for coercion.
   * @returns Params data coerced to the types defined by `schema`.
   */
  search: data,

  /**
   * Build a search object with proper data coercion and typing for a route.
   *
   * @param search An object of data to coerce.
   * @param schema The route schema to use for coercion.
   * @returns Search data coerced to the types defined by `schema`.
   */
  params: data,

  /**
   * Get a relative path from an existing path.
   *
   * @param prefixPath A prefix path to remove from the full path.
   * @param path Existing path to remove the prefix from.
   * @returns A relative path.
   */
  rel,
};

export default typeSafeRoutes;
