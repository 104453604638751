import type * as TypeFest from 'type-fest';

import tsroutes, { type RoutePartSchema } from '../lib/type-safe-routes';
import {
  type RouteNames as Rn,
  type RouteDefinitions,
  getRouteDefinition,
} from '../routes';

/*
|==========================================================================
| useRoute
|==========================================================================
|
| Hook to get the route definition and helper functions for a route.
|
*/

/*
|------------------
| Types
|------------------
*/

export type RouteNames = Rn;

export type RouteParams<N extends RouteNames> = TypeFest.Simplify<
  RoutePartSchema<RouteDefinitions[N]['params']>
>;

export type RouteSearch<N extends RouteNames> = TypeFest.Simplify<
  Partial<RoutePartSchema<RouteDefinitions[N]['search']>>
>;

/*
|------------------
| Utils
|------------------
*/

const getParams = <Name extends RouteNames>(
  routeName: Name,
  data: unknown
): RouteParams<Name> => {
  const route = getRouteDefinition(routeName);
  if (route.params) {
    return tsroutes.params(data, route.params) as RouteParams<Name>;
  }
  return {} as unknown as RouteParams<Name>;
};

const getSearch = <Name extends RouteNames>(
  routeName: Name,
  data: unknown
): RouteSearch<Name> => {
  const route = getRouteDefinition(routeName);
  if (route.search) {
    return tsroutes.search(data, route.search) as RouteSearch<Name>;
  }
  return {} as unknown as RouteSearch<Name>;
};

export const getUrl = <Name extends RouteNames>(
  routeName: Name,
  params?: RouteParams<Name>,
  search?: Partial<RouteSearch<Name>>
): string => {
  const route = getRouteDefinition(routeName);
  let path: string = route.path;

  if (params) {
    for (const key in params) {
      path = path.replace(`:${key}`, params[key] as string);
    }
  }

  if (search) {
    path += '?';

    for (const key in search) {
      path += `${key}=${search[key] as string}&`;
    }
  }

  return path.replace(/&$/, '');
};

/*
|------------------
| Public API
|------------------
*/

export const useRoute = <Name extends RouteNames>(routeName: Name) => {
  const route = getRouteDefinition(routeName);

  /**
   * Get the params object for the route.
   *
   * @param data A data object
   * @returns The params object for the route
   */
  const params = (data: unknown): RouteParams<Name> =>
    getParams(routeName, data);

  /**
   * Get the search object for the route.
   *
   * @param data A data object
   * @returns The search object for the route
   */
  const search = (data: unknown): RouteSearch<Name> =>
    getSearch(routeName, data);

  /**
   * Get the url for the route.
   *
   * @param params A params object
   * @param search A search object
   * @returns The url for the route
   */
  const url = (
    params?: RouteParams<Name>,
    search?: RouteSearch<Name>
  ): string => getUrl(routeName, params, search);

  return {
    definition: route,
    url,
    search,
    params,
  };
};
