import { create } from 'zustand';

export interface UIStateStore {
  showConfetti: boolean;
  setShowConfetti: (confetti: boolean) => void;
  userInvitesDialog: {
    open: boolean;
  };
  setUserInvitesDialog: (userInvitesDialog: { open: boolean }) => void;
}

/**
 * Global UI state store, used to manage UI state across the application.
 *
 * @deprecated - use `useAppState` instead.
 */
export const useUIState = create<UIStateStore>((set) => ({
  showConfetti: false,
  setShowConfetti: (confetti) => {
    set({ showConfetti: confetti });
  },
  userInvitesDialog: {
    open: false,
  },
  setUserInvitesDialog: (inviteUserModal) => {
    set({ userInvitesDialog: inviteUserModal });
  },
}));
