import * as Frigade from '@frigade/react';
import { useTheme } from '@joggrdocs/riker';
import _ from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useConfig } from '@stargate/hooks';

import { useFrigadeStore } from '../hooks/use-frigade-store';
import { createCSS, createTheme } from '../theme';
import { FrigadeCollection } from './FrigadeCollection';

export interface FrigadeProviderProps {
  /**
   * The children to render.
   */
  children: React.ReactNode;

  /**
   * Whether to disable the proxy.
   */
  disableProxy?: boolean;
}

export const FrigadeProvider: React.FC<FrigadeProviderProps> = ({
  children,
  disableProxy = false,
}) => {
  const config = useConfig();
  const frigadeStore = useFrigadeStore();
  const navigate = useNavigate();
  const theme = useTheme();

  const userId = React.useMemo(() => {
    return frigadeStore.userId ?? undefined;
  }, [frigadeStore.userId]);

  const frigadeTheme = React.useMemo(() => {
    return createTheme(theme);
  }, [theme]);

  const frigadeCSS = React.useMemo(() => {
    return createCSS(theme);
  }, [theme]);

  return (
    <Frigade.Provider
      apiKey={config.frigade.apiKey}
      apiUrl={disableProxy === true ? undefined : config.frigade.apiUrl}
      userId={userId}
      css={frigadeCSS}
      theme={frigadeTheme}
      navigate={(url, target) => {
        if (target === '_blank') {
          window.open(url, '_blank');
        } else {
          navigate(url);
        }
      }}
    >
      {children}
      <FrigadeCollection collectionSlug='productTours' />
    </Frigade.Provider>
  );
};
