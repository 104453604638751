import { Box, type BoxProps, useTheme } from '@joggrdocs/riker';
import React from 'react';
import type * as TypeFest from 'type-fest';

import { generateComponentClasses } from '@stargate/theme';

export const codeCardActionsClasses = generateComponentClasses(
  'CodeCardContentActions'
);

export type CodeCardContentActionsProps = TypeFest.Simplify<
  {
    anchorOrigin: {
      horizontal: 'left' | 'right';
      vertical: 'top' | 'bottom';
    };
  } & BoxProps
>;

/**
 * CardContentActions (floating) component for code.
 */
export const CodeCardContentActions: React.FC<CodeCardContentActionsProps> = ({
  anchorOrigin,
  ...props
}) => {
  const theme = useTheme();
  const spacingVertical = theme.spacing(1);
  const spacingHorizontal = theme.spacing(1);
  return (
    <Box
      {...props}
      className={theme.utils.classNames([
        codeCardActionsClasses.root,
        props.className,
      ])}
      sx={{
        ...props.sx,
        position: 'absolute',
        top: anchorOrigin.vertical === 'top' ? spacingVertical : undefined,
        bottom:
          anchorOrigin.vertical === 'bottom' ? spacingVertical : undefined,
        left:
          anchorOrigin.horizontal === 'left' ? spacingHorizontal : undefined,
        right:
          anchorOrigin.horizontal === 'right' ? spacingHorizontal : undefined,
      }}
    />
  );
};
