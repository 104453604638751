import {
  TableOfContents,
  getHierarchicalIndexes,
} from '@tiptap-pro/extension-table-of-contents';

// We directly import so we get a quasi-native TipTap extension, in future we will use a less tightly-coupled solution
import { useStore } from '@stargate/features/docs/hooks/use-jdoc-toc';

export default TableOfContents.configure({
  getIndex: getHierarchicalIndexes,
  onUpdate: (items) => {
    useStore.getState().update(items);
  },
});
