/**
 * Http Error Codes for our API.
 *
 * @todo integrate with localization
 */
export const httpErrorCodes = {
  DOC_NF001: 'JoggrDoc not found.',
  DOC_NF002: "JoggrDoc found but markdown file doesn't exist in GitHub.",
  IMP_001:
    'Some of the provided imports contain file paths that are associated with existing JoggrDocs.',
  IMP_002:
    'JoggrDocs are either already imported or the contents of one of the files contains JoggrDoc metadata. You can remove the metadata in the file manually and try again.',
  IMP_003:
    'JoggrDoc is missing key data such as title, description, or filePath in the import.',
  UNK_000: 'An unknown error occurred.',
} satisfies Record<string, string>;

export type HttpErrorCode = keyof typeof httpErrorCodes;

/**
 * Get a user-friendly error message from an error code.
 *
 * @param code An error code.
 * @returns A user-friendly error message.
 */
export const getHttpErrorMessage = (code: HttpErrorCode) => {
  return httpErrorCodes[code] ?? httpErrorCodes.UNK_000;
};
