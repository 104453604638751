import qs, { type ParsedQs } from 'qs';
import * as ReactRouter from 'react-router-dom';

/*
|==========================================================================
| useLocation Wrapper for React Router
|==========================================================================
|
| Provides a hook that returns the current location, including the query
| string as an object.
|
| @todo Assess typing this using Typesafe routes
|
*/

export interface Location<Q> extends ReactRouter.Location {
  query: Q;
}

/** @deprecated */
export default function useLocation<Q = ParsedQs>(): Location<Q> {
  const location = ReactRouter.useLocation();

  return {
    ...location,
    query: (location.search ? qs.parse(location.search) : {}) as unknown as Q,
  };
}
