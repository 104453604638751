import React from 'react';

import { IconAvatar, type IconAvatarProps } from '@stargate/components/Avatars';

import {
  type JoggrComponentClassesProp,
  generateComponentClasses,
} from '@stargate/theme';

/*
|==========================================================================
| GitHubBrandAvatar
|==========================================================================
|
| An avatar with the GitHub brand icon. 
|
*/

export const gitHubBrandAvatarClasses = generateComponentClasses(
  'GitHubBrandAvatar',
  ['root', 'icon'] as const
);

export interface GitHubBrandAvatarProps extends Omit<IconAvatarProps, 'icon'> {
  gap?: number;
  classes?: JoggrComponentClassesProp<typeof gitHubBrandAvatarClasses>;
}

export const GitHubBrandAvatar = React.forwardRef<
  HTMLDivElement,
  GitHubBrandAvatarProps
>((props, ref) => {
  return (
    <IconAvatar
      {...props}
      ref={ref}
      backgroundColor='#000000'
      icon='brand-github'
      classes={props.classes ?? gitHubBrandAvatarClasses}
    />
  );
});
GitHubBrandAvatar.muiName = 'IconAvatar';
