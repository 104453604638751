import React from 'react';

import { Drawer, drawerClasses, useTheme } from '@joggrdocs/riker';

import SideBarDrawer from './SideBarDrawer';
import SideBarNav from './SideBarNav';

/*
|==========================================================================
| Sidebar
|==========================================================================
|
| SideBar is the main navigation component for the dashboard.
|
*/

const Sidebar = () => {
  const theme = useTheme();
  return (
    <React.Fragment>
      <Drawer
        component='nav'
        sx={{
          position: 'relative',
          zIndex: theme.zIndex.appBar - 1,
          overflow: 'visible',
          width: theme.measurements.sideBar.width,
          borderRight: `1px solid ${theme.palette.divider}`,
          bgcolor: theme.palette.background.dashboardSideBar,
          color: theme.palette.text.primary,
          [`& .${drawerClasses.paper}`]: {
            height: `calc(100vh - ${theme.measurements.topBar.height}px)`,
            bgcolor: theme.palette.background.dashboardSideBar,
            width: theme.measurements.sideBar.width,
            mt: `${theme.measurements.topBar.height}px`,
            px: 1,
            py: 1,
          },
        }}
        PaperProps={{
          sx: {
            overflow: 'visible',
          },
        }}
        variant='permanent'
      >
        <SideBarNav />
      </Drawer>
      <SideBarDrawer />
    </React.Fragment>
  );
};

export default Sidebar;
