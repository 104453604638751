import { IconButton, IconCopy, Typography } from '@joggrdocs/riker';
import * as hookz from '@react-hookz/web';
import _ from 'lodash';
import React from 'react';
import type * as TypeFest from 'type-fest';

import api, { type APIResponse } from '@stargate/api';
import { LoadingOverlay } from '@stargate/components/Loading';
import { useDelayedState } from '@stargate/hooks';
import Codemirror, {
  useCodeMirrorLangs,
  type CodeMirrorLang,
  type CodeMirrorProps,
} from '@stargate/lib/codemirror';
import { useNotify } from '@stargate/lib/notify';

import {
  CodeBlockCard,
  CodeBlockCardContent,
  CodeBlockCardContentActions,
  CodeBlockCardFooter,
} from '@dashdraft/components/CodeBlockCards';

import { useDashDraftEditor } from '@dashdraft/hooks/use-dashdraft-editor';
import { useParams } from 'react-router-dom';
import {
  filterPlaceholderCode,
  getPlaceholderData,
  hasPlaceholderCode,
} from '../lib/placeholder';
import type {
  CodeBlockSnippetAttributes,
  CodeBlockTypeNodeViewProps,
  CodeSnippetAPIResponse,
} from '../types';
import { CodeBlockError } from './shared/CodeBlockError';
import { CodeSnippetHeader } from './snippets/CodeSnippetHeader';
import { CodeSnippetLocation } from './snippets/CodeSnippetLocation';
import { CodeSnippetPlaceHolder } from './snippets/CodeSnippetPlaceholder';

/**
 * The base props for the CodeBlockSnippet NodeView
 */
export type CodeBlockSnippetProps =
  CodeBlockTypeNodeViewProps<CodeBlockSnippetAttributes>;

export const CodeBlockSnippet = React.memo<CodeBlockSnippetProps>(
  ({
    code,
    language,
    selected,
    readonly = false,
    placeholder,
    codeSnippetId,
    onChangeCode,
    onChangeLanguage,
    onDelete,
    getPos,
    onKeyUp,
  }) => {
    const cmLangs = useCodeMirrorLangs();
    const notify = useNotify();
    const editor = useDashDraftEditor();
    const placeholderData = getPlaceholderData();
    const params = useParams<{ id: string }>();
    const [readCodeSnippetState, readCodeSnippetActions] = api.useRequestClient(
      'GET /code-sources/:codeSourceId'
    );
    // const [deleteCodeSnippetState, deleteCodeSnippetActions] =
    //   api.useRequestClient('DELETE /code-sources/:codeSourceId');

    /*
    |------------------
    | Computed
    |------------------
    */

    const autoFocus = React.useMemo(() => {
      if (placeholder || readonly) {
        return false;
      }

      return selected;
    }, [placeholder, selected, readonly]);

    const loading = React.useMemo<boolean>(() => {
      return readCodeSnippetState.status === 'loading';
    }, [readCodeSnippetState.status]);

    // const mutating = React.useMemo<boolean>(() => {
    //   return deleteCodeSnippetState.status === 'loading';
    // }, [deleteCodeSnippetState.status]);

    const data = React.useMemo<CodeSnippetData | null>(() => {
      if (
        !_.isNil(readCodeSnippetState.result?.lineNumberEnd) &&
        !_.isNil(readCodeSnippetState.result?.lineNumberStart)
      ) {
        return {
          ...readCodeSnippetState.result,
          lineNumberEnd: readCodeSnippetState.result.lineNumberEnd,
          lineNumberStart: readCodeSnippetState.result.lineNumberStart,
        };
      }

      if (placeholderData) {
        return placeholderData;
      }

      return null;
    }, [readCodeSnippetState, placeholderData]);

    const access = React.useMemo<CodeSnippetAccess>(() => {
      return data?.access ?? 'none';
    }, [data]);

    const computedCode = React.useMemo(() => {
      if (placeholder) {
        return placeholderData.code;
      }

      // if we somehow have a placeholder code, we should not render it
      if (!_.isNil(code) && hasPlaceholderCode(code)) {
        return '';
      }

      if (!_.isNil(code)) {
        return filterPlaceholderCode(code);
      }

      return '';
    }, [placeholderData.code, placeholder, code]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: only need to update when `data.path` or `attributes.language` changes
    const computedLanguage = React.useMemo<CodeMirrorLang>(() => {
      if (isPlaceholder(language)) {
        return placeholderData.language;
      }

      if (language) {
        return language;
      }

      if (data?.path) {
        const extension = data.path.split('/').pop();
        if (extension) {
          return cmLangs.findByFileExtension(extension);
        }
      }

      return 'plaintext';
    }, [data?.path, language]);

    /*
    |------------------
    | State
    |------------------
    */

    const [hover, setHover] = React.useState<boolean>(false);
    const [isTyping, setIsTyping, setDelayedIsTyping] =
      useDelayedState<boolean>(false);
    const [focusedCodeMirror, setCodeMirrorFocused] =
      React.useState<boolean>(false);

    /*
    |------------------
    | Callbacks
    |------------------
    */

    const handleCodeMirrorFocus = React.useCallback(() => {
      setCodeMirrorFocused(true);
    }, []);

    const handleCodeMirrorBlur = React.useCallback(() => {
      setCodeMirrorFocused(false);
    }, []);

    // biome-ignore lint/correctness/useExhaustiveDependencies: State changes can be ignored
    const handleCodeMirrorKeyUp = React.useCallback<
      Required<CodeMirrorProps>['onKeyUp']
    >(
      (event, view) => {
        onKeyUp?.(event, view);
        if (
          event.key !== 'ArrowDown' &&
          event.key !== 'ArrowUp' &&
          event.key !== 'Enter' &&
          event.key !== 'Escape'
        ) {
          setIsTyping(true);
          setDelayedIsTyping(false, 6000);
        } else {
          setIsTyping(false);
        }
      },
      [onKeyUp]
    );

    const handleCopyCode = React.useCallback(() => {
      if (computedCode) {
        navigator.clipboard.writeText(computedCode);
        notify.send({
          message: 'Copied code to clipboard',
          severity: 'success',
        });
      }
    }, [computedCode, notify]);

    const handleClickOpenExplorer = React.useCallback(() => {
      // Forces the current node to be selected
      editor.chain().setNodeSelection(getPos()).openCodeExplorer().run();
    }, [editor, getPos]);

    const handleClickViewCode = React.useCallback(() => {
      if (codeSnippetId) {
        editor
          .chain()
          .setNodeSelection(getPos())
          .openCodeExplorer({
            codeSourceId: codeSnippetId,
          })
          .run();
      }
    }, [codeSnippetId, editor, getPos]);

    const handleDelete = React.useCallback(() => {
      // @todo we need a much better way to handle this....

      // For now just delete the node only and upstream we handle the merging
      onDelete();

      // if (data?.id && placeholder !== true && params.id) {
      //   void deleteCodeSnippetActions
      //     .execute({
      //       params: {
      //         documentId: params.id,
      //         codeSourceId: data?.id,
      //       },
      //     })
      //     .then(() => {
      //       notify.send({
      //         message: 'Successfully deleted code snippet',
      //         severity: 'success',
      //       });
      //       onDelete();
      //     });
      // } else {
      //   onDelete();
      // }
    }, [
      // deleteCodeSnippetActions,
      data?.id,
      placeholder,
      onDelete,
      notify.send,
    ]);

    const handleAction = React.useCallback(
      (action: string) => {
        switch (action) {
          case 'copy':
            handleCopyCode();
            break;
          case 'delete':
            handleDelete();
            break;
          default:
            break;
        }
      },
      [handleCopyCode, handleDelete]
    );

    /*
    |------------------
    | Effects
    |------------------
    */

    // 🚨🚨🚨 HACK ALERT 🚨🚨🚨
    // If we have the placeholder data, we need to clear it
    // and "resave" the code snippet code, this is NOT ideal as we should
    // be able to replace the content but we need to dig deeper into NodePos and ProseMirror
    // to figure out how to do this properly.
    React.useEffect(() => {
      if (
        !_.isNil(code) &&
        hasPlaceholderCode(code) &&
        !isPlaceholder(language)
      ) {
        onChangeCode(filterPlaceholderCode(code));
      }
    }, [code, language, onChangeCode]);

    // If we have a code snippet id, and it's not a placeholder,
    // then we should fetch the code snippet from the API
    const previousCodeSnippetId = hookz.usePrevious(codeSnippetId);
    React.useEffect(() => {
      if (
        codeSnippetId &&
        codeSnippetId !== previousCodeSnippetId &&
        placeholder !== true &&
        _.isNil(readCodeSnippetState.result)
      ) {
        void readCodeSnippetActions.execute({
          params: {
            codeSourceId: codeSnippetId,
          },
        });
      }
    }, [
      codeSnippetId,
      previousCodeSnippetId,
      placeholder,
      readCodeSnippetState.result,
      readCodeSnippetActions,
    ]);

    return (
      <CodeBlockCard
        sx={{
          position: 'relative',
          '& .cm-cursorLayer': {
            display: readonly ? 'none !important' : undefined,
          },
        }}
      >
        <LoadingOverlay
          variant='contained'
          // loading={loading || mutating}
          loading={loading}
        />
        {readCodeSnippetState.status === 'error' && (
          <CodeBlockError
            message={
              readonly
                ? 'Failed to load Code Snippet, switch to edit mode to try again or delete the Code Snippet.'
                : 'Failed to load Code Snippet, click to delete the Code Snippet.'
            }
            onDelete={onDelete}
            overlay
          />
        )}
        {placeholder && readonly === true && (
          <CodeBlockError
            message='Cannot load Placeholder Code Snippet, please switch to edit mode and remove or insert a Code Snippet.'
            overlay
          />
        )}
        {placeholder && readonly === false && (
          <CodeSnippetPlaceHolder
            onOpenCodeExplorer={handleClickOpenExplorer}
            onDelete={onDelete}
          />
        )}
        <CodeSnippetHeader
          readonly={readonly}
          language={computedLanguage}
          codeSnippet={data}
          onViewCodeClick={handleClickViewCode}
          onChangeLanguage={onChangeLanguage}
          onActionClick={handleAction}
        />
        <CodeBlockCardContent
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => {
            setHover(false);
          }}
          sx={{
            minHeight: loading ? '200px' : undefined,
          }}
        >
          {code && (
            <React.Fragment>
              <Codemirror
                code={computedCode}
                lineNumberOffset={
                  data ? Math.max(0, data.lineNumberStart - 1) : undefined
                }
                lang={computedLanguage}
                onCodeChange={onChangeCode}
                readonly={readonly || access !== 'full'}
                onKeyUp={handleCodeMirrorKeyUp}
                ReactCodeMirrorProps={{
                  minHeight: '48px',
                  onFocus: handleCodeMirrorFocus,
                  onBlur: handleCodeMirrorBlur,
                  autoFocus,
                }}
              />
              {!readonly && (
                <Typography
                  variant='caption'
                  sx={{
                    ml: 1,
                    visibility:
                      !isTyping && focusedCodeMirror ? 'visible' : 'hidden',
                  }}
                >
                  <strong>Pro Tip:</strong> Hit the ↓ down or ↑ up arrow to exit
                  the code snippet.
                </Typography>
              )}
            </React.Fragment>
          )}
          <CodeBlockCardContentActions
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{
              display: hover ? 'block' : 'none',
            }}
          >
            <IconButton size='small' onClick={handleCopyCode}>
              <IconCopy />
            </IconButton>
          </CodeBlockCardContentActions>
        </CodeBlockCardContent>
        <CodeBlockCardFooter>
          <CodeSnippetLocation codeSnippet={data} />
        </CodeBlockCardFooter>
      </CodeBlockCard>
    );
  }
);
CodeBlockSnippet.displayName = 'CodeBlockSnippet';

/*
|------------------
| Internals
|------------------
*/

type CodeSnippetData = CodeSnippetAPIResponse | null;

type CodeSnippetAccess =
  APIResponse<'GET /code-sources/:codeSourceId'>['access'];

/**
 * Checks if a language is a placeholder.
 *
 * @param language A language to check.
 * @returns A boolean indicating if the language is a placeholder.
 */
const isPlaceholder = (language: unknown): boolean => {
  return language === 'placeholder';
};
