import _ from 'lodash';
import React from 'react';

import { Box, type SxProps, useTheme } from '@joggrdocs/riker';
import { BlockActions } from './BlockActions';

export interface BlockProps {
  className?: string;
  actions?: React.ReactNode | ((active: boolean) => React.ReactNode);
  sx?: SxProps;
  children: React.ReactNode;
}

/**
 * A generic component for rendering a NodeView block in the editor.
 */
export const Block = React.forwardRef<HTMLDivElement, BlockProps>(
  ({ actions, className, children, ...props }, ref) => {
    const [active, setActive] = React.useState(false);
    const theme = useTheme();

    /*
  |------------------
  | Computed
  |------------------
  */

    const actionsRendered = React.useMemo(() => {
      if (typeof actions === 'function') {
        return actions(active);
      }

      return actions;
    }, [active, actions]);

    /*
  |------------------
  | Callbacks
  |------------------
  */
    const handleSetActive = React.useCallback(() => {
      setActive(true);
    }, []);

    const handleSetNotActive = React.useCallback(() => {
      setActive(false);
    }, []);

    return (
      <Box
        ref={ref}
        className={theme.utils.classNames({
          'dashdraft-block': true,
          'dashdraft-block-active': active,
          [className ?? '']: _.isNil(className),
        })}
        onMouseEnter={handleSetActive}
        onMouseLeave={handleSetNotActive}
        sx={{
          ...props.sx,
          position: 'relative',
        }}
        contentEditable={false}
      >
        {!_.isNil(actionsRendered) && (
          <BlockActions active={active}>{actionsRendered}</BlockActions>
        )}
        {children}
      </Box>
    );
  }
);
Block.displayName = 'Block';

export default Block;
