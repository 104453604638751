import { Extension } from '@tiptap/core';

import code from './extensions/code';

export default Extension.create({
  name: 'dashdraft-code',

  addExtensions() {
    return [code];
  },
});
