import { produce } from 'immer';
import _ from 'lodash';
import { create } from 'zustand';

import type { DirectoryTree, DirectoryTreeFilter } from '../types';

/*
|==========================================================================
| useDirectoryAction
|==========================================================================
|
| A global store, used to manage the DirectoryActions dialog. 
|
*/

export interface State {
  /**
   * Whether the directory tree is being loaded.
   */
  loading: boolean;

  /**
   * The directory tree to display in the dialog.
   */
  data?: DirectoryTree;

  /**
   * Filter to apply to the directory tree.
   */
  filters: DirectoryTreeFilter | null;

  /**
   * The ids of the directories that are expanded in the directory tree.
   */
  expandedNodes: string[];

  /**
   * The id of the directory or document that is selected in the directory tree.
   */
  selectedNode: string | null;
}

export interface Actions {
  /**
   * Expand a list of nodes in the directory tree.
   *
   * @param ids A list of nodes ids to expand.
   */
  onExpandNodes: (ids: string[]) => void;

  /**
   * Collapse a list of nodes in the directory tree.
   *
   * @param ids A list of nodes ids to collapse.
   */
  onCollapseNodes: (ids: string[]) => void;

  /**
   * Trigger the selection of a directory or document in the directory tree.
   *
   * @param id A directory or document id.
   */
  onItemSelectionToggle: (id: string | null) => void;

  /**
   * Set the loading state of the directory tree API data.
   */
  setLoading: (update: boolean) => void;

  /**
   * Set the directory tree data, from the API.
   *
   * @param directoryTree Directory tree to set.
   */
  setData: (directoryTree: DirectoryTree) => void;

  /**
   * Clear the directory tree, from the API.
   */
  clearData: () => void;

  /**
   * Set the filters to apply to the directory tree.
   *
   * @param filters Filters to apply to the directory tree.
   */
  setFilters: (filters: DirectoryTreeFilter) => void;

  /**
   * Clear the filters to apply to the directory tree.
   */
  clearFilters: () => void;
}

export interface Store {
  state: State;
  actions: Actions;
}

const useMemoryStore = create<Store>((set) => ({
  state: {
    dialogOpen: false,
    filters: null,
    data: undefined,
    loading: false,
    expandedNodes: [],
    selectedNode: null,
  },
  actions: {
    setData: (directoryTree) => {
      set(
        produce((draft: Store) => {
          draft.state.data = directoryTree;
          draft.state.loading = false;
        })
      );
    },
    setLoading: (update) => {
      set(
        produce((draft: Store) => {
          draft.state.loading = update;
        })
      );
    },
    clearData: () => {
      set(
        produce((draft: Store) => {
          draft.state.loading = false;
          draft.state.data = undefined;
        })
      );
    },
    setFilters: (filters) => {
      set(
        produce((draft: Store) => {
          draft.state.filters = filters;
        })
      );
    },
    clearFilters: () => {
      set(
        produce((draft: Store) => {
          draft.state.filters = null;
        })
      );
    },
    onExpandNodes: (ids) => {
      set(
        produce((draft: Store) => {
          draft.state.expandedNodes = [...ids];
        })
      );
    },
    onCollapseNodes: (ids) => {
      set(
        produce((draft: Store) => {
          draft.state.expandedNodes = _.filter(
            draft.state.expandedNodes,
            (id) => !_.includes(ids, id)
          );
        })
      );
    },
    onItemSelectionToggle: (id) => {
      set(
        produce((draft: Store) => {
          draft.state.selectedNode = id;
        })
      );
    },
  },
}));

/**
 * Hook for managing the Document Directories feature.
 *
 * @returns A tuple containing the state and actions of the Directory Directories store.
 */
const useDirectoryTreeStore = (): [State, Actions] => {
  return useMemoryStore(({ state, actions }) => [state, actions]);
};

export default useDirectoryTreeStore;
