import { Box } from '@joggrdocs/riker';
import * as hookz from '@react-hookz/web';
import React from 'react';

import {
  DashDraftContent,
  type DashDraftJSONContent,
  DashDraftProvider,
  convertMarkdownToJSON,
} from '@stargate/dashdraft';

export interface MarkdownPreviewProps {
  /**
   * The Markdown content to display.
   */
  markdown: string;
}

/**
 * A component for viewing a Markdown using DashDraft.
 */
export const MarkdownPreview = React.memo<MarkdownPreviewProps>(
  ({ markdown }) => {
    const [mdState, mdActions] = hookz.useAsync<DashDraftJSONContent, [string]>(
      async (md) => {
        return await convertMarkdownToJSON(md);
      }
    );

    // We force this to rerender when the content changes
    // biome-ignore lint/correctness/useExhaustiveDependencies: only rerender when content changes
    React.useEffect(() => {
      void mdActions.execute(markdown);
    }, [markdown]);

    if (mdState.status !== 'success') {
      return null;
    }

    return (
      <Box
        component='span'
        sx={{
          // Override base styles to help with viewing
          '.dashdraft.dashdraft.dashdraft.dashdraft.dashdraft': {
            marginRight: '0px',
            marginLeft: '0px',
            // Hide the CodeBlockCardHeader
            '& .Riker-CodeBlockCardHeader-root': {
              display: 'none',
            },
            // CodeMirror
            '& .cm-root': {
              fontSize: '14px',
            },
            // Content
            '& > .dashdraft-content > .ProseMirror > *': {
              marginRight: '0px',
              marginLeft: '0px',
            },
            '& h1, & h2, & h3': {
              mb: '4px',
              mt: '16px',
              pb: '4px',
            },
            '& h1': {
              fontSize: '26px',
            },
            '& h2': {
              fontSize: '22px',
            },
            '& h3': {
              fontSize: '18px',
            },
            '& h4': {
              fontSize: '16px',
            },
            '& p': {
              fontSize: '14px',
              my: '6px',
            },
            '& ul': {
              fontSize: '14px',
              my: '6px',
            },
            '& ol': {
              fontSize: '14px',
              my: '6px',
            },
          },
        }}
      >
        <DashDraftProvider
          extensionsConfig={{
            tableOfContents: false,
            utilities: false,
          }}
        >
          <DashDraftContent defaultContent={mdState.result} readonly />
        </DashDraftProvider>
      </Box>
    );
  }
);
