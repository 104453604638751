import React from 'react';

import { ThemeProvider, useCreateTheme } from '@joggrdocs/riker';

import { useUserSettings } from '@stargate/features/user';
import { createMeasurements, createPalette } from '@stargate/theme/aspects';

import '@joggrdocs/riker/style.css';

/*
|==========================================================================
| Theme
|==========================================================================
|
| Wrapper that handles the theme for the application.
|
*/

export interface ThemeProps {
  children: React.ReactNode;
}

export const Theme: React.FC<ThemeProps> = (props) => {
  const userSettings = useUserSettings();
  const theme = useCreateTheme(userSettings.data.theme, (baseTheme) => ({
    measurements: createMeasurements(baseTheme),
    palette: createPalette(baseTheme),
  }));

  return (
    <ThemeProvider theme={theme} appendToBody>
      {props.children}
    </ThemeProvider>
  );
};

export default Theme;
