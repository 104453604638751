import { queryOptions } from '@tanstack/react-query';
import _ from 'lodash';

import { apiClient } from '@stargate/api/api-client';
import { transformFromAPIContent } from '@stargate/api/lib/transformers';

/**
 * Get the query options for a Joggr Template.
 *
 * @api `POST /templates/:templateId/render`
 * @param templateId The ID of the template.
 * @returns The query options.
 */
export const templateQueryOptions = (
  templateId?: string | null,
  variables: {
    name: string;
    value: string;
  }[] = []
) => {
  return queryOptions({
    queryKey: [
      'POST /templates/:templateId/render',
      { params: { templateId }, body: { variables } },
    ] as const,
    queryFn: async ({ queryKey }) => {
      const [route, payload] = queryKey;

      if (!_.isNil(payload.params.templateId)) {
        // For "blank" templates, we don't need to make a request to the API, and just return an empty object.
        if (templateId === 'blank') {
          return null;
        }

        const result = await apiClient.request(route, {
          params: { templateId: payload.params.templateId },
          body: {
            variables,
          },
        });
        return {
          ...result,
          content: await transformFromAPIContent(result.content),
        };
      }

      return null;
    },
  });
};
