/*
|==========================================================================
| time
|==========================================================================
|
| time utilities
|
*/

/**
 * Get a number of milliseconds in the specified number of seconds
 *
 * @param seconds A number of seconds
 * @returns A number of milliseconds in the specified number of seconds
 */
export const getMillisecondsBySeconds = (seconds: number) => {
  return 1000 * seconds;
};

/**
 * Get a number of milliseconds in the specified number of minutes
 *
 * @param minutes A number of minutes
 * @returns A number of milliseconds in the specified number of minutes
 */
export const getMillisecondsByMinutes = (minutes: number) => {
  return getMillisecondsBySeconds(60) * minutes;
};

/**
 * Get a number of milliseconds in the specified number of hours
 *
 * @param hours the number of hours
 * @returns A number of milliseconds in the specified number of hours
 */
export const getMillisecondsByHours = (hours: number) => {
  return getMillisecondsByMinutes(60) * hours;
};

/**
 * Get a number of milliseconds in the specified number of hours
 *
 * @param days A number of days
 * @returns A number of milliseconds in the specified number of days
 */
export const getMillisecondsByDays = (days: number) => {
  return getMillisecondsByHours(24) * days;
};

/**
 * Sleep for a specified number of milliseconds
 *
 * @param ms Milliseconds to sleep
 * @returns A promise that resolves after the specified number of milliseconds
 */
export const sleep = async (ms: number) => {
  return await new Promise((resolve) => setTimeout(resolve, ms));
};
