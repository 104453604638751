import {
  FormControl,
  type FormControlProps,
  FormHelperText,
  InputLabel,
  Select,
  type SelectChangeEvent,
  generateComponentClasses,
} from '@joggrdocs/riker';
import _ from 'lodash';
import React from 'react';

import {
  SelectMenuItem,
  SelectRenderValue,
} from '@stargate/components/FormFields';

import type { GitHubRepository } from '../../types';
import { GitHubRepositoryIcon } from '../Icons';

export const githubRepositorySelectClasses = generateComponentClasses(
  'GitHubRepositorySelect',
  ['root', 'select']
);

export interface GitHubRepositorySelectProps extends BaseProps {
  /**
   * The id for the label association
   */
  id: string;

  /**
   * Label for the select
   */
  label?: React.ReactNode;

  /**
   * Placeholder for the select
   */
  placeholder?: string;

  /**
   * Options to display in the select
   */
  options: GitHubRepository[];

  /**
   * Default value for the select
   */
  defaultValue?: GitHubRepository;

  /**
   * Text to display below the select
   */
  helperText?: React.ReactNode;

  /**
   * Flag to disable showing the owner name
   */
  hideOwner?: boolean;

  /**
   * Callback when the select value changes
   *
   * @param data The selected repository(s)
   * @returns A callback when the select value changes
   */
  onChange: (data: GitHubRepository) => void;

  /**
   * Callback when the select is closed
   */
  onClose?: () => void;

  /**
   * Callback when the select is opened
   */
  onOpen?: () => void;
}

export const GitHubRepositorySelect: React.FC<GitHubRepositorySelectProps> = ({
  id,
  fullWidth = true,
  label = 'GitHub Repository',
  placeholder = 'Select a GitHub Repository',
  options,
  defaultValue,
  onChange,
  onClose,
  onOpen,
  helperText,
  disabled = false,
  hideOwner = false,
  ...props
}) => {
  /*
  |------------------
  | Computed
  |------------------
  */

  const defaultRepositoryOption = React.useMemo(() => {
    if (_.isNil(defaultValue)) {
      return null;
    }
    return transformToOption(defaultValue, hideOwner);
  }, [defaultValue, hideOwner]);

  const opts = React.useMemo(() => {
    return options.map((repository) =>
      transformToOption(repository, hideOwner)
    );
  }, [options, hideOwner]);

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const handleChange = React.useCallback(
    (event: SelectChangeEvent<string>) => {
      const repository = options.find((repository) => {
        return repository.id === event.target.value;
      });
      if (!_.isNil(repository)) {
        onChange(repository);
      }
    },
    [options, onChange]
  );

  return (
    <FormControl
      {...props}
      classes={{
        root: githubRepositorySelectClasses.root,
      }}
      fullWidth={fullWidth}
      disabled={disabled}
    >
      <InputLabel id={id}>GitHub Repository</InputLabel>
      <Select
        classes={{
          root: githubRepositorySelectClasses.select,
        }}
        labelId={id}
        label={label}
        placeholder={placeholder}
        defaultValue={defaultRepositoryOption?.value}
        disabled={disabled}
        onOpen={onOpen}
        onClose={onClose}
        onChange={handleChange}
        renderValue={(selected) => {
          const opt = opts.find((repository) => repository.value === selected);
          if (!_.isNil(opt)) {
            return (
              <SelectRenderValue
                placeholder='Select a GitHub Repository'
                icon={<GitHubRepositoryIcon />}
                label={opt.value}
              />
            );
          }
          return null;
        }}
      >
        {opts.map((options) => (
          <SelectMenuItem
            key={options.value}
            value={options.value}
            icon={<GitHubRepositoryIcon />}
            label={options.label}
          />
        ))}
      </Select>
      {!_.isNil(helperText) && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
GitHubRepositorySelect.displayName = 'GitHubRepositorySelect';

/*
|------------------
| Utils
|------------------
*/

type BaseProps = Omit<FormControlProps, 'onChange' | 'defaultValue'>;

/**
 * Transform a GitHub repository to an option.
 *
 * @param repository The GitHub repository to transform
 * @param hideOwner Flag to hide the owner name
 * @returns The transformed option
 */
const transformToOption = (
  repository: GitHubRepository,
  hideOwner: boolean
) => ({
  value: repository.id.toString(),
  label: hideOwner
    ? repository.name
    : `${repository.owner.name}/${repository.name}`,
});
