import {
  type QuackrStoreActions,
  type QuackrStoreState,
  quackrStore,
} from '../store';

export type UseQuackrHook = [QuackrStoreState, QuackrStoreActions];

/**
 * Quackr hook for managing the state of the dev tools.
 *
 * @returns A tuple containing the state and actions of the store.
 */
export const useQuackr = (): UseQuackrHook => {
  const [state, actions] = quackrStore((store) => [
    {
      enabled: store.enabled,
      placement: store.placement,
      devtools: store.devtools,
    },
    {
      enable: store.enable,
      disable: store.disable,
      setDevtoolConfig: store.setDevtoolConfig,
      setDevtoolConfigValue: store.setDevtoolConfigValue,
      setPlacement: store.setPlacement,
    },
  ]);

  return [state, actions];
};
