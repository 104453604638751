import type APIContract from '@joggrdocs/contract';
import {
  Avatar,
  ListItemAvatar,
  ListItemIcon,
  MenuItem,
  RikerIcon,
  Typography,
  useTheme,
} from '@joggrdocs/riker';
import * as hookz from '@react-hookz/web';
import _ from 'lodash';
import React from 'react';

import apiClient from '@stargate/api';
import { useNotify } from '@stargate/lib/notify';

import type { AllowedAny } from '@stargate/lib/typist';
import {
  SuggestionMenu,
  type SuggestionMenuItem,
  type SuggestionMenuProps,
} from './SuggestionMenu';

const QUERY_MIN_LENGTH = 1;

export type MentionSuggestionItem =
  | MentionSuggestionDocItem
  | MentionSuggestionRepoItem
  | MentionSuggestionMemberItem;

export type MentionMenuProps = Omit<SuggestionMenuProps, 'items'>;

export const MentionMenu = React.forwardRef<HTMLElement, MentionMenuProps>(
  ({ editor, query, range, ...props }, ref) => {
    const [searchResultsOpen, setSearchResultsOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const previousSearchQuery = hookz.usePrevious(query);
    const [searchApiState, searchApiActions] =
      apiClient.useRequestClient('POST /search');
    const notify = useNotify();
    const theme = useTheme();

    /*
    |------------------
    | Computed
    |------------------
    */

    const items = React.useMemo(() => {
      const docs = searchApiState.result?.documents ?? [];
      const repos = searchApiState.result?.githubRepositories ?? [];
      const members = searchApiState.result?.workspaceMembers ?? [];

      return [
        ...docs.map((doc) => ({
          id: doc.id,
          group: 'docs',
          data: doc,
        })),
        ...repos.map((repo) => ({
          id: repo.id,
          group: 'repos',
          data: repo,
        })),
        ...members.map((member) => ({
          id: member.id,
          group: 'members',
          data: member,
        })),
      ];
    }, [searchApiState.result]);

    /*
    |------------------
    | Effects
    |------------------
    */

    React.useEffect(() => {
      // Reset the search results if the search query is empty
      if (_.isEmpty(query) && !_.isEmpty(previousSearchQuery)) {
        searchApiActions.reset();
        return;
      }

      // Run the search query if the query is long enough
      if (query?.length >= QUERY_MIN_LENGTH && query !== previousSearchQuery) {
        setLoading(true);
        searchApiActions
          .execute({
            body: {
              query: query,
            },
          })
          .then(() => {
            setLoading(false);
            if (!searchResultsOpen) {
              setSearchResultsOpen(true);
            }
          })
          .catch(() => {
            setLoading(false);
            setSearchResultsOpen(false);
            notify.error('Unable to search, please try again');
          });
      }
    }, [
      searchApiActions,
      query,
      previousSearchQuery,
      searchResultsOpen,
      notify,
    ]);

    return (
      <SuggestionMenu
        {...props}
        command={(item) => {
          props.command(item);
          searchApiActions.reset();
        }}
        menuRef={ref}
        loading={loading}
        items={items}
        editor={editor}
        range={range}
        query={query}
        renderItem={(item, selected) => {
          const avatarSx = {
            width: 32,
            height: 32,
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.primary.contrastText,
          };

          if (isMentionSuggestionRepoItem(item)) {
            return (
              <MentionMenuItem
                selected={selected}
                avatar={
                  <Avatar variant='rounded' sx={avatarSx}>
                    <RikerIcon name='brand-github' />
                  </Avatar>
                }
                content={item.data.name}
                onClick={item.onClick}
              />
            );
          }

          if (isMentionSuggestionDocItem(item)) {
            return (
              <MentionMenuItem
                selected={selected}
                avatar={
                  <Avatar variant='rounded' sx={avatarSx}>
                    <RikerIcon name='file-type-doc' />
                  </Avatar>
                }
                content={item.data.title}
                onClick={item.onClick}
              />
            );
          }

          if (isMemberSuggestionItem(item)) {
            return (
              <MentionMenuItem
                selected={selected}
                avatar={
                  <Avatar src={item.data.avatar} sx={avatarSx}>
                    <RikerIcon name='user' />
                  </Avatar>
                }
                content={item.data.githubUsername}
                onClick={item.onClick}
              />
            );
          }

          return null;
        }}
      />
    );
  }
);
MentionMenu.displayName = 'MentionMenu';

export type MentionSuggestionRepoItem = SuggestionMenuItem<
  {
    data: SearchResultGitHubRepository;
  },
  'repos'
>;

export type MentionSuggestionDocItem = SuggestionMenuItem<
  {
    data: SearchResultDocument;
  },
  'docs'
>;

export type MentionSuggestionMemberItem = SuggestionMenuItem<
  {
    data: SearchResultWorkspaceMember;
  },
  'members'
>;

export function isMentionSuggestionRepoItem(
  item: Record<string, AllowedAny>
): item is MentionSuggestionRepoItem {
  return item.group === 'repos';
}

export function isMentionSuggestionDocItem(
  item: Record<string, AllowedAny>
): item is MentionSuggestionDocItem {
  return item.group === 'docs';
}

export function isMemberSuggestionItem(
  item: Record<string, AllowedAny>
): item is MentionSuggestionMemberItem {
  return item.group === 'members';
}

/*
|------------------
| Utils
|------------------
*/

function MentionMenuItem(props: {
  selected: boolean;
  icon?: React.ReactNode;
  avatar?: React.ReactNode;
  content: React.ReactNode;
  onClick: () => void;
}) {
  const prefix = React.useMemo(() => {
    if (props.icon) {
      return <ListItemIcon>{props.icon}</ListItemIcon>;
    }

    if (props.avatar) {
      return <ListItemAvatar>{props.avatar}</ListItemAvatar>;
    }
    return null;
  }, [props.icon, props.avatar]);
  return (
    <MenuItem
      selected={props.selected}
      sx={{
        // fontSize: '1rem',
        my: 1,
      }}
      onClick={props.onClick}
    >
      {prefix} <Typography variant='inherit'>{props.content}</Typography>
    </MenuItem>
  );
}

type SearchResultDocument = APIContract.PostSearchResponse['documents'][number];

type SearchResultGitHubRepository =
  APIContract.PostSearchResponse['githubRepositories'][number];

type SearchResultWorkspaceMember =
  APIContract.PostSearchResponse['workspaceMembers'][number];

const getLink = (item: MentionSuggestionItem) => {
  if (isMentionSuggestionRepoItem(item)) {
    return {
      href: item.data.url,
      title: `@${item.data.owner}/${item.data.name}`,
    };
  }

  if (isMentionSuggestionDocItem(item)) {
    return {
      href: `${window.location.origin}/app/documents/${item.data.id}`,
      title: `📖 ${item.data.title}`,
    };
  }

  if (isMemberSuggestionItem(item)) {
    return {
      href: item.data.githubUrl,
      title: `@${item.data.githubUsername}`,
    };
  }

  return null;
};
