import type { Theme as RikerTheme } from '@joggrdocs/riker';

/*
|==========================================================================
| Aspects: Measurements
|==========================================================================
|
| Provide Global height/width and other measurements for components. 
|
*/

export const measurements = {
  topBar: {
    height: 56,
  },
  sideBar: {
    width: 64,
    widthClosed: 64,
  },
};

export type ThemeMeasurements = typeof measurements;

/*
|------------------
| Theme Type Overloads
|------------------
*/

declare module '@joggrdocs/riker' {
  interface Theme {
    measurements: ThemeMeasurements;
  }
}

/*
|------------------
| Public API
|------------------
*/

const createMeasurements = (baseTheme: RikerTheme): ThemeMeasurements => {
  return measurements;
};

export default createMeasurements;
