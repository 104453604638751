import React from 'react';

import {
  Chip,
  type ChipProps,
  IconBarrierBlock,
  type SxProps,
} from '@joggrdocs/riker';

export interface ComingSoonChipProps extends ChipProps {
  label?: string;
  size?: 'small' | 'medium';
  sx?: SxProps;
  showIcon?: boolean;
}

const ComingSoonChip = React.forwardRef<HTMLDivElement, ComingSoonChipProps>(
  (props, ref) => {
    const {
      label = 'Coming Soon',
      size = 'small',
      showIcon = false,
      ...restProps
    } = props;
    return (
      <Chip
        {...restProps}
        avatar={showIcon ? <IconBarrierBlock /> : undefined}
        ref={ref}
        label={label}
        size={size}
        variant='outlined'
        color='primary'
      />
    );
  }
);
ComingSoonChip.displayName = 'ComingSoonChip';
ComingSoonChip.muiName = 'Chip';

export default ComingSoonChip;
