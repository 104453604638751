import React from 'react';

import {
  Box,
  Divider,
  type DividerProps,
  Stack,
  rikerIconClasses,
  useTheme,
} from '@joggrdocs/riker';

import { useSearch } from '@stargate/features/search';
import { useShortcuts } from '@stargate/features/shortcuts';
import {
  sendInviteName,
  useUserInvites,
} from '@stargate/features/user-invites';
import { useHotkeys } from '@stargate/hooks';

import useSideBar from '../../hooks/use-side-bar';
import SideBarNavItem from './SideBarNavItem';

const SideBarDivider: React.FC<DividerProps> = (props) => {
  const theme = useTheme();
  return (
    <Divider
      {...props}
      sx={{
        ...props.sx,
        margin: `${theme.spacing(1)} 0`,
        borderColor:
          theme.palette.mode === 'dark'
            ? theme.palette.grey[500]
            : theme.palette.grey[300],
      }}
    />
  );
};

/*
|------------------
| Public API
|------------------
*/

export interface SideBarNavProps {
  sideBarOpen?: boolean;
}

export const SideBarNav: React.FC<SideBarNavProps> = (props) => {
  const [sidebarState, sidebarActions] = useSideBar();
  const [, userInvitesActions] = useUserInvites();
  const [searchBarState, searchBarActions] = useSearch();
  const [, shortcutActions] = useShortcuts();

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const handleOpenExplorer = React.useCallback(() => {
    sidebarActions.openDrawer({ drawerContent: 'explorer' });
  }, [sidebarActions]);

  const handleToggleExplorer = React.useCallback(() => {
    if (sidebarState.drawerOpen) {
      sidebarActions.closeDrawer();
    } else {
      sidebarActions.openDrawer({ drawerContent: 'explorer' });
    }
  }, [sidebarActions, sidebarState]);

  const handleOpenSearch = React.useCallback(() => {
    searchBarActions.openDialog();
  }, [searchBarActions]);

  const handleToggleSearch = React.useCallback(() => {
    if (searchBarState.dialogOpen) {
      searchBarActions.closeDialog();
    } else {
      searchBarActions.openDialog();
    }
  }, [searchBarActions, searchBarState]);

  const handleSendInviteClick = React.useCallback(() => {
    userInvitesActions.openDialog();
  }, [userInvitesActions]);

  const handleOpenShortcuts = React.useCallback(() => {
    shortcutActions.onOpen('blank');
  }, [shortcutActions]);

  /*
  |------------------
  | HotKeys
  |------------------
  */

  const searchHotKey = useHotkeys(
    'shift+mod+f',
    (e) => {
      e.stopPropagation();
      handleOpenSearch();
    },
    {
      enableOnContentEditable: true,
      enableOnFormTags: true,
    },
    [handleOpenSearch]
  );

  const explorerHotKey = useHotkeys(
    'shift+mod+e',
    (e) => {
      e.stopPropagation();
      handleOpenExplorer();
    },
    {
      enableOnContentEditable: true,
      enableOnFormTags: true,
    },
    [handleOpenExplorer]
  );

  const commandsHotKey = useHotkeys(
    'shift+mod+p',
    (e) => {
      e.stopPropagation();
      handleOpenShortcuts();
    },
    {
      enableOnContentEditable: true,
      enableOnFormTags: true,
    },
    [handleOpenShortcuts]
  );

  /*
  |------------------
  | Effects
  |------------------
  */

  return (
    <Stack
      spacing={2}
      sx={{
        height: '100%',
        width: '100%',
        [`& .${rikerIconClasses.root}`]: {
          height: '32px',
          width: '32px',
          strokeWidth: '1.5px',
        },
      }}
      justifyContent='normal'
      alignItems='center'
      direction='column'
    >
      <SideBarNavItem icon='home' hint='Home' to='app.root' link />
      <SideBarNavItem
        icon='bolt'
        hint='Commands'
        hotKey={commandsHotKey.hint}
        onClick={handleOpenShortcuts}
      />
      <SideBarNavItem
        icon='search'
        hint='Search'
        active={searchBarState.dialogOpen}
        hotKey={searchHotKey.hint}
        onClick={handleToggleSearch}
      />
      <SideBarNavItem
        icon='files'
        hint='Directory Explorer'
        active={
          sidebarState.drawerContent === 'explorer' && sidebarState.drawerOpen
        }
        hotKey={explorerHotKey.hint}
        onClick={handleToggleExplorer}
      />
      <Box sx={{ flexGrow: 1 }} />
      <SideBarNavItem
        icon={sendInviteName}
        hint='Send an invite to your team'
        onClick={handleSendInviteClick}
      />
      <SideBarDivider flexItem />
      <SideBarNavItem
        icon='user-circle'
        hint='View your profile'
        to='app.settings'
        link
      />
      <SideBarNavItem
        icon='settings'
        hint='View your settings'
        to='app.settings'
        search={{
          activeTab: 'configuration',
        }}
        link
      />
    </Stack>
  );
};

export default SideBarNav;
