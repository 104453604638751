import {
  Alert,
  AlertTitle,
  Box,
  Tooltip,
  Typography,
  darken,
  lighten,
  useTheme,
} from '@joggrdocs/riker';
import React from 'react';

import { IconAvatar } from '@stargate/components/Avatars';
import { generateComponentClasses } from '@stargate/theme';

export const codeSnippetStatusClasses = generateComponentClasses(
  'CodeSnippetStatus',
  ['root'] as const
);

/**
 * Component for showing the status of a code snippet (outdated, etc.).
 *
 * NOTE: current this is hardcoded to show the status as "Synced with Codebase".
 */
export const CodeSnippetStatus = React.memo(() => {
  const theme = useTheme();
  return (
    <span>
      <Tooltip
        placement='left-start'
        arrow
        slotProps={{
          arrow: {
            sx: {
              color: (theme) =>
                theme.palette.mode === 'dark'
                  ? darken(theme.palette.success.main, 0.9)
                  : theme.palette.success.main,
            },
          },
          tooltip: {
            sx: {
              p: 0,
              borderRadius: '8px',
              bgcolor: (theme) =>
                theme.palette.mode === 'dark'
                  ? darken(theme.palette.success.main, 0.9)
                  : lighten(theme.palette.success.main, 0.9),
              border: (theme) =>
                theme.palette.mode === 'light'
                  ? `1px solid ${theme.palette.success.main}`
                  : undefined,
            },
          },
        }}
        title={
          <Alert severity='success'>
            <AlertTitle>
              <Typography variant='h5'>Synced with Codebase</Typography>
            </AlertTitle>
            <Typography variant='body1' fontSize='12px'>
              Code Snippet maintained by Joggr
            </Typography>
          </Alert>
        }
      >
        <span>
          <IconAvatar
            backgroundColor='success'
            iconColor={theme.palette.background.default}
            icon='circle-check-filled'
            size={36}
          />
        </span>
      </Tooltip>
    </span>
  );
});
