import * as expiration from './expiration';

/*
|==========================================================================
| Create Session 
|==========================================================================
|
| This is a simple utility that can be used to generate a unique session ID.
|
*/

export interface Session {
  /**
   * The unique session ID.
   */
  sessionId: string;

  /**
   * Expiration epoch timestamp for the session.
   */
  expiresAt: number;

  /**
   * The epoch timestamp when the session was created.
   */
  setAt: number;
}

/**
 * Creates a new session.
 *
 * @returns a new session object.
 */
export const createSession = (): Session => {
  return {
    sessionId: createSessionId(),
    expiresAt: expiration.getExpiration(),
    setAt: Date.now(),
  };
};

/*
|------------------
| Utils
|------------------
*/

/**
 * Creates a new session ID.
 */
const createSessionId = () => {
  return `jgr_${uuid()}`;
};

/**
 * Lightweight UUID generator.
 */
const uuid = () => {
  try {
    return window.crypto.randomUUID();
  } catch (error) {
    const tempUrl = URL.createObjectURL(new Blob());
    const uuid = tempUrl.toString();
    URL.revokeObjectURL(tempUrl);
    // remove prefix (e.g. blob:null/, blob:www.test.com/, ...)
    return uuid.substring(uuid.lastIndexOf('/') + 1);
  }
};
