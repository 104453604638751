import { Extension } from '@tiptap/core';

import { Image } from './extensions/image';
import { Loom } from './extensions/loom';
import { Youtube } from './extensions/youtube';

export default Extension.create({
  name: 'dashdraft-media',

  addExtensions() {
    return [Image, Youtube, Loom];
  },
});
