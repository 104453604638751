import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  type DialogProps,
  DialogTitle,
  Typography,
} from '@joggrdocs/riker';
import React from 'react';

import { type Tag, Tags } from '@stargate/features/tags';

import { IconAvatar } from '@stargate/components/Avatars';
import { useUserDefaults } from '@stargate/features/user';
import type { JDocComponentProps } from '../../types';

export type JoggrDocTagsDialogProps = JDocComponentProps<
  {
    /**
     * Callback to close the dialog.
     */
    tags?: Tag[];

    /**
     * Callback triggered when the tags are changed.
     *
     * @param tagIds The new tag IDs.
     */
    onChange: (tags: Tag[]) => Promise<void>;

    /**
     * Callback to close the dialog.
     */
    onClose: () => void;
  } & Omit<DialogProps, 'onChange'>
>;

export const JoggrDocTagsDialog: React.FC<JoggrDocTagsDialogProps> = ({
  doc,
  mode,
  tags,
  open,
  onClose,
  onChange,
}) => {
  const githubRepository = React.useMemo(
    () => doc?.github?.repository ?? null,
    [doc?.github?.repository]
  );
  const [userDefaults] = useUserDefaults();

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle
        avatar={<IconAvatar icon='tag' />}
        title='JoggrDoc Tags'
        subTitle='Tags are used to categorize and organize your JoggrDocs.'
        onClose={onClose}
      />
      <DialogContent>
        {!githubRepository && !userDefaults.repositoryOwnerId ? (
          <Typography>
            Please select Github Location for the document in order to modify
            tags
          </Typography>
        ) : null}
        <Tags
          label='JoggrDoc Tags'
          size='medium'
          defaultSelected={tags}
          readonly={mode === 'view'}
          repositoryOwnerId={
            githubRepository?.owner.id.toString() ||
            userDefaults.repositoryOwnerId
          }
          disabled={
            (!githubRepository && !userDefaults.repositoryOwnerId) ||
            (doc?.version !== 'latest' && mode !== 'create')
          }
          onChange={onChange}
        />
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
