import {
  Box,
  IconChevronDown,
  IconChevronRight,
  Stack,
  TreeItem,
  type TreeItemContentProps,
  type TreeItemProps,
  useTheme,
} from '@joggrdocs/riker';
import * as hookz from '@react-hookz/web';
import _ from 'lodash';
import React from 'react';

import { useNightingale } from '@stargate/lib/nightingale';
import { useNotify } from '@stargate/lib/notify';
import { useLocation } from '@stargate/routes';
import { componentClassFactory } from '@stargate/theme';

import useDirectoryTree from '../../hooks/use-directory-tree';
import {
  type UseDndDirectoryOptions,
  useDnd,
} from '../../hooks/use-drag-and-drop';
import type { DirectoryTreeNode } from '../../types';
import { DirectoryIcon, DocumentIcon } from '../Icons';
import { DirectoryTreeItemContent } from './DirectoryTreeItemContent';
import { directoryTreeItemEmptyClasses } from './DirectoryTreeItemEmpty';
import {
  DirectoryTreeItemLabel,
  directoryTreeItemLabelClasses,
} from './DirectoryTreeItemLabel';

/*
|==========================================================================
| DirectoryTreeItem
|==========================================================================
|
| A tree view item of the directory structure, used for navigation.
|
*/

const cn = componentClassFactory('DirectoryTreeItem');

export const directoryTreeItemClasses = {
  root: cn('root'),
  content: cn('content'),
  group: cn('group'),
  active: cn('active'),
  iconContainer: cn('iconContainer'),
  label: cn('label'),
  directoryDocument: cn('item-document'),
  directory: cn('item-directory'),
  dndDragging: cn('dnd-dragging'),
  dndDropArea: cn('dnd-dropArea'),
  dndDropAreaOver: cn('dnd-dropArea-over'),
};

export interface DirectoryTreeItemProps
  extends Omit<TreeItemProps, 'label' | 'itemId'> {
  /**
   * The node to render
   */
  node: DirectoryTreeNode;

  /**
   * Override the label of the node.
   */
  label?: React.ReactNode;

  /**
   * Override the id of the node.
   */
  itemId?: string;

  /**
   * Whether to hide DroppableArea
   */
  hideDroppableArea?: boolean;

  /**
   * A ref to the TreeView
   */
  treeViewRef?: React.RefObject<HTMLUListElement>;
}

export const DirectoryTreeItem: React.FC<DirectoryTreeItemProps> = ({
  node,
  treeViewRef,
  itemId,
  hideDroppableArea,
  label,
  ...props
}) => {
  const theme = useTheme();
  const directoryTree = useDirectoryTree();
  const location = useLocation();
  const [dndHover, setDndHover] = React.useState(false);
  const [hover, setHover] = React.useState(false);
  const notify = useNotify();
  const nightingale = useNightingale();

  /*
  |------------------
  | Drag & Drop
  |------------------
  */

  const handleDndHover = hookz.useDebouncedCallback<
    Required<UseDndDirectoryOptions>['onHover']
  >(
    (draggedNode, monitor) => {
      // We only want to call the hover callback if the current item is being hovered
      if (monitor.canDrop() && monitor.isOver({ shallow: true })) {
        if (node.nodeType === 'directory' && !dndProps.isDragging) {
          directoryTree.onExpandNodes(
            _.uniq([...directoryTree.expandedNodes, node.id])
          );
          setDndHover(true);
        }
      }
    },
    [node, directoryTree, nightingale],
    300,
    500
  );

  const handleDndDrop = React.useCallback<
    Required<UseDndDirectoryOptions>['onHover']
  >(
    (draggedNode, monitor) => {
      // We only want to call the hover callback if the current item is being hovered
      if (monitor.canDrop() && monitor.isOver({ shallow: true })) {
        if (node.nodeType === 'directory') {
          const toDirectoryId = node.id;
          const fromDirectoryId = draggedNode.parentId ?? null;

          if (
            draggedNode.nodeType === 'document' &&
            toDirectoryId !== fromDirectoryId
          ) {
            directoryTree
              .onMoveDirectoryDocument({
                documentId: draggedNode.id,
                toDirectoryId: node.id,
                fromDirectoryId: draggedNode.parentId ?? null,
              })
              .then(() => {
                void directoryTree.onLoad();
                void nightingale.capture(['move', 'directory_document'], {
                  documentId: draggedNode.id,
                  targetDirectoryId: null,
                  sourceDirectoryId: draggedNode.parentId,
                  moveType: 'drag-and-drop',
                });
              })
              .catch((err) => {
                notify.error(err.message);
              });
          }

          // Check if the dragged node is a directory and not the same as the current node
          if (
            draggedNode.nodeType === 'directory' &&
            ![draggedNode.id, fromDirectoryId].includes(toDirectoryId)
          ) {
            directoryTree
              .onMoveDirectory({
                directoryId: draggedNode.id,
                toDirectoryId: node.id,
                fromDirectoryId: draggedNode.parentId ?? null,
              })
              .then(() => {
                void directoryTree.onLoad();
                void nightingale.capture(['move', 'directory'], {
                  directoryId: draggedNode.id,
                  targetDirectoryId: null,
                  sourceDirectoryId: draggedNode.parentId,
                  moveType: 'drag-and-drop',
                });
              })
              .catch((err) => {
                notify.error(err.message as string);
              });
          }
        }
      }
    },

    [node, nightingale]
  );

  const [dndProps, dndRef] = useDnd(node, {
    onHover: handleDndHover,
    onDrop: handleDndDrop,
  });

  /**
   * Prevents Treeview from hijacking drag events (e.g. dragstart) so react-dnd works
   */
  const handleDndPreventBubble = React.useCallback(
    (e: React.SyntheticEvent) => {
      e.stopPropagation();
    },
    []
  );

  /**
   * Propagates keydown events to the TreeView for navigation
   */
  const handleDndKeyDown = React.useCallback<
    React.KeyboardEventHandler<HTMLLIElement>
  >(
    (e) => {
      if (!_.isNil(treeViewRef) && e.target === e.currentTarget) {
        // Forward the keydown event. You should pass the ref to the TreeView somewhere.
        treeViewRef.current?.dispatchEvent(
          new KeyboardEvent('keydown', e as unknown as KeyboardEvent)
        );
      }
    },
    [treeViewRef]
  );

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const handleMouseEnter = React.useCallback((e: React.SyntheticEvent) => {
    e.stopPropagation();
    setHover(true);
  }, []);

  const handleMouseLeave = React.useCallback((e: React.SyntheticEvent) => {
    setHover(false);
  }, []);

  /*
  |------------------
  | Computed
  |------------------
  */

  const viewing = React.useMemo(() => {
    return _.some([
      location.active('app.documents.edit', {
        id: node.id,
      }),
      location.active('app.documents.view', {
        id: node.id,
      }),
    ]);
  }, [location, node]);

  const iconProps = React.useMemo<Omit<TreeItemProps, 'itemId'>>(() => {
    if (node.nodeType === 'directory') {
      return {
        slots: {
          expandIcon: createDirectoryExpandIcon(node),
          collapseIcon: createDirectoryCollapseIcon(node),
        },
      };
    }
    return {
      slots: {
        icon: DocumentIcon,
      },
    };
  }, [node]);

  /**
   * The content component for the TreeItem
   * used so we can use Anchors to auto-navigate to the document
   */
  const ContentComponent = React.forwardRef<
    HTMLAnchorElement,
    TreeItemContentProps
  >((contentProps, ref) => (
    <DirectoryTreeItemContent {...contentProps} ref={ref} node={node} />
  ));
  ContentComponent.displayName = 'TreeItemContent';

  /*
  |------------------
  | Effects
  |------------------
  */

  React.useEffect(() => {
    if (dndHover && !dndProps.isOver && !dndProps.canDrop) {
      setDndHover(false);
    }
  }, [dndHover, dndProps.canDrop, dndProps.isOver]);

  React.useEffect(() => {
    const isExpanded = directoryTree.expandedNodes.includes(node.id);

    if (dndProps.isDragging && isExpanded) {
      directoryTree.onCollapseNodes([node.id]);
    }
  }, [directoryTree, dndProps.isDragging, node.id]);

  return (
    <Box sx={{ position: 'relative' }}>
      <TreeItem
        {...iconProps}
        {...props}
        classes={props.classes ?? directoryTreeItemClasses}
        itemId={itemId ?? node.id}
        ref={dndRef}
        ContentComponent={ContentComponent}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        disabled={props.disabled === true || dndProps.isDragging}
        onFocusCapture={handleDndPreventBubble}
        onKeyDown={handleDndKeyDown}
        className={theme.utils.classNames([
          props.className,
          theme.utils.classNames({
            [directoryTreeItemClasses.active]: viewing,
            [directoryTreeItemClasses.directoryDocument]:
              node.nodeType === 'document',
            [directoryTreeItemClasses.directory]: node.nodeType === 'directory',
            [directoryTreeItemClasses.dndDragging]: dndProps.isDragging,
            [directoryTreeItemClasses.dndDropAreaOver]: dndHover,
            [directoryTreeItemClasses.dndDropArea]:
              node.nodeType === 'directory',
          }),
        ])}
        sx={{
          /*
          |------------------
          | Base
          |------------------
          */

          [`& .${directoryTreeItemClasses.group}`]: {
            ml: '12px',
          },

          /*
          |------------------
          | State
          |------------------
          */

          [`&.${directoryTreeItemClasses.active} > .${directoryTreeItemClasses.content}:first-of-type`]:
            {
              bgcolor: theme.utils.modeValue({
                light: theme.palette.grey[200],
                dark: theme.palette.grey[800],
              }),
            },

          /*
          |------------------
          | Content: Base
          |------------------
          */

          [`& .${directoryTreeItemClasses.content}`]: {
            marginRight: '0px !important',
            borderRadius: '4px',
            fontSize: '14px',
            padding: '2px 8px',

            [`&. ${directoryTreeItemClasses.label}`]: {
              gap: '4px',
            },

            [`& .${directoryTreeItemLabelClasses.actions}`]: {
              visibility: 'hidden',
              opacity: 0,
              transition: 'opacity 0.2s ease-in-out',
            },
            [`&:hover .${directoryTreeItemLabelClasses.actions}`]: {
              visibility: 'visible',
              opacity: 1,
            },

            // Active States
            '&:hover': {
              bgcolor: theme.palette.action.hover,
            },
          },

          /*
          |------------------
          | Icon Container: Base
          |------------------
          */

          [`& .${directoryTreeItemClasses.iconContainer}`]: {
            alignItems: 'center',
            minWidth: '32px',
            height: '28px',
          },

          /*
          |------------------
          | Content: Directory
          |------------------
          */

          [`&.${directoryTreeItemClasses.directory}`]: {
            [`& .${directoryTreeItemClasses.label}`]: {
              pl: 1,
            },
          },

          /*
          |------------------
          | Content: DirectoryDocument
          |------------------
          */

          [`&.${directoryTreeItemClasses.directoryDocument}`]: {
            [`& .${directoryTreeItemClasses.iconContainer}`]: {
              ml: 1,
              mr: '2px',
            },
            [`& .${directoryTreeItemClasses.label}`]: {
              pl: 0,
            },
          },

          /*
        |------------------
        | Drag & Drop
        |------------------
        */

          [`&.${directoryTreeItemClasses.dndDropArea}.${directoryTreeItemClasses.dndDropAreaOver}`]:
            {
              pb: 1,
              [`& .${directoryTreeItemEmptyClasses.root}`]: {
                bgcolor: theme.palette.action.hover,
              },
              [`& > .${directoryTreeItemClasses.content}:last-of-type`]: {
                bgcolor: theme.utils.modeValue({
                  light: theme.palette.action.hover,
                  dark: theme.palette.action.hover,
                }),
              },
            },

          /*
        |------------------
        | Custom
        |------------------
        */

          ...props.sx,
        }}
        label={
          label ?? (
            <DirectoryTreeItemLabel
              node={node}
              showActions={hover}
              hintDisabled={dndProps.isDragging}
              showRepositoryInfo={hover}
            />
          )
        }
      />
    </Box>
  );
};

/*
|------------------
| Utils
|------------------
*/

const createDirectoryExpandIcon = (node: DirectoryTreeNode) => {
  if (node.nodeType === 'document') {
    return undefined;
  }
  return () => (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'center'}
      spacing={0.5}
    >
      <IconChevronRight size={14} />
      <DirectoryIcon open={false} icon={node.icon} iconType={node.iconType} />
    </Stack>
  );
};

const createDirectoryCollapseIcon = (node: DirectoryTreeNode) => {
  if (node.nodeType === 'document') {
    return undefined;
  }
  return () => (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'center'}
      spacing={0.5}
    >
      <IconChevronDown size={14} />
      <DirectoryIcon open={true} icon={node.icon} iconType={node.iconType} />
    </Stack>
  );
};
