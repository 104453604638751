import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  type DialogProps,
  DialogTitle,
  Popover,
  Stack,
  TextField,
  Typography,
  dialogClasses,
  lighten,
  useTheme,
} from '@joggrdocs/riker';
import * as hookz from '@react-hookz/web';
import React from 'react';
import { TwitterPicker } from 'react-color';

import {
  type GitHubOrganization,
  GitHubOrganizationSelect,
  useGitHubOrganizations,
} from '@stargate/features/github';
import { Link, useLocation } from '@stargate/routes';

import type { Tag } from '../types';

export interface TagSaveDialogProps extends DialogProps {
  /**
   * Whether the dialog is saving
   */
  saving: boolean;

  /**
   * Callback when the tag is saved
   */
  onSave: (tag: Tag) => void;

  /**
   * Callback when the dialog is closed
   */
  onClose: () => void;
}

export const TagSaveDialog: React.FC<TagSaveDialogProps> = (props) => {
  const location = useLocation();
  const theme = useTheme();
  const githubOrganizations = useGitHubOrganizations();
  const [tag, setTag] = React.useState<Partial<Tag>>({
    color: DEFAULT_COLOR,
  });
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  /*
  |------------------
  | Computed
  |------------------
  */

  const canSave = hookz.useDeepCompareMemo(() => {
    return (
      tag &&
      Object.hasOwn(tag, 'name') &&
      Object.hasOwn(tag, 'color') &&
      Object.hasOwn(tag, 'repositoryOwnerId')
    );
  }, [tag]);

  const defaultGitHubOrganization = hookz.useDeepCompareMemo(() => {
    if (githubOrganizations.data.length) {
      return githubOrganizations.data[0];
    }
  }, [githubOrganizations.data]);

  /*
  |------------------
  | Handlers
  |------------------
  */

  const handleOpenColorPicker = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChangeDescription = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTag({
      ...tag,
      description: event.target.value,
    });
  };

  const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTag({
      ...tag,
      name: event.target.value,
    });
  };

  const handleChangeGitHubOrganization = (org: GitHubOrganization) => {
    setTag({
      ...tag,
      repositoryOwnerId: org.id.toString(),
    });
  };

  const handleSave = () => {
    if (canSave) {
      props.onSave(tag as Tag);
    }
  };

  /*
  |------------------
  | Effects
  |------------------
  */

  hookz.useMountEffect(() => {
    void githubOrganizations.load();
  });

  React.useEffect(() => {
    // Once we load user data and update the "default owner", we need to set it in the tag
    if (defaultGitHubOrganization?.id && !tag.repositoryOwnerId) {
      setTag({
        ...tag,
        repositoryOwnerId: defaultGitHubOrganization.id.toString(),
      });
    }
  }, [defaultGitHubOrganization?.id, tag]);

  return (
    <Dialog
      open={props.open ?? false}
      onClose={props.onClose}
      sx={{
        [`& .${dialogClasses.paper}`]: {
          minWidth: '400px',
        },
      }}
    >
      <DialogTitle
        title='Create a New Tag'
        subTitle={
          !location.active('app.tags.list') && (
            <React.Fragment>
              You can view all tags <Link to='app.tags.list'>here</Link>
            </React.Fragment>
          )
        }
      />
      <DialogContent sx={{ pt: '12px !important' }}>
        <Stack spacing={2}>
          <GitHubOrganizationSelect
            id='tag-github-organization-select'
            options={githubOrganizations.data}
            defaultValue={defaultGitHubOrganization}
            onChange={handleChangeGitHubOrganization}
          />
          <TextField
            label='Name'
            placeholder='Enter a name for the tag...'
            onChange={handleChangeTitle}
            required
          />
          <TextField
            label='Description'
            onChange={handleChangeDescription}
            placeholder='Enter a short description for the tag...'
          />
          <Stack direction='column'>
            <Typography variant='subtitle1'>Select a Color</Typography>
            <Box
              sx={{
                width: '48px',
                height: '48px',
                borderRadius: '25%',
                bgcolor: tag?.color ?? '#000',
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
              onClick={handleOpenColorPicker}
            />
          </Stack>
          <Popover
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={() => {
              setAnchorEl(null);
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Box
              sx={{
                '& > .twitter-picker': {
                  backgroundColor: `${lighten(
                    theme.palette.background.paper,
                    0.05
                  )} !important`,
                  boxShadow: `${theme.shadows[10]} !important`,
                },
                '& > .twitter-picker input': {
                  backgroundColor: theme.palette.common.white,
                },
              }}
            >
              <TwitterPicker
                color={tag?.color ?? DEFAULT_COLOR}
                onChange={(color) => {
                  setTag({
                    ...tag,
                    color: color.hex,
                  });
                }}
              />
            </Box>
          </Popover>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          color='primary'
          size='medium'
          variant='outlined'
          onClick={props.onClose}
        >
          Cancel
        </Button>
        <Button
          color='success'
          size='medium'
          variant='contained'
          loading={props.saving}
          disabled={!canSave || props.saving}
          onClick={handleSave}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

/*
|------------------
| Utils & Constants
|------------------
*/

const DEFAULT_COLOR = '#7bdcb5';
