import React from 'react';

import { Box, Divider } from '@joggrdocs/riker';

import { generateComponentClasses } from '@stargate/theme';

export const joggrDocLayoutDividerClasses = generateComponentClasses(
  'JoggrDocLayoutDivider',
  ['root', 'divider'] as const
);

export interface JoggrDocLayoutDividerProps {
  /**
   * The layout direction.
   *
   * @default 'row'
   */
  direction?: 'row' | 'column';

  /**
   * The gutter size.
   *
   * @default 1
   */
  gutter?: number;

  /**
   * If `true`, the divider will be a flex item.
   *
   * @default true
   */
  flexItem?: boolean;
}

export const JoggrDocLayoutDivider: React.FC<JoggrDocLayoutDividerProps> = ({
  direction = 'row',
  gutter = 1,
  flexItem = true,
}) => {
  return (
    <Box
      className={joggrDocLayoutDividerClasses.root}
      sx={{
        display: flexItem ? 'flex' : 'block',
        flexDirection: flexItem ? direction : undefined,
        alignSelf: flexItem ? 'stretch' : undefined,
        px: direction === 'row' ? gutter : 0,
        py: direction === 'column' ? gutter : 0,
      }}
    >
      <Divider
        classes={{
          root: joggrDocLayoutDividerClasses.divider,
        }}
        orientation={direction === 'row' ? 'vertical' : 'horizontal'}
        flexItem={flexItem}
      />
    </Box>
  );
};
