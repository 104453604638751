import React from 'react';

import {
  IconButton,
  type IconButtonProps,
  RikerIcon,
  type RikerIconName,
  Tooltip,
} from '@joggrdocs/riker';

/*
|==========================================================================
| ActionButton
|==========================================================================
|
| A button for performing actions on a directory tree.
|
*/

export interface ActionButtonProps extends Omit<IconButtonProps, 'size'> {
  icon: RikerIconName;
  size?: 'xsmall' | 'small';
  hint?: string;
}

export const ActionButton: React.FC<ActionButtonProps> = ({
  icon,
  size = 'small',
  ...props
}) => {
  return (
    <Tooltip
      title={props.hint}
      enterDelay={700}
      enterNextDelay={500}
      disableInteractive
    >
      <span>
        <IconButton
          {...props}
          size='small'
          sx={{
            p: 0.5,
          }}
        >
          <RikerIcon name={icon} size={size === 'small' ? 24 : 16} />
        </IconButton>
      </span>
    </Tooltip>
  );
};
