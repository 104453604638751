import _ from 'lodash';

import { type HttpErrorCode, httpErrorCodes } from './http-error-codes';

/*
|==========================================================================
| HTTP Errors
|==========================================================================
|
| Errors for handling HTTP 4XX and 5XX responses.
|
*/

export enum HttpErrorStatusCode {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  Conflict = 409,
  Fatal = 500,
}

/*
|------------------
| Classes
|------------------
*/

/**
 * Base class for HTTP errors.
 */
export abstract class HttpError extends Error {
  public static readonly isHttpError = true;
  public readonly isHttpError = true;
  public readonly statusCode: number = 500;
  public readonly errorCode: HttpErrorCode = 'UNK_000';
}

/**
 * Error for HTTP 400 responses.
 */
export class HttpBadRequestError extends Error implements HttpError {
  public static readonly isHttpError = true;
  public readonly isHttpError = true;
  public readonly statusCode: number;
  public readonly name = 'HttpBadRequestError';
  public readonly errorCode: HttpErrorCode;

  constructor(errorCode: HttpErrorCode = 'UNK_000', message = 'Bad request') {
    super(message);
    this.statusCode = HttpErrorStatusCode.BadRequest;
    this.errorCode = errorCode;
  }
}

/**
 * Error for HTTP 401 responses.
 */
export class HttpUnauthorizedError extends Error implements HttpError {
  public static readonly isHttpError = true;
  public readonly isHttpError = true;
  public readonly statusCode: number;
  public readonly name = 'HttpUnauthorizedError';
  public readonly errorCode: HttpErrorCode;

  constructor(errorCode: HttpErrorCode = 'UNK_000', message = 'Unauthorized') {
    super(message);
    this.statusCode = HttpErrorStatusCode.Unauthorized;
    this.errorCode = errorCode;
  }
}

/**
 * Error for HTTP 403 responses.
 */
export class HttpForbiddenError extends Error implements HttpError {
  public static readonly isHttpError = true;
  public readonly isHttpError = true;
  public readonly statusCode: number;
  public readonly name = 'HttpForbiddenError';
  public readonly errorCode: HttpErrorCode;

  constructor(errorCode: HttpErrorCode = 'UNK_000', message = 'Forbidden') {
    super(message);
    this.statusCode = HttpErrorStatusCode.Forbidden;
    this.errorCode = errorCode;
  }
}

/**
 * Error for HTTP 404 responses.
 */
export class HttpNotFoundError extends Error implements HttpError {
  public static readonly isHttpError = true;
  public readonly isHttpError = true;
  public readonly statusCode: number;
  public readonly name = 'HttpNotFoundError';
  public readonly errorCode: HttpErrorCode;

  constructor(errorCode: HttpErrorCode = 'UNK_000', message = 'Not found') {
    super(message);
    this.statusCode = HttpErrorStatusCode.NotFound;
    this.errorCode = errorCode;
  }
}

/**
 * Error for HTTP 409 responses.
 */
export class HttpConflictError extends Error implements HttpError {
  public static readonly isHttpError = true;
  public readonly isHttpError = true;
  public readonly statusCode: number;
  public readonly name = 'HttpConflictError';
  public readonly errorCode: HttpErrorCode;

  constructor(errorCode: HttpErrorCode = 'UNK_000', message = 'Conflict') {
    super(message);
    this.statusCode = HttpErrorStatusCode.Conflict;
    this.errorCode = errorCode;
  }
}

/**
 * Error for HTTP 5XX responses, as we treat all 5XX errors as "fatal".
 */
export class HttpFatalError extends Error implements HttpError {
  public static readonly isHttpError = true;
  public readonly isHttpError = true;
  public readonly statusCode: number;
  public readonly name = 'HttpFatalError';
  public readonly errorCode: HttpErrorCode;

  constructor(errorCode: HttpErrorCode = 'UNK_000', message = 'Fatal error') {
    super(message);
    this.statusCode = HttpErrorStatusCode.Fatal;
    this.errorCode = errorCode;
  }
}

/*
|------------------
| Utils
|------------------
*/

/**
 * Check if a status code is a HTTP error status code.
 *
 * @param statusCode A HTTP status code.
 * @returns A boolean indicating if the status code is an error status code.
 */
export const isHttpErrorStatus = (statusCode: number): boolean =>
  statusCode >= 400 && statusCode <= 599;

/**
 * Check if an error code is a valid HTTP error code.
 *
 * @param errorCode A HTTP error code.
 * @returns A boolean indicating if the error code is a valid HTTP error code.
 */
export const isHttpErrorCode = (
  errorCode: unknown
): errorCode is HttpErrorCode => {
  return (
    _.isString(errorCode) &&
    !_.isNil(httpErrorCodes[errorCode as HttpErrorCode])
  );
};

/**
 * Check if a status code is a HTTP 404 status code.
 *
 * @param statusCode A HTTP status code.
 * @returns A boolean indicating if the status code is a 404 status code.
 */
export const isNotFoundHttpStatus = (
  statusCode: number
): statusCode is HttpErrorStatusCode.NotFound =>
  statusCode === HttpErrorStatusCode.NotFound;

/**
 * Check if a status code is a HTTP 403 status code.
 *
 * @param statusCode A HTTP status code.
 * @returns A boolean indicating if the status code is a 403 status code.
 */
export const isForbiddenHttpStatus = (
  statusCode: number
): statusCode is HttpErrorStatusCode.Forbidden =>
  statusCode === HttpErrorStatusCode.Forbidden;

/**
 * Check if a status code is a HTTP 401 status code.
 *
 * @param statusCode A HTTP status code.
 * @returns A boolean indicating if the status code is a 401 status code.
 */
export const isUnauthorizedHttpStatus = (
  statusCode: number
): statusCode is HttpErrorStatusCode.Unauthorized =>
  statusCode === HttpErrorStatusCode.Unauthorized;

/**
 * Check if a status code is a HTTP 400 status code.
 *
 * @param statusCode A HTTP status code.
 * @returns A boolean indicating if the status code is a 400 status code.
 */
export const isBadRequestHttpStatus = (
  statusCode: number
): statusCode is HttpErrorStatusCode.BadRequest =>
  statusCode === HttpErrorStatusCode.BadRequest;

/**
 * Check if a status code is a HTTP 409 status code.
 *
 * @param statusCode A HTTP status code.
 * @returns A boolean indicating if the status code is a 409 status code.
 */
export const isConflictHttpStatus = (
  statusCode: number
): statusCode is HttpErrorStatusCode.Conflict =>
  statusCode === HttpErrorStatusCode.Conflict;

/**
 * Check if a status code is a HTTP 5XX status code.
 *
 * @param statusCode A HTTP status code.
 * @returns A boolean indicating if the status code is a 5XX status code.
 */
export const isFatalHttpStatus = (
  statusCode: number
): statusCode is HttpErrorStatusCode.Fatal => statusCode >= 500;

/**
 * Check if an error is an HTTP error.
 *
 * @param error An error.
 * @returns A boolean indicating if the error is an HTTP error.
 */
export const isHttpError = (error: unknown): error is HttpError => {
  if (
    !_.isNil(error) &&
    _.isObject(error) &&
    Object.hasOwn(error, 'isHttpError')
  ) {
    return error.isHttpError === true;
  }
  return false;
};

export type CreatedError<S extends number> =
  S extends HttpErrorStatusCode.BadRequest
    ? HttpBadRequestError
    : S extends HttpErrorStatusCode.Unauthorized
      ? HttpUnauthorizedError
      : S extends HttpErrorStatusCode.Forbidden
        ? HttpForbiddenError
        : S extends HttpErrorStatusCode.NotFound
          ? HttpNotFoundError
          : S extends HttpErrorStatusCode.Conflict
            ? HttpConflictError
            : S extends HttpErrorStatusCode.Fatal
              ? HttpFatalError
              : HttpFatalError;

/**
 * Create an HTTP error based on a status code.
 *
 * @param statusCode A HTTP status code.
 * @param message An optional message for the error.
 * @returns An HTTP error.
 */
export function createError<S extends HttpErrorStatusCode>(
  statusCode: S,
  errorCode: HttpErrorCode = 'UNK_000',
  message?: string
): CreatedError<S> {
  if (isNotFoundHttpStatus(statusCode)) {
    return new HttpNotFoundError(errorCode, message) as CreatedError<S>;
  }
  if (isForbiddenHttpStatus(statusCode)) {
    return new HttpForbiddenError(errorCode, message) as CreatedError<S>;
  }
  if (isUnauthorizedHttpStatus(statusCode)) {
    return new HttpUnauthorizedError(errorCode, message) as CreatedError<S>;
  }
  if (isBadRequestHttpStatus(statusCode)) {
    return new HttpBadRequestError(errorCode, message) as CreatedError<S>;
  }
  if (isConflictHttpStatus(statusCode)) {
    return new HttpConflictError(errorCode, message) as CreatedError<S>;
  }
  return new HttpFatalError(errorCode, message) as CreatedError<S>;
}
