import _ from 'lodash';
import React from 'react';

import {
  IconButton,
  InputAdornment,
  RikerIcon,
  TextField,
  type TextFieldProps,
} from '@joggrdocs/riker';

export interface TextSearchFilterProps extends BaseProps {
  /**
   * The placeholder text.
   *
   * @default 'Type to filter...'
   */
  placeholder?: string;

  /**
   * The input size.
   *
   * @default 'small'
   */
  size?: 'small' | 'medium';

  /**
   * Whether the input should take up the full width.
   *
   * @default true
   */
  fullWidth?: boolean;

  /**
   * Callback when the text changes.
   *
   * @param text A text to filter.
   */
  onChange: (text: string) => void;
}

export const TextSearchFilter: React.FC<TextSearchFilterProps> = ({
  size = 'small',
  fullWidth = true,
  placeholder = 'Type to filter...',
  onChange,
  ...props
}) => {
  const [textFilter, setTextFilter] = React.useState('');

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const handleChangeSearchQuery = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setTextFilter(event.target.value);
      onChange(event.target.value);
    },
    [onChange]
  );

  const handleClearSearchQuery = React.useCallback(() => {
    setTextFilter('');
    onChange('');
  }, [onChange]);

  return (
    <TextField
      {...props}
      size={size}
      fullWidth={fullWidth}
      value={textFilter}
      onChange={handleChangeSearchQuery}
      slotProps={{
        input: {
          startAdornment: (
            <InputAdornment position='start'>
              <RikerIcon name='filter-search' />
            </InputAdornment>
          ),
          endAdornment: !_.isEmpty(textFilter) ? (
            <InputAdornment position='end'>
              <IconButton
                size='small'
                sx={{ mr: -1 }}
                onClick={handleClearSearchQuery}
              >
                <RikerIcon name='x' size={16} />
              </IconButton>
            </InputAdornment>
          ) : undefined,
        },
      }}
      sx={{ mb: 1 }}
      placeholder={placeholder}
    />
  );
};

/*
|------------------
| Utils
|------------------
*/

type BaseProps = Omit<
  TextFieldProps,
  'size' | 'fullWidth' | 'placeholder' | 'onChange'
>;
