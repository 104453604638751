import { useMountEffect } from '@react-hookz/web';
import React from 'react';

import { useFrigadeStore } from './use-frigade-store';

/**
 * Get id prop prepended with Frigade extension for targeting.
 *
 * @param id A step selector.
 * @returns A id object containing id prop.
 */
export const useFrigadeTarget = (id: string): { id: string } => {
  const frigadeStore = useFrigadeStore();
  const builtId = React.useMemo(() => `fg-${id}`, [id]);

  // We only run on mount
  useMountEffect(() => {
    frigadeStore.addTargetId(builtId);
  });

  return {
    id: builtId,
  };
};
