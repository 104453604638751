import type { langs as cmLangs } from '@uiw/codemirror-extensions-langs';

/**
 * This contains the definitions of the supported languages in the editor. The
 * list is used to determine which language to use for syntax highlighting
 * based on the file extension & to determine available languages for
 * the language picker.
 *
 * The list is based on the list of languages supported by CodeMirror:
 * @link https://gist.githubusercontent.com/ppisarczyk/43962d06686722d26d176fad46879d41/raw/211547723b4621a622fc56978d74aa416cbd1729/Programming_Languages_Extensions.json
 * @link https://codemirror.net/5/mode/
 */
export const langDefinitions = [
  {
    name: 'Bash',
    type: 'shell',
    language: 'shell',
    extensions: ['.bash'],
  },
  {
    name: 'C',
    language: 'c',
    type: 'c',
    extensions: ['.c', '.cats', '.h', '.idc', '.w'],
  },
  {
    name: 'CoffeeScript',
    language: 'coffeescript',
    type: 'coffeescript',
    extensions: ['.coffee', '._coffee', '.cake', '.cjsx', '.cson', '.iced'],
  },
  {
    name: 'C++',
    language: 'cpp',
    type: 'cpp',
    extensions: [
      '.cpp',
      '.c++',
      '.cc',
      '.cp',
      '.cxx',
      '.h',
      '.h++',
      '.hh',
      '.hpp',
      '.hxx',
      '.inc',
      '.inl',
      '.ipp',
      '.tcc',
      '.tpp',
    ],
  },
  {
    name: 'C#',
    language: 'csharp',
    type: 'csharp',
    extensions: ['.cs', '.cake', '.cshtml', '.csx'],
  },
  { name: 'CSS', language: 'css', type: 'css', extensions: ['.css'] },
  {
    name: 'Docker',
    language: 'dockerfile',
    type: 'dockerfile',
    extensions: ['Dockerfile', '.Dockerfile', '.dockerfile', '.Dockerfile'],
  },
  { name: 'Go', language: 'go', type: 'go', extensions: ['.go'] },
  {
    name: 'GraphQL',
    language: 'graphql',
    type: 'graphql',
    extensions: ['.graphql', '.gql'],
  },
  { name: 'Html', language: 'html', type: 'html', extensions: ['.html'] },
  {
    name: 'Terraform (HCL)',
    language: 'hcl',
    type: 'nginx',
    extensions: ['.tf', '.tfvars', '.tfstate'],
  },
  { name: 'Java', language: 'java', type: 'java', extensions: ['.java'] },
  {
    name: 'Java Server Pages',
    language: 'jsp',
    type: 'java',
    extensions: ['.jsp'],
  },
  {
    name: 'JavaScript',
    language: 'javascript',
    type: 'javascript',
    extensions: [
      '.js',
      '.mjs',
      '.cjs',
      '._js',
      '.bones',
      '.es',
      '.es6',
      '.frag',
      '.gs',
      '.jake',
      '.jsb',
      '.jscad',
      '.jsfl',
      '.jsm',
      '.jss',
      '.njs',
      '.pac',
      '.sjs',
      '.ssjs',
      '.sublime-build',
      '.sublime-commands',
      '.sublime-completions',
      '.sublime-keymap',
      '.sublime-macro',
      '.sublime-menu',
      '.sublime-mousemap',
      '.sublime-project',
      '.sublime-settings',
      '.sublime-theme',
      '.sublime-workspace',
      '.sublime_metrics',
      '.sublime_session',
      '.xsjs',
      '.xsjslib',
    ],
  },
  {
    name: 'JSON',
    language: 'json',
    type: 'json',
    extensions: ['.json', '.geojson', '.lock', '.topojson'],
  },
  { name: 'JSX', language: 'jsx', type: 'jsx', extensions: ['.jsx'] },
  {
    name: 'Kotlin',
    language: 'kotlin',
    type: 'kotlin',
    extensions: ['.kt', '.kts', '.ktm'],
  },
  { name: 'Less', language: 'less', type: 'less', extensions: ['.less'] },
  {
    name: 'Markdown',
    language: 'markdown',
    type: 'markdown',
    extensions: ['.md', '.markdown', '.mkd', '.mkdn', '.mkdown', '.ron'],
  },
  {
    name: 'Mermaid',
    language: 'mermaid',
    type: 'mermaid',
    extensions: ['.mermaid', '.mmd'],
  },
  {
    name: 'Swift',
    language: 'swift',
    type: 'swift',
    extensions: ['.swift'],
  },
  {
    name: 'Objective-C++',
    language: 'objectiveCpp',
    type: 'objectiveCpp',
    extensions: ['.mm'],
  },
  {
    name: 'Objective-C',
    type: 'objectiveC',
    language: 'objectiveC',
    extensions: ['.m', '.h'],
  },
  { name: 'PHP', language: 'php', type: 'php', extensions: ['.php'] },
  {
    name: 'Plaintext',
    language: 'plaintext',
    type: 'plaintext',
    extensions: ['.txt'],
  },
  {
    name: 'Prisma',
    language: 'prisma',
    type: 'lezer', // Currently the best...
    extensions: ['.prisma'],
  },
  {
    name: 'Python',
    type: 'python',
    language: 'python',
    extensions: [
      '.py',
      '.bzl',
      '.cgi',
      '.fcgi',
      '.gyp',
      '.lmi',
      '.pyde',
      '.pyp',
      '.pyt',
      '.pyw',
      '.rpy',
      '.tac',
      '.wsgi',
      '.xpy',
    ],
  },
  { name: 'Ruby', language: 'ruby', type: 'ruby', extensions: ['.rb'] },
  {
    name: 'Rust',
    type: 'rust',
    language: 'rust',
    extensions: ['.rs', '.rlib', '.rs.bk'],
  },
  { name: 'Sass', language: 'sass', type: 'sass', extensions: ['.sass'] },
  { name: 'SCSS', language: 'scss', type: 'scss', extensions: ['.scss'] },
  {
    name: 'Shell',
    type: 'shell',
    language: 'shell',
    extensions: [
      '.sh',
      '.bash',
      '.bats',
      '.cgi',
      '.command',
      '.fcgi',
      '.ksh',
      '.sh.in',
      '.tmux',
      '.tool',
      '.zsh',
    ],
  },
  {
    name: 'SQL',
    type: 'sql',
    language: 'sql',
    extensions: [
      '.sql',
      '.cql',
      '.ddl',
      '.inc',
      '.prc',
      '.tab',
      '.udf',
      '.viw',
    ],
  },
  { name: 'Stylus', language: 'stylus', type: 'stylus', extensions: ['.styl'] },
  { name: 'TSX', language: 'tsx', type: 'tsx', extensions: ['.tsx'] },
  {
    name: 'TypeScript',
    language: 'typescript',
    type: 'typescript',
    extensions: ['.ts', '.d.ts', '.mts'],
  },
  {
    name: 'XML',
    type: 'xml',
    language: 'xml',
    extensions: [
      '.xml',
      '.xsl',
      '.xslt',
      '.dtd',
      '.xsd',
      '.rss',
      '.svg',
      '.kml',
      '.atom',
    ],
  },
  {
    name: 'YAML',
    type: 'yaml',
    language: 'yaml',
    extensions: [
      '.yml',
      '.reek',
      '.rviz',
      '.sublime-syntax',
      '.syntax',
      '.yaml',
      '.yaml-tmlanguage',
    ],
  },
  {
    name: 'Vue',
    language: 'vue',
    type: 'vue',
    extensions: ['.vue'],
  },
  {
    name: 'Haml',
    language: 'haml',
    type: 'yaml',
    extensions: ['.haml', '.haml.deface'],
  },
  {
    name: 'CUE',
    language: 'cue',
    type: 'go',
    extensions: ['.cue'],
  },
] as const satisfies LangDefinition[];

interface LangDefinition {
  name: string;
  language: string;
  type: AvailableType;
  extensions: string[];
}

type CustomType = 'plaintext' | 'graphql' | 'scss';

type AvailableType = keyof typeof cmLangs | CustomType;
