import _ from 'lodash';
import React from 'react';
import type * as TF from 'type-fest';

import {
  Avatar,
  Chip,
  type ChipProps,
  IconBrandGithub,
  useTheme,
} from '@joggrdocs/riker';

export type GitHubChipProps<RootComponent extends React.ElementType = 'div'> =
  TF.Merge<
    BaseProps<RootComponent>,
    GitHubChipRenderIconProps | GitHubChipRenderAvatarProps
  >;

export const GitHubChip = React.forwardRef<HTMLDivElement, GitHubChipProps>(
  ({ renderIcon, renderAvatar, url, shape, ...props }, ref) => {
    const theme = useTheme();

    const sx = React.useMemo(() => {
      const baseSx = { borderRadius: shape === 'rounded' ? '4px' : undefined };

      if (theme.palette.mode === 'light') {
        return {
          ...baseSx,
          backgroundColor: theme.palette.grey[500],
          color: theme.palette.grey[50],
        };
      }
      return {
        ...baseSx,
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.grey[200],
      };
    }, [theme, shape]);

    const size = React.useMemo(() => {
      return props.size === 'small' ? 18 : 22;
    }, [props.size]);

    const avatar = React.useMemo(() => {
      if (_.isFunction(renderAvatar)) {
        return renderAvatar({
          size,
          color: sx.color,
          backgroundColor: sx.backgroundColor,
          borderRadius: sx.borderRadius,
        });
      }

      return (
        <Avatar sx={sx}>
          {_.isFunction(renderIcon) ? (
            renderIcon({
              size,
              color: sx.color,
              backgroundColor: sx.backgroundColor,
            })
          ) : (
            <IconBrandGithub size={size} color={sx.color} />
          )}
        </Avatar>
      );
    }, [renderAvatar, renderIcon, size, sx]);

    return (
      <Chip
        {...props}
        icon={undefined}
        shape={shape}
        component={!_.isNil(url) ? 'a' : (props.component ?? 'div')}
        href={url}
        target={!_.isNil(url) ? '_blank' : undefined}
        rel={!_.isNil(url) ? 'noopener noreferrer' : undefined}
        ref={ref}
        clickable={!_.isNil(url)}
        avatar={avatar}
        label={props.label ?? 'GitHub'}
      />
    );
  }
);
GitHubChip.displayName = 'GitHubChip';
GitHubChip.muiName = 'Chip';

/*
|------------------
| Utils
|------------------
*/

interface GitHubChipRenderIconProps {
  /**
   * Render the icon.
   */
  renderIcon: (props: {
    size: number;
    color: string;
    backgroundColor: string;
  }) => React.ReactNode;

  // not needed
  renderAvatar?: never;
}

interface GitHubChipRenderAvatarProps {
  /**
   * Render the avatar.
   */
  renderAvatar: (props: {
    size: number;
    color: string;
    backgroundColor: string;
    borderRadius?: string | number;
  }) => React.JSX.Element | React.ReactElement;

  // not needed
  renderIcon?: never;
}

type BaseProps<RootComponent extends React.ElementType = 'div'> = Omit<
  ChipProps<RootComponent>,
  'avatar' | 'icon'
> & {
  /**
   * The URL to navigate to when the chip is clicked. If set, the chip will be clickable.
   */
  url?: string;
};
