import { type RikerIconName, StepLabel } from '@joggrdocs/riker';
import React from 'react';

import { generateComponentClasses } from '@stargate/theme';

import { OnboardingStepLabelAvatar } from './OnboardingStepLabelAvatar';

/*
|==========================================================================
| OnboardingStepLabel
|==========================================================================
|
| Component for StepLabel in the onboarding stepper.
|
*/

export const onboardingStepLabelClasses = generateComponentClasses(
  'OnboardingStepLabel',
  ['root'] as const
);

export interface OnboardingStepLabelProps {
  /**
   * Is the step active?
   */
  active: boolean;

  /**
   * Is the step completed?
   */
  completed: boolean;

  /**
   * The label of the step.
   */
  label: string;

  /**
   * The icon of the step.
   */
  icon: RikerIconName;
}

/**
 * Component for StepL
 */
export const OnboardingStepLabel: React.FC<OnboardingStepLabelProps> = ({
  active = false,
  completed = false,
  icon,
  label,
}) => {
  const StepIconComponent = React.useMemo(() => {
    return () => (
      <OnboardingStepLabelAvatar
        completed={completed}
        active={active}
        icon={icon}
      />
    );
  }, [active, completed, icon]);

  return <StepLabel StepIconComponent={StepIconComponent}>{label}</StepLabel>;
};
