import _ from 'lodash';

import { langDefinitions as supportedLanguages } from '../config/langs';
import type {
  CodeMirrorLang,
  CodeMirrorLangDefinition,
  CodeMirrorLangFileExtension,
  CodeMirrorLangType,
} from '../types';

/**
 * Parse a file extension from a file extension or file path. Defaults to `.txt` if no extension is found.
 *
 * @param extensionOrFilePath A file extension or file path
 * @returns A file extension
 */
export function parseLangExtension(
  extensionOrFilePath: string
): CodeMirrorLangFileExtension {
  const filePathParts = extensionOrFilePath.split('/');
  const fileName = filePathParts[filePathParts.length - 1];
  const fileNameParts = fileName.split('.');
  const extension = fileNameParts[fileNameParts.length - 1].toLowerCase();
  return `.${extension}` as CodeMirrorLangFileExtension;
}

/**
 * Get the language definition for the given language.
 *
 * @param lang A language, used to determine the language type
 * @returns A language definition for the given language
 */
export function findLangDefinition(
  lang: CodeMirrorLang
): CodeMirrorLangDefinition | null {
  return (
    _.find(supportedLanguages, (supportedLanguage) => {
      return supportedLanguage.language === lang;
    }) ?? null
  );
}

/**
 * Get the language definition for the given extension.
 *
 * @param extensionOrFilePath a file extension or file path, used to determine the language type
 * @returns The language definition for the given extension
 */
export function findLangDefinitionByFileExtension(
  extensionOrFilePath: string
): CodeMirrorLangDefinition | null {
  const definition = _.find(supportedLanguages, (supportedLanguage) => {
    return _.includes(
      supportedLanguage.extensions,
      parseLangExtension(extensionOrFilePath)
    );
  });

  if (definition) {
    return definition;
  }
  return null;
}

/**
 * Get the language type for the given extension. Defaults to `plaintext` if the extension is not supported.
 *
 * @param extensionOrFilePath a file extension or file path, used to determine the language type
 * @returns The language type for the given extension, with a fallback to `lezer` if the extension is not supported
 */
export function findLangByFileExtension(
  extensionOrFilePath: string
): CodeMirrorLang {
  const languageDefinition =
    findLangDefinitionByFileExtension(extensionOrFilePath);

  if (languageDefinition) {
    return languageDefinition.language;
  }

  return 'plaintext';
}

/**
 * Get the language type for the given language. Language types are used to proxy the language to other languages (i.e. jsp => java).
 *
 * @param lang a language, used to determine the language type
 * @returns The language type for the given language, with a fallback to `lezer` if the language is not supported
 */
export function getLangType(lang: CodeMirrorLang): CodeMirrorLangType {
  const languageDefinition = findLangDefinition(lang);

  if (languageDefinition) {
    return languageDefinition.type;
  }

  return 'lezer' as CodeMirrorLangType;
}
