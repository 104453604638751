import React from 'react';

import { Page } from '@stargate/components/Utils';
import { useDirectoryTree } from '@stargate/features/directories';
import { useUser } from '@stargate/features/user';
import useSearchParams from '@stargate/hooks/use-search-params';

import SettingsSections, {
  type SettingsSection,
} from './components/SettingsSections';

/*
|==========================================================================
| View User Profile Page
|==========================================================================
|
| This page is used to render the User Profile page.
|
*/

const ViewPage: React.FC = () => {
  const authenticatedUser = useUser();
  const [searchParams] = useSearchParams<{ activeTab: SettingsSection }>();
  const directoryTree = useDirectoryTree();

  /*
  |------------------
  | Callbacks
  |------------------
  */
  const reloadUser = (): void => {
    void authenticatedUser.refresh({ clearCache: true });
    void directoryTree.onLoad();
  };

  return (
    <Page
      id='user-profile'
      loading={authenticatedUser.loading}
      errors={[authenticatedUser.error]}
      renderProps={{ user: authenticatedUser.data }}
      render={({ user }) => (
        <SettingsSections
          reloadUser={reloadUser}
          authenticatedUser={user}
          defaultTab={searchParams.activeTab}
        />
      )}
    />
  );
};

export default ViewPage;
