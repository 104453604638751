import React from 'react';
import type * as TypeFest from 'type-fest';

import {
  RikerIcon,
  type RikerIconComponent,
  type RikerIconProps,
} from '@joggrdocs/riker';

/*
|==========================================================================
| UserProfileIcon 
|==========================================================================
|
| Proxy component for the RikerIcon component.
|
*/

export type UserProfileIconProps = TypeFest.Simplify<
  Omit<RikerIconProps, 'name'>
>;

export const userProfileIconName = 'user-cog';

/**
 * Icon for the user profile.
 */
export const UserProfileIcon = React.forwardRef<
  RikerIconComponent,
  UserProfileIconProps
>((props, ref) => (
  <RikerIcon {...props} ref={ref} name={userProfileIconName} />
));
UserProfileIcon.displayName = 'UserProfileIcon';
