import * as reacthookz from '@react-hookz/web';
import React from 'react';

import { useMermaidExamples } from '@stargate/lib/sirena';

import type {
  CodeBlockMermaidAttributes,
  CodeBlockTypeNodeViewProps,
} from '../types';
import MermaidCodeBlock from './mermaid/MermaidCodeBlock';
import MermaidEditDialog from './mermaid/MermaidEditDialog';

/**
 * The base props for the CodeBlockMermaid NodeView
 */
export type CodeBlockMermaidProps = Pick<
  CodeBlockTypeNodeViewProps<CodeBlockMermaidAttributes>,
  'code' | 'onChangeCode' | 'readonly' | 'onDelete'
>;

/**
 * A mermaid code block that allows the user to edit the graph.
 */
export const CodeBlockMermaid = React.memo<CodeBlockMermaidProps>(
  ({ readonly, code, onChangeCode, onDelete }) => {
    const [openDialog, setOpenDialog] = React.useState(false);

    /*
    |------------------
    | Callbacks
    |------------------
    */

    const handleOpenDialog = React.useCallback(() => {
      setOpenDialog(true);
    }, []);

    const handleCloseDialog = React.useCallback(() => {
      setOpenDialog(false);
    }, []);

    const handleSave = React.useCallback(
      (graphCode: string) => {
        onChangeCode(graphCode);
      },
      [onChangeCode]
    );

    return (
      <React.Fragment>
        {!readonly && (
          <MermaidEditDialog
            defaultGraphCode={code}
            open={openDialog}
            onSave={handleSave}
            onClose={handleCloseDialog}
          />
        )}
        <MermaidCodeBlock
          readOnly={readonly}
          graphCode={code}
          onDelete={onDelete}
          onOpenEditor={handleOpenDialog}
        />
      </React.Fragment>
    );
  }
);
CodeBlockMermaid.displayName = 'CodeBlockMermaid';
