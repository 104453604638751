import * as hookz from '@react-hookz/web';
import React from 'react';
import { Helmet } from 'react-helmet-async';

import { useApp } from '@stargate/app';
import { useInviteParams } from '@stargate/features/user-invites';
import { useNightingale } from '@stargate/lib/nightingale';
import { useAuth0 } from '@stargate/vendors/auth0';

export const AcceptInvitePage: React.FC = () => {
  const auth0 = useAuth0();
  const inviteParams = useInviteParams();
  const nightingale = useNightingale();
  const app = useApp();

  /*
  |------------------
  | Computed
  |------------------
  */

  const isValidInvite = React.useMemo(() => {
    return !!(inviteParams?.params?.id && inviteParams.params?.sid);
  }, [inviteParams]);

  /*
  |------------------
  | Handlers
  |------------------
  */

  const handleRedirect = () => {
    auth0.login({
      authorizationParams: {
        screen_hint: 'signup',
        login_hint: inviteParams.params.email,
      },
    });
  };

  /*
  |------------------
  | Effects
  |------------------
  */

  // Force redirect after 3 seconds
  const [cancel] = hookz.useTimeoutEffect(() => {
    handleRedirect();
  }, 3000);

  hookz.useMountEffect(() => {
    app.setLoading(true);
    if (isValidInvite) {
      // We do manual tracking here because we don't want to track the user as anonymous
      nightingale
        .captureWithUserId(inviteParams.params.sid, ['accept', 'invite'], {
          inviteId: inviteParams.params.id,
        })
        .then(() => {
          cancel();
          handleRedirect();
        });
    }
  });

  return (
    <React.Fragment>
      <Helmet>
        <title>Accept Invite to Joggr</title>
      </Helmet>
    </React.Fragment>
  );
};
