import type * as TypeFest from 'type-fest';
import { create } from 'zustand';

import type { DashDraftTableOfContentsItem } from '@dashdraft/types';

export type UseJoggrDocTableOfContentsHook = UseJoggrDocTocStore;

/**
 * Store for the  Dialog and other related state.
 *
 * @todo MOVE to the Dashdraft provider, as this SHOULD be `useDashDraftTableOfContents()`
 *
 * @returns A tuple containing the state and actions of the store.
 */
export const useJDocTableOfContents = (): UseJoggrDocTableOfContentsHook => {
  const store = useStore((store) => store);
  return {
    items: store.items,
    update: store.update,
    clear: store.clear,
  };
};

/*
|------------------
| Utils
|------------------
*/

export const useStore = create<UseJoggrDocTocStore>((set) => ({
  items: [],
  update: (itemsUpdate) => {
    set((state) => {
      state.items = itemsUpdate;
      return state;
    });
  },
  clear: () => {
    set((state) => {
      state.items = [];
      return state;
    });
  },
}));

interface UseJoggrDocTocStore {
  /**
   * The table of contents items.
   */
  items: DashDraftTableOfContentsItem[];

  /**
   * Update the table of contents data.
   *
   * @param data - The new table of contents data.
   */
  update: (data: DashDraftTableOfContentsItem[]) => void;

  /**
   * Clear the table of contents data.
   */
  clear: () => void;
}
