import {
  darken,
  formLabelClasses,
  inputLabelClasses,
  lighten,
  useTheme,
} from '@joggrdocs/riker';
import * as hookz from '@react-hookz/web';
import React from 'react';

import api from '@stargate/api';
import { TagSelect, type TagSelectProps } from '@stargate/features/tags';

export interface DocumentTagsFilterProps {
  onChange: (tagIds: string[]) => void;
}

export const DocumentTagsFilter: React.FC<DocumentTagsFilterProps> = ({
  onChange,
}) => {
  const [listTagsState, listTagsActions] = api.useRequestClient('GET /tags');
  const theme = useTheme();

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const handleChange = React.useCallback<TagSelectProps['onChange']>(
    (tags) => {
      onChange(tags.map((tag) => tag.id));
    },
    [onChange]
  );

  /*
  |------------------
  | Effects
  |------------------
  */

  hookz.useMountEffect(() => {
    void listTagsActions.execute();
  });

  return (
    <TagSelect
      options={listTagsState.result ?? []}
      onChange={handleChange}
      label='Filter by Tags'
      size='small'
      sx={{
        [`& .${formLabelClasses.root}.${inputLabelClasses.root}:not(.Mui-focused)`]:
          {
            color: theme.utils.modeValue({
              light: lighten(theme.palette.text.primary, 0.5),
              dark: darken(theme.palette.text.primary, 0.4),
            }),
          },
      }}
      fullWidth
    />
  );
};

export default DocumentTagsFilter;
