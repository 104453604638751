import TipTapHeading from '@tiptap/extension-heading';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { HeadingNodeView } from '../components/Heading';

export const Heading = TipTapHeading.extend({
  addNodeView() {
    // @todo fix types here or in TipTap
    // @ts-expect-error - types broken due to TipTap, need to fix
    return ReactNodeViewRenderer(HeadingNodeView);
  },
}).configure({
  levels: [1, 2, 3, 4, 5, 6],
  HTMLAttributes: {
    class: 'dashdraft-heading',
  },
});
