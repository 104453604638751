import { LocalStorage, useStorageItem } from '@stargate/lib/storage';

import * as expiration from './expiration';
import type { Session } from './session';

/*
|==========================================================================
| cache
|==========================================================================
|
| This is used to sync data between tabs. It is used to store the current sessionId,
| which is used to track the current session in PostHog, Frigade, and other analytics.
|
*/

export type SessionCache = Session | null;

/**
 * Gets the current session from the cache,
 * if it exists and is not expired.
 */
export function getSession(): SessionCache {
  const session = LocalStorage.get<SessionCache>(STORAGE_KEY, null);

  if (expiration.isExpired(session)) {
    return null;
  }
  return session ?? null;
}

/**
 * Sets passed in session to the cache.
 *
 * @param session A SessionCache object.
 */
export function setSession(session: SessionCache) {
  LocalStorage.set<SessionCache>(STORAGE_KEY, session);
}

/**
 * The key used to store the session in local storage.
 */
export const STORAGE_KEY = 'user:session';

/**
 * A React-specific hook for accessing the session cache.
 */
export const useSessionCache = () => {
  return useStorageItem<SessionCache>(STORAGE_KEY, null);
};
