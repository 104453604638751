import type { Extension } from '@codemirror/state';

import type { CodeMirrorLangType } from '../../types';
import graphql from './extensions/graphql';
import core from './extensions/languages';

/**
 * Support syntax highlighting for any given language.
 *
 * @param langType a CodeMirrorLang
 * @returns the language extensions for the given language
 */
export const lang = <LangType extends CodeMirrorLangType>(
  langType: LangType
): Extension[] => {
  switch (langType) {
    case 'graphql':
      return [graphql()];
    case 'scss':
      return [core('css'), core('sass')];
    case 'plaintext':
      return [];
    default:
      return [core(langType)];
  }
};
