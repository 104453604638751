import * as Sentry from '@sentry/react';
import _ from 'lodash';

import logger from '@stargate/lib/logger';

import { useAuth0Store } from '../hooks/use-auth0-store';
import { auth0Client } from './auth0-client';

/*
|==========================================================================
| AuthToken
|==========================================================================
|
| Read/write the access tokens to Browser Storage.
|
*/

/**
 * Refresh the Auth0 access token (outside of React context).
 *
 * @returns The refreshed token
 */
export const refreshAuth0Jwt = async (): Promise<string | null> => {
  try {
    const refreshedToken = await auth0Client.getTokenSilently();
    useAuth0Store.getState().setJwt(refreshedToken);
    return refreshedToken;
  } catch (error) {
    const err = error instanceof Error ? error : new Error('Unknown error');
    const ex = new Error(`Failed to refresh token: ${err.message}`);
    Sentry.captureException(ex);
    return null;
  }
};

/**
 * Get the Auth0 access token (outside of React context).
 *
 * @returns The Auth0 access token
 */
export const getAuth0Jwt = async (): Promise<string> => {
  const token = useAuth0Store.getState().jwt;

  if (_.isNil(token) || isAuth0JwtExpired(token)) {
    const refreshedToken = await refreshAuth0Jwt();

    // If we still don't have a token, redirect to login
    if (_.isNil(refreshedToken)) {
      auth0Client.loginWithRedirect({
        appState: {
          returnTo: `${window.location.pathname}${window.location.search}`,
        },
      });
      return '';
    }

    return refreshedToken;
  }

  return token;
};

/**
 * Parse an access token.
 *
 * @param token A valid access token
 * @returns An object representing the token
 */
export const parseAuth0Jwt = (token: string): Auth0Jwt | null => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );

    return JSON.parse(jsonPayload);
  } catch (err) {
    logger.error('Failed to parse token');
    Sentry.captureException(err, {
      extra: {
        message: 'Failed to parse token',
      },
    });
    return null;
  }
};

/**
 * Check if the access token is expired.
 *
 * @returns true if the token is expired, false otherwise
 */
export const isAuth0JwtExpired = (token?: string | Auth0Jwt | null) => {
  if (_.isNil(token)) {
    return true;
  }

  const parsedToken = _.isString(token) ? parseAuth0Jwt(token) : token;
  if (_.isNil(parsedToken) || !_.isNumber(parsedToken.exp)) {
    return true;
  }

  return Date.now() / 1000 >= parsedToken.exp;
};

/**
 * A token returned by Auth0.
 */
export interface Auth0Jwt {
  iss: string;
  sub: string;
  aud: string[];
  iat: number;
  exp: number;
  azp: string;
  scope: string;
  permissions: string[];
}
