import { Extension } from '@tiptap/core';

import bulletList from './extensions/bullet-list';
import listItem from './extensions/list-item';
import orderedList from './extensions/ordered-list';

export default Extension.create({
  name: 'dashdraft-lists',

  addExtensions() {
    return [listItem, bulletList, orderedList];
  },
});
