import { StreamLanguage } from '@codemirror/language';
import { graphql as graphqlStreamParser } from 'codemirror-graphql/cm6-legacy/mode';

/*
|==========================================================================
| graphql
|==========================================================================
|
| Support syntax highlighting for GraphQL.
|
*/

const graphql = (): StreamLanguage<any> => {
  return StreamLanguage.define(graphqlStreamParser);
};

export default graphql;
