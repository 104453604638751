import { Box, Button, IconShare, Skeleton } from '@joggrdocs/riker';
import React from 'react';

import { useConfig } from '@stargate/hooks';
import { useRoute } from '@stargate/routes';
import { generateComponentClasses } from '@stargate/theme';

import { JoggrDocShareDialog } from './JoggrDocShareDialog';

export const joggrDocShareClasses = generateComponentClasses('JoggrDocShare', [
  'root',
] as const);

export interface JoggrDocShareProps {
  /**
   * The ID of the JoggrDoc to share.
   */
  docId?: string;

  /**
   * Whether to show the reward for sharing the JoggrDoc.
   */
  showReward?: boolean;
}

export const JoggrDocShare: React.FC<JoggrDocShareProps> = ({ docId }) => {
  const config = useConfig();
  const route = useRoute('app.documents.view');
  const [open, setOpen] = React.useState(false);

  const shareUrl = React.useMemo(() => {
    if (!docId) return null;

    return `${config.url}${route.url({ id: docId })}`;
  }, [docId, route, config]);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  if (!shareUrl) {
    return <Skeleton variant='rounded' animation={false} />;
  }

  return (
    <Box className={joggrDocShareClasses.root}>
      <Button
        startIcon={<IconShare size={16} />}
        size='small'
        variant='contained'
        color='info'
        onClick={handleOpen}
      >
        Share
      </Button>
      <JoggrDocShareDialog
        shareUrl={shareUrl}
        open={open}
        onClose={handleClose}
      />
    </Box>
  );
};
