import { Box, Typography } from '@joggrdocs/riker';
import React from 'react';

import { Img } from '@stargate/components/Images';
import {
  GitHubAuthorizeButton,
  GitHubBrandAvatar,
} from '@stargate/features/github';

import {
  OnboardingStep,
  type OnboardingStepItem,
} from '../OnboardingStep/OnboardingStep';

/*
|==========================================================================
| OnboardingStepAuthorizeGithub
|==========================================================================
|
| Tells the user to authorize with Github, and shares helpful information
| about what to expect & to encourage them it's safe to continue.
|
*/

export type OnboardingStepAuthorizeGithubProps = OnboardingStepItem;

export const OnboardingStepAuthorizeGithub: React.FC<
  OnboardingStepAuthorizeGithubProps
> = (props) => {
  return (
    <OnboardingStep
      {...props}
      title='Authorize with GitHub'
      avatar={<GitHubBrandAvatar />}
      contentTitle='Enable access to your GitHub Account & start creating docs!'
      contentDescription='We enable you to create and maintain documentation for your GitHub repositories and will need access to read your repositories code and metadata.'
      content={
        <Box
          sx={{
            textAlign: 'center',
          }}
        >
          <Img
            src={'/features/user-onboarding/github-authorization-example.png'}
            alt='Example GitHub Authorization'
            width={400}
            height={471}
            sx={{ maxWidth: '400px', m: 'auto' }}
          />
          <Typography variant='caption' sx={{ mt: 1, mb: 0, display: 'block' }}>
            You will see a screen similar to the above.
          </Typography>
        </Box>
      }
      action={<GitHubAuthorizeButton size='large' />}
    />
  );
};
