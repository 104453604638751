import React from 'react';
import type { RouteObject } from 'react-router-dom';

import { DashboardLayout, FullWidthLayout } from '@stargate/layouts';
import DocumentTagsListPage from '@stargate/pages/DocumentTags/List';
import { DocCreatePage, DocPage } from '@stargate/pages/Documents';
import {
  DocumentsImportListPage,
  DocumentsImportPage,
} from '@stargate/pages/DocumentsImport';
import {
  GitHubAuthorizeUserPage,
  GitHubInstallAppPage,
} from '@stargate/pages/Github';
import OnboardingPage from '@stargate/pages/Onboarding/OnboardingPage';
import IntegrationsPage from '@stargate/pages/Settings/IntegrationsList';
import AccountSettingsPage from '@stargate/pages/Settings/View';
import {
  AcceptInvitePage,
  ErrorPage,
  HomePage,
  LogoutPage,
} from '@stargate/pages/Utils';

import { JoggrDocProvider } from '@stargate/features/docs/components/JoggrDocProvider';
import ProtectedRouteElement from './components/ProtectedRoute';
import Redirect from './components/Redirect';
import tsr from './lib/type-safe-routes';

/*
|==========================================================================
| Routes
|==========================================================================
|
| The Joggr Platform routes.
|
*/

/**
 * Route definitions, safely typed.
 */
export const routeDefinitions = {
  /*
  |------------------
  | Core Routes
  |------------------
  */

  root: tsr.route('/'),
  'root.logout': tsr.route('/logout'),
  'root.login': tsr.route('/login'),
  'root.error': tsr.route('/error', {
    search: {
      errorType: {
        type: 'string',
        enum: [
          'not-found',
          'unauthorized',
          'forbidden',
          'internal-server-error',
          'bad-request',
          'unknown',
        ] as const,
      },
      reloadUrl: { type: 'string' },
    },
  }),

  /*
  |------------------
  | Utility Routes
  |------------------
  */

  'utility.accept-invite': tsr.route('/accept-invite'),
  'utility.onboarding': tsr.route('/onboarding'),
  'utility.authorize': tsr.route('/authorize'),
  'utility.authorize-github': tsr.route('/authorize/github'),
  'utility.integrations-github': tsr.route('/integrations/github'),

  /*
  |------------------
  | App Routes
  |------------------
  */

  'app.root': tsr.route('/app/home'),
  'app.integrations': tsr.route('/app/integrations'),
  'app.settings': tsr.route('/app/settings', {
    search: {
      activeTab: { type: 'string' },
    },
  } as const),
  'app.tags.list': tsr.route('/app/tags'),
  'app.documents.view': tsr.route('/app/documents/:id', {
    params: {
      id: { type: 'string' },
    },
    search: {
      shareOpen: { type: 'boolean' },
      showReward: { type: 'boolean' },
      firstTimeCreate: { type: 'boolean' },
    },
  } as const),
  'app.document-imports.create': tsr.route('/app/document-imports/create', {
    search: {
      rootDirId: { type: 'string' },
    },
  } as const),
  'app.document-imports.list': tsr.route('/app/document-imports'),
  'app.documents.edit': tsr.route('/app/documents/:id/edit', {
    params: {
      id: { type: 'string' },
    },
  } as const),
  'app.documents.create': tsr.route('/app/documents/create', {
    search: {
      blank: { type: 'boolean' },
      dir: { type: 'string' },
      template: { type: 'string' },
    },
  } as const),
} as const;

/**
 * The Joggr Route Definitions, safely typed.
 */
export type RouteDefinitions = typeof routeDefinitions;

/**
 * The Joggr Route Names, safely typed.
 */
export type RouteNames = keyof RouteDefinitions;

/**
 * Get a route definition by name.
 *
 * @param definition The route definition name
 * @returns A route definition
 */
export const getRouteDefinition = <D extends RouteNames>(definition: D) => {
  return routeDefinitions[definition];
};

/*
|----------------------------------
| Route Configuration
|----------------------------------
|
| Configure the React-Router routes, using the route definitions. 
|
*/

const routeConfiguration = [
  /*
  |----------------------------------
  | Public Routes
  |----------------------------------
  |
  | Routes that do not require authentication. 
  |
  */

  {
    path: routeDefinitions.root.path,
    element: <Redirect to={routeDefinitions['app.root'].path} replace />,
  },
  {
    path: routeDefinitions['utility.accept-invite'].path,
    element: <AcceptInvitePage />,
  },
  {
    path: routeDefinitions['root.logout'].path,
    element: <LogoutPage />,
  },
  {
    path: routeDefinitions['root.error'].path,
    element: (
      <FullWidthLayout>
        <ErrorPage />
      </FullWidthLayout>
    ),
  },

  /*
  |----------------------------------
  | Protected Routes
  |----------------------------------
  |
  | Routes that require authentication 
  |
  */

  {
    path: routeDefinitions['root.login'].path,
    element: (
      <ProtectedRouteElement
        element={<Redirect to={routeDefinitions['app.root'].path} />}
      />
    ),
  },

  /*
  |------------------
  | Utility Routes
  |------------------
  */

  {
    path: routeDefinitions['utility.authorize-github'].path,
    element: (
      <ProtectedRouteElement
        element={<GitHubAuthorizeUserPage />}
        skipOnboardingCheck={true}
      />
    ),
  },
  {
    path: routeDefinitions['utility.integrations-github'].path,
    element: (
      <ProtectedRouteElement
        element={<GitHubInstallAppPage />}
        skipOnboardingCheck={true}
      />
    ),
  },
  {
    path: routeDefinitions['utility.authorize'].path,
    element: (
      <ProtectedRouteElement
        element={<Redirect to={routeDefinitions['app.root'].path} />}
      />
    ),
  },

  {
    path: routeDefinitions['utility.onboarding'].path,
    element: (
      <ProtectedRouteElement
        element={
          <FullWidthLayout>
            <OnboardingPage />
          </FullWidthLayout>
        }
        skipOnboardingCheck={true}
      />
    ),
  },

  /*
  |------------------
  | App Routes
  |------------------
  */

  {
    path: '/app/*',
    element: <ProtectedRouteElement element={<DashboardLayout />} />,
    errorElement: <ErrorPage errorType='unknown' />,
    children: [
      {
        index: true,
        element: (
          <Redirect
            to={tsr.rel('/app', routeDefinitions['app.root'].path)}
            replace
          />
        ),
      },
      {
        path: tsr.rel('/app', routeDefinitions['app.root'].path),
        element: <HomePage />,
      },
      {
        path: 'document-imports/*',
        children: [
          {
            path: tsr.rel(
              '/app/document-imports',
              routeDefinitions['app.document-imports.create'].path
            ),
            element: <DocumentsImportPage />,
          },
          {
            path: tsr.rel(
              '/app/document-imports',
              routeDefinitions['app.document-imports.list'].path
            ),
            element: <DocumentsImportListPage />,
          },
        ],
      },
      {
        path: 'documents/*',
        element: <JoggrDocProvider />,
        children: [
          {
            path: '',
            index: true,
            element: <Redirect to={routeDefinitions['app.root'].path} />,
          },
          {
            path: tsr.rel(
              '/app/documents',
              routeDefinitions['app.documents.create'].path
            ),
            element: <DocCreatePage />,
          },
          {
            path: tsr.rel(
              '/app/documents',
              routeDefinitions['app.documents.edit'].path
            ),
            element: <DocPage />,
          },
          {
            path: tsr.rel(
              '/app/documents',
              routeDefinitions['app.documents.view'].path
            ),
            element: <DocPage />,
          },
          {
            path: '*',
            element: <ErrorPage errorType='not-found' />,
          },
        ],
      },
      {
        path: tsr.rel('/app', routeDefinitions['app.tags.list'].path),
        element: <DocumentTagsListPage />,
      },
      {
        path: tsr.rel('/app', routeDefinitions['app.integrations'].path),
        element: <IntegrationsPage />,
      },
      {
        path: tsr.rel('/app', routeDefinitions['app.settings'].path),
        element: <AccountSettingsPage />,
      },
      {
        path: '*',
        element: <ErrorPage errorType='not-found' />,
      },
    ],
  },

  /*
  |------------------
  | Catch All
  |------------------
  */
  {
    path: '*',
    element: <ErrorPage errorType='not-found' />,
  },
] as const satisfies RouteObject[];

export default routeConfiguration;
