import Table from '@tiptap/extension-table';
import type { EditorState } from '@tiptap/pm/state';
import type { EditorView } from '@tiptap/pm/view';
import type { Editor } from '@tiptap/react';
import _ from 'lodash';

import { isTableSelected } from '@stargate/lib/dashdraft/extensions/table/extensions/utils';

export interface TableSelectionOptions {
  editor: Editor;
  view: EditorView;
  state: EditorState;
  from: number;
}

// Retrieves the relevant DOM node at a given position.
const getTableNode = (
  view: TableSelectionOptions['view'],
  from: TableSelectionOptions['from']
): HTMLElement | null => {
  const domAtPos = view.domAtPos(from).node as HTMLElement;
  const nodeDOM = view.nodeDOM(from) as HTMLElement;
  return !_.isNil(nodeDOM) ? nodeDOM : domAtPos;
};

// Traverses up the DOM tree to find the nearest table cell (TD or TH).
const findNearestTableCell = (node: HTMLElement): HTMLElement | null => {
  let container: HTMLElement | null = node;

  while (
    !_.isNil(container) &&
    !['TD', 'TH'].includes(container.tagName?.toUpperCase())
  ) {
    container = container.parentElement;
  }

  return container;
};

const findNearestTable = (node: HTMLElement): HTMLElement | null => {
  let container: HTMLElement | null = node;

  while (
    !_.isNil(container) &&
    !['TABLE'].includes(container.tagName?.toUpperCase())
  ) {
    container = container.parentElement;
  }

  return container;
};

export const isRowGripSelected = ({
  editor,
  view,
  state,
  from,
}: TableSelectionOptions): boolean => {
  const node = getTableNode(view, from);

  if (
    !editor.isActive(Table.name) ||
    _.isNil(node) ||
    isTableSelected(state.selection)
  ) {
    return false;
  }

  const container = findNearestTableCell(node);
  const gripRow = container?.querySelector?.('a.grip-row.selected');

  return Boolean(gripRow);
};

export const isColumnGripSelected = ({
  editor,
  view,
  state,
  from,
}: TableSelectionOptions): boolean => {
  const node = getTableNode(view, from);

  if (
    !editor.isActive(Table.name) ||
    _.isNil(node) ||
    isTableSelected(state.selection)
  ) {
    return false;
  }

  const container = findNearestTableCell(node);
  const gripColumn = container?.querySelector?.('a.grip-column.selected');

  return Boolean(gripColumn);
};

export const isMultipleColumnsSelected = ({
  editor,
  view,
  state,
  from,
}: TableSelectionOptions): boolean => {
  const node = getTableNode(view, from);

  if (
    !editor.isActive(Table.name) ||
    _.isNil(node) ||
    isTableSelected(state.selection)
  ) {
    return false;
  }

  const container = findNearestTable(node);
  const gripColsSelected =
    container?.querySelectorAll('a.grip-column.selected') ?? [];

  if (gripColsSelected.length > 1) {
    return true;
  }

  return false;
};

export const isMultipleRowsSelected = ({
  editor,
  view,
  state,
  from,
}: TableSelectionOptions): boolean => {
  const node = getTableNode(view, from);

  if (
    !editor.isActive(Table.name) ||
    _.isNil(node) ||
    isTableSelected(state.selection)
  ) {
    return false;
  }

  const container = findNearestTable(node);
  const gripRowsSelected =
    container?.querySelectorAll('a.grip-row.selected') ?? [];

  if (gripRowsSelected.length > 1) {
    return true;
  }

  return false;
};

export const isMultipleCellsSelected = ({
  editor,
  view,
  state,
  from,
}: TableSelectionOptions): boolean => {
  const node = getTableNode(view, from);

  if (
    !editor.isActive(Table.name) ||
    _.isNil(node) ||
    isTableSelected(state.selection)
  ) {
    return false;
  }

  const container = findNearestTableCell(node);
  const selectedCells = container
    ?.closest('table')
    ?.querySelectorAll('.selectedCell');

  if (!_.isNil(selectedCells) && selectedCells.length !== 0) {
    const gripRowSelected = Boolean(
      container?.querySelector('a.grip-row.selected')
    );
    const gripColumnSelected = Boolean(
      container?.querySelector('a.grip-column.selected')
    );

    if (!gripRowSelected && !gripColumnSelected) {
      return true;
    }
  }

  return false;
};
