import _ from 'lodash';

import { useUserDefaults } from '@stargate/features/user';

import { useJdocDraftLocalCache } from '@/features/docs/hooks/use-jdoc-draft-cache';
import type {
  JDocCodeSource,
  JDocDraftLocalCacheData,
  JDocTag,
} from '@/features/docs/types';

export interface UseJDocDraftMutateHook {
  /**
   * Update the JoggrDoc Local Draft
   */
  update(partial: Omit<JDocDraftLocalCacheData, 'codeSources' | 'tags'>): void;

  /**
   * Add a code source to the JoggrDoc Local Draft
   *
   * @param codeSource
   */
  addCodeSource(codeSource: JDocCodeSource): void;

  /**
   * Remove a code source from the JoggrDoc Local Draft
   *
   * @param codeSourceId
   */
  removeCodeSource(codeSourceId: string): void;

  /**
   * Add a tag to the JoggrDoc Local Draft
   *
   * @param tag
   */
  addTag(tag: JDocTag): void;

  /**
   * Remove a tag from the JoggrDoc Local Draft
   *
   * @param tag
   */
  removeTag(tag: string): void;

  /**
   * Replace the tags in the JoggrDoc Local Draft
   */
  updateTags(tags: JDocTag[]): void;

  /**
   * Reset the JoggrDoc Local Draft
   */
  clear: () => void;
}

/**
 * Mutate the JoggrDoc Local Draft.
 *
 * @returns A hook to mutate the JoggrDoc Local Draft
 */
export const useJDocDraftMutate = (): UseJDocDraftMutateHook => {
  const [, defaults] = useUserDefaults();
  const cache = useJdocDraftLocalCache();

  /**
   * Merge the cache with the update.
   *
   * @param update The update to merge with the cache
   * @returns The new JoggrDocDraft
   */
  const merge = (update: JDocDraftLocalCacheData) => {
    const existing = cache.data ?? null;

    const newDraft = {
      ...existing,
      ...update,
      content: update.content ?? existing?.content ?? undefined,
      tags: update.tags ?? existing?.tags ?? undefined,
      codeSources: update.codeSources ?? existing?.codeSources ?? undefined,
      loading: update?.loading ?? existing?.loading ?? false,
    };

    cache.set(newDraft);
    return newDraft;
  };

  return {
    addCodeSource: (codeSource) => {
      const updatedCodeSources = [
        ...(cache.data?.codeSources ?? []),
        codeSource,
      ];
      merge({ codeSources: updatedCodeSources });
      return codeSource;
    },
    removeCodeSource: (codeSourceId) => {
      const updatedCodeSources = (cache.data?.codeSources ?? []).filter(
        (cs) => cs.id !== codeSourceId
      );
      merge({ codeSources: updatedCodeSources });
    },
    addTag: (tag) => {
      const updatedTags = [...(cache.data?.tags ?? []), tag];
      merge({ tags: updatedTags });
      return tag;
    },
    removeTag: (tag) => {
      const updatedTags = (cache.data?.tags ?? []).filter((t) => t.id !== tag);
      merge({ tags: updatedTags });
    },
    updateTags: (tags) => {
      merge({ tags });
      return tags;
    },
    update: (cacheUpdate) => {
      if (!_.isNil(cacheUpdate.github?.repository)) {
        defaults.setRepositoryId(cacheUpdate.github.repository.id.toString());
      }

      if (!_.isNil(cacheUpdate.github?.repository)) {
        defaults.setRepositoryOwnerId(
          cacheUpdate.github.repository.owner.id.toString()
        );
      }

      return merge(cacheUpdate);
    },
    clear: cache.remove,
  };
};
