import {
  Backdrop,
  Box,
  type BoxProps,
  Card,
  CardHeader,
  IconBarrierBlock,
  IconGitCommit,
  IconLink,
  IconPhotoUp,
  Stack,
  Tab,
  type TabProps,
  Tabs,
  useTheme,
} from '@joggrdocs/riker';
import * as hookz from '@react-hookz/web';
import type { APIResponse } from '@stargate/api';
import _ from 'lodash';
import React from 'react';

import { ComingSoonChip } from '@stargate/components/Chips';
import useConfig from '@stargate/hooks/use-config';
import { useHTTPClient } from '@stargate/lib/http';
import { useNotify } from '@stargate/lib/notify';

import {
  ImageFormExternal,
  type ImageFormExternalProps,
} from './Forms/ImageFormExternal';
import { ImageFormGitCommit } from './Forms/ImageFormGitCommit';
import {
  ImageFormUpload,
  type ImageFormUploadProps,
} from './Forms/ImageFormUpload';

export interface SaveImageBase {
  type: 'external' | 'upload';
}

export interface SaveImageExternal extends SaveImageBase {
  type: 'external';
  url: string;
}

export interface SaveImageUpload extends SaveImageBase {
  type: 'upload';
  file: File;
}

export type SaveImage = SaveImageExternal | SaveImageUpload;

export interface ImageFormProps {
  defaultSrc?: string;
  defaultAlt?: string;
  onSave: (payload: { src: string; alt: string }) => void;
  onClose: () => void;
}

/**
 * ImageDialog is a component that allows the user to upload an image,
 * link to an external image, or commit an image.
 */
export const ImageForm: React.FC<ImageFormProps> = ({
  onClose,
  onSave,
  ...props
}) => {
  const config = useConfig();
  const [value, setValue] = React.useState<'commit' | 'external' | 'upload'>(
    getDefaultValue(config.cdn.url, props.defaultSrc)
  );
  const [loading, setLoading] = React.useState(false);
  const [, cdnAssetActions] = useUploadCdnAsset();
  const notify = useNotify();
  const theme = useTheme();

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const handleChangeTab = React.useCallback(
    (_event: React.SyntheticEvent, newValue: TabValue) => {
      setValue(newValue);
    },
    []
  );

  const handleSaveExternal = React.useCallback<
    ImageFormExternalProps['onSave']
  >(
    (img) => {
      onSave(img);
      onClose();
    },
    [onSave, onClose]
  );

  const handleSaveUpload = React.useCallback<ImageFormUploadProps['onSave']>(
    (img) => {
      setLoading(true);
      void cdnAssetActions
        .execute(img.file)
        .then((result) => {
          onSave({ src: result.fileUrl, alt: img.alt });
        })
        .catch(() => {
          notify.send({
            severity: 'error',
            title: 'Error',
            message: 'Unable to upload image. Please try again.',
          });
        })
        .finally(() => {
          setLoading(false);
          onClose();
        });
    },
    [cdnAssetActions, onSave, notify, onClose]
  );

  return (
    <React.Fragment>
      <Tabs
        value={value}
        onChange={handleChangeTab}
        sx={{
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      >
        <TabDense
          label='Upload'
          value='upload'
          icon={<IconPhotoUp />}
          iconPosition='start'
        />
        <TabDense
          label='External Link'
          value='external'
          icon={<IconLink />}
          iconPosition='start'
        />
        <TabDense
          label={
            <Stack direction='row' spacing={1} alignItems='center'>
              <Box component='span'>Commit</Box>
              <ComingSoonChip
                sx={{
                  fontSize: '12px',
                }}
              />
            </Stack>
          }
          value='commit'
          icon={<IconGitCommit />}
          iconPosition='start'
        />
      </Tabs>
      <TabPanel active={value === 'upload'}>
        <ImageFormUpload
          alt={props.defaultAlt}
          onSave={handleSaveUpload}
          loading={loading}
        />
      </TabPanel>
      <TabPanel active={value === 'external'}>
        <ImageFormExternal
          src={props.defaultSrc}
          alt={props.defaultAlt}
          onSave={handleSaveExternal}
        />
      </TabPanel>
      <TabPanel active={value === 'commit'}>
        <Backdrop
          sx={{
            zIndex: (theme) => theme.zIndex.drawer,
            position: 'absolute',
          }}
          open
        >
          <Card>
            <CardHeader
              title={
                <Stack
                  direction='row'
                  spacing={1}
                  alignItems='center'
                  justifyContent='center'
                >
                  <IconBarrierBlock size={24} />
                  <Box>Coming Soon</Box>
                </Stack>
              }
              subheader='This feature is on our roadmap and will be available soon'
              sx={{
                m: 0,
                px: 2,
                py: 1,
              }}
            />
          </Card>
        </Backdrop>
        <ImageFormGitCommit />
      </TabPanel>
    </React.Fragment>
  );
};
ImageForm.displayName = 'ImageForm';

/*
|------------------
| Utils
|------------------
*/

const getDefaultValue = (cdnUrl: string, src?: string): TabValue => {
  if (!_.isNil(src) && !_.isEmpty(src)) {
    return src.includes(cdnUrl) ? 'upload' : 'external';
  }
  return 'upload';
};

type TabValue = 'commit' | 'external' | 'upload';

/*
|------------------
| Styled Components
|------------------
*/

const TabPanel: React.FC<{ active: boolean } & BoxProps> = (props) => (
  <Box
    {...props}
    sx={{
      minHeight: '148px',
      minWidth: '300px',
      display: props.active ? 'block' : 'none',
      position: 'relative',
      padding: 3,
    }}
  />
);

const TabDense: React.FC<TabProps> = (props) => (
  <Tab
    {...props}
    sx={{
      minHeight: '48px',
      minWidth: '120px',
      padding: 2,
    }}
  />
);

/*
|------------------
| Hooks
|------------------
*/

/**
 * Uploads a file to the CDN.
 *
 * NOTE: We have to do this since the client currently does not have the ability to upload files to the CDN (i.e. FormData).
 */
export function useUploadCdnAsset() {
  const apiClient = useHTTPClient();
  return hookz.useAsync<
    APIResponse<'POST /assets/content/:mediaType/upload'>,
    [File]
  >(async (file) => {
    const data = new FormData();
    data.append('file', file);
    return await apiClient.fetchAuthorized<
      APIResponse<'POST /assets/content/:mediaType/upload'>
    >('/assets/content/images/upload', {
      method: 'POST',
      data,
    });
  });
}
