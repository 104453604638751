import { produce } from 'immer';
import _ from 'lodash';
import { create } from 'zustand';

import { auth0JwtCache } from '../lib/auth0-jwt-cache';
import type { Auth0Context } from '../types';

export interface Auth0Store extends Auth0Context {
  /**
   * The Auth0 access token.
   */
  jwt: string | null;

  /**
   * Set the Auth0 user.
   *
   * @param user The Auth0 user object.
   */
  setUser: (user: Auth0Context['user']) => void;

  /**
   * Set the Auth0 access token.
   *
   * @param token The Auth0 access token.
   */
  setJwt: (token: string | null) => void;

  /**
   * Set the Auth0 context loading state.
   *
   * @param loading If the Auth0 context is loading.
   */
  setError: (error: Error | null) => void;

  /**
   * Clear the Auth0 data.
   */
  clear: () => void;
}

/**
 * Store for exposing the Auth0 context outside of the Auth0Provider.
 */
export const useAuth0Store = create<Auth0Store>((set) => ({
  jwt: auth0JwtCache.get(),
  user: null,
  isAuthenticated: false,
  isLoading: true,
  isError: false,
  error: null,
  setError: (error) => {
    set(
      produce<Auth0Store>((draft) => {
        draft.error = error;
        draft.isError = _.isError(error);
        draft.isLoading = false;
        if (_.isError(error)) {
          draft.isAuthenticated = false;
          draft.user = null;
        }
      })
    );
  },
  setUser: (user) => {
    set(
      produce<Auth0Store>((draft) => {
        draft.user = user;
        draft.isAuthenticated = Boolean(user);
        draft.isLoading = false;
      })
    );
  },
  setJwt: (token) => {
    set(
      produce<Auth0Store>((draft) => {
        draft.jwt = token;
      })
    );

    if (!_.isNil(token)) {
      auth0JwtCache.set(token);
    } else {
      auth0JwtCache.remove();
    }
  },
  clear: () => {
    set(
      produce<Auth0Store>((draft) => {
        draft.jwt = null;
        draft.user = null;
        draft.isAuthenticated = false;
        draft.isLoading = true;
        draft.isError = false;
        draft.error = null;
      })
    );
    auth0JwtCache.remove();
  },
}));
