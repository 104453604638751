import useSearchParams from '@stargate/hooks/use-search-params';

export interface InviteParams extends Record<string, string> {
  /**
   * Invite ID
   */
  id: string;

  /**
   * Sender ID or User ID for the person that sent the invite
   */
  sid: string;

  /**
   * Recipient Email
   */
  email: string;
}

const useInviteParams = () => {
  const [params, update, remove] = useSearchParams<InviteParams>();

  return {
    params,
    updateParams(params: InviteParams) {
      update(params);
    },
    removeParams() {
      remove(['id', 'sid', 'email']);
    },
  };
};

export default useInviteParams;
