import {
  type EditorState,
  NodeSelection,
  TextSelection,
  type Transaction,
} from '@tiptap/pm/state';
import _ from 'lodash';

/**
 * Retrieve the selected node in the editor
 *
 * @param data current editor state or the current transaction
 * @returns The selected node in the editor
 */
export const getSelectionNode = (data: EditorState | Transaction) => {
  if (data.selection instanceof NodeSelection) {
    return data.selection.node;
  }

  if (data.selection instanceof TextSelection) {
    return data.selection.$head.parent;
  }

  return null;
};

/**
 * Check if the selection is a code snippet node and is empty ("placeholder" code snippet)
 *
 * @param data current editor state or the current transaction
 * @returns A boolean indicating whether the selection is a code snippet node
 */
export const isSelectionEmptyCodeSnippet = (
  data: EditorState | Transaction
) => {
  const node = getSelectionNode(data);

  if (!node) {
    return false;
  }

  return (
    node.type.name === 'codeBlock' &&
    node.attrs.type === 'snippet' &&
    node.attrs.placeholder === true
  );
};

/**
 * Check if the selection is an empty (paragraph) line
 *
 * @param data current editor state or the current transaction
 * @returns A boolean indicating whether the selection is an empty line
 */
export const isSelectionEmptyLine = (data: EditorState | Transaction) => {
  const node = getSelectionNode(data);

  if (!node) {
    return false;
  }

  return node.type.name === 'paragraph' && node.textContent === '';
};
