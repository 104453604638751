import { Divider, Stack } from '@joggrdocs/riker';
import React from 'react';

import { TextSearchFilter } from '@stargate/components/FormFields';
import { useNightingale } from '@stargate/lib/nightingale';

import type { DirectoryTreeFilter } from '../../types';
import {
  DocumentTagsFilter,
  GitHubRepositoriesFilter,
} from '../DirectoryTreeFilters';

/*
|==========================================================================
| DocumentDirectoryExplorerFilters
|==========================================================================
|
| Filters for the document directory explorer.
|
*/

export interface DocumentDirectoryExplorerFiltersProps {
  onFilterChange: (filter: DirectoryTreeFilter) => void;
}

export const DocumentDirectoryExplorerFilters = React.forwardRef<
  HTMLDivElement,
  DocumentDirectoryExplorerFiltersProps
>(({ onFilterChange }, ref) => {
  const [filter, setFilter] = React.useState<DirectoryTreeFilter>({});
  const nightingale = useNightingale();

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const handleChangeTextFilter = React.useCallback(
    (text: string) => {
      const update = {
        ...filter,
        text,
      };
      setFilter(update);
      onFilterChange(update);

      nightingale.capture(['filter', 'directory_explorer'], {
        filter,
        filterType: 'text',
      });
    },
    [filter, onFilterChange, nightingale]
  );

  const handleChangeTagsFilter = React.useCallback(
    (tagIds: string[]) => {
      const update = {
        ...filter,
        tagIds,
      };
      setFilter(update);
      onFilterChange(update);

      nightingale.capture(['filter', 'directory_explorer'], {
        filter,
        filterType: 'tags',
      });
    },
    [filter, onFilterChange, nightingale]
  );

  const handleChangeGitHubRepositoryIdsFilter = React.useCallback(
    (githubRepositoryIds: string[]) => {
      const update = {
        ...filter,
        githubRepositoryIds,
      };
      setFilter(update);
      onFilterChange(update);

      nightingale.capture(['filter', 'directory_explorer'], {
        filter,
        filterType: 'githubRepositoryIds',
      });
    },
    [filter, onFilterChange, nightingale]
  );

  return (
    <Stack
      ref={ref}
      direction='column'
      spacing={1.25}
      sx={{
        pb: 1,
      }}
    >
      <TextSearchFilter
        onChange={handleChangeTextFilter}
        placeholder='Filter by file name or directory'
      />
      <DocumentTagsFilter onChange={handleChangeTagsFilter} />
      <GitHubRepositoriesFilter
        onChange={handleChangeGitHubRepositoryIdsFilter}
      />
      <Divider />
    </Stack>
  );
});
