import { produce } from 'immer';
import { create } from 'zustand';

import type { CodeExplorerPlacement, CodeExplorerQuery } from '../types';

export interface UseCodeExplorerHookState {
  /**
   * Whether the shortcuts explorer is open.
   */
  open: boolean;

  /**
   * The current query of the CodeExplorer.
   */
  query?: CodeExplorerQuery;

  /**
   * The current placement of the CodeExplorer.
   *
   * @default 'right'
   */
  placement: CodeExplorerPlacement;

  /**
   * Whether the file tree is open.
   */
  fileTreeOpen: boolean;
}

export interface UseCodeExplorerHookActions {
  /**
   * Close the CodeExplorer.
   */
  close: () => void;

  /**
   * Open the CodeExplorer.
   */
  open: () => void;

  /**
   * Close the CodeExplorer file tree.
   */
  closeFileTree: () => void;

  /**
   * Open the CodeExplorer file tree.
   */
  openFileTree: () => void;

  /**
   * Set the query of the CodeExplorer.
   */
  setQuery: (query: CodeExplorerQuery) => void;

  /**
   * Merge the query of the CodeExplorer.
   */
  mergeQuery: (query: Partial<CodeExplorerQuery>) => void;

  /**
   * Clear the query of the CodeExplorer.
   */
  clearQuery: () => void;
}

export type UseCodeExplorerHook = [
  UseCodeExplorerHookState,
  UseCodeExplorerHookActions,
];

/**
 * Store for the Code Explorer and other related state.
 *
 * @returns A tuple containing the state and actions of the store.
 */
export const useCodeExplorer = (): UseCodeExplorerHook => {
  return useStore((store) => [
    {
      // Hardcoded default placement for now
      placement: 'right',
      open: store.explorerOpen,
      fileTreeOpen: store.fileTreeOpen,
      query: store.query,
    },
    {
      close: store.closeExplorer,
      open: store.openExplorer,
      closeFileTree: store.closeExplorerFileTree,
      openFileTree: store.openExplorerFileTree,
      setQuery: store.setQuery,
      mergeQuery: store.mergeQuery,
      clearQuery: store.clearQuery,
    },
  ]);
};

/*
|------------------
| Utils
|------------------
*/

// We export ONLY so we can use in dashdraft (for now)
export const useStore = create<{
  query: CodeExplorerQuery;
  explorerOpen: boolean;
  fileTreeOpen: boolean;
  closeExplorer: () => void;
  openExplorer: () => void;
  closeExplorerFileTree: () => void;
  openExplorerFileTree: () => void;
  setQuery: (query: CodeExplorerQuery) => void;
  mergeQuery: (query: Partial<CodeExplorerQuery>) => void;
  clearQuery: () => void;
}>((set) => ({
  query: {
    githubOrganizationId: null,
    githubRepositoryId: null,
    branch: null,
    code: null,
  },
  explorerOpen: false,
  fileTreeOpen: false,
  closeExplorer: () => {
    set(
      produce((draft) => {
        draft.explorerOpen = false;
      })
    );
  },
  openExplorer: () => {
    set(
      produce((draft) => {
        draft.explorerOpen = true;
      })
    );
  },
  closeExplorerFileTree: () => {
    set(
      produce((draft) => {
        draft.fileTreeOpen = false;
      })
    );
  },
  openExplorerFileTree: () => {
    set(
      produce((draft) => {
        draft.fileTreeOpen = true;
      })
    );
  },
  setQuery: (query: CodeExplorerQuery) => {
    set(
      produce((draft) => {
        draft.query = query;
      })
    );
  },
  mergeQuery: (query: Partial<CodeExplorerQuery>) => {
    set(
      produce((draft) => {
        for (const key in query) {
          const k = key as keyof CodeExplorerQuery;
          if (query[k] !== undefined) {
            draft.query[key] = query[k];
          }
        }
      })
    );
  },
  clearQuery: () => {
    set(
      produce((draft) => {
        draft.query = undefined;
      })
    );
  },
}));
