import {
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconAlertTriangle,
  Link,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  radioClasses,
  useTheme,
} from '@joggrdocs/riker';
import _ from 'lodash';
import React from 'react';

import { InfoIcon } from '@stargate/components/Guides';
import type { JDocMode } from '@stargate/features/docs';
import { GitHubRepositoryBranchAutocomplete } from '@stargate/features/github';
import { useUser } from '@stargate/features/user';

import type { GitHubRepository } from '@stargate/features/github';

export type JoggrDocSaveFormBranchType =
  | 'default'
  | 'pr-existing'
  | 'pr-open'
  | 'pr-draft';

export interface JoggrDocSaveMessageRecommendation {
  id: string;
  message: string;
  priority: number;
}

export interface JoggrDocSaveFormProps {
  /**
   * The GitHub repository to commit to.
   */
  gitHubRepository: GitHubRepository;

  /**
   * Whether the form is loading.
   */
  loading: boolean;

  /**
   * The mode of the JoggrDoc
   */
  mode: JDocMode;

  /**
   * Disallow the select and force the documents current branch.
   *
   * @default false
   */
  disableBranchSelect?: boolean;

  /**
   * The commit message.
   */
  message: string;

  /**
   * The branch to commit to.
   */
  branch: string;

  /**
   * Whether to skip CI checks.
   */
  skipCi: boolean;

  /**
   * The type of branch to commit to.
   */
  branchType: JoggrDocSaveFormBranchType;

  /**
   * A list of recommended commit messages.
   */
  messageRecommendations?: JoggrDocSaveMessageRecommendation[];

  /**
   * Callback triggered when the user changes the branch type.
   */
  onChangeBranchType: (branchType: JoggrDocSaveFormBranchType) => void;

  /**
   * Callback triggered when the user changes the branch text.
   */
  onChangeBranch: (branch: string) => void;

  /**
   * Callback triggered when the user changes the commit message.
   */
  onChangeMessage: (message: string) => void;

  /**
   * Callback triggered when the user changes the skip CI checkbox.
   *
   * @param skipCi A boolean indicating whether to skip CI.
   */
  onChangeSkipCI: (skipCi: boolean) => void;
}

export const JoggrDocSaveForm: React.FC<JoggrDocSaveFormProps> = ({
  mode,
  gitHubRepository,
  disableBranchSelect,
  branch,
  branchType,
  loading,
  skipCi,
  message,
  messageRecommendations = [],
  onChangeMessage,
  onChangeBranch,
  onChangeBranchType,
  onChangeSkipCI,
}) => {
  /*
  |------------------
  | Callbacks
  |------------------
  */

  const handleChangeMessage = React.useCallback(
    (value: string) => {
      // If it's empty, set it to an empty string, otherwise append the skip CI tag
      onChangeMessage(value);
    },
    [onChangeMessage]
  );

  return (
    <Box>
      <Stack direction='column' spacing={1} useFlexGap>
        <TextField
          placeholder='Add a commit message or select from a recommended message below...'
          value={message}
          fullWidth
          onChange={(e) => {
            handleChangeMessage(e.target.value);
          }}
          minRows={3}
          multiline
        />
        <GitCommitRecommendations
          currentMessage={message}
          recommendations={messageRecommendations}
          onSelectRecommendation={(recommendedMessage) => {
            handleChangeMessage(recommendedMessage);
          }}
        />
        <Divider sx={{ mt: 1, mb: 1 }} flexItem />
        {disableBranchSelect && (
          <TextWarningOverwriteJoggrDoc branch={branch} />
        )}
        {!disableBranchSelect && (
          <Box sx={{ py: 1 }}>
            <GitBranchRadioGroup
              mode={mode}
              gitHubRepository={gitHubRepository}
              branch={branch}
              branchType={branchType}
              onChangeBranchType={onChangeBranchType}
              onChangeBranch={onChangeBranch}
            />
          </Box>
        )}
        <Divider sx={{ mt: 1, mb: 1 }} flexItem />
        <CheckboxSkipCI
          disabled={loading}
          checked={skipCi}
          onChange={(checkValue) => {
            onChangeSkipCI(checkValue);
          }}
        />
      </Stack>
    </Box>
  );
};

/*
|------------------
| Components: Form Fields
|------------------
*/

const GitBranchRadioGroup: React.FC<{
  /**
   * The repository to commit to.
   */
  gitHubRepository: GitHubRepository;

  /**
   * The mode of the JoggrDoc.
   */
  mode: JDocMode;

  /**
   * The branch to commit to.
   */
  branch: string;

  /**
   * The type of branch to commit to.
   */
  branchType: JoggrDocSaveFormBranchType;

  /**
   * Callback triggered when the user changes the branch type.
   */
  onChangeBranchType: (branchType: JoggrDocSaveFormBranchType) => void;

  /**
   * Callback triggered when the user changes the branch text.
   */
  onChangeBranch: (branch: string) => void;
}> = ({
  mode,
  branchType,
  branch,
  gitHubRepository,
  onChangeBranchType,
  onChangeBranch,
}) => {
  const user = useUser();

  // Keep a local cache in-case the user switches between PR types after
  // entering a branch name.
  const [localPrBranch, setLocalPrBranch] = React.useState<string>('');

  return (
    <FormControl>
      <RadioGroup
        name='branch-type'
        onChange={(_e, _value) => {
          const value = _value as JoggrDocSaveFormBranchType;
          onChangeBranchType(value);
          if (value === 'default') {
            onChangeBranch(gitHubRepository.defaultBranch);
          } else if (value === 'pr-open' || value === 'pr-draft') {
            const newBranch = `docs/${user.data?.githubUsername ?? 'mod'}-${Date.now()}`;
            onChangeBranch(
              localPrBranch.length > 0 ? localPrBranch : newBranch
            );
          } else {
            onChangeBranch('');
          }
        }}
        sx={{
          [`& .${radioClasses.root}`]: {
            py: 0.75,
          },
        }}
      >
        <FormControlLabel
          value='default'
          control={<Radio size='small' />}
          label={
            <Stack direction='column' spacing={0.5}>
              <Typography variant='body2'>
                Commit directly to the{' '}
                <code>{gitHubRepository.defaultBranch}</code> branch.
              </Typography>
            </Stack>
          }
        />
        {branchType === 'default' && <TextWarningCommitDefaultBranch />}
        <FormControlLabel
          value='pr-existing'
          control={<Radio size='small' />}
          label={
            <Typography variant='body2'>
              Commit to an existing pull request.
            </Typography>
          }
        />
        {branchType === 'pr-existing' && (
          <Box sx={{ mt: 1, mb: 1, pl: 3 }}>
            <GitHubRepositoryBranchAutocomplete
              size='small'
              repositoryId={gitHubRepository.id.toString()}
              onChange={(value) => {
                if (!_.isNil(value)) {
                  onChangeBranch(value);
                }
              }}
              pullRequests
            />
            {mode !== 'create' && (
              <FormHelperText>
                This will create a new version of this document on the selected
                branch.
              </FormHelperText>
            )}
          </Box>
        )}
        <FormControlLabel
          value='pr-open'
          control={<Radio size='small' />}
          label={
            <Typography variant='body2'>
              Create a new branch and start a pull request.
            </Typography>
          }
        />
        {['pr-draft', 'pr-open'].includes(branchType) && (
          <Box sx={{ mt: 1, pl: 3 }}>
            <TextField
              size='small'
              fullWidth
              value={branch}
              onChange={(e) => {
                const value = e.target.value;
                onChangeBranch(value);
                setLocalPrBranch(value);
              }}
              placeholder='Branch name'
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={branchType === 'pr-draft'}
                  size='small'
                  sx={{
                    p: 1,
                    pl: 1.5,
                  }}
                  onChange={(_e, checked) => {
                    if (checked) {
                      onChangeBranchType('pr-draft');
                    } else {
                      onChangeBranchType('pr-open');
                    }
                  }}
                />
              }
              disableTypography
              label={
                <Stack direction='row' spacing={0.5}>
                  <Typography variant='body2' sx={{ fontSize: '14px' }}>
                    Create as a draft
                  </Typography>
                  <InfoIcon
                    href='https://docs.github.com/en/pull-requests/collaborating-with-pull-requests/proposing-changes-to-your-work-with-pull-requests/about-pull-requests#draft-pull-requests'
                    tooltip='Click here to learn more about draft pull requests.'
                    placement='right'
                    size={16}
                  />
                </Stack>
              }
            />
          </Box>
        )}
      </RadioGroup>
    </FormControl>
  );
};

const CheckboxSkipCI: React.FC<{
  /**
   * If the checkbox is disabled.
   */
  disabled: boolean;

  /**
   * If the checkbox is checked.
   */
  checked: boolean;

  /**
   * Callback triggered when the user changes the skip CI checkbox.
   *
   * @param skipCi A boolean indicating whether to skip CI.
   */
  onChange: (skipCi: boolean) => void;
}> = ({ disabled, checked, onChange }) => {
  return (
    <FormControlLabel
      sx={{ ml: '-10px !important' }}
      disabled={disabled}
      checked={checked}
      control={
        <Checkbox
          size='small'
          onChange={(_e, checked) => {
            onChange(checked);
          }}
        />
      }
      label={
        <Typography variant='body2'>
          Skip CI checks for this commit, click{' '}
          <Link
            href='https://docs.github.com/en/actions/managing-workflow-runs/skipping-workflow-runs'
            target='_blank'
            rel='noreferrer noopener'
          >
            here
          </Link>{' '}
          to learn more.
        </Typography>
      }
    />
  );
};

const GitCommitRecommendations: React.FC<{
  /**
   * The current message.
   */
  currentMessage: string;

  /**
   * The list of recommendations to display.
   */
  recommendations: JoggrDocSaveMessageRecommendation[];

  /**
   * Callback to handle the selection of a recommendation.
   */
  onSelectRecommendation: (message: string) => void;
}> = ({ currentMessage, recommendations, onSelectRecommendation }) => {
  const theme = useTheme();

  const orderedRecommendations = React.useMemo(() => {
    return _.orderBy(recommendations, ['priority'], ['asc']);
  }, [recommendations]);

  return (
    <Stack direction='row' flexWrap='wrap' spacing={0.5} useFlexGap>
      {orderedRecommendations.map(({ message }) => (
        <Chip
          key={message}
          sx={{
            bgcolor:
              currentMessage === message
                ? theme.palette.action.selected
                : undefined,
          }}
          shape='rounded'
          size='medium'
          label={message}
          variant='outlined'
          onClick={() => {
            if (currentMessage !== message) {
              onSelectRecommendation(message);
            } else {
              // Allow toggling off the recommendation
              onSelectRecommendation('');
            }
          }}
        />
      ))}
    </Stack>
  );
};

/*
|------------------
| Components: Warnings & Helpers
|------------------
*/

/**
 * A warning to inform the user that they are about to commit directly to the default branch.
 */
const TextWarningCommitDefaultBranch: React.FC = () => {
  const theme = useTheme();
  return (
    <Stack
      sx={{ ml: '24px', mt: '-4px' }}
      direction='row'
      spacing={0.5}
      alignItems='center'
    >
      <IconAlertTriangle size={13} color={theme.palette.error.main} />
      <Typography color='error' fontSize='13px'>
        Committing directly to the default branch might bypass branch protection
        rules.
      </Typography>
    </Stack>
  );
};

/**
 * A warning to inform the user that they are about to overwrite the JoggrDoc on default/primary branch.
 */
const TextWarningOverwriteJoggrDoc: React.FC<{ branch: string }> = (props) => {
  return (
    <Typography variant='body2'>
      Overwrite the existing document on the <code>{props.branch}</code> branch.
    </Typography>
  );
};
