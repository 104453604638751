import React from 'react';

import {
  Box,
  Button,
  ButtonAnchor,
  Dialog,
  DialogContent,
  Divider,
  IconAlertTriangle,
  IconAlertTriangleFilled,
  IconDoorExit,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@joggrdocs/riker';

import { useStaticUrls } from '@stargate/hooks';
import { useStorageItem } from '@stargate/lib/storage';

/*
|==========================================================================
| MobileWarningFeedback
|==========================================================================
|
| Provide feedback to the user that the app is not optimized for mobile if screen size is "md" or smaller (mobile).
|
*/

export const MobileWarningFeedback: React.FC = () => {
  const theme = useTheme();
  const staticUrls = useStaticUrls();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const ignoreMobile = useStorageItem('user:ignore-mobile-warning', false);

  const handleIgnore = React.useCallback(() => {
    ignoreMobile.set(true);
  }, [ignoreMobile.set]);

  return (
    <Dialog open={matchDownMd && !ignoreMobile.data} fullScreen>
      <DialogContent>
        <Stack
          direction='row'
          spacing={2}
          alignItems='center'
          sx={{ mt: 1, mb: 2 }}
        >
          <Box
            component={IconAlertTriangleFilled}
            size={36}
            sx={{
              color: theme.palette.warning.main,
              fill: theme.palette.warning.main,
              stroke: theme.palette.warning.main,
            }}
          />
          <Typography variant='h2'>Mobile Warning</Typography>
        </Stack>
        <Typography variant='body1' fontSize='20px' gutterBottom>
          Joggr is not optimized for mobile devices. Please use a desktop or
          laptop computer for the best experience.
        </Typography>
        <Typography variant='body1' fontSize='20px' gutterBottom>
          If you really want to use Joggr on your mobile device, you can
          continue to the app, but you may experience some issues.
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Stack direction='row' spacing={2} sx={{ mt: 2 }}>
          <Button
            startIcon={<IconAlertTriangle />}
            onClick={handleIgnore}
            color='error'
            fullWidth
          >
            Continue to App
          </Button>
          <ButtonAnchor
            startIcon={<IconDoorExit />}
            href={staticUrls.marketingSite}
            color='success'
            fullWidth
          >
            Exit Joggr
          </ButtonAnchor>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default MobileWarningFeedback;
