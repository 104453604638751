import React from 'react';

import type { QuackrPlacement } from '../types';

export interface QuackrPlacementStyles {
  top: number | 'auto';
  left: number | 'auto';
  right: number | 'auto';
  bottom: number | 'auto';
  position: string;
}

/**
 * Calculate the styles for a Quackr placement.
 */
export const useQuackrPlacement = (
  placement: QuackrPlacement,
  options?: {
    /**
     * The gutter (in pixels) to apply to the placement.
     * @default 8
     */
    gutter?: number;
  }
): QuackrPlacementStyles => {
  return React.useMemo(() => {
    const gutter = options?.gutter ?? 8;
    return {
      top: placement.includes('top') ? gutter : 'auto',
      left: placement.includes('left') ? gutter : 'auto',
      right: placement.includes('right') ? gutter : 'auto',
      bottom: placement.includes('bottom') ? gutter : 'auto',
      position: 'fixed',
    };
  }, [placement, options?.gutter]);
};
