import * as hookz from '@react-hookz/web';
import React from 'react';

import { useApp } from '@stargate/app';
import {
  useGitHubOrganizations,
  useGitHubRepositories,
  useGitHubUrls,
} from '@stargate/features/github';
import { type User, useUser } from '@stargate/features/user';
import { useUserOnboarding } from '@stargate/features/user-onboarding';
import { useWorkspace } from '@stargate/features/workspaces';
import { useConfig } from '@stargate/hooks';
import { useDebugSession } from '@stargate/lib/debug-session';
import { useHttpErrors } from '@stargate/lib/http';
import { useNightingale } from '@stargate/lib/nightingale';
import { useDevLogger, useLogger } from '@stargate/logger';
import { useLocation } from '@stargate/routes';
import { useNavigate } from '@stargate/routes';
import { withAuthenticationRequired } from '@stargate/vendors/auth0';
import { useAuth0 } from '@stargate/vendors/auth0';
import { useSentry } from '@stargate/vendors/sentry';

/*
|==========================================================================
| ProtectedRouteElement
|==========================================================================
|
| This component is used to wrap all routes that require authentication.
|
*/

/*
|------------------
| Public API
|------------------
*/

export interface ProtectedRouteElementProps {
  element: React.ReactNode;
  skipOnboardingCheck?: boolean;
  user: User;
}

export const ProtectedRouteElement: React.FC<ProtectedRouteElementProps> = (
  props
) => {
  const workspace = useWorkspace();
  const userOnboarding = useUserOnboarding();
  const nightingale = useNightingale();
  const location = useLocation();
  const sentry = useSentry();
  const debugSession = useDebugSession();
  const previousLocation = hookz.usePrevious(location);
  const navigate = useNavigate();
  const logger = useLogger();

  /*
  |------------------
  | Utils
  |------------------
  */

  const getWorkspace = async () => {
    try {
      if (workspace.data) {
        return workspace.data;
      }
      return await workspace.refresh();
    } catch (err) {
      logger.error(
        {
          error: err,
          group: 'workspace',
        },
        'Failed to load workspace.'
      );
      sentry.captureException(err, {
        extra: {
          message: 'Failed to load workspace.',
        },
      });
      return null;
    }
  };

  /*
  |------------------
  | Effects
  |------------------
  */

  // biome-ignore lint/correctness/useExhaustiveDependencies: we only want to run this effect when the location changes.
  React.useEffect(() => {
    if (previousLocation?.pathname !== location.pathname) {
      nightingale.capturePageView(location);

      // We log the previous location as a page leave event.
      if (previousLocation) {
        nightingale.capturePageLeave(previousLocation);
      }

      // Refresh on every page change.
      debugSession.refresh();
    }
  }, [location.pathname]);

  hookz.useMountEffect(() => {
    const refreshedSession = debugSession.refresh();

    // Set the sentry data for the user.
    sentry.setUser({
      id: props.user.id,
      email: props.user.email,
      username: props.user.githubUsername ?? undefined,
    });
    sentry.setTag('workspace_id', props.user.activeWorkspaceId);
    sentry.setTag('debug_client_session_id', refreshedSession.sessionId);
    // Set the workspace tag for sentry if the workspace is loaded AND we have an active workspace.
    if (props.user.activeWorkspaceId) {
      void getWorkspace().then((workspace) => {
        if (workspace) {
          sentry.setTag('workspace', workspace.name);
        }
      });
    }

    nightingale.login(props.user).catch((err) => {
      logger.error(
        {
          error: err,
          group: 'analytics',
        },
        'Failed to login analytics user.'
      );
    });
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: we only want to run this effect when the user onboarding state changes.
  React.useEffect(() => {
    if (
      !userOnboarding.onboarded &&
      !userOnboarding.loading &&
      props.skipOnboardingCheck !== true
    ) {
      navigate('utility.onboarding');
    }
  }, [
    userOnboarding.onboarded,
    userOnboarding.loading,
    props.skipOnboardingCheck,
  ]);

  if (userOnboarding.onboarded || props.skipOnboardingCheck === true) {
    return props.element;
  }

  return null;
};

const WrappedProtectedRouteElement: React.FC<
  Omit<ProtectedRouteElementProps, 'user'>
> = (props) => {
  const authenticatedUser = useUser();
  const logger = useLogger();
  const devLogger = useDevLogger();
  const auth0 = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const httpError = useHttpErrors();
  const config = useConfig();
  const sentry = useSentry();
  const githubUrls = useGitHubUrls();

  const loadAuthenticatedUser = async () => {
    try {
      if (!authenticatedUser.data) {
        sentry.addBreadcrumb({
          type: 'query',
          category: 'user.load',
          message: 'Loading authenticated user',
          level: 'info',
        });
        await authenticatedUser.load();
      } else {
        sentry.addBreadcrumb({
          type: 'query',
          category: 'user.refresh',
          message: 'Refreshing authenticated user',
          level: 'info',
        });
        await authenticatedUser.refresh({ clearCache: false });
      }
    } catch (err) {
      // If we get a 401, we need to log the user out and redirect them to the login page.
      if (httpError.isHttpError(err) && err.statusCode === 401) {
        sentry.captureMessage('User unauthorized', 'debug');
        auth0.logout();
        return;
      }

      if (httpError.isHttpError(err) && err.statusCode === 403) {
        sentry.captureMessage('User does not have GitHub Access', 'debug');
        window.open(
          githubUrls.authorize({ clientId: config.githubApp.clientId }),
          '_self'
        );
        return;
      }

      sentry.captureException(err, {
        extra: {
          message: 'Failed to load authenticated user.',
        },
      });

      // All other errors should be logged and the user should be redirected to an error page.
      const ex =
        err instanceof Error
          ? err
          : new Error('Unknown error: Unable to authenticate user.');
      logger.error(
        {
          error: ex,
          group: 'auth',
        },
        'Failed to load authenticated user.'
      );
      throw ex;
    }
  };

  hookz.useMountEffect(() => {
    // If the user is already on the authorize page, we don't need to load the authenticated user.
    if (location.active('utility.authorize-github')) return;
    void loadAuthenticatedUser();
  });

  if (location.active('utility.authorize-github')) {
    return props.element;
  }

  if (!authenticatedUser.data) {
    devLogger.debug(
      {
        user: authenticatedUser.data,
        location,
      },
      'Unable to render route, user not loaded.'
    );
    return null;
  }

  return (
    <React.Fragment>
      <TriggerWarmCache user={authenticatedUser.data} />
      <ProtectedRouteElement {...props} user={authenticatedUser.data} />
    </React.Fragment>
  );
};

export default withAuthenticationRequired(WrappedProtectedRouteElement, {
  loadingFallback: () => <TriggerGlobalLoading />,
});

/*
|------------------
| Utils
|------------------
*/

/**
 * Warm the cache for the current user.
 */
const TriggerWarmCache = (props: { user: User }) => {
  const workspace = useWorkspace();
  const githubRepos = useGitHubRepositories();
  const githubOrgs = useGitHubOrganizations();

  hookz.useMountEffect(() => {
    // We only want to load the workspace and github
    // data if the user is authenticated & has an active workspace.
    if (props.user.activeWorkspaceId) {
      void workspace.load();
      void githubRepos.load();
      void githubOrgs.load();
    }
  });

  return null;
};

/**
 * Trigger a global loading state.
 */
const TriggerGlobalLoading = () => {
  const app = useApp();
  hookz.useMountEffect(() => {
    app.setLoading(true);
  });
  return null;
};
