import React from 'react';

import { Chip, type ChipProps } from '@joggrdocs/riker';

/*
|==========================================================================
| TagChip
|==========================================================================
|
| A chip that displays a tag, or a label with a color.
|
*/

export interface Tag {
  id: string;
  name: string;
  color: string;
  description?: string | undefined;
}

export interface TagChipProps extends Omit<ChipProps, 'label'> {
  tag: Tag;
}

const TagChip = React.forwardRef<HTMLDivElement, TagChipProps>((props, ref) => {
  const { tag, size = 'small', ...rest } = props;

  /*
  |------------------
  | Computed
  |------------------
  */
  const getSx = (props: TagChipProps) => {
    const { clickable } = props;

    if (clickable) {
      const hoverSx = {
        opacity: 0.8,
        bgcolor: tag.color,
      };
      return {
        cursor: 'pointer',
        '&:hover': hoverSx,
        '&:focus': hoverSx,
        '&:active': hoverSx,
      };
    }
    return {};
  };

  return (
    <Chip
      {...rest}
      ref={ref}
      sx={{
        ...rest.sx,
        ...getSx(props),
        color: 'white',
        fontWeight: 'bold',
        bgcolor: tag.color,
      }}
      size={size}
      label={tag.name}
    />
  );
});
TagChip.displayName = 'TagChip';
TagChip.muiName = 'Chip';

export default TagChip;
