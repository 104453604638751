import { Button, type ButtonProps, IconEdit } from '@joggrdocs/riker';
import { FormattedMessage } from '@stargate/localization';
import React from 'react';

export type EditButtonProps = ButtonProps;

export const EditButton = React.forwardRef<HTMLButtonElement, EditButtonProps>(
  (props, ref) => {
    const {
      color = 'secondary',
      variant = 'contained',
      children,
      ...restProps
    } = props;
    return (
      <Button
        {...restProps}
        ref={ref}
        startIcon={<IconEdit />}
        color={color}
        variant={variant}
      >
        {children || <FormattedMessage id='generic.edit' />}
      </Button>
    );
  }
);
EditButton.displayName = 'EditButton';
