import _ from 'lodash';
import React from 'react';

import {
  Alert,
  Button,
  Divider,
  IconButton,
  RikerIcon,
  Stack,
  Typography,
} from '@joggrdocs/riker';

import FileDropZone from '@stargate/components/Utils/FileDropZone';
import {
  ImageAltTextField,
  type ImageAltTextFieldProps,
} from './ImageAltTextField';

/*
|==========================================================================
| ImageUploadForm
|==========================================================================
|
| This component is used to upload an image to our CDN.
|
*/

export interface ImageFormUploadProps {
  loading?: boolean;
  alt?: string;
  onSave: (img: { file: File; alt: string }) => void;
}

export const ImageFormUpload: React.FC<ImageFormUploadProps> = ({
  onSave,
  ...props
}) => {
  const [error, setError] = React.useState<string | null>(null);
  const [imageFile, setImageFile] = React.useState<File | null>(null);
  const [altText, setAltText] = React.useState(props.alt ?? '');

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const handleChangeAltText = React.useCallback<
    ImageAltTextFieldProps['onChange']
  >((alt) => {
    setAltText(alt);
  }, []);

  const handleDrop = React.useCallback((files: File[]) => {
    setError(null);
    setImageFile(files[0]);
  }, []);

  const handleError = React.useCallback((error: Error) => {
    setError(error.message);
  }, []);

  const handleSave = React.useCallback(() => {
    if (!_.isNil(imageFile) && !_.isEmpty(altText)) {
      onSave({
        file: imageFile,
        alt: altText,
      });
    }
  }, [altText, imageFile, onSave]);

  const handleRemoveFile = React.useCallback(() => {
    setImageFile(null);
  }, []);

  return (
    <Stack
      spacing={1.5}
      justifyContent='center'
      sx={{
        textAlign: 'center',
      }}
    >
      {!_.isNil(error) && (
        <Alert severity='error' sx={{ mb: 1 }}>
          {error}
        </Alert>
      )}
      <ImageAltTextField alt={altText} onChange={handleChangeAltText} />
      {_.isNil(imageFile) && (
        <React.Fragment>
          <FileDropZone
            onDrop={handleDrop}
            onError={handleError}
            maxFiles={1}
            maxSize={5 * 1024 * 1024}
            accept={{
              'image/jpeg': ['.jpg', '.jpeg'],
              'image/png': ['.png'],
              'image/svg+xml': ['.svg'],
            }}
            size='small'
            dragActiveLabel='Drop image here'
            dragInactiveLabel='Drag and drop image here'
            icon={'photo-up'}
          />
        </React.Fragment>
      )}
      {!_.isNil(imageFile) && (
        <Stack spacing={1}>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='center'
            spacing={1}
            sx={{
              mt: 1,
            }}
          >
            <Stack
              direction='row'
              alignItems='center'
              spacing={0.5}
              sx={{
                ml: 1,
              }}
            >
              <RikerIcon name='photo' />
              <Typography variant='body2'>{imageFile.name}</Typography>
              <IconButton onClick={handleRemoveFile} size='small'>
                <RikerIcon name='x' />
              </IconButton>
            </Stack>
          </Stack>
        </Stack>
      )}
      <Divider flexItem />
      <Button
        sx={{ mt: 1 }}
        size='small'
        variant='outlined'
        fullWidth
        disabled={!_.every([!_.isNil(imageFile), !_.isEmpty(altText)])}
        onClick={handleSave}
        loading={props.loading === true}
      >
        Upload
      </Button>
      <Typography variant='caption' color='text.secondary'>
        5 mb max
      </Typography>
    </Stack>
  );
};
ImageFormUpload.displayName = 'ImageFormUpload';
