import Link from '@tiptap/extension-link';

export default Link.extend({
  addKeyboardShortcuts() {
    return {
      'Mod-k': () => {
        const { view, state } = this.editor;
        const { from, to } = view.state.selection;
        const text = state.doc.textBetween(from, to, '');
        return this.editor.commands.toggleLink({
          href: text,
        });
      },
    };
  },
}).configure({
  HTMLAttributes: {
    target: '_blank',
    rel: 'noopener noreferrer',
    class: 'dashdraft-link',
  },
});
