import {
  type NavigateOptions as RRNavigateOptions,
  useNavigate as useRRNavigate,
} from 'react-router-dom';
import type * as TF from 'type-fest';

import {
  type RouteNames,
  type RouteParams,
  type RouteSearch,
  getUrl,
} from './use-route';

/*
|==========================================================================
| useNavigate
|==========================================================================
|
| Type-safe wrapper around react-router's useNavigate hook.
|
*/

export type NavigateOptions<Name extends RouteNames> = BaseNavigateOptions & {
  /**
   * Route parameters, based on the route's path.
   */
  params?: RouteParams<Name>;

  /**
   * Route search parameters, based on the route's search.
   */
  search?: RouteSearch<Name>;
};

/**
 * Type-safe wrapper around react-router's useNavigate hook.
 *
 * @returns Type-safe navigate function
 */
export const useNavigate = () => {
  const nav = useRRNavigate();

  /**
   * Navigate to a route by name.
   *
   * @param routeName The name of the route to navigate to
   * @param options Navigation options i.e. Params and Search
   */
  const navigate = <Name extends RouteNames>(
    routeName: Name,
    options?: TF.Simplify<NavigateOptions<Name>>
  ) => {
    const { params, search, openInNewTab, ...navOptions } = options || {};
    const url = getUrl(routeName, params, search);
    if (openInNewTab) {
      window.open(url, '_blank');
    } else {
      nav(url, navOptions);
    }
  };

  return navigate;
};

/*
|------------------
| Utils
|------------------
*/

type ReactRouterNavigateOptions = RRNavigateOptions & {
  /**
   * If you pass ReactRouter NavigateOptions, you cannot open in a new tab.
   */
  openInNewTab?: false;
};

type WindowNavigateOptions = {
  /**
   * Open the route in a new tab.
   */
  openInNewTab: true;
};

type BaseNavigateOptions = ReactRouterNavigateOptions | WindowNavigateOptions;
