import { Box, CircularProgress, Stack, useTheme } from '@joggrdocs/riker';
import React from 'react';

import { useUser } from '@stargate/features/user';
import { generateComponentClasses } from '@stargate/theme';
import { useInterval, useTimeout } from 'usehooks-ts';

export const loadingConsoleClasses = generateComponentClasses(
  'LoadingConsole',
  ['root', 'words', 'dollar', 'cursor', 'cursor-hidden'] as const
);

export interface LoadingConsoleProps {
  /**
   * Whether or not to show the loading console.
   * @default false
   */
  loading?: boolean;

  /**
   * How much to delay the typing effect.
   * @default 0
   */
  delay?: number;
}

export const LoadingConsole: React.FC<LoadingConsoleProps> = ({
  loading = false,
  delay = 0,
}) => {
  if (!loading) {
    return null;
  }
  return <LoadingConsoleInner delay={delay} />;
};

const LoadingConsoleInner: React.FC<
  Pick<Required<LoadingConsoleProps>, 'delay'>
> = ({ delay }) => {
  const theme = useTheme();
  const user = useUser();
  const [typingContent, setTypingContent] = React.useState<string>('');
  const [contentIndex, setContentIndex] = React.useState<number>(0);
  const [allowTyping, setAllowTyping] = React.useState<boolean>(false);

  const userName = React.useMemo(() => {
    return user?.data?.githubUsername || 'user';
  }, [user]);

  const updateTypingContent = React.useCallback(() => {
    if (!allowTyping) {
      return;
    }

    const idx = typingContent.length;
    if (CONTENT_TYPING !== typingContent) {
      setTypingContent([typingContent, CONTENT_TYPING[idx]].join(''));
    }
  }, [typingContent, allowTyping]);

  useTimeout(() => {
    setAllowTyping(true);
  }, delay);

  useInterval(updateTypingContent, allowTyping ? 76 : null);

  useInterval(
    () => {
      setContentIndex((prev) => {
        if (prev === CONTENT_LOADING.length - 1) {
          return 0;
        }
        return prev + 1;
      });
    },
    allowTyping ? 3000 : null
  );

  return (
    <Stack
      className={loadingConsoleClasses.root}
      sx={{
        backgroundColor: theme.palette.grey[900],
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '6px',
        width: '524px',
        minHeight: '48px',
        px: 2,
        py: 1,
        [`& .${loadingConsoleClasses.dollar}`]: {
          color: theme.palette.primary.light,
          pr: 1,
        },
        [`& .${loadingConsoleClasses.cursor}`]: {
          height: '16px',
          width: '8px',
          backgroundColor: theme.palette.text.primary,
          opacity: 1,
          // transition: 'opacity 0s',
          [`&.${loadingConsoleClasses['cursor-hidden']}`]: {
            opacity: 0,
          },
        },
      }}
      direction='row'
      alignItems='center'
      justifyContent='normal'
      flexWrap='wrap'
    >
      <Box className={loadingConsoleClasses.dollar}>{userName}@web:~ $</Box>
      <Box className={loadingConsoleClasses.words}>{typingContent}</Box>
      {typingContent === CONTENT_TYPING ? (
        <React.Fragment>
          <Box sx={{ width: '100%', flexGrow: 1 }} />
          <CircularProgress size={16} sx={{ mr: 1 }} />
          <Box className={loadingConsoleClasses.words}>
            {CONTENT_LOADING[contentIndex]}
          </Box>
        </React.Fragment>
      ) : (
        ''
      )}
      <Box className={loadingConsoleClasses.cursor} />
    </Stack>
  );
};

/*
|------------------
| Utils
|------------------
*/

// @todo switch to localization after we fix the localization issue
// @link https://linear.app/joggr/issue/ENG-2280
const CONTENT_TYPING = 'joggr init --verbose     ';

// @todo switch to localization after we fix the localization issue
// @link https://linear.app/joggr/issue/ENG-2280
const CONTENT_LOADING = [
  // Initial System Boot
  'Initializing Galactic Knowledge Repository...',
  'Establishing quantum connections to documentation nodes...',
  'Calibrating neural documentation networks...',

  // Gathering Knowledge
  'Scanning local star systems for documentation fragments...',
  'Collecting tribal knowledge from across the cosmos...',
  'Extracting wisdom from ancient Stack Overflow scrolls...',
  'Intercepting documentation signals from parallel universes...',

  // Processing
  'Converting scattered knowledge into structured data...',
  'Translating documentation from 2,147 alien languages...',
  'Upgrading markdown to quantumdown format...',
  'Compressing infinite wisdom into digestible chunks...',

  // AI Integration
  'Teaching AI archivists proper documentation etiquette...',
  'Training neural networks in technical writing...',
  'Synchronizing human and AI writing styles...',
  'Optimizing content for carbon and silicon-based readers...',

  // Quality Control
  'Detecting temporal documentation paradoxes...',
  'Scanning for outdated knowledge anomalies...',
  'Validating cross-dimensional references...',
  'Quantum spell-checking in progress...',

  // Final Steps
  'Reorganizing knowledge base across space-time...',
  'Generating AI-powered improvement suggestions...',
  'Calculating final documentation entropy levels...',
  'Preparing knowledge transfer protocols...',
  'Opening the gates to infinite wisdom...',
];
