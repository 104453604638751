import { CardContent, type CardContentProps, useTheme } from '@joggrdocs/riker';
import React from 'react';

import { generateComponentClasses } from '@stargate/theme';

export const codeCardContentClasses =
  generateComponentClasses('CodeCardContent');

export type CodeCardContentProps = CardContentProps;

/**
 * CardContent component for code.
 */
export const CodeCardContent = React.forwardRef<
  HTMLDivElement,
  CodeCardContentProps
>((props, ref) => {
  const theme = useTheme();
  return (
    <CardContent
      {...props}
      ref={ref}
      classes={codeCardContentClasses}
      sx={{
        ...props.sx,
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(2),
        background: 'transparent',
        position: 'relative',
        overflowX: 'auto',
      }}
    />
  );
});
CodeCardContent.displayName = 'CodeCardContent';
