import {
  Box,
  IconButton,
  IconChevronRight,
  LinearProgress,
  Skeleton,
  Stack,
} from '@joggrdocs/riker';
import * as hookz from '@react-hookz/web';
import _ from 'lodash';
import React from 'react';

import {
  type GitHubOrganization,
  GitHubOrganizationSelect,
  type GitHubRepository,
  GitHubRepositoryAutocomplete,
  GitHubRepositoryBranchAutocomplete,
  useGitHubOrganizations,
  useGitHubRepositories,
} from '@stargate/features/github';
import { generateComponentClasses } from '@stargate/theme';

import type { CodeExplorerQuery } from '../../types';

export const codeExplorerHeaderClasses = generateComponentClasses(
  'CodeExplorerHeader',
  ['root']
);

export interface CodeExplorerHeaderProps {
  /**
   * The default organization ID to select.
   */
  defaultGitHubOrganizationId?: string;

  /**
   * The default repository ID to select.
   */
  defaultGitHubRepositoryId?: string;

  /**
   * The query for the CodeExplorer
   */
  query?: CodeExplorerQuery;

  /**
   * Callback when the query changes
   */
  onChangeQuery: (query: Partial<CodeExplorerQuery>) => void;

  /**
   * Callback when the CodeExplorer is closed
   */
  onClose: () => void;

  /**
   * Whether the CodeExplorer is readonly
   */
  readonly?: boolean;
}

export const CodeExplorerHeader: React.FC<CodeExplorerHeaderProps> = ({
  readonly = false,
  onChangeQuery,
  query,
  onClose,
}) => {
  const ghOrgs = useGitHubOrganizations();
  const ghRepos = useGitHubRepositories();

  /*
  |------------------
  | Computed
  |------------------
  */

  const defaultRepository = React.useMemo(() => {
    if (!_.isNil(query?.githubRepositoryId)) {
      const foundRepo = ghRepos.data.find(
        (repo) => repo.id.toString() === query?.githubRepositoryId
      );

      if (!_.isNil(foundRepo)) {
        return foundRepo;
      }
    }
  }, [query?.githubRepositoryId, ghRepos.data]);

  const defaultGitHubOrganization = React.useMemo(() => {
    const ghOrgId = defaultRepository?.owner.id ?? query?.githubOrganizationId;
    if (!_.isNil(ghOrgId)) {
      const foundOrg = findGitHubOrganization(ghOrgs.data, ghOrgId);
      if (!_.isNil(foundOrg)) {
        return foundOrg;
      }
    }
    // Force to select the only organization if there is only one
    if (ghOrgs.data.length === 1) {
      return ghOrgs.data[0];
    }
  }, [defaultRepository, query?.githubOrganizationId, ghOrgs.data]);

  const repositoryOwnerIds = React.useMemo(() => {
    return defaultGitHubOrganization
      ? [defaultGitHubOrganization.id.toString()]
      : [];
  }, [defaultGitHubOrganization]);

  /*
  |------------------
  | Effects
  |------------------
  */

  hookz.useMountEffect(() => {
    void ghOrgs.load();
    void ghRepos.load();
  });

  return (
    <Box className={codeExplorerHeaderClasses.root}>
      <Stack
        direction='row'
        justifyContent='center'
        alignItems='center'
        spacing={1}
        sx={{
          ml: -1,
        }}
      >
        <IconButton onClick={onClose}>
          <IconChevronRight />
        </IconButton>
        {ghOrgs.loading || ghRepos.loading ? (
          <SelectSkeleton />
        ) : (
          <GitHubOrganizationSelect
            id='github-organization-select'
            options={ghOrgs.data}
            defaultValue={defaultGitHubOrganization}
            size='small'
            disabled={readonly}
            onChange={(org) => {
              onChangeQuery({ githubOrganizationId: org.id.toString() });
            }}
          />
        )}
        {ghRepos.loading ? (
          <React.Fragment>
            <SelectSkeleton />
            <SelectSkeleton />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <GitHubRepositoryAutocomplete
              size='small'
              disabled={readonly || repositoryOwnerIds.length === 0}
              repositoryOwnerIds={repositoryOwnerIds}
              defaultValue={defaultRepository}
              onChange={(repo) => {
                if (!_.isNil(repo)) {
                  onChangeQuery({
                    githubOrganizationId: repo.owner.id.toString(),
                    githubRepositoryId: repo.id.toString(),
                    branch: repo.defaultBranch,
                  });
                } else {
                  onChangeQuery({
                    githubRepositoryId: null,
                    branch: null,
                  });
                }
              }}
            />
            <GitHubRepositoryBranchAutocomplete
              size='small'
              disabled={readonly || _.isNil(query?.githubRepositoryId)}
              repositoryId={query?.githubRepositoryId ?? undefined}
              defaultBranch={
                query?.branch ?? defaultRepository?.defaultBranch ?? undefined
              }
              onChange={(branch) => {
                onChangeQuery({ branch });
              }}
            />
          </React.Fragment>
        )}
      </Stack>
      {ghOrgs.loading && (
        <LinearProgress
          sx={{
            position: 'absolute',
            bottom: '-1px',
            left: 0,
            right: 0,
            height: '2px',
          }}
        />
      )}
    </Box>
  );
};

/*
|------------------
| Utils
|------------------
*/

const SelectSkeleton = () => {
  return (
    <Skeleton
      variant='rounded'
      sx={{
        display: 'inline-block',
        minWidth: '180px',
        width: '100%',
        height: '32px',
        borderRadius: '4px',
      }}
    />
  );
};

/**
 * Find a GitHub organization by ID.
 *
 * @param organizations
 * @param id
 * @returns a GitHub organization or null if not found
 */
const findGitHubOrganization = (
  organizations: GitHubOrganization[],
  id: string | number
): GitHubOrganization | null => {
  return (
    organizations.find((org) => org.id.toString() === id.toString()) ?? null
  );
};

/**
 * Get the default branch for a given repository.
 *
 * @param repositories
 * @param repositoryId
 * @returns
 */
const getDefaultBranch = (
  repositories: GitHubRepository[],
  repositoryId: string
): string | undefined => {
  const foundRepo = repositories.find((repo) => repo.id === repositoryId);
  return foundRepo?.defaultBranch;
};
