import { type Extension, RangeSetBuilder } from '@codemirror/state';
import {
  Decoration,
  type DecorationSet,
  type EditorView,
  ViewPlugin,
  type ViewUpdate,
} from '@codemirror/view';

import { getTopDownSelectionLines } from '../lib/helpers';

/*
|==========================================================================
| highlightEntireLine
|==========================================================================
|
| Highlight the entire line of the selection, vs the default behavior of
| only highlighting the selection marks on the line of the selection.
|
*/

/**
 * A decoration that highlights the entire line of the selection.
 *
 * NOTE: We are leveraging the "activeLine" class vs the "selection" class because the "selection" class
 *   is applied to a layer that is behind the text layer, and the "activeLine" class is applied to the actual
 *   line of text.
 */
const activeLineDecoration = Decoration.line({ class: 'cm-activeLine' });

/**
 * Create a decoration set that highlights the entire line of the selection
 *
 * @param view A codemirror view
 * @returns A decoration set that highlights the entire line of the selection
 */
function addActiveLineDecoration(view: ViewUpdate) {
  const builder = new RangeSetBuilder<Decoration>();
  const { start: startLine, end: endLine } = getTopDownSelectionLines(view);

  for (let pos = startLine.from; pos <= endLine.from; ) {
    const currentLine = view.state.doc.lineAt(pos);
    builder.add(currentLine.from, currentLine.from, activeLineDecoration);
    pos = currentLine.to + 1;
  }

  return builder.finish();
}

/**
 * Highlight the entire line of the selection, vs the default behavior of only highlighting the selection
 * marks on the line of the selection.
 *
 * @returns An extension that highlights the entire line of the selection
 */
function highlightEntireLine(): Extension {
  return ViewPlugin.fromClass(
    class HighlightEntireLine {
      decorations = Decoration.none;

      update(update: ViewUpdate) {
        if (update.selectionSet) {
          this.decorations = addActiveLineDecoration(update);
        }
      }
    },
    {
      decorations: (v) => v.decorations,
    }
  );
}

export default highlightEntireLine;
