import _ from 'lodash';
import type * as TF from 'type-fest';

import { type DashDraftJSONContent, generateText } from '@stargate/dashdraft';
import type { JDocDraft, JDocMode } from '@stargate/features/docs/types';

import { fields } from './fields';
import type { JDocModifiableField } from './types';

/**
 * Compare a JoggrDoc and a JoggrDocDraft to find the modified fields
 *
 * @param doc A JoggrDoc
 * @param draft A JoggrDocDraft
 * @param mode A JoggrDocMode
 * @returns An array of modified fields with the two values being compared
 */
export const getModifiedFields = (
  mode: JDocMode,
  doc?: JDocDraft | null,
  draft?: JDocDraft | null
): ModifiedFieldResult[] => {
  const modified = [];

  if (!_.isNil(draft)) {
    for (const docField of fields.modifiable) {
      // View will NEVER have modified fields
      if (mode === 'view') {
        continue;
      }

      // If the doc is Nil than we will NEVER have modified fields
      if (mode === 'edit' && _.isNil(doc)) {
        continue;
      }

      const existingValue = _.get(doc, docField);
      const draftValue = _.get(draft, docField);
      if (
        mode === 'edit' &&
        _.some([
          _.isNil(draftValue),
          _.isNil(existingValue),
          compareFields(docField, existingValue, draftValue),
        ])
      ) {
        continue;
      }

      // For create we just need to make sure the field is not nil
      if (mode === 'create' && _.isNil(draftValue)) {
        continue;
      }

      // If we get here then the field was modified
      modified.push({
        field: docField,
        currentValue: existingValue,
        newValue: draftValue,
      });
    }
  }

  return modified;
};

export type ModifiedFieldResult<
  F extends JDocModifiableField = JDocModifiableField,
> = {
  field: F;
  currentValue: TF.Get<JDocDraft, F>;
  newValue: TF.Get<JDocDraft, F>;
};

/*
|------------------
| Utils
|------------------
*/

/**
 * Compare two values to see if they are equal
 *
 * @param a A string or number
 * @param b A string or number
 * @returns A boolean if the two values are equal
 */
const compareFields = (
  field: JDocModifiableField,
  a: string | number | DashDraftJSONContent | undefined,
  b: string | number | DashDraftJSONContent | undefined
) => {
  if (field === 'content') {
    return isContentEqual(a as DashDraftJSONContent, b as DashDraftJSONContent);
  }

  return a?.toString() === b?.toString();
};

/**
 * Compare two DashDraftJSONContent objects to see if they are equal (we remove empty paragraphs at end, since its due to an extension)
 *
 * @param a A DashDraftJSONContent
 * @param b A DashDraftJSONContent
 * @returns True if the content is equal
 */
const isContentEqual = (a?: DashDraftJSONContent, b?: DashDraftJSONContent) => {
  const aContentClean = _.isNil(a?.content) ? [] : a.content;
  const bContentClean = _.isNil(b?.content) ? [] : b.content;

  const aContent =
    _.last(aContentClean)?.type === 'paragraph' &&
    _.isNil(_.last(aContentClean)?.content)
      ? aContentClean.slice(0, -1)
      : aContentClean;
  const bContent =
    _.last(bContentClean)?.type === 'paragraph' &&
    _.isNil(_.last(bContentClean)?.content)
      ? bContentClean.slice(0, -1)
      : bContentClean;

  const aText = !_.isNil(a) ? generateText({ ...a, content: aContent }) : '';
  const bText = !_.isNil(b) ? generateText({ ...b, content: bContent }) : '';

  return _.isEqual(aText, bText);
};
