import _ from 'lodash';
import React from 'react';
import type * as TypeFest from 'type-fest';

import { type HttpError, useHttpErrors } from '@stargate/lib/http';

import type { APIContract, APIPayload, APIResponse, APIRoute } from '../types';
import {
  type APIClientCacheStoreState,
  createClientCacheStore,
} from './client-cache-store-factory';
import { useAPIRequestClient } from './use-api-request-client';

export interface APIClientFactoryHookState<Data>
  extends APIClientCacheStoreState<Data> {}

export interface APIClientFactoryHookActions<Data, Args> {
  /**
   * Execute the API call.
   *
   * @returns The data from the API.
   */
  execute: (args: Args) => Promise<Data>;

  /**
   * Abort the API call.
   *
   * @returns Abort the API call.
   */
  abort: () => void;

  /**
   * Refresh the data from the API, without changing the status.
   *
   * @returns The data from the API.
   */
  refresh: (args: Args) => Promise<Data>;

  /**
   * Reset the state for the API call.
   */
  reset: () => void;

  /**
   * Force the error state for the API call.
   */
  forceError(error: unknown): void;
}

export type APIClientFactoryHookResult<Route extends APIRoute> = TypeFest.Merge<
  APIClientFactoryHookState<APIResponse<Route>>,
  APIClientFactoryHookActions<APIResponse<Route>, APIPayload<Route>>
>;

export type APIClientFactoryHook<Route extends APIRoute> =
  () => APIClientFactoryHookResult<Route>;

/**
 * Create a new API client with a built-in cache (global store).
 *
 * @example
 *  const useReadDocs = createClient('GET /documents');
 *
 *  const Foobar = () => {
 *    const readDocs = useReadDocs();
 *
 *    return (
 *     <div>
 *      {readDocs.status === 'loading' && <p>Loading...</p>}
 *      {readDocs.error && <p>Error: {state.error.message}</p>}
 *      {readDocs.data && <p>Data: {state.data}</p>}
 *    </div>
 *  };
 *
 * @deprecated Use 'tanstack/react-query' instead.
 * @param route A schema name, in the format of `METHOD /path/to/resource`, e.g. `GET /documents`.
 * @returns A built API client with a built-in cache.
 */
export const createCachedClient = <Route extends APIRoute>(config: {
  route: Route;
  defaultValue?: APIContract<Route>['response'];
}): APIClientFactoryHook<Route> => {
  const useClientStore = createClientCacheStore(config.route);
  return () => {
    const [, apiActions] = useAPIRequestClient(config.route);
    const store = useClientStore();
    const httpErrors = useHttpErrors();

    const forceError = React.useCallback<
      APIClientFactoryHookResult<Route>['forceError']
    >(
      (error) => {
        if (error instanceof Error || httpErrors.isHttpError(error)) {
          store.setError(error);
        } else {
          store.setError(new Error('Unknown error'));
        }
      },
      [store, httpErrors.isHttpError]
    );

    const execute = React.useCallback<
      APIClientFactoryHookResult<Route>['execute']
    >(
      async (args) => {
        try {
          store.setStatus('loading');
          const result =
            (await apiActions.execute(args)) ?? config.defaultValue ?? null;
          store.setData(result);
          return result;
        } catch (error) {
          const ex = toError(error);
          store.setError(ex);
          throw ex;
        }
      },
      [apiActions, store, config]
    );

    const refresh = React.useCallback<
      APIClientFactoryHookResult<Route>['refresh']
    >(
      async (args) => {
        try {
          const result =
            (await apiActions.execute(args)) ?? config.defaultValue ?? null;
          store.setData(result);
          return result;
        } catch (error) {
          const ex = toError(error);
          store.setError(ex);
          throw ex;
        }
      },
      [apiActions, store, config]
    );

    const reset = React.useCallback<
      APIClientFactoryHookResult<Route>['reset']
    >(() => {
      store.clearData();
      store.clearError();
    }, [store]);

    return React.useMemo<APIClientFactoryHookResult<Route>>(() => {
      return {
        data: store.data ?? null,
        error: store.error,
        status: store.status,
        createdAt: store.createdAt,
        updatedAt: store.updatedAt,
        erroredAt: store.erroredAt,
        abort: apiActions.abort,
        execute,
        refresh,
        reset,
        forceError,
      };
    }, [store, execute, reset, refresh, forceError, apiActions.abort]);
  };
};

/*
|------------------
| Utils
|------------------
*/

const toError = (error: unknown): HttpError | Error => {
  if (error instanceof Error) {
    return error;
  }

  return new Error('Unknown error');
};
