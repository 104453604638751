import React from 'react';
import type * as TypeFest from 'type-fest';

import {
  RikerIcon,
  type RikerIconComponent,
  type RikerIconProps,
} from '@joggrdocs/riker';

/*
|==========================================================================
| DirectoryDeleteIcon 
|==========================================================================
|
| Proxy component for the RikerIcon component.
|
*/

export type DirectoryDeleteIconProps = TypeFest.Simplify<
  Omit<RikerIconProps, 'name'>
>;

export const directoryDeleteIconName = 'trash';

/**
 * Icon for deleting a directory.
 */
export const DirectoryDeleteIcon = React.forwardRef<
  RikerIconComponent,
  DirectoryDeleteIconProps
>((props, ref) => (
  <RikerIcon {...props} ref={ref} name={directoryDeleteIconName} />
));
