import { Node, mergeAttributes, nodeInputRule } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import type * as TypeFest from 'type-fest';

import { ImageNodeView } from '../components/Image/ImageNodeView';
import type { ImageNodeAttributes } from '../types';

/*
|==========================================================================
| image
|==========================================================================
|
| Custom extension for embedding images, copied from TipTap's example and modified.
|
| @link https://github.com/ueberdosis/tiptap/tree/develop/packages/extension-image
|
*/

export type SetImageOptions = Required<ImageNodeAttributes>['options'];

export type SetImagePayload = TypeFest.SetRequired<
  Omit<ImageNodeAttributes, 'options'>,
  'src'
>;

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    image: {
      /**
       * Add an image
       *
       * @param payload A payload object containing the src, alt, and title of the image.
       * @param options An options object containing a forceOpen boolean.
       */
      setImage: (
        payload: SetImagePayload,
        options?: SetImageOptions
      ) => ReturnType;

      /**
       * Add a blank image
       *
       * @param options A options object containing a forceOpen boolean.
       */
      setEmptyImage: (options?: SetImageOptions) => ReturnType;
    };
  }
}

export const Image = Node.create({
  name: 'image',

  inline: false,

  group: 'block',

  draggable: true,

  addNodeView() {
    // @todo fix types here or in TipTap
    // @ts-expect-error - types broken due to TipTap, need to fix
    return ReactNodeViewRenderer(ImageNodeView);
  },

  addAttributes() {
    return {
      src: {
        default: null,
      },
      alt: {
        default: null,
      },
      title: {
        default: null,
      },
      options: {
        default: { forceOpen: false },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'img[src]:not([src^="data:"])',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'img',
      mergeAttributes(
        { className: 'dashdraft-media dashdraft-media-image' },
        HTMLAttributes
      ),
    ];
  },

  addCommands() {
    return {
      setImage:
        (payload, options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: {
              ...payload,
              options,
            },
          });
        },
      setEmptyImage:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: {
              src: '',
              alt: '',
              title: '',
              options,
            },
          });
        },
    };
  },

  addInputRules() {
    return [
      nodeInputRule({
        find: /(?:^|\s)(!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\))$/,
        type: this.type,
        getAttributes: (match) => {
          const [, , alt, src, title] = match;

          return { src, alt, title };
        },
      }),
    ];
  },
}).configure();
