import React from 'react';

import {
  Box,
  IconFilePlus,
  Select,
  Stack,
  Typography,
  useTheme,
} from '@joggrdocs/riker';

import { ButtonLink } from '@stargate/components/Buttons';
import { ComingSoonChip } from '@stargate/components/Chips';
import { Img } from '@stargate/components/Images';

function PullRequest() {
  const theme = useTheme();
  return (
    <Stack spacing={2} direction='row'>
      <Box>
        <Typography variant='h3' gutterBottom>
          Create from a Pull Request <ComingSoonChip />
        </Typography>
        <Typography variant='body1' gutterBottom>
          Create a new document based on the code changes and comments in a Pull
          Request.
        </Typography>
        <Box sx={{ mb: 2 }} />
        <Stack spacing={1} direction='row'>
          <Select
            size='small'
            displayEmpty
            renderValue={() => 'Select a Repository'}
            disabled
          />
          <Select
            size='small'
            displayEmpty
            renderValue={() => 'Select a Pull Request'}
            disabled
          />
        </Stack>
        <Box sx={{ mb: 2 }} />
        <ButtonLink
          startIcon={<IconFilePlus />}
          variant='contained'
          to='app.documents.create'
          search={{ blank: true }}
          disabled
        >
          Create a new doc
        </ButtonLink>
      </Box>
      <Img
        src={`/features/shortcuts/examples-pr-${theme.palette.mode}.png`}
        alt='PR Doc'
        sx={{
          maxWidth: 400,
        }}
      />
    </Stack>
  );
}

export default PullRequest;
