import * as hookz from '@react-hookz/web';
import _ from 'lodash';
import React from 'react';

import {
  Box,
  type Breakpoint,
  Dialog,
  DialogActions,
  DialogTitle,
  type RikerIconName,
  useTheme,
} from '@joggrdocs/riker';

import { IconAvatar } from '@stargate/components/Avatars';
import { generateComponentClasses } from '@stargate/theme';

import type { LoomOembed } from '../hooks/use-loom-oembed';
import { LoomVideo } from './LoomVideo';

/*
|==========================================================================
| LoomDialog
|==========================================================================
|
| A dialog component that displays a Loom video.
|
*/

export type VideoSize = Breakpoint;

const loomDialogClasses = generateComponentClasses('LoomDialog', [
  'root',
  'title',
  'content',
  'actions',
] as const);

export interface LoomDialogProps {
  loom: string | LoomOembed;
  icon?: RikerIconName;
  size?: VideoSize;
  actions?: React.ReactNode;
  onClose: () => void;
  open?: boolean;
  title?: string;
  autoPlay?: boolean;
}

/*
|------------------
| Utils
|------------------
*/

const getTitle = (props: Pick<LoomDialogProps, 'loom' | 'title'>): string => {
  if (!_.isNil(props.title)) {
    return props.title;
  }
  if (
    !_.isNil(props.loom) &&
    !_.isString(props.loom) &&
    Object.hasOwn(props.loom, 'title')
  ) {
    return props.loom.title;
  }
  return 'Loom Video';
};

export const LoomDialog: React.FC<LoomDialogProps> = ({
  size = 'md',
  loom,
  open = true,
  actions,
  title,
  icon,
  autoPlay = false,
  onClose,
}) => {
  const theme = useTheme();
  const windowSize = hookz.useWindowSize();
  const minWidth = Math.min(windowSize.width, theme.breakpoints.values[size]);
  const minHeight = minWidth / (4 / 3);

  return (
    <Dialog
      open={open}
      classes={{
        root: loomDialogClasses.root,
      }}
      onClose={onClose}
      maxWidth='xl'
      PaperProps={{
        sx: {
          minHeight,
          minWidth,
          p: 0,
          overflowY: 'unset',
        },
      }}
    >
      <Box className={loomDialogClasses.content}>
        <DialogTitle
          startIcon={
            !_.isNil(icon) && (
              <IconAvatar icon={icon} backgroundColor='primary' />
            )
          }
          classes={{
            root: loomDialogClasses.title,
          }}
          title={getTitle({
            loom,
            title,
          })}
          onClose={onClose}
        />
        <LoomVideo width={minWidth} loom={loom} autoPlay={autoPlay} />
      </Box>
      {!_.isNil(actions) && (
        <DialogActions classes={{ root: loomDialogClasses.actions }}>
          {actions}
        </DialogActions>
      )}
    </Dialog>
  );
};
