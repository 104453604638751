import * as hookz from '@react-hookz/web';
import _ from 'lodash';
import React from 'react';

import { Backdrop, type BackdropProps, Box } from '@joggrdocs/riker';

import { generateComponentClasses } from '@stargate/theme';

import LoadingLogo from './LoadingLogo';

export const loadingOverlayClasses = generateComponentClasses(
  'LoadingOverlay',
  ['root', 'logo']
);

export interface LoadingOverlayProps {
  /**
   * The variant of the loading overlay.
   *
   * @default 'full'
   */
  variant: 'full' | 'contained';

  /**
   * Whether or not to show the loading overlay.
   *
   * @default true
   */
  loading: boolean;

  /**
   * The children. If provided, the overlay will be contained within the children.
   */
  children?: React.ReactNode;

  /**
   * The time in milliseconds to wait before showing a timeout error.
   *
   * @default 0 executes immediately
   */
  timeout?: number;

  /**
   * A callback to call when the timeout is reached.
   */
  onTimeout?: () => void;

  /**
   * The component to display while loading.
   */
  loadingComponent?: React.ReactNode;

  /**
   * Props to pass to the Backdrop component.
   */
  BackdropProps?: BackdropProps;
}
/**
 * LoadingOverlay provides the ability to display a loading indicator over the entire window
 * or a contained element (MUST have position relative to work for contained element).
 */
export const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
  variant = 'full',
  loading = true,
  children = null,
  timeout = 0,
  onTimeout,
  loadingComponent = null,
  BackdropProps,
}) => {
  const previousLoading = hookz.usePrevious(loading);

  const [cancelTimeout, resetTimeout] = hookz.useTimeoutEffect(() => {
    onTimeout?.();
  }, timeout);

  React.useEffect(() => {
    if (loading && (previousLoading !== loading || _.isNil(previousLoading))) {
      resetTimeout();
    } else {
      cancelTimeout();
    }
  }, [loading, previousLoading, cancelTimeout, resetTimeout]);

  return (
    <Box
      sx={{
        position: !_.isNil(children) ? 'relative' : undefined,
        width: 'fit-content',
        height: 'fit-content',
      }}
    >
      <Backdrop
        {...BackdropProps}
        open={loading}
        sx={{
          ...(BackdropProps?.sx ?? {}),
          position: variant === 'full' ? 'fixed' : 'absolute',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Box>{loadingComponent ?? <LoadingLogo loading />}</Box>
        </Box>
      </Backdrop>
      {children}
    </Box>
  );
};

export default LoadingOverlay;
