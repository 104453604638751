import React from 'react';

import { useConfig } from '@/hooks';
import {
  Quackr,
  type QuackrDevtools,
  useQuackr,
  useQuackrConfigValue,
} from '@/lib/quackr';
import { useSpotlight } from '@/vendors/sentry';

/**
 * Pre-configured Quackr component for development environments
 */
export const Devtools = () => {
  const config = useConfig();
  const spotlight = useSpotlight();
  const spotlightEnabled = useQuackrConfigValue('sentry', 'spotlight');
  const [, quackrActions] = useQuackr();

  const devtools = React.useMemo<QuackrDevtools>(() => {
    const devtools: QuackrDevtools = {
      tanstack: {
        enabled: true,
        configs: {
          query: {
            label: 'Toggle Query Devtools',
            type: 'boolean',
            helperText: 'Toggle the devtools for React Query.',
          },
        },
      },
      frigade: {
        enabled: true,
        configs: {
          targets: {
            label: 'Toggle Targets',
            type: 'boolean',
            helperText:
              'Show the available "Frigade Targets" that can be used for product tours, badges and more.',
          },
        },
      },
    };

    if (config.environment === 'local') {
      devtools.sentry = {
        enabled: true,
        configs: {
          spotlight: {
            label: 'Toggle Spotlight',
            type: 'boolean',
            helperText: 'Show the Spotlight overlay for Sentry error tracking.',
          },
        },
      };
    }

    return devtools;
  }, [config.environment]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Only call when spotlightEnabled changes
  React.useEffect(() => {
    if (spotlightEnabled) {
      spotlight.openSpotlight();
    } else {
      spotlight.onClose(() => {
        quackrActions.setDevtoolConfigValue('sentry', 'spotlight', false);
      });
      spotlight.closeSpotlight();
    }
  }, [spotlightEnabled]);

  // We NEVER allow in production
  if (config.environment === 'production') {
    return null;
  }

  return (
    <Quackr
      defaultEnabled={config.environment === 'local'}
      devtools={devtools}
      actionLink={{
        title: 'API Docs',
        description: 'View the API documentation',
        avatar: {
          src: 'https://upload.wikimedia.org/wikipedia/commons/a/ab/Swagger-logo.png',
        },
        url: `${config.api.url}/documentation`,
      }}
      quickLinks={{
        mui: {
          title: 'Material-UI',
          avatar: {
            src: 'https://media.licdn.com/dms/image/v2/C4D0BAQFlZPMNmAzOGw/company-logo_100_100/company-logo_100_100/0/1631744507301/material_ui_logo?e=1736985600&v=beta&t=_EMiti0nWb2HXdaFcGlubMbc4l2A1ljp6PGK3fg76w4',
          },
          description: 'View the Material-UI documentation',
          url: 'https://mui.com/material-ui/all-components/',
        },
        tanstackQuery: {
          title: 'Tanstack Query',
          avatar: {
            src: 'https://tanstack.com/_build/assets/logo-color-600w-Bx4vtR8J.png',
          },
          description: 'View the Tanstack Query documentation',
          url: 'https://tanstack.com/query/',
        },
        tanstackRouter: {
          title: 'Tanstack Router',
          avatar: {
            src: 'https://tanstack.com/_build/assets/logo-color-600w-Bx4vtR8J.png',
          },
          description: 'View the Tanstack Router documentation',
          url: 'https://tanstack.com/router/',
        },
        lodash: {
          title: 'lodash',
          avatar: {
            icon: 'line-scan',
          },
          description: 'View the lodash documentation',
          url: 'https://lodash.com/docs',
        },
        zustand: {
          title: 'Zustand',
          avatar: {
            icon: 'database',
          },
          description: 'View the Zustand documentation',
          url: 'https://github.com/pmndrs/zustand',
        },
        errorCodes: {
          title: 'Error Codes',
          avatar: {
            icon: 'exclamation-circle',
          },
          description: 'View the error codes',
          url: 'https://github.com/joggrdocs/launchpad/blob/main/docs/errors/error-codes.md',
        },
      }}
    />
  );
};
