/*
|==========================================================================
| Config
|==========================================================================
|
| This file is used to define the flow names. This is used to enforce the types in upstream components & hooks.
|
| To add a new flow:
| 1. Add the flow name to the 'flowNames' array
| 2. Update the Id => name mapping for each environment in the 'data' folder
| 3. (Optional) Add types for the StepData and FlowData in the 'types' file
| 4. Profit!
|
*/

const core = [] as const;

const storybook = [] as const;

const all = [...core, ...storybook] as const;

/**
 * Config for the flow names & other constants
 */
export default {
  flowSlugs: {
    core,
    storybook,
    all,
  },
} as const;
