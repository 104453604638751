import React from 'react';

import { Divider, Typography } from '@joggrdocs/riker';

/*
|==========================================================================
| SettingsSectionHeader
|==========================================================================
|
| Header for a Settings Section
|
*/

export interface SettingsSectionHeaderProps {
  title: string;
  description: string;
}

export const SettingsSectionHeader: React.FC<SettingsSectionHeaderProps> = (
  props
) => {
  return (
    <React.Fragment>
      <Typography variant='h2' gutterBottom>
        {props.title}
      </Typography>
      <Typography variant='body1' fontSize='18px'>
        {props.description}
      </Typography>
      <Divider sx={{ my: 2 }} />
    </React.Fragment>
  );
};

export default SettingsSectionHeader;
