import React from 'react';

import { useConfig } from '@stargate/hooks';

import { useGitHubUrls } from '../../hooks/use-github-urls';
import { GitHubButton, type GitHubButtonProps } from './GitHubButton';

export type GitHubAuthorizeButtonProps = Omit<GitHubButtonProps, 'href'>;

/**
 * This component redirects the user to the Web application flow for authorizing access to Github.
 *
 * @link https://docs.github.com/en/developers/apps/building-github-apps/identifying-and-authorizing-users-for-github-apps
 */
export const GitHubAuthorizeButton = React.forwardRef<
  HTMLAnchorElement,
  GitHubAuthorizeButtonProps
>(({ children, ...props }, ref) => {
  const config = useConfig();
  const githubUrls = useGitHubUrls();
  const url = React.useMemo(() => {
    return githubUrls.authorize({ clientId: config.githubApp.clientId });
  }, [githubUrls, config.githubApp.clientId]);

  return (
    <GitHubButton {...props} ref={ref} href={url.href} openInNewTab={false}>
      {children ?? 'Authorize with GitHub'}
    </GitHubButton>
  );
});
GitHubAuthorizeButton.displayName = 'GitHubAuthorizeButton';
GitHubAuthorizeButton.muiName = 'Button';
