import _ from 'lodash';
import type * as TypeFest from 'type-fest';

import {
  type ClassName,
  type ComponentClassName,
  type ComponentClasses,
  generateComponentClasses as generateComponentClassesRiker,
} from '@joggrdocs/riker';

import { devLogger } from '@stargate/logger';

/*
|==========================================================================
| classes
|==========================================================================
|
| Create classes for components.
|
*/

/*
|------------------
| Types
|------------------
*/

/**
 * The namespace for joggr classes.
 */
export const joggrClassNameNamespace = 'Joggr';

export type JoggrClassNameNamespace = typeof joggrClassNameNamespace;

/**
 * Class name for joggr theme.
 */
export type JoggrClassName<Modifier extends string> = ClassName<
  JoggrClassNameNamespace,
  Modifier
>;

/**
 * Class name for a component class.
 */
export type JoggrComponentClassName<
  ComponentName extends Capitalize<string>,
  Modifier extends string,
> = ComponentClassName<JoggrClassNameNamespace, ComponentName, Modifier>;

/**
 * The classes for a component.
 */
export type JoggrComponentClasses<
  ComponentName extends Capitalize<string>,
  Classes extends readonly string[],
> = ComponentClasses<ComponentName, Classes, JoggrClassNameNamespace>;

/**
 * The classes prop for a component.
 */
export type JoggrComponentClassesProp<
  BuiltClasses extends JoggrComponentClasses<
    Capitalize<string>,
    readonly string[]
  >,
> = TypeFest.Simplify<{
  [key in keyof BuiltClasses]?: string;
}>;

/*
|------------------
| Public API
|------------------
*/

/**
 * Base util for creating classes.
 *
 * @param modifier A modifier for the class.
 * @returns A class for Stargate, in the format of `Joggr-something`.
 * @deprecated Use `@joggrdocs/riker` instead.
 */
export const createClass = <Modifier extends string>(
  modifier: Modifier
): JoggrClassName<Modifier> => `Joggr-${modifier}`;

/**
 * Create a single class for a component.
 *
 * @param componentName A component name.
 * @param modifier A modifier for the component.
 * @returns A class for the component, in the format of `Joggr-Button-root`.
 * @deprecated Use `@joggrdocs/riker` instead.
 */
export const createComponentClass = <
  ComponentName extends Capitalize<string>,
  Class extends string,
>(
  componentName: ComponentName,
  className?: Class
): JoggrComponentClassName<ComponentName, Class> => {
  return createClass(`${componentName}-${className || 'root'}`);
};

/**
 * Create a class (css) factory for a component.
 *
 * @param componentName A component name.
 * @returns A function that creates a class for the component, in the format of `Joggr-Button-root`.
 * @deprecated Use `generateComponentClasses` instead.
 */
export const componentClassFactory =
  (componentName: Capitalize<string>) =>
  (modifier = 'root') => {
    return createComponentClass(componentName, modifier);
  };

/**
 * Generate classes for a component.
 *
 * @example `const buttonClasses = generateComponentClasses('Button', ['root', 'label'] as const);`
 *
 * @param componentName A component name.
 * @param classNames The class names for the component.
 * @returns A map of classes for the component, in the format of `Joggr-Button-root`.
 */
export const generateComponentClasses = <
  ComponentName extends Capitalize<string>,
  ClassName extends string,
>(
  componentName: ComponentName,
  classNames?: ReadonlyArray<ClassName>
) => {
  try {
    return generateComponentClassesRiker(
      componentName,
      classNames,
      joggrClassNameNamespace
    );
  } catch (error) {
    if (error instanceof Error) {
      devLogger.error({
        message: error.message,
        data: {
          componentName,
          classNames,
        },
      });
    }
    throw error;
  }
};
