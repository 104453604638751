import {
  IconButton,
  type IconButtonProps,
  RikerIcon,
  type RikerIconName,
} from '@joggrdocs/riker';
import React from 'react';

export interface DeleteIconButtonProps extends IconButtonProps {
  /**
   * The icon to display.
   *
   * @default 'trash'
   */
  icon?: RikerIconName;
}

export const DeleteIconButton = React.forwardRef<
  HTMLButtonElement,
  DeleteIconButtonProps
>(({ color = 'secondary', children, icon = 'trash', ...props }, ref) => {
  return (
    <IconButton {...props} ref={ref} color={color}>
      <RikerIcon name={icon} />
    </IconButton>
  );
});
DeleteIconButton.displayName = 'DeleteIconButton';
