import { Button, type ButtonProps, IconPlus } from '@joggrdocs/riker';
import { FormattedMessage } from '@stargate/localization';
import React from 'react';

export type CreateButtonProps = ButtonProps;

export const CreateButton = React.forwardRef<
  HTMLButtonElement,
  CreateButtonProps
>((props, ref) => {
  const {
    color = 'secondary',
    variant = 'contained',
    children,
    ...restProps
  } = props;
  return (
    <Button
      {...restProps}
      ref={ref}
      startIcon={<IconPlus />}
      color={color}
      variant={variant}
    >
      {children || <FormattedMessage id='generic.create' />}
    </Button>
  );
});
CreateButton.displayName = 'CreateButton';
