import config from '@stargate/config';
import { devLogger } from '@stargate/logger';

import { quackrStore } from './store';

declare global {
  interface Window {
    toggleDevTools: () => void;
  }
}

if (typeof window !== 'undefined') {
  // biome-ignore lint/complexity/useArrowFunction: Ignore as we want to use a function declaration here
  window.toggleDevTools = function () {
    if (config.environment !== 'production') {
      quackrStore.setState((state) => ({
        enabled: !(state.enabled ?? false),
      }));
      devLogger.info(
        `Quackr DevTools are now ${quackrStore.getState().enabled === true ? 'enabled' : 'disabled'}`
      );
    }
  };
}
