import React from 'react';

import { RikerIcon, type RikerIconProps, useTheme } from '@joggrdocs/riker';

import { IconPickerIcon } from '@stargate/components/IconPicker';

import { componentClassFactory } from '@stargate/theme';

import type * as types from '../../types';

/*
|==========================================================================
| DirectoryIcon
|==========================================================================
|
| A component that displays a directory icon. 
|
*/

const cn = componentClassFactory('DirectoryIcon');

export const directoryIconClasses = {
  root: cn('root'),
  riker: cn('riker'),
  emoji: cn('emoji'),
};

export interface DirectoryIconProps extends Omit<RikerIconProps, 'name'> {
  open?: boolean;
  icon?: string;
  iconType?: types.DirectoryIconType;
}

export const directoryIconName = 'folder';

export const DirectoryIcon = React.forwardRef<any, DirectoryIconProps>(
  ({ open = false, size = 24, icon, iconType, ...props }, ref) => {
    const theme = useTheme();

    // Handle Icons stored in the DB
    if (icon && iconType) {
      return (
        <IconPickerIcon
          {...props}
          icon={icon}
          iconType={iconType}
          className={theme.utils.classNames([
            directoryIconClasses.root,
            directoryIconClasses.riker,
            props.className,
          ])}
          size={size}
          ref={ref}
        />
      );
    }
    if (open) {
      return (
        <RikerIcon
          {...props}
          name={`${directoryIconName}-open`}
          className={theme.utils.classNames([
            directoryIconClasses.root,
            directoryIconClasses.riker,
            props.className,
          ])}
          size={size}
          ref={ref}
          fill={theme.palette.primary.light}
          color={theme.palette.primary.dark}
        />
      );
    }
    return (
      <RikerIcon
        {...props}
        name={directoryIconName}
        className={theme.utils.classNames([
          directoryIconClasses.root,
          directoryIconClasses.riker,
          props.className,
        ])}
        size={size}
        ref={ref}
        fill={theme.palette.primary.light}
        color={theme.palette.primary.dark}
      />
    );
  }
);
DirectoryIcon.displayName = 'DirectoryIcon';
