import { createLocale } from '../../lib/locale-factory';

const images = createLocale('images', {
  'placeholder.error.title': 'Uh oh!',
  'placeholder.error.description': 'Seems like something went wrong.',
  'placeholder.info.title': 'Placeholder',
  'placeholder.info.description': 'This is a placeholder image.',
});

const dialogs = createLocale('dialogs', {
  'confirm.title': 'Are you sure?',
  'confirm.subTitle': 'Confirm that you want to take this action.',
  'confirm-delete.title': 'Confirm Deletion',
  'confirm-delete.subtitle': 'Confirm that you want to delete this item.',
  'confirm-delete.question':
    'Are you sure you want to delete this item? This action cannot be undone.',
  'confirm-delete.task':
    'Please type <code>{word}</code> to confirm you want to delete this item.',
});

const chips = createLocale('chips', {
  'release.alpha.title': 'Alpha',
  'release.alpha.description': 'This feature is in alpha.',
  'release.beta.title': 'Beta',
  'release.beta.description': 'This feature is in beta.',
  'release.stable.title': 'Stable',
  'release.stable.description': 'This feature is stable.',
});

export default createLocale('components', {
  ...dialogs,
  ...chips,
  ...images,
});
