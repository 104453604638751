import _ from 'lodash';
import React from 'react';
import * as ReactRouter from 'react-router-dom';
import type * as TypeFest from 'type-fest';

import type { RouteNames } from '../routes';
import { type RouteSearch, useRoute } from './use-route';

/*
|==========================================================================
| useSearchParams Wrapper for React Router
|==========================================================================
|
| Wraps the useSearchParams hook from react-router-dom to provide a
|
*/

export type UseSearchParamsHook<Name extends RouteNames> = [
  TypeFest.Simplify<RouteSearch<Name>>,
  SetSearchParams<Name>,
  RemoveSearchParams<Name>,
];

export const useSearchParams = <Name extends RouteNames>(
  routeName: Name
): TypeFest.Simplify<UseSearchParamsHook<Name>> => {
  const [_searchParams, _setSearchParams] = ReactRouter.useSearchParams();
  const route = useRoute(routeName);

  const searchParams = React.useMemo<
    TypeFest.Simplify<RouteSearch<Name>>
  >(() => {
    const result: Record<string, string> = {};

    for (const [key, value] of _searchParams) {
      result[key] = value;
    }

    return route.search(result) as RouteSearch<Name>;
  }, [_searchParams, route]);

  /*
  |------------------
  | Callbacks
  |------------------
  */

  /**
   * Set the search params for the current route & allows merging of existing params
   */
  const setSearchParams: SetSearchParams<Name> = React.useCallback<
    SetSearchParams<Name>
  >(
    (data, options) => {
      if (data) {
        if (options?.merge) {
          _setSearchParams(
            coerceSearchParams({
              ...searchParams,
              ...data,
            })
          );
        } else {
          _setSearchParams(coerceSearchParams(data));
        }
      }
    },
    [searchParams, _setSearchParams]
  );

  /**
   * Remove the specified search params from the current route
   */
  const removeSearchParams: RemoveSearchParams<Name> = React.useCallback<
    RemoveSearchParams<Name>
  >(
    (key) => {
      _setSearchParams(
        _.pickBy(coerceSearchParams(searchParams), (_, k) => k !== key)
      );
    },
    [searchParams, _setSearchParams]
  );

  return [searchParams, setSearchParams, removeSearchParams];
};

/*
|------------------
| Utils
|------------------
*/

type SetSearchParams<Name extends RouteNames> = (
  searchParams: Required<RouteSearch<Name>>,
  options?: { merge: boolean }
) => void;

type RemoveSearchParams<Name extends RouteNames> = (
  searchParam: keyof RouteSearch<Name>
) => void;

/**
 * Coerce the search params to string values
 */
const coerceSearchParams = (
  searchParams: Record<string, unknown>
): Record<string, string> => {
  const cleanParams = _.mapValues(searchParams, (v) => {
    if (!_.isNil(v)) {
      return _.toString(v);
    }

    return null;
  });
  return _.pickBy(cleanParams, (v) => !_.isNil(v));
};
