import React from 'react';

import ErrorGuard, { type ErrorGuardProps } from './ErrorGuard';
import LoadingGuard, { type LoadingGuardProps } from './LoadingGuard';
import RenderGuard, { type RenderGuardProps } from './RenderGuard';

/*
|==========================================================================
| Guardian
|==========================================================================
|
| A component that wraps the ErrorGuard, LoadingGuard, and RenderGuard, providing
| a single component to handle all three cases.
|
*/

export { isObjectNotNil } from './RenderGuard';

export type GuardianProps<P extends object> = Omit<
  LoadingGuardProps,
  keyof RenderGuardProps<P>
> &
  RenderGuardProps<P> & {
    renderError?: ErrorGuardProps['fallbackRender'];
  };

export function Guardian<P extends object>(props: GuardianProps<P>) {
  return (
    <ErrorGuard>
      <LoadingGuard {...props} render={() => <RenderGuard {...props} />} />
    </ErrorGuard>
  );
}

export default Guardian;
