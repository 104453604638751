import * as loomSdk from '@loomhq/loom-embed';
import * as hookz from '@react-hookz/web';

export type LoomOembed = Awaited<ReturnType<typeof loomSdk.oembed>>;

export type LoomOembedArgs =
  | [string]
  | [string, { width: number; height: number; gifThumbnail?: boolean }];

export type UseLoomOEmbedHook = [
  hookz.AsyncState<LoomOembed | undefined>,
  hookz.UseAsyncActions<LoomOembed, LoomOembedArgs>,
  hookz.UseAsyncMeta<LoomOembed, LoomOembedArgs>,
];

export const useLoomOembed = (): UseLoomOEmbedHook => {
  return hookz.useAsync<LoomOembed, LoomOembedArgs>(async (...args) => {
    const [url, options] = args;
    return await loomSdk.oembed(url, options ?? undefined);
  });
};
