import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { LocalStorage } from '@stargate/lib/storage';

/**
 * Global defaults for a User, backed by localStorage.
 *
 * @returns {[State, Actions]} A tuple of the state and actions used to manage the defaults.
 */
export const useUserDefaults = (): [State, Actions] => {
  return useStore(
    ({
      repositoryId,
      repositoryOwnerId,
      setRepositoryId,
      setRepositoryOwnerId,
      clear,
    }) => [
      { repositoryId, repositoryOwnerId },
      { setRepositoryId, setRepositoryOwnerId, clear },
    ]
  );
};

/*
|------------------
| Types
|------------------
*/

export interface State {
  /**
   * The "Global" default repository id.
   */
  repositoryId?: string;

  /**
   * The "Global" default repository owner id.
   */
  repositoryOwnerId?: string;
}

export interface Actions {
  /**
   * Set the "Global" repository id.
   * @param repositoryId
   */
  setRepositoryId: (repositoryId: string) => void;

  /**
   * Set the "Global" repository owner id.
   * @param repositoryOwnerId
   */
  setRepositoryOwnerId: (repositoryOwnerId: string) => void;

  /**
   * Clear all defaults.
   */
  clear: () => void;
}

export type Store = State & Actions;

/*
|------------------
| Public API
|------------------
*/

const useStore = create<Store>()(
  persist(
    (set, _get) => ({
      repositoryId: undefined,
      repositoryOwnerId: undefined,
      setRepositoryId: (repositoryId) => {
        set((store) => ({
          ...store,
          repositoryId,
        }));
      },
      setRepositoryOwnerId: (repositoryOwnerId) => {
        set((store) => ({
          ...store,
          repositoryOwnerId,
        }));
      },
      clear: () => {
        set((store) => ({
          ...store,
          repositoryId: undefined,
          repositoryOwnerId: undefined,
        }));
      },
    }),
    {
      name: LocalStorage.createKey('joggrdoc:defaults'),
    }
  )
);
