import _ from 'lodash';

import { fetchAuthorized, fetchUnauthorized } from '@stargate/lib/http';

/**
 * Send an authorized HTTP request to the backend, & refreshes the Auth0 token if its expired.
 */
export const useHTTPClient = () => {
  return {
    fetchAuthorized,
    fetch: fetchUnauthorized,
  };
};
