import React from 'react';

import { Box, type ComponentClassesProp, useTheme } from '@joggrdocs/riker';

import { generateComponentClasses } from '@stargate/theme';

/*
|==========================================================================
| IconEmoji
|==========================================================================
|
| Component for IconEmoji
|
*/

export const iconEmojiClasses = generateComponentClasses('IconEmoji', [
  'root',
] as const);

export interface IconEmojiProps {
  emoji: string;
  size?: number | string;
  className?: string;
  classes?: ComponentClassesProp<typeof iconEmojiClasses>;
}

/**
 * Component for an Emoji Icon
 */
export const IconEmoji = React.forwardRef<any, IconEmojiProps>(
  ({ size = 24, className, emoji }, ref) => {
    const theme = useTheme();
    return (
      <Box
        ref={ref}
        className={theme.utils.classNames([iconEmojiClasses.root, className])}
        sx={{
          [`&.${iconEmojiClasses.root}`]: {
            fontSize: `${size}px !important`,
          },
        }}
      >
        {emoji}
      </Box>
    );
  }
);
IconEmoji.displayName = 'IconEmoji';
