import { Node, mergeAttributes, nodePasteRule } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import _ from 'lodash';

import {
  REGEX_YOUTUBE,
  getYoutubeEmbedUrl,
  getYoutubeVideoId,
  isValidYoutubeUrl,
} from '@joggrdocs/bumblebee';

import { YoutubeNodeView } from '../components/Youtube/YoutubeNodeView';
import type { YoutubeNodeAttributes } from '../types';

/*
|==========================================================================
| youtube
|==========================================================================
|
| Extension for embedding youtube videos. Copied from TipTap's example and modified.
|
| @link https://github.com/ueberdosis/tiptap/tree/develop/packages/extension-youtube
|
*/

export type SetYoutubePayload = YoutubeNodeAttributes;

export type SetYoutubeOptions = Required<YoutubeNodeAttributes>['options'];

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    youtube: {
      /**
       * Insert a Youtube video
       *
       * @param payload A payload object containing the src, width, height, and start of the video.
       * @param options An options object containing a forceOpen boolean.
       */
      setYoutubeVideo: (
        payload: SetYoutubePayload,
        options?: SetYoutubeOptions
      ) => ReturnType;

      /**
       * Insert a blank Youtube video
       *
       * @param options A options object containing a forceOpen boolean.
       */
      setEmptyYoutubeVideo: (options?: SetYoutubeOptions) => ReturnType;
    };
  }
}

export const Youtube = Node.create({
  name: 'youtube',

  inline: false,

  group: 'block',

  draggable: true,

  addNodeView() {
    // @todo fix types here or in TipTap
    // @ts-expect-error - types broken due to TipTap, need to fix
    return ReactNodeViewRenderer(YoutubeNodeView);
  },

  addAttributes() {
    return {
      src: {
        default: null,
      },
      options: {
        default: { forceOpen: false },
      },
    };
  },

  addCommands() {
    return {
      setYoutubeVideo:
        (payload, options) =>
        ({ commands }) => {
          if (!isValidYoutubeUrl(payload.src)) {
            throw new Error('Invalid Youtube URL');
            // return false;
          }

          return commands.insertContent({
            type: this.name,
            attrs: {
              ...payload,
              options,
            },
          });
        },

      setEmptyYoutubeVideo:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: {
              src: '',
              options,
            },
          });
        },
    };
  },

  addPasteRules() {
    return [
      nodePasteRule({
        find: new RegExp(REGEX_YOUTUBE, 'g'),
        type: this.type,
        getAttributes: (match) => {
          return { src: match.input };
        },
      }),
    ];
  },

  parseHTML() {
    return [
      {
        tag: 'div[data-youtube-video] iframe',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const videoId =
      _.isString(HTMLAttributes.src) && !_.isEmpty(HTMLAttributes.src)
        ? getYoutubeVideoId(HTMLAttributes.src)
        : null;
    const embedUrl =
      !_.isNil(videoId) && !_.isEmpty(videoId)
        ? getYoutubeEmbedUrl(videoId)
        : null;
    HTMLAttributes.src = embedUrl;
    return [
      'div',
      { 'data-youtube-video': '' },
      [
        'iframe',
        mergeAttributes(
          { className: 'dashdraft-media dashdraft-media-youtube' },
          HTMLAttributes
        ),
      ],
    ];
  },
}).configure();
