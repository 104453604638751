import { produce } from 'immer';
import _ from 'lodash';
import type * as TypeFest from 'type-fest';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import type {
  QuackrDevtoolConfigItemValue,
  QuackrDevtools,
  QuackrPlacement,
} from './types';

export const quackrStore = create<QuackrStore>()(
  persist(
    (set, _get) => ({
      enabled: null,
      devtools: null,
      placement: null,
      setDevtoolConfigValue: (toolKey, itemKey, item) => {
        set(
          produce((draft) => {
            if (!draft.devtools) {
              draft.devtools = {};
            }

            if (!draft.devtools[toolKey]) {
              draft.devtools[toolKey] = {
                enabled: true,
                configs: {},
              };
            }

            draft.devtools[toolKey].configs[itemKey] = item;
          })
        );
      },
      setDevtoolConfig: (tools) => {
        set(
          produce((draft) => {
            draft.devtools = _.mapValues(tools, (tool) => ({
              enabled: tool.enabled ?? false,
              configs: _.mapValues(
                tool.configs,
                (config) => config.defaultValue ?? null
              ),
            }));
          })
        );
      },
      setPlacement: (placement) => {
        set(
          produce((draft) => {
            draft.placement = placement;
          })
        );
      },
      enable: (key) => {
        set(
          produce((draft) => {
            if (key === 'all') {
              draft.enabled = true;
              const allKeys = _.keys(draft.devtools);
              for (const k of allKeys) {
                draft.devtools[k].enabled = true;
              }
            } else {
              draft.devtools[key].enabled = true;
            }
          })
        );
      },
      disable: (key) => {
        set(
          produce((draft) => {
            if (key === 'all') {
              draft.enabled = false;
              const allKeys = _.keys(draft.devtools);
              for (const k of allKeys) {
                draft.devtools[k].enabled = false;
              }
            } else {
              draft.devtools[key].enabled = false;
            }
          })
        );
      },
    }),
    { name: 'quackr' }
  )
);

export type QuackrStore = TypeFest.Simplify<
  QuackrStoreState & QuackrStoreActions
>;

export type QuackrStoreDevtoolState = Record<
  string,
  {
    enabled: boolean;
    configs: Record<string, QuackrDevtoolConfigItemValue> | null;
  }
>;

export interface QuackrStoreState {
  /**
   * If the Quackr component is enabled.
   */
  enabled: boolean | null;

  /**
   * The placement of the dev tools.
   */
  placement: QuackrPlacement | null;

  /**
   * The configuration for the dev tools.
   */
  devtools: QuackrStoreDevtoolState | null;
}

export interface QuackrStoreActions {
  /**
   * Disable the dev tools.
   */
  disable: <K extends string>(tool: K | 'all') => void;

  /**
   * Enable the dev tools.
   */
  enable: <K extends string>(tool: K | 'all') => void;

  /**
   * Set the placement of the dev tools.
   */
  setPlacement: (placement: QuackrPlacement) => void;

  /**
   * Set the default configuration for the dev tools.
   */
  setDevtoolConfig: <K extends string>(tools: QuackrDevtools<K>) => void;

  /**
   * Update a dev tool config item.
   */
  setDevtoolConfigValue: <K extends string, I extends string>(
    toolKey: K,
    itemKey: I,
    value: unknown
  ) => void;
}
