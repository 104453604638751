/*
|==========================================================================
| media
|==========================================================================
|
| Media utilities for tiptap editor.
|
*/

/**
 * Get the name of an image from a URL.
 *
 * @param url The image source to get the name of.
 * @returns an image name.
 */
export async function getImageNameFromURL(url: string) {
  const response = await fetch(url);
  const blob = await response.blob();
  const file = new File([blob], 'image');
  return file.name;
}

/**
 * Downloads an image from a URL, using the DOM.
 *
 * @param url The image source to download.
 */
export async function downloadImage(url: string, nameOfImage?: string) {
  const image = await fetch(url);
  const imageBlog = await image.blob();
  const imageURL = URL.createObjectURL(imageBlog);

  const link = document.createElement('a');
  link.href = imageURL;
  link.download = nameOfImage ?? (await getImageNameFromURL(url));
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
