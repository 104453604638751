import { Box, LinearProgress, Typography } from '@joggrdocs/riker';
import * as hookz from '@react-hookz/web';
import _ from 'lodash';
import React from 'react';

import { Page } from '@stargate/components/Utils';
import { useUser } from '@stargate/features/user';
import { useUserOnboarding } from '@stargate/features/user-onboarding';
import { useNotify } from '@stargate/lib/notify';
import { useLogger } from '@stargate/logger';
import { useNavigate } from '@stargate/routes';
import { useSentry } from '@stargate/vendors/sentry';

export const GitHubInstallAppPage: React.FC = () => {
  const userOnboarding = useUserOnboarding();
  const authenticatedUser = useUser();
  const notify = useNotify();
  const navigate = useNavigate();
  const sentry = useSentry();
  const logger = useLogger();
  const [verified, setVerified] = React.useState(false);

  /*
  |------------------
  | Effects: Error
  |------------------
  */

  const TIMEOUT = 15000;
  const [cancelErrorTimeout] = hookz.useTimeoutEffect(() => {
    notify.error('GitHub App installation verification failed');
    sentry.captureException(
      new Error('GitHub App installation verification timeout'),
      {
        extra: {
          message: 'GitHub App installation verification failed',
          timeout: TIMEOUT,
        },
      }
    );
    navigate('root.error');
  }, TIMEOUT);

  /*
  |------------------
  | Utils
  |------------------
  */

  const verifyInstallation = async (): Promise<void> => {
    try {
      const user = await authenticatedUser.refresh({ clearCache: true });

      if (_.isNil(user?.metadata.githubAppInstalled)) {
        throw new Error('API verification: GitHub App not installed');
      }

      if (userOnboarding.onboarded) {
        logger.debug('GitHub App installation verified');
        navigate('app.root');
      } else {
        await userOnboarding.markStepCompleted('github-install-app');
        logger.debug('GitHub App installation verified during onboarding');
        navigate('utility.onboarding');
      }

      notify.success('GitHub App installation verified');
    } catch (err) {
      notify.error('GitHub App installation verification failed');
      logger.error(
        { error: err },
        'GitHub App installation verification failed'
      );
      sentry.captureException(err, {
        extra: { message: 'GitHub App installation verification failed' },
      });
      navigate('root.error');
    }

    setVerified(true);
    cancelErrorTimeout();
  };

  /*
  |------------------
  | Effects
  |------------------
  */

  hookz.useDebouncedEffect(
    () => {
      if (!authenticatedUser.loading && !userOnboarding.loading && !verified) {
        void verifyInstallation();
      }
    },
    [userOnboarding.loading, authenticatedUser.loading, verified],
    500
  );

  return (
    <Page
      id='install-github-app'
      title='Integrations | GitHub App Installation'
      render={() => (
        <Box sx={{ width: '100%' }}>
          <Typography variant='h6' component='p'>
            Verifying GitHub App Installation...
          </Typography>
          <LinearProgress />
        </Box>
      )}
    />
  );
};
