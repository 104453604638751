import type { Theme } from '@joggrdocs/riker';

import type { FrigadeCSS } from '../types';

/**
 * Generate a Frigade Components Aspect (CSS) from a Riker theme
 *
 * @param theme A theme object
 * @returns A FrigadeCSS object
 */
export const components = (theme: Theme): FrigadeCSS => {
  return {
    /*
    |------------------
    | Typography
    |------------------
    */
    '.fr-title, .fr-subtitle': {
      color: theme.palette.text.primary,
    },

    /*
    |------------------
    | Dialog
    |------------------
    */

    '.fr-dialog-wrapper': {
      zIndex: theme.zIndex.modal,

      '.fr-title': {
        fontSize: theme.typography.h4.fontSize,
        fontWeight: theme.typography.h4.fontWeight,
      },
    },

    // Special styles for videos
    '.fr-dialog-wrapper.fr-announcement-video .fr-card.fr-announcement': {
      maxWidth: '80vw',
      [`@media (min-width: ${theme.breakpoints.values.lg}px)`]: {
        maxWidth: `${theme.breakpoints.values.md}px`,
      },
    },

    /*
    |------------------
    | Buttons
    |------------------
    */

    "[class*='fr-button']": {
      cursor: 'pointer',
    },
  };
};
