import {
  Avatar,
  type AvatarProps,
  RikerIcon,
  type RikerIconName,
  type ThemeColorPaletteVariant,
  useTheme,
} from '@joggrdocs/riker';
import React from 'react';
import type * as TypeFest from 'type-fest';

import {
  type JoggrComponentClassesProp,
  generateComponentClasses,
} from '@stargate/theme';

export const iconAvatarClasses = generateComponentClasses('IconAvatar', [
  'icon',
] as const);

export interface IconAvatarProps extends Pick<AvatarProps, 'variant' | 'sx'> {
  /**
   * The Riker icon to display.
   */
  icon: RikerIconName;

  /**
   * The color of the icon.
   *
   * @default 'contrast'
   */
  iconColor?: TypeFest.LiteralUnion<
    'contrast' | ThemeColorPaletteVariant,
    string
  >;

  /**
   * The fill color of the icon.
   *   */
  iconFill?: TypeFest.LiteralUnion<
    'contrast' | ThemeColorPaletteVariant,
    string
  >;

  /**
   * The background color of the avatar.
   *
   * @default 'primary'
   */
  backgroundColor?: TypeFest.LiteralUnion<ThemeColorPaletteVariant, string>;

  /**
   * The size of the avatar.
   */
  size?: number;

  /**
   * The gap using the Riker spacing (i.e. 1 = 8px) between the icon and the edge of the avatar.
   *
   * @default 0.5
   */
  gap?: number;

  /**
   * Override the classes applied to the component.
   */
  classes?: JoggrComponentClassesProp<typeof iconAvatarClasses>;
}

/**
 * An avatar with a Riker icon.
 */
export const IconAvatar = React.forwardRef<HTMLDivElement, IconAvatarProps>(
  (
    {
      gap = 0.5,
      iconColor = 'contrast',
      iconFill,
      backgroundColor = 'primary',
      variant = 'rounded',
      size = 40,
      icon,
      ...props
    },
    ref
  ) => {
    const theme = useTheme();

    /*
  |------------------
  | Computed
  |------------------
  */

    const bgcolor = React.useMemo(() => {
      if (Object.hasOwn(theme.palette, backgroundColor)) {
        return theme.palette[backgroundColor].main;
      }
      return backgroundColor;
    }, [backgroundColor, theme]);

    const contrastText = React.useMemo(() => {
      return theme.palette.getContrastText(bgcolor);
    }, [theme, bgcolor]);

    const color = React.useMemo(() => {
      if (Object.hasOwn(theme.palette, iconColor)) {
        return theme.palette[iconColor].main;
      }

      if (iconColor === 'contrast') {
        return contrastText;
      }

      return iconColor;
    }, [iconColor, theme, contrastText]);

    const fill = React.useMemo(() => {
      if (iconFill && Object.hasOwn(theme.palette, iconFill)) {
        return theme.palette[iconFill].main;
      }

      if (iconFill === 'contrast') {
        return contrastText;
      }

      return iconFill ?? undefined;
    }, [iconFill, theme, contrastText]);

    const classes = React.useMemo(() => {
      return {
        ...iconAvatarClasses,
        ...props.classes,
      };
    }, [props.classes]);

    return (
      <Avatar
        {...props}
        ref={ref}
        variant={variant}
        classes={classes}
        sx={{
          [`&.${classes.root}`]: {
            ...props.sx,
            height: `${size}px`,
            width: `${size}px`,
            p: gap,
            backgroundColor: bgcolor,
            color,
            [`& .${classes.icon}`]: {
              fill,
              height: 'auto',
              width: '100%',
            },
          },
        }}
      >
        <RikerIcon
          name={icon}
          classes={{
            root: props.classes?.icon ?? iconAvatarClasses.icon,
          }}
        />
      </Avatar>
    );
  }
);
IconAvatar.muiName = 'Avatar';
