import React from 'react';
import type * as TypeFest from 'type-fest';

import {
  RikerIcon,
  type RikerIconComponent,
  type RikerIconProps,
} from '@joggrdocs/riker';

/*
|==========================================================================
| DocumentCreateIcon 
|==========================================================================
|
| Proxy component for the RikerIcon component.
|
*/

export type DocumentCreateIconProps = TypeFest.Simplify<
  Omit<RikerIconProps, 'name'>
>;

export const documentCreateIconName = 'file-plus';

/**
 * Icon for creating a document.
 */
export const DocumentCreateIcon = React.forwardRef<
  RikerIconComponent,
  DocumentCreateIconProps
>((props, ref) => (
  <RikerIcon {...props} ref={ref} name={documentCreateIconName} />
));
