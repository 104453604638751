import { alertTypes } from '@joggrdocs/bumblebee';
import { Node, mergeAttributes } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { AlertNodeView } from '../components/AlertNodeView';
import type { AlertNodeAttributes } from '../types';

/**
 * Extension for embedding alerts (info panels).
 */
export const Alerts = Node.create({
  name: 'alerts',

  inline: false,

  group: 'block',

  content: '(paragraph | heading | list)*',

  draggable: true,

  addNodeView() {
    // @todo fix types here or in TipTap
    // @ts-expect-error - types broken due to TipTap, need to fix
    return ReactNodeViewRenderer(AlertNodeView);
  },

  addAttributes() {
    return {
      type: {
        default: 'info',
        parseHTML: (element) => element.getAttribute('data-type'),
        renderHTML: (attributes) => {
          return { 'data-type': attributes.type };
        },
      },
    };
  },

  addCommands() {
    return {
      setAlert:
        (payload, options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: 'alerts',
            attrs: {
              type: payload.type,
            },
            content: [
              {
                type: 'paragraph',
                content: [],
              },
            ],
          });
        },
    };
  },

  parseHTML() {
    return [{ tag: 'div[data-alert]' }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(
        {
          'data-alert': '',
          'data-type': HTMLAttributes['data-type'],
        },
        HTMLAttributes,
        {
          class: `dashdraft-alert dashdraft-alert-${HTMLAttributes['data-type']}`,
        }
      ),
      [
        'span',
        {
          class: 'dashdraft-alert-content',
        },
        0,
      ],
    ];
  },
}).configure();

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    alerts: {
      /**
       * Insert a Alert
       *
       * @param payload A payload object containing the title, caption, and type of the alert.
       * @param options An options object containing a forceOpen boolean.
       */
      setAlert: (
        payload: AlertNodeAttributes,
        options?: Required<AlertNodeAttributes>['options']
      ) => ReturnType;
    };
  }
}
