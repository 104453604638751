import React from 'react';

import { useDevLogger } from '@stargate/logger';
import * as cache from './lib/cache';
import * as exp from './lib/expiration';
import * as core from './lib/session';

/*
|==========================================================================
| Debug Session
|==========================================================================
|
| Core library for managing the debug session.
|
*/

export interface DebugSession {
  /**
   * Get the current session ID.
   */
  getId: () => string;

  /**
   * Refreshes the current session, usually when a user is taking action in the UI.
   */
  refresh: () => core.Session;

  /**
   * Clears the current session.
   */
  clear: () => void;
}

/**
 * The debug session object (used in non-react contexts).
 */
export const debugSession = {
  getId: () => {
    const session = cache.getSession();

    if (!session) {
      const newSession = core.createSession();
      return newSession.sessionId;
    }

    return session.sessionId;
  },
  refresh: () => {
    const session = cache.getSession();

    if (!session || exp.isExpired(session)) {
      const newSession = core.createSession();
      cache.setSession(newSession);
      return newSession;
    }

    const refreshedSession = {
      ...session,
      setAt: Date.now(),
      expiresAt: exp.getExpiration(),
    };

    cache.setSession(refreshedSession);
    return refreshedSession;
  },
  clear: () => {
    cache.setSession(null);
  },
} satisfies DebugSession;

export interface UseDebugSessionHook extends Omit<DebugSession, 'getId'> {
  /**
   * The current session ID.
   */
  id: string;
}

export const useDebugSession = (): UseDebugSessionHook => {
  const sessionCache = cache.useSessionCache();
  const devLogger = useDevLogger();

  const currentSession = React.useMemo(() => {
    if (!sessionCache.data) {
      return core.createSession();
    }

    return sessionCache.data;
  }, [sessionCache.data]);

  const refresh = React.useCallback(() => {
    if (exp.isExpired(currentSession)) {
      const newSession = core.createSession();
      cache.setSession(newSession);
      return newSession;
    }

    const refreshedSession = {
      ...currentSession,
      setAt: Date.now(),
      expiresAt: exp.getExpiration(),
    };

    sessionCache.set(refreshedSession);
    return refreshedSession;
  }, [sessionCache.set, currentSession]);

  const clear = React.useCallback(() => {
    sessionCache.set(null);
  }, [sessionCache.set]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: We only care about the sessionCache.data
  React.useEffect(() => {
    if (!sessionCache.data) {
      sessionCache.set(currentSession);
      devLogger.debug({
        message: 'Created a new Debug Session',
        data: {
          ...currentSession,
        },
      });
    }
  }, [sessionCache.data, currentSession]);

  // use memo so never updates
  return {
    id: currentSession.sessionId,
    refresh,
    clear,
  };
};
