import type { SnackbarOrigin } from '@joggrdocs/riker';
import { useSnackbar } from 'notistack';
import React from 'react';
import type * as TypeFest from 'type-fest';

/*
|==========================================================================
| useNotify
|==========================================================================
|
| This hook provides a simple interface for sending notifications to the
| notification system. It is a wrapper around notistack's enqueueSnackbar
|
*/

export type NotificationSeverity = 'success' | 'error' | 'warning' | 'info';

export type NotificationLocation =
  | 'top-left'
  | 'bottom-left'
  | 'top-right'
  | 'bottom-right'
  | 'top-center'
  | 'bottom-center';

export type NotificationMessage = string | React.ReactNode;

export interface Notification {
  title?: string;
  message: NotificationMessage;
  severity?: NotificationSeverity;
  location?: NotificationLocation;
  persist?: boolean;
  duration?: number;
}

export type NotificationOptions = TypeFest.Simplify<
  TypeFest.RequireAtLeastOne<Partial<Omit<Notification, 'message' | 'type'>>>
>;

/*
|------------------
| Utils
|------------------
*/

/**
 * Get the anchor origin for the notification based on the location string
 *
 * @param location A string representing the location of the notification
 * @returns A SnackbarOrigin object
 */
const getAnchorOrigin = (location: NotificationLocation): SnackbarOrigin => {
  const arr = location.split('-');
  if (arr.length !== 2) {
    return {
      vertical: 'top',
      horizontal: 'right',
    };
  }
  const [vertical, horizontal] = arr as [
    SnackbarOrigin['vertical'],
    SnackbarOrigin['horizontal'],
  ];
  return {
    vertical,
    horizontal,
  };
};

/*
|------------------
| Public API
|------------------
*/

export interface UseNotifyHook {
  /**
   * Send a fully customizable notification to the user
   *
   * @param notification A notification configuration object
   */
  send: (notification: Notification) => void;

  /**
   * Send an info notification to the user
   *
   * @param message A message to display to the user
   * @param options An object containing options for the notification
   */
  info: (message: NotificationMessage, options?: NotificationOptions) => void;

  /**
   * Send an success notification to the user
   *
   * @param message A message to display to the user
   * @param options An object containing options for the notification
   */
  success: (
    message: NotificationMessage,
    options?: NotificationOptions
  ) => void;

  /**
   * Send an warning notification to the user
   *
   * @param message A message to display to the user
   * @param options An object containing options for the notification
   */
  warning: (
    message: NotificationMessage,
    options?: NotificationOptions
  ) => void;

  /**
   * Send an error notification to the user
   *
   * @param message A message to display to the user
   * @param options An object containing options for the notification
   */
  error: (message: NotificationMessage, options?: NotificationOptions) => void;

  /**
   * Clear all notifications
   */
  clear: () => void;
}

/**
 * Send notifications to an end user in the application.
 */
export const useNotify = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const send = React.useCallback(
    (notification: Notification) => {
      enqueueSnackbar(notification.message, {
        variant: notification.severity,
        title: notification.title,
        autoHideDuration: notification.duration ?? 5000,
        anchorOrigin: getAnchorOrigin(notification.location ?? 'top-right'),
        persist: notification.persist ?? false,
      });
    },
    [enqueueSnackbar]
  );

  const notificationFactory = React.useCallback(
    (severity: NotificationSeverity, title: string) => {
      return (message: string, options?: NotificationOptions) => {
        send({
          message,
          severity,
          title: options?.title ?? title,
          location: options?.location,
          duration: options?.duration,
          persist: !!(options?.persist ?? severity === 'error'),
        });
      };
    },
    [send]
  );

  return React.useMemo(
    () => ({
      send,
      info: notificationFactory('info', 'Important'),
      success: notificationFactory('success', 'Success'),
      warning: notificationFactory('warning', 'Warning'),
      error: notificationFactory('error', 'Error'),
      clear: () => {
        closeSnackbar();
      },
    }),
    [closeSnackbar, send, notificationFactory]
  );
};
