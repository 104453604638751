import {
  Box,
  Card,
  CardHeader,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  RikerIcon,
  Stack,
  Switch,
  Tab,
  TabContext,
  TabList,
  TabPanel,
  TextField,
  Typography,
  useTheme,
} from '@joggrdocs/riker';
import _ from 'lodash';
import React from 'react';

import { useQuackr } from '../hooks/use-quackr';
import type {
  QuackrConfig,
  QuackrDevtoolConfigItem,
  QuackrDevtoolConfigItemValue,
  QuackrPlacement,
} from '../types';
import { QuackrActionLink } from './quackr-action-link';
import { QuackrQuickLinks } from './quackr-quick-links';

export const quackrConfigurationClasses = {
  root: 'QuackrConfiguration-root',
  logo: 'QuackrConfiguration-logo',
};

export interface QuackrConfigurationProps {
  /**
   * The configuration for the Quackr devtools
   */
  config: Pick<
    QuackrConfig,
    'placement' | 'devtools' | 'actionLink' | 'quickLinks'
  >;
}

export const QuackrConfiguration: React.FC<QuackrConfigurationProps> = ({
  config,
}) => {
  const [state, actions] = useQuackr();
  const theme = useTheme();
  const [mainTab, setMainTab] = React.useState<string>('quick-links');
  const [toolTab, setToolTab] = React.useState<string>(
    getFirstToolKey(config.devtools)
  );

  const quickLinks = React.useMemo(() => {
    return _.chain(config.quickLinks)
      .mapValues((link, key) => ({
        ...link,
        title: link.title ?? _.capitalize(key),
      }))
      .values()
      .value();
  }, [config.quickLinks]);

  const toolConfigs = React.useMemo(() => {
    const c = _.mapValues(config.devtools, (tool, key) => {
      return {
        ...tool,
        configs: _.values(
          _.mapValues(tool.configs, (item, k) => ({ ...item, key: k }))
        ),
        title: tool.title ?? _.capitalize(key),
        key,
      };
    });
    return _.values(c);
  }, [config]);

  return (
    <Card
      classes={{
        root: quackrConfigurationClasses.root,
      }}
      sx={{
        maxWidth: '80vh',
        minWidth: '600px',
        height: 'auto',
        borderColor: theme.palette.divider,
        [`& .${quackrConfigurationClasses.logo}`]: {
          width: '64px',
          height: 'auto',
        },
      }}
    >
      <CardHeader
        avatar={
          <img
            className={quackrConfigurationClasses.logo}
            src='https://storage.googleapis.com/joggr-public-assets/quackr/quackr-icon-rounded.png'
            alt='Quackr Logo'
          />
        }
        action={
          config.actionLink && <QuackrActionLink {...config.actionLink} />
        }
        title='Quackr Configuration'
        subTitle='Configure your devtools with Quackr'
      />
      <TabContext value={mainTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={(_e, value: typeof mainTab) => {
              setMainTab(value);
            }}
          >
            {quickLinks.length > 0 && (
              <Tab
                label='Links'
                value='quick-links'
                icon={<RikerIcon name='link' />}
                iconPosition='start'
              />
            )}
            <Tab
              label='Devtools'
              value='devtools'
              icon={<RikerIcon name='tools' />}
              iconPosition='start'
            />
            <Tab
              label='Settings'
              value='internal'
              icon={<RikerIcon name='settings' />}
              iconPosition='start'
            />
          </TabList>
        </Box>
        <Box
          sx={{
            minHeight: MIN_HEIGHT,
            maxHeight: MAX_HEIGHT,
            overflowY: 'auto',
          }}
        >
          <TabPanel value='quick-links' sx={{ p: 0 }}>
            <QuackrQuickLinks quickLinks={quickLinks} />
          </TabPanel>
          <TabPanel value='devtools' sx={{ p: 0 }}>
            <TabContext value={toolTab}>
              <Box
                sx={{
                  flexGrow: 1,
                  bgcolor: 'background.paper',
                  display: 'flex',
                  minHeight: MIN_HEIGHT,
                }}
              >
                <TabList
                  orientation='vertical'
                  variant='scrollable'
                  onChange={(_e, value: typeof toolTab) => {
                    setToolTab(value);
                  }}
                  sx={{ borderRight: 1, borderColor: 'divider' }}
                >
                  {toolConfigs.map((tool) => (
                    <Tab
                      key={tool.key}
                      label={tool.title ?? _.capitalize(tool.key)}
                      value={tool.key}
                    />
                  ))}
                </TabList>
                {toolConfigs.map((tool) => (
                  <TabPanel value={tool.key} key={tool.key}>
                    <Typography variant='h4'>{tool.title}</Typography>
                    <Typography variant='subtitle1'>
                      {tool.description ??
                        `Configuration for the ${tool.title} devtool`}
                    </Typography>
                    {tool.description && <Divider />}
                    <Stack spacing={1}>
                      {tool.configs.map((item) => (
                        <QuackrConfigFormField
                          {...item}
                          key={item.key}
                          value={
                            (state.devtools?.[tool.key]?.configs?.[item.key] ??
                              item.defaultValue) as any
                          }
                          onChange={(v) =>
                            actions.setDevtoolConfigValue(tool.key, item.key, v)
                          }
                        />
                      ))}
                    </Stack>
                  </TabPanel>
                ))}
              </Box>
            </TabContext>
          </TabPanel>
          <TabPanel value='internal'>
            <Stack spacing={1}>
              <TextField
                id='quackr-placement'
                select
                size='small'
                label='Placement'
                value={config.placement}
                onChange={(e) => {
                  const v = e.target.value as QuackrPlacement;
                  actions.setPlacement(v);
                }}
                helperText='Select the placement of the Quackr devtools button'
              >
                {['top-right', 'top-left', 'bottom-right', 'bottom-left'].map(
                  (option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  )
                )}
              </TextField>
            </Stack>
          </TabPanel>
        </Box>
      </TabContext>
    </Card>
  );
};

const MIN_HEIGHT = '360px';

const MAX_HEIGHT = '480px';

/*
|------------------
| Utils
|------------------
*/

const getFirstToolKey = (tools?: Record<string, unknown> | null) => {
  return _.chain(tools).keys().first().value();
};

const QuackrConfigFormField: React.FC<
  QuackrDevtoolConfigItem & {
    value?: QuackrDevtoolConfigItemValue;
    onChange: (v: unknown) => void;
  }
> = ({ label, helperText, value, type, onChange }) => {
  if (type === 'boolean') {
    return (
      <FormControl variant='standard'>
        <FormControlLabel
          control={
            <Switch
              onChange={(e) => onChange(e.target.checked)}
              checked={_.isBoolean(value) ? value : false}
            />
          }
          label={label}
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  }

  if (type === 'number') {
    return (
      <TextField
        label={label}
        helperText={helperText}
        onChange={(e) => onChange(_.toNumber(e.target.value))}
        type='number'
        value={_.isNumber(value) ? value : 0}
      />
    );
  }

  if (type === 'string') {
    return (
      <TextField
        label={label}
        onChange={(e) => onChange(e.target.value)}
        helperText={helperText}
        value={value}
      />
    );
  }

  return null;
};
