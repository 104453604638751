import React from 'react';

import { MenuItem, TextField, type TextFieldProps } from '@joggrdocs/riker';

import useMermaidExamples from '../hooks/useMermaidExamples';
import type { MermaidGraphType } from '../lib/mermaidUtils';

/*
|==========================================================================
| SirenaExampleSelect
|==========================================================================
|
| A select component that allows the user to select a mermaid example.
|
*/

export interface MermaidExample {
  name: string;
  graphType: MermaidGraphType;
  graphCode: string;
}

export interface SirenaExamplesSelectProps
  extends Omit<TextFieldProps, 'onChange'> {
  onChangeExample: (mermaidExample: MermaidExample) => void;
}

export const SirenaExampleSelect: React.FC<SirenaExamplesSelectProps> = ({
  onChangeExample,
  ...props
}) => {
  const mermaidExamples = useMermaidExamples();

  const handleChangeExample = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const example = mermaidExamples.getExample(
        event.target.value as MermaidGraphType
      );

      if (example) {
        onChangeExample(example);
      }
    },
    [mermaidExamples, onChangeExample]
  );

  return (
    <TextField
      size={props.size ?? 'small'}
      label='Example Diagram'
      select
      defaultValue=''
      onChange={handleChangeExample}
      sx={{
        ...props.sx,
        minWidth: '200px',
      }}
    >
      {mermaidExamples.examples.map(({ type, name }) => (
        <MenuItem key={type} value={type}>
          {name}
        </MenuItem>
      ))}
    </TextField>
  );
};

const MemoizedSirenaExampleSelect = React.memo(SirenaExampleSelect);
MemoizedSirenaExampleSelect.displayName = 'SirenaExampleSelect';

export default MemoizedSirenaExampleSelect;
