import React from 'react';

import {
  Box,
  Button,
  IconGitBranch,
  IconListTree,
  Stack,
  Tooltip,
  Typography,
} from '@joggrdocs/riker';

import {
  GitHubOrganizationIcon,
  GitHubRepositoryIcon,
} from '@stargate/features/github';

import type { CodeExplorerQuery } from '../../types';
import { CodeSnippetExample } from '../sources/CodeSnippetExample';

export interface CodeExplorerContentPlaceholderProps {
  /**
   * Whether the file tree is open.
   */
  fileTreeOpen: boolean;

  /**
   * The code explorer query.
   */
  query?: CodeExplorerQuery;

  /**
   * Callback triggered when the file tree focus changes.
   */
  onOpenFileTree: () => void;
}

export const CodeExplorerContentPlaceholder: React.FC<
  CodeExplorerContentPlaceholderProps
> = ({ fileTreeOpen, query, onOpenFileTree }) => {
  /*
  |------------------
  | Computed
  |------------------
  */

  const canOpenFileTree = Boolean(
    query?.githubOrganizationId && query?.githubRepositoryId
  );

  const missingQuery = null;

  // const missingQuery = React.useMemo<MissingQueryType | null>(() => {
  //   if (props.searchQuery?.owner === null || !props.searchQuery) {
  //     return 'owner';
  //   }
  //   if (!props.searchQuery?.repository) {
  //     return 'repository';
  //   }
  //   if (!props.searchQuery?.branch) {
  //     return 'branch';
  //   }
  //   return null;
  // }, [props.searchQuery]);

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const handleFileTreeFocusChange = (x: any) => {};

  // const handleFileTreeFocusChange = React.useCallback<
  //   CodeExplorerContentPlaceholderProps['onFileTreeFocusChange']
  // >(
  //   (focus) => {
  //     onFileTreeFocusChange(focus);
  //   },
  //   [onFileTreeFocusChange]
  // );

  return (
    <Box
      sx={{
        ml: 'auto',
        mr: 'auto',
        textAlign: 'center',
        mt: 4,
      }}
    >
      <CodeSnippetExample />
      <Stack
        direction='column'
        spacing={1}
        sx={{
          mt: 2,
          mx: 'auto',
          maxWidth: '400px',
        }}
      >
        {missingQuery === 'owner' && (
          <React.Fragment>
            <Button
              startIcon={<GitHubOrganizationIcon />}
              onClick={() => {
                handleFileTreeFocusChange('owner');
              }}
              // disabled={props.fileTreeQueryFocus === 'owner'}
              sx={{
                maxWidth: '300px',
                margin: 'auto !important',
              }}
            >
              Select GitHub Organization
            </Button>
            <Typography variant='caption' lineHeight={1.2}>
              To get started you need to select a GitHub organization and a
              repository.
            </Typography>
          </React.Fragment>
        )}
        {missingQuery === 'repository' && (
          <React.Fragment>
            <Button
              startIcon={<GitHubRepositoryIcon />}
              onClick={() => {
                handleFileTreeFocusChange('repository');
              }}
              // disabled={props.fileTreeQueryFocus === 'repository'}
              sx={{
                maxWidth: '300px',
                margin: 'auto !important',
              }}
            >
              Select GitHub Repository
            </Button>
            <Typography variant='caption' lineHeight={1.2}>
              To get started you need to select a GitHub organization and a
              repository.
            </Typography>
          </React.Fragment>
        )}
        {missingQuery === 'branch' && (
          <React.Fragment>
            <Button
              startIcon={<IconGitBranch />}
              onClick={() => {
                handleFileTreeFocusChange('branch');
              }}
              // disabled={props.fileTreeQueryFocus === 'branch'}
              sx={{
                maxWidth: '300px',
                margin: 'auto !important',
              }}
            >
              Select a branch
            </Button>
            <Typography variant='caption' lineHeight={1.2}>
              You need to select a branch to continue.
            </Typography>
          </React.Fragment>
        )}
        {missingQuery === null && (
          <React.Fragment>
            <Tooltip
              title={
                !canOpenFileTree
                  ? 'You need to select a GitHub Organization and Repository before you can open the file tree.'
                  : null
              }
            >
              <span>
                <Button
                  startIcon={<IconListTree />}
                  onClick={onOpenFileTree}
                  disabled={fileTreeOpen || !canOpenFileTree}
                  sx={{
                    maxWidth: '300px',
                    margin: 'auto !important',
                  }}
                >
                  Open the file tree
                </Button>
              </span>
            </Tooltip>
            <Typography variant='caption' lineHeight={1.2}>
              Open the file tree and select a file to inject a code snippet.
            </Typography>
          </React.Fragment>
        )}
      </Stack>
    </Box>
  );
};
