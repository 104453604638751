import * as Sentry from '@sentry/react';
import { Node, mergeAttributes, textblockTypeInputRule } from '@tiptap/core';
import { Plugin, PluginKey, TextSelection } from '@tiptap/pm/state';
import { ReactNodeViewRenderer } from '@tiptap/react';
import _ from 'lodash';

import { mermaidUtils } from '@stargate/lib/sirena';
import { devLogger } from '@stargate/logger';

import {
  getSelectionNode,
  isSelectionEmptyCodeSnippet,
  isSelectionEmptyLine,
} from '@dashdraft/utils/editor';

import { CodeBlock as CodeBlockNodeView } from '../components/CodeBlock';
import { PLACEHOLDER_DEFAULT_CONTENT } from '../lib/placeholder';
import type {
  CodeBlockBasicAttributes,
  CodeBlockLanguage,
  CodeBlockMermaidAttributes,
  CodeBlockSnippetAttributes,
} from '../types';

export const PREFIX_LANGUAGE_CLASS = 'language-';

export const REGEX_BACKTICK_INPUT = /^```([a-z]+)?[\s\n]$/;

export const REGEX_TILDE_INPUT = /^~~~([a-z]+)?[\s\n]$/;

export interface CodeBlockOptions {
  HTMLAttributes: Record<string, string>;
}

/**
 * An extension that allows to create code blocks and code snippets.
 *
 * copied from the tiptap code block extension:
 * @see https://github.com/ueberdosis/tiptap/blob/develop/packages/extension-code-block/src/code-block.ts
 */
export const CodeBlock = Node.create<CodeBlockOptions>({
  name: 'codeBlock',
  content: 'text*',
  marks: '',
  group: 'block',
  code: true,
  whitespace: 'pre',
  selectable: true,
  draggable: true,
  defining: true,
  allowGapCursor: true,

  addNodeView() {
    // @todo fix types here or in TipTap
    // @ts-expect-error - types broken due to TipTap, need to fix
    return ReactNodeViewRenderer(CodeBlockNodeView);
  },

  addAttributes() {
    return {
      type: {
        default: 'basic',
        parseHTML: (element) => {
          if (
            element.dataset.language === 'placeholder' ||
            element.dataset.type === 'codesnippet'
          ) {
            return 'snippet';
          }

          if (
            element.dataset.type === 'mermaid' ||
            element.dataset.language === 'mermaid'
          ) {
            return 'mermaid';
          }

          return 'basic';
        },
        renderHTML(attributes) {
          if (!attributes.type) {
            return {};
          }
          return {
            // Has to match Bumblebee's code block data type aka "mermaid + basic = codeblock" and "snippet = codesnippet"
            'data-type':
              attributes.type === 'snippet' ? 'codesnippet' : 'codeblock',
          };
        },
      },
      placeholder: {
        default: false,
        parseHTML: (element) => {
          return (
            element.dataset.placeholder === 'true' ||
            // Trick for us to maintain placeholder state in markdown
            element.dataset.language === 'placeholder'
          );
        },
        renderHTML: (attributes) => {
          if (attributes.placeholder === false) {
            return {};
          }
          return {
            'data-placeholder': `${attributes.placeholder}`,
          };
        },
      },
      language: {
        default: null,
        parseHTML: (element) => {
          return element.dataset.language;
        },
        renderHTML: (attributes) => {
          if (!attributes.language) {
            return {};
          }
          return {
            class: `${PREFIX_LANGUAGE_CLASS}${attributes.language} ${
              attributes.class || ''
            }`,
            'data-language': attributes.language,
          };
        },
      },
      codeSnippetId: {
        default: null,
        parseHTML: (element: HTMLElement) => {
          try {
            if (element.dataset.codeSnippetId) {
              return element.dataset.codeSnippetId;
            }
            return null;
          } catch (err) {
            devLogger.fatal(
              {
                err,
                element,
              },
              'Unable to Parse HTML for a Code Snippet ID'
            );
            Sentry.captureException(err, {
              extra: {
                message: 'Failed to parse HTML for a Code Snippet ID',
              },
            });
            return null;
          }
        },
        renderHTML: (attributes: Record<string, string>) => {
          try {
            if (attributes.codeSnippetId) {
              return {
                'data-code-snippet-id': attributes.codeSnippetId,
              };
            }
            return {};
          } catch (err) {
            devLogger.fatal(
              {
                err,
                attributes,
              },
              'Unable to Render HTML for a Code Snippet ID'
            );
            Sentry.captureException(err, {
              extra: {
                message: 'Unable to Render HTML for a Code Snippet ID',
              },
            });
            return {};
          }
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'pre',
        preserveWhitespace: 'full',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'pre',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      ['code', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0],
    ];
  },

  addCommands() {
    return {
      setMermaid:
        (payload) =>
        ({ chain, tr }) => {
          return chain()
            .focus()
            .insertContentAt(
              tr.selection.from,
              {
                type: this.name,
                attrs: {
                  ...payload,
                  type: 'mermaid',
                  language: 'mermaid',
                  placeholder: false,
                } as CodeBlockMermaidAttributes,
                content: [
                  {
                    type: 'text',
                    text:
                      payload?.code ??
                      mermaidUtils.getExampleGraph('flowchart'),
                  },
                ],
              },
              {
                updateSelection: true,
              }
            )
            .run();
        },
      setCodeBlock:
        (payload) =>
        ({ chain, commands, tr }) => {
          if (payload?.language === 'mermaid') {
            return commands.setMermaid();
          }

          return chain()
            .focus()
            .insertContentAt(
              tr.selection.from,
              {
                type: this.name,
                attrs: {
                  ...payload,
                  language:
                    payload?.language ?? codeBlockBasicDefaults.language,
                  codeSnippetId: null,
                  type: 'basic',
                  placeholder: false,
                } satisfies CodeBlockBasicAttributes,
                content: [
                  {
                    type: 'text',
                    text: payload?.code ?? codeBlockBasicDefaults.code,
                  },
                ],
              },
              {
                updateSelection: true,
              }
            )
            .run();
        },
      toggleCodeBlock:
        (payload) =>
        ({ chain }) => {
          if (payload?.language === 'mermaid') {
            devLogger.warn('Cannot toggle code block to mermaid ');
            return false;
          }

          return chain()
            .toggleNode('paragraph', this.name, {
              ...payload,
              language: payload?.language ?? codeBlockBasicDefaults.language,
              codeSnippetId: null,
              type: 'basic',
              placeholder: false,
            } satisfies CodeBlockBasicAttributes)
            .run();
        },
      setCodeSnippet:
        (payload) =>
        ({ chain, tr }) => {
          // Empty lines we can just replace
          if (isSelectionEmptyLine(tr)) {
            return chain()
              .insertContentAt(
                {
                  from: tr.selection.from,
                  to: tr.selection.to,
                },
                {
                  type: this.name,
                  attrs: {
                    language: payload.language,
                    type: 'snippet',
                    codeSnippetId: payload.codeSnippetId,
                    placeholder: false,
                  },
                  content: [
                    {
                      type: 'text',
                      text: payload.code,
                    },
                  ],
                },
                {
                  updateSelection: true,
                }
              )
              .closeCodeExplorer()
              .run();
          }

          const currNode = getSelectionNode(tr);
          if (isSelectionEmptyCodeSnippet(tr) && !_.isNil(currNode)) {
            if (!_.isNil(currNode)) {
              return chain()
                .toggleNode(currNode.type, this.name, {
                  type: 'snippet',
                  codeSnippetId: payload.codeSnippetId,
                  language: payload.language,
                  placeholder: false,
                } as CodeBlockSnippetAttributes)
                .insertContent(
                  {
                    type: 'text',
                    text: payload.code,
                  },
                  {
                    updateSelection: true,
                    parseOptions: {
                      preserveWhitespace: 'full',
                    },
                  }
                )
                .run();
            }
          }

          devLogger.warn(
            'Cannot set code snippet in non-empty code block due to invalid selection'
          );
          return false;
        },
      setEmptyCodeSnippet:
        () =>
        ({ chain }) => {
          return chain()
            .insertContent(
              {
                type: this.name,
                attrs: {
                  type: 'snippet',
                  codeSnippetId: null,
                  // Trick for us to maintain placeholder state in markdown
                  language: 'placeholder' as CodeBlockLanguage,
                  placeholder: true,
                } satisfies CodeBlockSnippetAttributes,
                content: [
                  {
                    type: 'text',
                    text: PLACEHOLDER_DEFAULT_CONTENT,
                  },
                ],
              },
              {
                updateSelection: true,
              }
            )
            .openCodeExplorer()
            .run();
        },
    };
  },
  addKeyboardShortcuts() {
    return {
      'Mod-Alt-c': () => this.editor.commands.toggleCodeBlock(),

      // remove code block when at start of document or code block is empty
      Backspace: () => {
        const { empty, $anchor } = this.editor.state.selection;
        const isAtStart = $anchor.pos === 1;

        if (!empty || $anchor.parent.type.name !== this.name) {
          return false;
        }

        if (isAtStart || !$anchor.parent.textContent.length) {
          return this.editor.commands.clearNodes();
        }

        return false;
      },

      // ArrowUp: ({ editor }) => {
      //   const { state } = editor;
      //   const { selection, doc } = state;
      //   const { $from } = selection;

      //   const before = $from.before();
      //   const childBefore = doc.childBefore(before);

      //   if (before <= 0) {
      //     return false;
      //   }

      //   if (childBefore.node?.type.name === this.name) {
      //     const node = editor.$node('codeBlock', {
      //       uid: childBefore.node.attrs.uid,
      //     });

      //     if (node) {
      //       return editor.commands.setNodeSelection(node.to - node.size);
      //     }
      //   }

      //   return false;
      // },

      // ArrowDown: ({ editor }) => {
      //   const { state } = editor;
      //   const { selection, doc } = state;
      //   const { $from } = selection;
      //   const after = $from.after();

      //   if (after === undefined) {
      //     return false;
      //   }

      //   const nodeAfter = doc.nodeAt(after);

      //   if (!nodeAfter) {
      //     return false;
      //   }

      //   if (nodeAfter.type.name === this.name) {
      //     return editor.commands.setNodeSelection(after);
      //   }

      //   return false;
      // },
    };
  },

  addInputRules() {
    return [
      textblockTypeInputRule({
        find: REGEX_BACKTICK_INPUT,
        type: this.type,
        getAttributes: (match) => ({
          language: match[1],
          type: match[1] === 'mermaid' ? 'mermaid' : 'basic',
        }),
      }),
      textblockTypeInputRule({
        find: REGEX_TILDE_INPUT,
        type: this.type,
        getAttributes: (match) => ({
          language: match[1],
          type: match[1] === 'mermaid' ? 'mermaid' : 'basic',
        }),
      }),
    ];
  },

  addProseMirrorPlugins() {
    return [
      // this plugin creates a code block for pasted content from VS Code
      // we can also detect the copied code language
      new Plugin({
        key: new PluginKey('codeBlockVSCodeHandler'),
        props: {
          handlePaste: (view, event) => {
            if (!event.clipboardData) {
              return false;
            }

            // don’t create a new code block within code blocks
            if (this.editor.isActive(this.type.name)) {
              return false;
            }

            const text = event.clipboardData.getData('text/plain');
            const vscode = event.clipboardData.getData('vscode-editor-data');
            const vscodeData = vscode ? JSON.parse(vscode) : undefined;
            const language = vscodeData?.mode;

            if (!text || !language) {
              return false;
            }

            const { tr } = view.state;

            // create an empty code block
            tr.replaceSelectionWith(this.type.create({ language }));

            // put cursor inside the newly created code block
            tr.setSelection(
              TextSelection.near(
                tr.doc.resolve(Math.max(0, tr.selection.from - 2))
              )
            );

            // add text to code block
            // strip carriage return chars from text pasted as code
            // see: https://github.com/ProseMirror/prosemirror-view/commit/a50a6bcceb4ce52ac8fcc6162488d8875613aacd
            tr.insertText(text.replace(/\r\n?/g, '\n'));

            // store meta information
            // this is useful for other plugins that depends on the paste event
            // like the paste rule plugin
            tr.setMeta('paste', true);

            view.dispatch(tr);

            return true;
          },
        },
      }),
    ];
  },
});

export default CodeBlock.configure({
  HTMLAttributes: {
    class: 'dashdraft-codeblock',
  },
});

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    codeBlock: {
      /**
       * Set a mermaid code block
       *
       * @param payload - The mermaid code block payload
       * @param payload.code - The mermaid code block content
       */
      setMermaid: (attributes?: {
        code: string;
      }) => ReturnType;

      /**
       * Set a code block
       *
       * @param payload - The code block payload
       * @param payload.code - The code block content
       * @param payload.language - The language of the code block
       */
      setCodeBlock: (payload?: {
        language?: CodeBlockLanguage;
        code?: string;
      }) => ReturnType;

      /**
       * Toggle a code block
       *
       * @param payload - The code block payload
       * @param payload.code - The code block content
       * @param payload.language - The language of the code block
       */
      toggleCodeBlock: (attributes?: {
        language?: CodeBlockLanguage;
        code?: string;
      }) => ReturnType;

      /**
       * Set a code snippet
       *
       * @param payload - The code snippet payload
       * @param payload.code - The code snippet content
       * @param payload.language - The language of the code block
       */
      setCodeSnippet: (attributes: {
        codeSnippetId: string;
        code: string;
        language?: CodeBlockLanguage;
      }) => ReturnType;

      /**
       * Toggle a code snippet with an empty code block (placeholder)
       */
      setEmptyCodeSnippet: () => ReturnType;
    };
  }
}

/*
|------------------
| Utils
|------------------
*/

export const codeBlockBasicDefaults = {
  language: 'javascript',
  code: 'console.log("Hello, World!")',
} as const;
