import React from 'react';

import {
  IconBuildingCommunity,
  type RikerNamedIcon,
  type RikerNamedIconProps,
  useTheme,
} from '@joggrdocs/riker';

export type GitHubOrganizationIconProps = RikerNamedIconProps;

/**
 * A component that displays a repository icon.
 */
export const GitHubOrganizationIcon = React.forwardRef<
  RikerNamedIcon,
  GitHubOrganizationIconProps
>((props, ref) => {
  const theme = useTheme();
  return (
    <IconBuildingCommunity
      {...props}
      ref={ref}
      fill={theme.palette.primary.light}
      color={theme.palette.primary.dark}
    />
  );
});
GitHubOrganizationIcon.displayName = 'GitHubOrganizationIcon';
