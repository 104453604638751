import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import React from 'react';

import { useMountEffect, usePrevious } from '@react-hookz/web';
import {
  type JDocCreateMutationPayload,
  jdocCreateMutationFn,
  templateQueryOptions,
  useJDocUpdateCodeSourcesMutation,
  useJDocUpdateTagsMutation,
} from '@stargate/api';
import { Page } from '@stargate/components/Utils';
import { useDashDraftEditor } from '@stargate/dashdraft';
import { CodeExplorer } from '@stargate/features/code';
import { useDirectoryTree } from '@stargate/features/directories';
import {
  type JDoc,
  JDocContent,
  type JDocDraft,
  type JDocDraftQueryResult,
  type JDocTemplate,
  JoggrDocActions,
  type JoggrDocActionsProps,
  JoggrDocAuthor,
  JoggrDocBreadcrumbs,
  JoggrDocCodeLinks,
  JoggrDocContentLayout,
  JoggrDocDraftDetails,
  JoggrDocFileLocation,
  JoggrDocLayout,
  JoggrDocLayoutDivider,
  JoggrDocSave,
  type JoggrDocSaveProps,
  JoggrDocSummary,
  JoggrDocTableOfContents,
  JoggrDocTemplateChip,
  JoggrDocTemplateDirections,
  JoggrDocTitle,
  JoggrDocVersionDetails,
  joggrDocContentLayoutClasses,
  useJDocContext,
  useJDocDraftMutate,
  useJDocDraftQuery,
  useJDocTableOfContents,
  useJdocValidate,
} from '@stargate/features/docs';
import { filterCodeLinks } from '@stargate/features/docs/utils';
import { useGithubRepository } from '@stargate/features/github';
import { useUser } from '@stargate/features/user';
import { useDelayedState } from '@stargate/hooks';
import { useNightingale } from '@stargate/lib/nightingale';
import { useNotify } from '@stargate/lib/notify';
import { useLocalization } from '@stargate/localization';
import { useNavigate } from '@stargate/routes';
import * as utils from '@stargate/utils';
import { FrigadeTarget } from '@stargate/vendors/frigade/components/FrigadeTarget';
import { sentryInstance, useSentry } from '@stargate/vendors/sentry';

/**
 * Edit or View an existing JoggrDoc.
 */
const DocCreatePageBase: React.FC = () => {
  const ctx = useJDocContext();
  const jdocDraftQuery = useJDocDraftQuery();
  const jdocDraftMutate = useJDocDraftMutate();
  const editor = useDashDraftEditor();
  const dirTree = useDirectoryTree();
  const sentry = useSentry();
  const queryClient = useQueryClient();
  const joggrDocTOC = useJDocTableOfContents();
  const nightingale = useNightingale();
  const navigate = useNavigate();
  const localz = useLocalization();
  const notify = useNotify();
  const user = useUser();
  const [contentLoading, triggerContentLoading] = useContentLoading();

  const dirId = React.useMemo(() => {
    if (jdocDraftQuery.dirId) {
      return jdocDraftQuery.dirId;
    }

    if (ctx.dirId && ctx.dirId !== 'root') {
      return ctx.dirId;
    }
  }, [jdocDraftQuery.dirId, ctx.dirId]);

  /*
  |------------------
  | Utils
  |------------------
  */

  /**
   * Clear the editor, resetting the ToC and the content.
   */
  const clearEditor = React.useCallback(() => {
    editor.commands.clearContent(false);
    joggrDocTOC.clear();
    triggerContentLoading();
  }, [joggrDocTOC.clear, editor.commands.clearContent, triggerContentLoading]);

  /**
   * Clear the draft, resetting the draft to the initial state.
   */
  const clearDraft = React.useCallback(() => {
    clearEditor();
    jdocDraftMutate.clear();
    queryClient.invalidateQueries({
      queryKey: templateQueryOptions(ctx.templateId).queryKey,
    });
  }, [jdocDraftMutate.clear, clearEditor, queryClient, ctx.templateId]);

  /*
  |------------------
  | Queries & Mutations
  |------------------
  */

  const templateQuery = useQuery({
    ...templateQueryOptions(ctx.templateId),
    enabled: !_.isNil(ctx.templateId),
  });

  const jdocUpdateCodeSourcesMutation = useJDocUpdateCodeSourcesMutation();
  const jdocUpdateTagsMutation = useJDocUpdateTagsMutation();

  const jdocCreateMutation = useMutation({
    mutationFn: async (payload: JDocCreateMutationPayload) => {
      const result = await jdocCreateMutationFn(payload);

      // If we have code links we add them... codesnippet handled in content
      const codeLinks = filterCodeLinks(jdocDraftQuery.codeSources ?? []);
      if (codeLinks.length > 0) {
        void jdocUpdateCodeSourcesMutation.mutateAsync({
          documentId: result.id,
          codeSourceIds: codeLinks.map((codeSource) => codeSource.id),
        });
      }

      if (dirId) {
        await dirTree.onMoveDirectoryDocument({
          fromDirectoryId: null,
          documentId: result.id,
          toDirectoryId: dirId,
        });
      }

      // Always reload the directory tree
      void dirTree.onLoad();

      // If we have tags, update (upsert) them
      if (jdocDraftQuery.tags) {
        void jdocUpdateTagsMutation.mutateAsync({
          documentId: result.id,
          tags: jdocDraftQuery.tags.map((tag) => tag.id),
        });
      }

      return result;
    },
    onSuccess: (data) => {
      notify.success(localz.formatMessage('features.docs.save.success.create'));
      void nightingale.capture(['create', 'document'], {
        documentId: data.id,
      });
      clearEditor();
      jdocDraftMutate.clear();
      navigate('app.documents.edit', {
        params: {
          id: data.id,
        },
      });
    },
    onError: (error, variables) => {
      notify.error(localz.formatMessage('features.docs.save.error'));
      sentry.captureException(error, {
        data: {
          payload: variables,
        },
      });
    },
  });

  /*
  |------------------
  | Computed
  |------------------
  */

  const draftDoc = useMergeDraftDoc(jdocDraftQuery.doc, templateQuery.data);
  // Merge the data from the doc and the template
  const draft = React.useMemo<JDocDraftQueryResult>(() => {
    return {
      ...jdocDraftQuery,
      dirId,
      doc: draftDoc,
    };
  }, [jdocDraftQuery, draftDoc, dirId]);

  const jdocValid = useJdocValidate({
    doc: draft.doc,
    draft: draft.doc,
    mode: ctx.mode,
  });

  const loading = React.useMemo(() => {
    return _.some([templateQuery.isLoading, contentLoading, user.loading]);
  }, [templateQuery, contentLoading, user.loading]);

  // Only show when we have content and are missing the file location
  const showFileLocationHint = React.useMemo(() => {
    const missingFields = jdocValid.missingFields;
    const fileLocationMissing =
      missingFields.includes('github.repository.id') ||
      missingFields.includes('github.filePath');
    return (
      !missingFields.includes('content') &&
      !missingFields.includes('title') &&
      fileLocationMissing
    );
  }, [jdocValid.missingFields]);

  // The author is always the current user (for now...)
  const author = React.useMemo<JDoc['author'] | null>(() => {
    if (user.data) {
      return {
        id: user.data.id,
        email: user.data.email,
        githubUsername: user.data.githubUsername,
        githubId: user.data.githubId,
      };
    }
    return null;
  }, [user.data]);

  /*
  |------------------
  | Callbacks
  |------------------
  */

  /**
   * Handle the actions from the JoggrDocActions component.
   */
  const handleAction = React.useCallback<JoggrDocActionsProps['onAction']>(
    async (payload) => {
      if (payload.action === 'revert') {
        clearDraft();
      } else if (payload.action === 'tags') {
        jdocDraftMutate.updateTags(payload.data);
      }
    },
    [clearDraft, jdocDraftMutate.updateTags]
  );

  const handleSave = React.useCallback<JoggrDocSaveProps['onSave']>(
    async (commit) => {
      try {
        const validDraft = jdocValid.validateCurrentDraft();

        if (!validDraft.valid) {
          const missingData = validDraft.missing
            .map((field) => {
              return localz.formatMessage(
                `features.docs.save.error.missing.${field}`
              );
            })
            .join(', ');

          notify.error(
            localz.formatMessage('features.docs.save.error.missing', {
              missingData,
            })
          );
          return;
        }

        await jdocCreateMutation.mutateAsync({
          data: {
            title: validDraft.draft.title,
            summary: validDraft.draft.summary,
            content: validDraft.draft.content,
            filePath: validDraft.draft.github.filePath,
            branchName: commit.branch,
            repositoryId: validDraft.draft.github.repository.id.toString(),
            repositoryOwnerId:
              validDraft.draft.github.repository.owner.id.toString(),
            message: commit.message,
            sha: commit.sha,
            draftPullRequest: commit.pullRequest === 'draft',
            createPullRequest: commit.pullRequest !== 'none',
          },
        });
      } catch (error) {
        const ex = utils.getErrorSafely(
          error,
          'Unknown error when saving JoggrDoc'
        );
        notify.error(ex.message);
        sentry.captureException(ex, {
          data: {
            commit,
          },
        });
      }
    },
    [
      notify,
      jdocValid.validateCurrentDraft,
      sentry,
      jdocCreateMutation.mutateAsync,
      localz.formatMessage,
    ]
  );

  /*
  |------------------
  | Effects
  |------------------
  */

  useMountEffect(() => {
    clearEditor();
    triggerContentLoading();
  });

  const prevTemplateId = usePrevious(ctx.templateId);
  // biome-ignore lint/correctness/useExhaustiveDependencies: Only run when the templateId changes
  React.useEffect(() => {
    if (ctx.templateId && ctx.templateId !== prevTemplateId) {
      clearEditor();
      triggerContentLoading();
      queryClient.invalidateQueries({
        queryKey: templateQueryOptions(ctx.templateId).queryKey,
      });
    }
  }, [ctx.templateId]);

  return (
    <Page
      id={'create-document'}
      title={draft?.doc?.title ?? 'Create JoggrDoc'}
      errors={[templateQuery.error]}
      loading={loading}
      renderProps={{
        draft: draft ?? ({} as JDocDraftQueryResult),
        author,
        mode: 'create' as const,
      }}
      gutter={false}
      render={({ draft, mode, author }) => {
        return (
          <React.Fragment>
            <JoggrDocLayout
              loading={false}
              metadata={
                <React.Fragment>
                  <FrigadeTarget targetId='jdoc-file-location'>
                    <JoggrDocFileLocation
                      doc={null}
                      draft={draft}
                      mode={mode}
                      showHint={showFileLocationHint}
                    />
                  </FrigadeTarget>
                  <JoggrDocLayoutDivider gutter={0} />
                  <FrigadeTarget targetId='jdoc-version-details'>
                    <JoggrDocVersionDetails
                      doc={null}
                      draft={draft}
                      mode={mode}
                    />
                  </FrigadeTarget>
                  <JoggrDocLayoutDivider gutter={0} />
                  <JoggrDocDraftDetails
                    doc={null}
                    mode={mode}
                    draft={draft}
                    modifiedFields={jdocValid.modifiedFields}
                  />
                </React.Fragment>
              }
              actions={
                <React.Fragment>
                  <FrigadeTarget targetId='jdoc-save'>
                    <JoggrDocSave
                      doc={null}
                      draft={draft}
                      mode={mode}
                      modifiedFields={jdocValid.modifiedFields}
                      missingFields={jdocValid.missingFields}
                      onSave={handleSave}
                    />
                  </FrigadeTarget>
                  <FrigadeTarget targetId='jdoc-actions'>
                    <JoggrDocActions
                      doc={null}
                      draft={draft}
                      mode={mode}
                      modified={jdocValid.modifiedFields.length > 0}
                      loading={false}
                      onAction={handleAction}
                    />
                  </FrigadeTarget>
                </React.Fragment>
              }
              content={
                <JoggrDocContentLayout
                  title={
                    <React.Fragment>
                      <JoggrDocTemplateChip template={templateQuery.data} />
                      <JoggrDocTitle doc={null} draft={draft} mode={mode} />
                    </React.Fragment>
                  }
                  summary={
                    <JoggrDocSummary doc={null} draft={draft} mode={mode} />
                  }
                  metadata={
                    <React.Fragment>
                      <FrigadeTarget targetId='jdoc-author'>
                        <JoggrDocAuthor author={author} />
                      </FrigadeTarget>
                      <JoggrDocLayoutDivider />
                      <FrigadeTarget targetId='jdoc-breadcrumbs'>
                        <JoggrDocBreadcrumbs
                          doc={null}
                          draft={draft}
                          mode={mode}
                        />
                      </FrigadeTarget>
                      <JoggrDocLayoutDivider />
                      <FrigadeTarget targetId='jdoc-code-links'>
                        <JoggrDocCodeLinks
                          doc={null}
                          draft={draft}
                          mode={mode}
                        />
                      </FrigadeTarget>
                    </React.Fragment>
                  }
                  content={
                    <React.Fragment>
                      <JoggrDocTemplateDirections
                        template={templateQuery.data}
                      />
                      <JDocContent
                        doc={null}
                        draft={draft}
                        mode={mode}
                        loading={false}
                      />
                    </React.Fragment>
                  }
                  onClick={(env, section) => {
                    if (
                      section === 'content' &&
                      !editor.isFocused &&
                      // @ts-expect-error - valid check
                      env.target.classList?.contains(
                        joggrDocContentLayoutClasses.content
                      )
                    ) {
                      editor.commands.focus();
                    }
                  }}
                />
              }
            />
            <JoggrDocTableOfContents
              doc={null}
              draft={draft}
              mode={mode}
              loading={false}
            />
            <CodeExplorer
              doc={null}
              draft={draft}
              mode={mode}
              readonly={false}
            />
          </React.Fragment>
        );
      }}
    />
  );
};

export const DocCreatePage = sentryInstance.withProfiler(DocCreatePageBase, {
  name: 'DocCreatePage',
});

/*
|------------------
| Utils
|------------------
*/

/**
 *
 * @param draft A draft document
 * @param template A template document
 * @returns A new draft document with the template data merged in
 */
const useMergeDraftDoc = (
  draft?: JDocDraft | null,
  template?: JDocTemplate | null
): JDocDraft => {
  const githubRepository = useGithubRepository(
    draft?.github?.repository?.id.toString()
  );
  return React.useMemo(() => {
    // We have to append the `untitled.md` to the directory path for FileLocation components
    const templateFilePath = `${template?.recommendations.directoryPath ?? 'docs'}/untitled.md`;
    return {
      ...draft,
      github: {
        ...draft?.github,
        repository: githubRepository.data ?? undefined,
        filePath: draft?.github?.filePath ?? templateFilePath,
      },
      title: draft?.title ?? template?.name ?? '',
      summary: draft?.summary ?? template?.description ?? '',
      content: draft?.content ?? template?.content ?? undefined,
    };
  }, [draft, template, githubRepository]);
};

const useContentLoading = () => {
  const [loading, setLoading, setLoadingDelayed] = useDelayedState(false);

  const trigger = React.useCallback(
    (ts = 250) => {
      setLoading(true);
      setLoadingDelayed(false, ts);
    },
    [setLoading, setLoadingDelayed]
  );

  return [loading, trigger] as const;
};
