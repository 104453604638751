import { Node, mergeAttributes, nodePasteRule } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import _ from 'lodash';

import {
  REGEX_LOOM,
  getLoomEmbedUrl,
  getLoomVideoId,
  isValidLoomUrl,
} from '@joggrdocs/bumblebee';

import { LoomNodeView } from '../components/Loom/LoomNodeView';
import type { LoomNodeAttributes } from '../types';

export type SetLoomPayload = LoomNodeAttributes;

export type SetLoomOptions = Required<LoomNodeAttributes>['options'];

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    loom: {
      /**
       * Insert a Loom video
       *
       * @param payload A payload object containing the src, width, height, and start of the video.
       * @param options An options object containing a forceOpen boolean.
       */
      setLoomVideo: (
        payload: SetLoomPayload,
        options?: SetLoomOptions
      ) => ReturnType;

      /**
       * Insert a blank Loom video
       *
       * @param options A options object containing a forceOpen boolean.
       */
      setEmptyLoomVideo: (options?: SetLoomOptions) => ReturnType;
    };
  }
}

export const Loom = Node.create({
  name: 'loom',

  inline: false,

  group: 'block',

  draggable: true,

  addNodeView() {
    // @todo fix types here or in TipTap
    // @ts-expect-error - types broken due to TipTap, need to fix
    return ReactNodeViewRenderer(LoomNodeView);
  },

  addAttributes() {
    return {
      src: {
        default: null,
      },
      options: {
        default: { forceOpen: false },
      },
    };
  },

  addCommands() {
    return {
      setLoomVideo:
        (payload, options) =>
        ({ commands }) => {
          if (!isValidLoomUrl(payload.src)) {
            return false;
          }

          return commands.insertContent({
            type: this.name,
            attrs: {
              ...payload,
              options,
            },
          });
        },

      setEmptyLoomVideo:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: {
              src: '',
              options,
            },
          });
        },
    };
  },

  addPasteRules() {
    return [
      nodePasteRule({
        find: new RegExp(REGEX_LOOM, 'g'),
        type: this.type,
        getAttributes: (match) => {
          return { src: match.input };
        },
      }),
    ];
  },

  parseHTML() {
    return [
      {
        tag: 'div[data-loom-video] iframe',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const videoId =
      _.isString(HTMLAttributes.src) && !_.isEmpty(HTMLAttributes.src)
        ? getLoomVideoId(HTMLAttributes.src)
        : null;
    const embedUrl =
      !_.isNil(videoId) && !_.isEmpty(videoId)
        ? getLoomEmbedUrl(videoId)
        : null;
    HTMLAttributes.src = embedUrl;
    return [
      'div',
      { 'data-loom-video': '' },
      [
        'iframe',
        mergeAttributes(
          { className: 'dashdraft-media dashdraft-media-loom' },
          HTMLAttributes
        ),
      ],
    ];
  },
}).configure();
