import _ from 'lodash';
import React from 'react';

import { useShortcuts } from '@stargate/features/shortcuts';

import type { DirectoryTreeNode } from '../../types';
import { ActionButton, type ActionButtonProps } from './ActionButton';

/*
|==========================================================================
| DocumentAction
|==========================================================================
|
| Create a document from the current selection or at the top level.
|
*/

export interface DocumentActionProps extends Pick<ActionButtonProps, 'size'> {
  directory?: DirectoryTreeNode;
}

export const DocumentAction: React.FC<DocumentActionProps> = ({
  directory,
  size,
}) => {
  const [, shortcutActions] = useShortcuts();

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const handleCreateDocument = React.useCallback(() => {
    shortcutActions.onOpen(
      'blank',
      !_.isNil(directory?.id)
        ? {
            directoryId: directory?.id,
          }
        : undefined
    );
  }, [directory]);

  return (
    <ActionButton
      hint={'Create document'}
      icon={'file-plus'}
      size={size}
      onClick={handleCreateDocument}
    />
  );
};
