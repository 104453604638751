import React from 'react';

import type { MermaidGraphType } from '../lib/mermaidUtils';
import {
  exampleGraphDefinitions,
  getExampleGraph,
  getExampleGraphDefinition,
} from '../lib/mermaidUtils';

/*
|==========================================================================
| useMermaidExamples
|==========================================================================
|
| This hook is used to provide examples of mermaid diagrams to the user.
|
*/

/*
|------------------
| Public API
|------------------
*/

export const useMermaidExamples = () => {
  return React.useMemo(
    () => ({
      examples: exampleGraphDefinitions,
      getExample: (type: MermaidGraphType) => {
        const graphCode = getExampleGraph(type);
        const definition = getExampleGraphDefinition(type);
        return {
          graphCode,
          graphType: definition.type,
          name: definition.name,
        };
      },
    }),
    []
  );
};

export default useMermaidExamples;
