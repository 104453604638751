import * as hookz from '@react-hookz/web';
import { type NodePos, NodeViewContent, NodeViewWrapper } from '@tiptap/react';
import _ from 'lodash';
import React from 'react';

import { Fade, Popper, RikerIcon, Stack, useTheme } from '@joggrdocs/riker';

import type {
  DashDraftEditor,
  DashDraftNode,
  DashDraftNodeViewProps,
} from '@dashdraft/types';
import { useDelayedState } from '@stargate/hooks';
import { useNotify } from '@stargate/lib/notify';

export type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;

export interface DashDraftHeadingNodeAttributes {
  level: HeadingLevel;
  id: string;
  'data-toc-id': string;
  active: boolean;
}

export type DashDraftHeadingNode =
  DashDraftNode<DashDraftHeadingNodeAttributes>;

export type HeadingNodeViewProps =
  DashDraftNodeViewProps<DashDraftHeadingNodeAttributes>;

/*
|------------------
| Utils
|------------------
*/

/**
 * Create a id from a string.
 *
 * @param text A string to create an id from.
 * @returns A kebab-cased string.
 */
const createIdFromText = (text: string): string => {
  return _.kebabCase(text);
};

/**
 * Find duplicate heading nodes with the same id.
 *
 * @param editor A DashDraftEditor instance.
 * @param node A DashDraftHeadingNode instance.
 * @returns An array of NodePos instances, that are nodes with duplicate IDs.
 */
const findDuplicateHeadingNodes = (
  editor: DashDraftEditor,
  node: DashDraftHeadingNode
): NodePos[] => {
  const allHeadingNodes: NodePos[] = editor.$nodes('heading') ?? [];
  const duplicateHeadingsNodes: NodePos[] = [];
  for (const headingNodePos of allHeadingNodes) {
    const textId = createIdFromText(node.textContent);
    const headingNodePosTextId = createIdFromText(
      headingNodePos.node.textContent
    );
    if (
      textId === headingNodePosTextId &&
      headingNodePos.attributes['data-toc-id'] !== node.attrs['data-toc-id']
    ) {
      duplicateHeadingsNodes.push(headingNodePos);
    }
  }

  return _.sortBy(duplicateHeadingsNodes, (n) => n.pos, 'asc');
};

/**
 * Create an id for a heading node based on the content.
 *
 * @param editor A DashDraftEditor instance.
 * @param node A DashDraftHeadingNode instance.
 * @returns A unique id for the heading node.
 */
const createId = (
  editor: DashDraftEditor,
  node: DashDraftHeadingNode
): string => {
  const nodes = findDuplicateHeadingNodes(editor, node);
  const baseId = createIdFromText(node.textContent);
  const currentNodePos = editor.$node('heading', {
    'data-toc-id': node.attrs['data-toc-id'],
  });
  if (nodes.length === 0 || _.isNil(currentNodePos)) {
    return baseId;
  }
  let dup = 0;

  for (const n of nodes) {
    if (n.pos < currentNodePos?.pos) {
      dup++;
    }
  }

  return `${baseId}${dup > 0 ? `-$${dup}` : ''}`;
};

export const HeadingNodeView = React.memo<HeadingNodeViewProps>(
  ({ editor, node, updateAttributes }) => {
    const id = createId(editor, node);
    const previousId = hookz.usePrevious(id);
    const previousLevel = hookz.usePrevious(node.attrs.level);
    const notify = useNotify();
    const [showLink, setShowLink, setShowLinkDelayed] = useDelayedState(false);
    const theme = useTheme();
    const contentRef = React.useRef<HTMLDivElement>(null);

    /*
    |------------------
    | Computed
    |------------------
    */

    const linkSize = React.useMemo(() => {
      switch (node.attrs.level) {
        case 1:
          return 24;
        case 2:
          return 20;
        default:
          return 18;
      }
    }, [node.attrs.level]);

    /*
    |------------------
    | Handlers
    |------------------
    */

    const handleMouseEnter = (): void => {
      setShowLinkDelayed(true, 350);
    };

    const handleMouseLeave = (): void => {
      setShowLink(false);
    };

    const handleHeadingLinkClick = (e: React.MouseEvent): void => {
      e.preventDefault();
      const url = window.location.href.split('#')[0];
      void navigator.clipboard
        .writeText(`${url}#${node.attrs.id}`)
        .then(() => {
          notify.send({
            title: 'Link Copied',
            message: `Link to "${node.textContent}" copied to clipboard`,
          });
        })
        .catch(() => {
          notify.send({
            severity: 'error',
            title: 'Error',
            message: 'Failed to copy link to clipboard',
          });
        });
    };

    /*
    |------------------
    | Effects
    |------------------
    */

    React.useEffect(() => {
      if (_.isNil(id)) return;

      // If we toggle the heading level, we need to update the id again
      if (previousLevel !== node.attrs.level) {
        queueMicrotask(() => {
          updateAttributes({
            id,
          });
        });
      }
    }, [previousLevel, node.attrs.level, id, updateAttributes]);

    // If we start typing we should hide the link
    hookz.useEventListener(
      window,
      'keyup',
      () => {
        setShowLink(false);
      },
      { passive: true }
    );

    hookz.useDebouncedEffect(
      () => {
        if (_.isNil(id)) return;

        // If we toggle the heading level, we need to update the id again
        if (previousId !== id) {
          queueMicrotask(() => {
            updateAttributes({
              id,
            });
          });
        }
      },
      [id, previousId, updateAttributes],
      100,
      500
    );

    return (
      <NodeViewWrapper
        as={`h${node.attrs.level}`}
        id={node.attrs.id}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        data-placeholder={`Heading ${node.attrs.level}`}
        data-active={node.attrs.active ?? undefined}
        data-toc-id={node.attrs['data-toc-id']}
        className={'dashdraft-heading'}
      >
        <div ref={contentRef} className='dashdraft-heading-content'>
          <NodeViewContent />
        </div>
        <Popper
          open={showLink}
          anchorEl={contentRef.current}
          placement='right'
          disablePortal
        >
          <Fade in={showLink} timeout={{ enter: 200 }}>
            <Stack
              contentEditable={false}
              onClick={handleHeadingLinkClick}
              className='dashdraft-heading-link'
              sx={{
                cursor: 'pointer',
                p: 0.5,
                borderRadius: '50%',
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                },
              }}
            >
              <RikerIcon name='link' size={linkSize} />
            </Stack>
          </Fade>
        </Popper>
      </NodeViewWrapper>
    );
  }
);
HeadingNodeView.displayName = 'HeadingNodeView';
