import _ from 'lodash';
import React from 'react';
import * as dnd from 'react-dnd';
import type * as TypeFest from 'type-fest';

import type { DirectoryTreeNode, DirectoryTreeNodeType } from '../types';

/*
|==========================================================================
| useDragAndDrop
|==========================================================================
|
| A hook that handles drag and drop functionality.",
|
*/

/*
|------------------
| Constants
|------------------
*/

export const ACCEPT_DIRECTORY = 'directory';

export const ACCEPT_DOCUMENT = 'document';

export const ACCEPT = [
  ACCEPT_DIRECTORY,
  ACCEPT_DOCUMENT,
] as const satisfies DirectoryTreeNodeType[];

/*
|------------------
| Types
|------------------
*/

export type DndAccept = (typeof ACCEPT)[number];

export type DndAcceptDirectory = typeof ACCEPT_DIRECTORY;

export type DndAcceptDocument = typeof ACCEPT_DOCUMENT;

/*
|----------------------------------
| UseDirectoryHook
|----------------------------------
|
| A hook to handle the drag & drop functionality for a directory.
|
*/

export interface UseDndDirectoryOptions {
  /**
   * Override the type of item that the directory accepts.
   *
   * @default 'directory'
   */
  accept?: DndAccept[];

  /**
   * Override the dragging feature.
   *
   * @default false
   */
  disableDrag?: boolean;

  /**
   * Called when a compatible item is dropped on the target.
   *
   * @param node
   * @returns
   */
  onDrop?: (node: DirectoryTreeNode, monitor: dnd.DropTargetMonitor) => void;

  /**
   * Called when a compatible item is hovered over the target.
   *
   * @param node
   * @returns
   */
  onHover?: (node: DirectoryTreeNode, monitor: dnd.DropTargetMonitor) => void;
}

export interface UseDndDirectoryProps {
  /*
  |------------------
  | Data
  |------------------
  */

  type: DndAcceptDirectory;

  /*
  |------------------
  | Flags
  |------------------
  */

  isDragging: boolean;

  isOver: boolean;

  canDrop: boolean;

  didDrop: boolean;
}

export type UseDndDirectorySource = React.RefCallback<HTMLElement>;

export type UseDndDirectoryPreview = dnd.ConnectDragPreview;

export type UseDndDirectoryHook = TypeFest.Simplify<
  [UseDndDirectoryProps, UseDndDirectorySource, UseDndDirectoryPreview]
>;

/**
 * A hook to handle the drag & drop functionality for a directory.
 *
 * @returns A tuple containing the props, ref, and preview.
 */
export const useDndDirectory = (
  node: DirectoryTreeNode,
  options: UseDndDirectoryOptions
): UseDndDirectoryHook => {
  const { accept = ACCEPT, disableDrag = false, onDrop, onHover } = options;
  const [dropProps, dropRef] = dnd.useDrop(
    () => ({
      accept,
      drop: (item: DirectoryTreeNode, monitor) => {
        if (onDrop) {
          onDrop(item, monitor);
        }
      },
      hover: (item: DirectoryTreeNode, monitor) => {
        if (onHover) {
          onHover(item, monitor);
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver({ shallow: true }),
        canDrop: monitor.canDrop(),
      }),
    }),
    [onDrop, onHover, accept]
  );
  const [dragProps, dragRef, dragPreview] = dnd.useDrag(
    () => ({
      type: ACCEPT_DIRECTORY,
      canDrag: !disableDrag,
      item: node,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        didDrop: monitor.didDrop(),
      }),
    }),
    [node, disableDrag]
  );

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const mergeRef = React.useCallback(
    (el: HTMLElement) => {
      dragRef(dropRef(el));
    },
    [dropRef, dragRef]
  );

  /*
  |------------------
  | Computed
  |------------------
  */

  const props = React.useMemo(() => {
    return {
      ...dropProps,
      ...dragProps,
      type: ACCEPT_DIRECTORY,
    } satisfies UseDndDirectoryProps;
  }, [dropProps, dragProps]);

  return [props, mergeRef, dragPreview];
};

/*
|----------------------------------
| useDndDocument
|----------------------------------
|
| A hook to handle the drag functionality.
|
*/

export interface UseDndDocumentProps {
  /*
  |------------------
  | Data
  |------------------
  */

  type: DndAcceptDocument;

  /*
  |------------------
  | Flags
  |------------------
  */

  isDragging: boolean;

  didDrop: boolean;
}

export type UseDndDocumentSource = dnd.ConnectDragSource;

export type UseDndDocumentPreview = dnd.ConnectDragPreview;

export type UseDndDocumentHook = TypeFest.Simplify<
  [UseDndDocumentProps, UseDndDocumentSource, UseDndDocumentPreview]
>;

/**
 * A hook to handle the drag functionality of a document.
 *
 * @returns A tuple containing the props, ref, and preview.
 */
export const useDndDocument = (node: DirectoryTreeNode): UseDndDocumentHook => {
  const [dragProps, dragRef, dragPreview] = dnd.useDrag(() => ({
    type: ACCEPT_DOCUMENT,
    item: node,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      didDrop: monitor.didDrop(),
    }),
  }));

  const props = React.useMemo(() => {
    return {
      ...dragProps,
      type: ACCEPT_DOCUMENT,
    } satisfies UseDndDocumentProps;
  }, [dragProps]);

  return [props, dragRef, dragPreview];
};

/*
|------------------
| useDnd
|------------------
*/

export type UseDndOptions = UseDndDirectoryOptions;

export type UseDndProps = TypeFest.Simplify<
  Omit<UseDndDirectoryProps, 'type'> &
    Omit<UseDndDocumentProps, 'type'> & {
      type: TypeFest.LiteralUnion<DndAccept, string>;
    }
>;

export type UseDndSource = UseDndDirectorySource | UseDndDocumentSource;

export type UseDndPreview = UseDndDirectoryPreview | UseDndDocumentPreview;

export type UseDndHook = [UseDndProps, UseDndSource, UseDndPreview];

export const useDnd = (
  node: DirectoryTreeNode,
  options: UseDndOptions
): UseDndHook => {
  const [docProps, docRef, docPreview] = useDndDocument(node);
  const [dirProps, dirRef, dirPreview] = useDndDirectory(node, options);

  /*
  |------------------
  | Computed
  |------------------
  */

  const props = React.useMemo(() => {
    switch (node.nodeType) {
      case ACCEPT_DIRECTORY:
        return dirProps as UseDndProps;
      default:
        return {
          ...(_.mapValues(dirProps, (value, key) => {
            if (
              key.startsWith('is') ||
              key.startsWith('did') ||
              key.startsWith('can')
            ) {
              return false;
            }
            return value;
          }) as UseDndDirectoryProps),
          ...docProps,
        } as unknown as UseDndProps;
    }
  }, [node.nodeType, dirProps, docProps]);

  const ref = React.useMemo(() => {
    switch (node.nodeType) {
      case ACCEPT_DIRECTORY:
        return dirRef;
      default:
        return docRef;
    }
  }, [node.nodeType, dirRef, docRef]);

  const preview = React.useMemo(() => {
    switch (node.nodeType) {
      case ACCEPT_DIRECTORY:
        return dirPreview;
      default:
        return docPreview;
    }
  }, [node.nodeType, dirPreview, docPreview]);

  return React.useMemo(() => {
    return [props, ref, preview];
  }, [props, ref, preview]);
};
