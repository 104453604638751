import _ from 'lodash';
import React from 'react';

import type * as typist from '@stargate/lib/typist';

/*
|------------------
| Utils
|------------------
*/

export const isObjectNotNil = (obj: unknown) => {
  return (
    !_.isNil(obj) &&
    Object.values(obj as object).every((v) => !_.isNil(v)) &&
    Object.keys(obj as object).length > 0
  );
};

/*
|------------------
| Component
|------------------
*/

export interface RenderGuardProps<P> {
  renderProps: P;
  render: (props: P) => React.ReactNode;
  shouldRender?: (props: P) => boolean;
  noRenderElement?: React.ReactNode;
}

export function RenderGuard<P>(props: RenderGuardProps<P>) {
  const shouldRender = () => {
    if (props.shouldRender) {
      return props.shouldRender(props.renderProps);
    }

    return isObjectNotNil(props.renderProps);
  };

  const noRenderElement = React.useMemo(() => {
    return props.noRenderElement ?? null;
  }, [props.noRenderElement]);

  if (shouldRender()) {
    return (
      <React.Fragment>
        {props.render(props.renderProps as typist.RequiredStrict<P>)}
      </React.Fragment>
    );
  }
  return noRenderElement;
}

export default RenderGuard;
