import { Stack, type StackProps, Tooltip, Typography } from '@joggrdocs/riker';
import React from 'react';

import { useLocalization } from '@stargate/localization';
import { generateComponentClasses } from '@stargate/theme';

import { CodeExplorerFileTreeToggle } from './CodeExplorerFileTreeToggle';

export const codeExplorerFileTreeHeaderClasses = generateComponentClasses(
  'CodeExplorerFileTreeHeader',
  ['root'] as const
);

export interface CodeExplorerFileTreeHeaderProps extends BaseProps {
  /**
   * If the file tree is loading.
   */
  loading: boolean;

  /**
   * Whether the drawer is open.
   */
  open: boolean;

  /**
   * Whether the file tree is disabled.
   */
  disabled: boolean;

  /**
   * Callback when the button is clicked, used to toggle the file tree.
   */
  onToggle: () => void;
}

export const CodeExplorerFileTreeHeader = React.forwardRef<
  HTMLDivElement,
  CodeExplorerFileTreeHeaderProps
>(({ open, disabled, onToggle, loading, ...props }, ref) => {
  const localz = useLocalization();
  const status = open ? 'open' : 'close';
  return (
    <Stack
      {...props}
      ref={ref}
      direction='row'
      alignItems='center'
      spacing={1}
      className={codeExplorerFileTreeHeaderClasses.root}
      sx={{
        [`&.${codeExplorerFileTreeHeaderClasses.root}`]: {
          borderBottom: '1px solid',
          borderColor: 'divider',
          px: 1,
          py: 1,
        },
      }}
    >
      <Tooltip
        enterDelay={600}
        enterNextDelay={350}
        placement='left'
        title={localz.formatMessage(`features.code.tree.tooltip.${status}`)}
      >
        <span>
          <CodeExplorerFileTreeToggle
            status={status}
            disabled={disabled}
            onClick={onToggle}
            loading={loading}
          />
        </span>
      </Tooltip>
      {status === 'open' && (
        <Typography variant='h5'>
          {localz.formatMessage('features.code.tree.title')}
        </Typography>
      )}
    </Stack>
  );
});

/*
|------------------
| Utils
|------------------
*/

type BaseProps = Pick<StackProps, 'sx' | 'ref'>;
