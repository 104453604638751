import type { EditorEvents } from '@tiptap/core';
import _ from 'lodash';
import React from 'react';

import { useDashDraftEditor } from './use-dashdraft-editor';

export type DashDraftEventListenerHandler<E extends SupportedEventName> = (
  props: EditorEvents[E]
) => void;

/**
 * Add event listeners to the editor.
 *
 * @see https://github.com/ueberdosis/tiptap/blob/develop/packages/react/src/useEditor.ts
 * @see https://github.com/juliencrn/usehooks-ts/blob/master/packages/usehooks-ts/src/useEventListener/useEventListener.ts
 *
 * @param eventName The event name to listen for.
 * @param handler The event handler.
 */
export const useDashDraftEventListener = <E extends SupportedEventName>(
  eventName: E,
  handler: DashDraftEventListenerHandler<E>
) => {
  const editor = useDashDraftEditor();

  React.useEffect(() => {
    const listener: (...args: any[]) => any = (event) => {
      handler(event);
    };

    editor.on(eventName, listener);
    return () => {
      editor.off(eventName, listener);
    };
  }, [editor, eventName, handler]);
};

type SupportedEventName = 'update';
