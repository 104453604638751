import Ai from '@tiptap-pro/extension-ai';

import type { DashDraftExtensionsAIConfig } from '../types';

export default (config: DashDraftExtensionsAIConfig) => {
  return Ai.configure({
    appId: config.appId,
    token: config.token,
    autocompletion: true,
    autocompletionOptions: {
      modelName: 'gpt-4-turbo-preview',
    },
  });
};
