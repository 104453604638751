import componentsLocale from './components';
import errorLocale from './error';
import featureLocale from './features';
import genericLocale from './generic';
import pagesLocale from './pages';

/*
|==========================================================================
| Locale: English
|==========================================================================
|
| Locale for the English language. Save in dot notation, i.e. 'page.home.title'
|
| @todo Move to server & Generate Client
|
*/

export default {
  ...genericLocale,
  ...errorLocale,
  ...featureLocale,
  ...pagesLocale,
  ...componentsLocale,
};
