import { useTheme } from '@joggrdocs/riker';
import React from 'react';
import type * as TypeFest from 'type-fest';

import { Img } from '@stargate/components/Images';
import { Feedback, type FeedbackProps } from './Feedback';

export type FeedbackSuccessFeedback = TypeFest.Simplify<Partial<FeedbackProps>>;

export const FeedbackSuccess = React.forwardRef<
  HTMLDivElement,
  FeedbackSuccessFeedback
>(({ title = 'Success!', imagery, ...props }, ref) => {
  const theme = useTheme();
  return (
    <Feedback
      ref={ref}
      title={title}
      imagery={
        imagery ?? (
          <Img
            src={`/components/feedback/feedback-great-job-${theme.palette.mode}.png`}
            alt='Duck holding a sign that says "Great Job!"'
            sx={{
              maxWidth: '248px',
            }}
          />
        )
      }
      titleTypographyProps={{
        variant: 'h3',
      }}
      center
      {...props}
    />
  );
});
FeedbackSuccess.displayName = 'FeedbackSuccess';
