import type { Theme } from '@joggrdocs/riker';

import { components } from './aspects/components';
import { palette } from './aspects/palette';
import { typography } from './aspects/typography';
import type { FrigadeCSS, FrigadeTheme } from './types';

/**
 * Create a Frigade Theme from a Riker theme
 *
 * @param theme A Riker theme
 * @returns A Frigade Theme
 */
export const createTheme = (theme: Theme): Omit<FrigadeTheme, 'space'> => {
  return {
    ...typography(theme),
    ...palette(theme),
    radii: {
      round: `${theme.shape.borderRadius}px`,
      md: '4px',
      lg: '8px',
    },
    borderWidths: {
      md: '1px',
    },
    shadows: {
      md: theme.shadows[1],
    },
  };
};

/**
 * Create a Frigade CSS object from a Riker theme for global component & style overrides
 *
 * @param theme A Riker theme
 * @returns A FrigadeCSS object
 */
export const createCSS = (theme: Theme): FrigadeCSS => {
  return {
    ...components(theme),
  };
};
