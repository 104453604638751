import React from 'react';
import { create } from 'zustand';

import type { Shortcut, ShortcutMeta } from '../types';

/*
|==========================================================================
| useShortcuts
|==========================================================================
|
| A global store, used to manage the shortcuts feature.
|
*/

export interface State {
  /**
   * Whether the shortcuts dialog is open.
   */
  open: boolean;

  /**
   * The "Active" shortcut.
   */
  active: Shortcut | null;

  /**
   * The meta data for the shortcut.
   */
  meta: ShortcutMeta;
}

export interface Actions {
  /**
   * Close the shortcuts dialog.
   */
  onClose: () => void;

  /**
   * Open the shortcuts dialog.
   *
   * @param shortcut The shortcut to open the dialog for.
   * @param options The options for the dialog.
   */
  onOpen: (
    shortcut: Shortcut,
    options?: {
      directoryId: string;
    }
  ) => void;
}

export interface Store {
  state: State;
  actions: Actions;
}

const useStore = create<Store>((set) => ({
  state: {
    open: false,
    active: null,
    meta: {},
  },
  actions: {
    onClose: () => {
      set(({ state }) => ({
        state: {
          ...state,
          meta: {},
          open: false,
        },
      }));
    },
    onOpen: (shortcut, options) => {
      set(({ state }) => ({
        state: {
          ...state,
          active: shortcut,
          meta: {
            directoryId: options?.directoryId,
          },
          open: true,
        },
      }));
    },
  },
}));

/**
 * Hook for managing the shortcuts feature.
 *
 * @returns A tuple containing the state and actions of the shortcuts store.
 */
const useShortcuts = (): [State, Actions] => {
  return useStore(({ state, actions }) => [state, actions]);
};

export default useShortcuts;
