import TipTapEmoji, {
  gitHubEmojis,
  type EmojiStorage,
  type EmojiItem,
} from '@tiptap-pro/extension-emoji';
import { ReactRenderer } from '@tiptap/react';
import type { SuggestionOptions } from '@tiptap/suggestion';
import tippy, { type GetReferenceClientRect } from 'tippy.js';

import EmojiMenu from '../components/EmojiMenu';

/*
|==========================================================================
| emoji
|==========================================================================
|
| Support emojis and allow user to search and insert them.
|
*/

const suggestion: Omit<SuggestionOptions<EmojiItem>, 'editor'> = {
  allowSpaces: false,

  items: ({ editor, query }) => {
    const storage = editor.storage.emoji as EmojiStorage;
    return storage.emojis
      .filter(({ shortcodes, tags }) => {
        return (
          shortcodes.find((shortcode) =>
            shortcode.startsWith(query.toLowerCase())
          ) || tags.find((tag) => tag.startsWith(query.toLowerCase()))
        );
      })
      .slice(0, 5);
  },

  render: () => {
    let component: ReactRenderer | null = null;
    let popup: ReturnType<typeof tippy> | null = null;

    return {
      onStart(props) {
        component = new ReactRenderer(EmojiMenu, {
          props,
          editor: props.editor,
        });

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect as GetReferenceClientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        });
      },

      onUpdate(props) {
        component?.updateProps(props);

        popup?.[0].setProps({
          getReferenceClientRect: props.clientRect as GetReferenceClientRect,
        });
      },

      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          popup?.[0].hide();
          component?.destroy();

          return true;
        }

        const ref = component?.ref as {
          onKeyDown: (props: any) => boolean;
        } | null;
        return ref?.onKeyDown(props) ?? false;
      },

      onExit() {
        popup?.[0].destroy();
        component?.destroy();
      },
    };
  },
};

export const Emoji = TipTapEmoji.configure({
  emojis: gitHubEmojis,
  enableEmoticons: true,
  suggestion,
  HTMLAttributes: {
    class: 'dashdraft-emoji',
  },
});
