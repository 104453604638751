import React from 'react';

import {
  DialogConfirmation,
  type DialogConfirmationProps,
} from '@stargate/components/Dialogs';
import { useLocalization } from '@stargate/localization';
import { generateComponentClasses } from '@stargate/theme';

import type { JDocMode } from '@/features/docs/types';

export const joggrDocDraftRevertDialogClasses = generateComponentClasses(
  'JoggrDocRevertDialog',
  ['root'] as const
);

export interface JoggrDocDraftRevertDialogProps
  extends Omit<DialogConfirmationProps, 'title' | 'confirmation'> {
  /**
   * The JoggrDoc mode.
   */
  mode: JDocMode;

  /**
   * Callback triggered when the dialog is confirmed.
   */
  onConfirm: () => void;

  /**
   * Callback triggered when the dialog is closed.
   */
  onClose: () => void;
}

/**
 * Component for reverting a JoggrDoc draft.
 */
export const JoggrDocDraftRevertDialog: React.FC<
  JoggrDocDraftRevertDialogProps
> = ({ mode, open, onConfirm, onClose }) => {
  const localz = useLocalization();

  // We only want to show the dialog in edit/create mode
  if (mode === 'view') {
    return null;
  }

  return (
    <DialogConfirmation
      open={open}
      icon='arrow-back-up'
      title={localz.formatMessage(`features.docs.${mode}.drafts.revert.title`)}
      confirmation={localz.formatMessage(
        `features.docs.${mode}.drafts.revert.confirmation`
      )}
      onConfirm={onConfirm}
      onClose={onClose}
    />
  );
};
