import {
  ButtonAnchor,
  type ButtonAnchorProps,
  IconBrandGithubFilled,
} from '@joggrdocs/riker';
import React from 'react';

/*
|==========================================================================
| GitHubButton
|==========================================================================
|
| 
|
*/

export type GitHubButtonProps = Omit<ButtonAnchorProps, 'external'>;

/**
 * GitHubButton is a ButtonAnchor with a GitHub icon and the text
 */
export const GitHubButton = React.forwardRef<
  HTMLAnchorElement,
  GitHubButtonProps
>((props, ref) => {
  const {
    color = 'primary',
    children = 'View on GitHub',
    openInNewTab = true,
    ...restProps
  } = props;
  return (
    <ButtonAnchor
      {...restProps}
      ref={ref}
      openInNewTab={openInNewTab}
      startIcon={<IconBrandGithubFilled />}
      color={color}
    >
      {children}
    </ButtonAnchor>
  );
});
GitHubButton.displayName = 'GitHubButton';
GitHubButton.muiName = 'Button';
