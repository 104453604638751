import React from 'react';
import type * as TypeFest from 'type-fest';

import {
  RikerIcon,
  type RikerIconComponent,
  type RikerIconProps,
} from '@joggrdocs/riker';

/*
|==========================================================================
| DirectoryEditIcon
|==========================================================================
|
| Proxy component for the RikerIcon component.
|
*/

export type DirectoryEditIconProps = TypeFest.Simplify<
  Omit<RikerIconProps, 'name'>
>;

export const directoryEditIconName = 'folder-cog';

/**
 * Icon for editing a directory.
 */
export const DirectoryEditIcon = React.forwardRef<
  RikerIconComponent,
  DirectoryEditIconProps
>((props, ref) => (
  <RikerIcon {...props} ref={ref} name={directoryEditIconName} />
));
