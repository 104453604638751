import React from 'react';

/*
|==========================================================================
| useDetectOS
|==========================================================================
|
| Relatively naive OS detection.
|
*/

export type OperatingSystem = 'mac' | 'windows' | 'unknown';

/**
 * Relatively naive OS detection.
 *
 * @returns 'windows' or 'mac'
 */
export const useDetectOS = (): OperatingSystem => {
  return React.useMemo(() => {
    // Experimental support: https://developer.mozilla.org/en-US/docs/Web/API/NavigatorUAData/platform
    if (Object.hasOwn(navigator, 'userAgentData')) {
      const uaData = navigator.userAgentData as { platform: string };
      return /(Mac|iPhone|iPod|iPad)/i.test(uaData.platform)
        ? 'mac'
        : 'windows';
    }

    // Deprecated support: https://developer.mozilla.org/en-US/docs/Web/API/Navigator/platform
    if (navigator.platform) {
      return /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)
        ? 'mac'
        : 'windows';
    }

    return 'unknown';
  }, []);
};

export default useDetectOS;
