import React from 'react';
import type * as TypeFest from 'type-fest';

import {
  RikerIcon,
  type RikerIconComponent,
  type RikerIconProps,
} from '@joggrdocs/riker';

/*
|==========================================================================
| DocumentIcon 
|==========================================================================
|
| Proxy component for the RikerIcon component.
|
*/

export type DocumentIconProps = TypeFest.Simplify<Omit<RikerIconProps, 'name'>>;

export const documentIconName = 'file-description';

/**
 * Icon for documents.
 */
export const DocumentIcon = React.forwardRef<
  RikerIconComponent,
  DocumentIconProps
>((props, ref) => <RikerIcon {...props} ref={ref} name={documentIconName} />);
