import { decode, encode } from 'js-base64';

/*
|==========================================================================
| files
|==========================================================================
|
| Utils for working with files.
|
*/

/**
 * Decode a base64 string.
 *
 * @param base64 A base64 encoded string
 * @returns A decoded string
 */
export function decodeBase64(base64: string) {
  return decode(base64);
}

/**
 * Encode a string to base64.
 *
 * @param content A string to encode to base64
 * @returns A base64 encoded string
 */
export function encodeBase64(content: string) {
  return encode(content);
}

/**
 * Break a file path into its parts.
 *
 * @param filePath A full file path, i.e. /path/to/file.txt
 * @returns A parts of the file path, i.e. { fileName: 'file.txt', filePathWithoutFileName: '/path/to', fileExtension: 'txt' }
 */
export function getFilePartsFromFilePath(filePath: string) {
  const parts = filePath.split('/');
  const fileName = parts.pop();
  const filePathWithoutFileName = parts.join('/');

  if (!fileName) {
    return null;
  }
  return {
    fileName,
    filePathWithoutFileName: filePathWithoutFileName || '/',
    fileExtension: fileName.split('.')[1] || undefined,
  };
}
