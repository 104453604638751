import React, { type ComponentProps } from 'react';
import { FormattedMessage as ReactIntlFormattedMessage } from 'react-intl';

import type { LocaleId } from '../types';

export interface FormattedMessageProps
  extends Omit<
    ComponentProps<typeof ReactIntlFormattedMessage>,
    'defaultMessage'
  > {
  id: LocaleId;
}

/**
 * A thin wrapper around the `react-intl` `FormattedMessage` component that provides a default message and type safety.
 */
export const FormattedMessage: React.FC<FormattedMessageProps> = (props) => {
  return (
    <ReactIntlFormattedMessage
      {...props}
      defaultMessage={'Missing translation'}
    />
  );
};
