import {
  Box,
  ButtonAnchor,
  IconBooks,
  IconMoodSad,
  Stack,
  Typography,
} from '@joggrdocs/riker';
import React from 'react';

import useMermaidDocs from '../hooks/useMermaidDocs';
import type { MermaidGraphType } from '../lib/mermaidUtils';

/*
|==========================================================================
| MermaidError
|==========================================================================
|
| A component to display a mermaid syntax error.
|
*/

export interface Props {
  graphType?: MermaidGraphType;
}

export const SirenaViewerError: React.FC<Props> = (props) => {
  const docs = useMermaidDocs(props.graphType);

  React.useEffect(() => {
    if (props.graphType) {
      docs.update(props.graphType);
    }
  }, [props.graphType]);

  return (
    <Box
      sx={{
        textAlign: 'center',
        maxWidth: '400px',
      }}
    >
      <Stack
        direction='row'
        spacing={1}
        sx={{
          mb: 2,
          mx: 'auto',
        }}
        alignItems='center'
        justifyContent='center'
      >
        <IconMoodSad size={32} />
        <Typography variant='h3'>Syntax Error</Typography>
      </Stack>
      <Typography
        variant='body1'
        sx={{
          mb: 2,
        }}
      >
        You had a syntax error with the mermaid code. <br />
        Please check your code and try again.
      </Typography>
      <ButtonAnchor
        target='_blank'
        rel='noopener noreferrer'
        href={docs.url!}
        size='small'
        color='warning'
        sx={{
          textTransform: 'none',
        }}
        startIcon={<IconBooks />}
      >
        View Documentation
      </ButtonAnchor>
    </Box>
  );
};

const MemoizedSirenaViewerError = React.memo(SirenaViewerError);
MemoizedSirenaViewerError.displayName = 'SirenaViewerError';

export default MemoizedSirenaViewerError;
