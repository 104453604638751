import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  IconSquareArrowDownFilled,
  IconX,
} from '@joggrdocs/riker';

import { SirenaEditor } from '@stargate/lib/sirena';

/*
|==========================================================================
| MermaidEditDialog
|==========================================================================
|
| A dialog that allows the user to edit a mermaid graph, in split view.
|
*/

export interface Props {
  open: boolean;
  defaultGraphCode?: string;
  onSave?: (graphCode: string) => void;
  onClose: () => void;
}

export const MermaidEditDialog: React.FC<Props> = ({
  onSave,
  onClose,
  ...props
}) => {
  const [graphCode, setGraphCode] = React.useState<string>(
    props.defaultGraphCode ?? ''
  );

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const handleGraphCodeChange = React.useCallback((graphCode: string) => {
    setGraphCode(graphCode);
  }, []);

  const handleSave = React.useCallback(() => {
    if (onSave) {
      onSave(graphCode);
    }
    onClose();
  }, [graphCode, onSave, onClose]);

  /*
  |------------------
  | Effects
  |------------------
  */

  const containerRef = useHotkeys<HTMLDivElement>('mod+s', (e) => {
    e.preventDefault();
    handleSave();
  });

  return (
    <Dialog
      ref={containerRef}
      open={props.open}
      onClose={onClose}
      PaperProps={{
        sx: {
          p: 1,
          m: 0,
        },
      }}
      maxWidth='xl'
      fullWidth
      fullScreen
      disableEscapeKeyDown
    >
      <DialogContent
        sx={{
          p: 0,
        }}
      >
        <SirenaEditor
          defaultGraphCode={props.defaultGraphCode}
          onGraphCodeChange={handleGraphCodeChange}
          rightAction={
            <IconButton size='large' onClick={onClose}>
              <IconX />
            </IconButton>
          }
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          startIcon={<IconSquareArrowDownFilled />}
          variant='contained'
          color='success'
          size='large'
          disabled={!graphCode}
          onClick={handleSave}
        >
          Save
        </Button>
        <Button
          startIcon={<IconX />}
          variant='outlined'
          color='error'
          size='large'
          onClick={onClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const MemoizedMermaidEditDialog = React.memo(MermaidEditDialog);
MemoizedMermaidEditDialog.displayName = 'MermaidEditDialog';

export default MemoizedMermaidEditDialog;
