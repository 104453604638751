import React from 'react';

import { useAuth0 } from '../hooks/use-auth0';
import { auth0Client } from '../lib/auth0-client';

export interface WithAuthenticationRequiredOptions {
  loadingFallback?: (() => React.ReactNode) | React.ReactNode;
}

/**
 * Wrap a component with authentication required for Auth0 (based on auth0-react HOC).
 *
 * @param Component A React component
 * @param options Options for the HOC
 * @returns A wrapped React component
 */
export const withAuthenticationRequired = <P extends object>(
  Component: React.ComponentType<P>,
  options: WithAuthenticationRequiredOptions = {}
): React.FC<P> => {
  return function WithAuthenticationRequired(p) {
    const { isAuthenticated, isLoading } = useAuth0();

    React.useEffect(() => {
      if (isLoading || isAuthenticated) {
        return;
      }

      void auth0Client.loginWithRedirect({
        appState: {
          returnTo: `${window.location.pathname}${window.location.search}`,
        },
      });
    }, [isLoading, isAuthenticated]);

    const loading = React.useMemo(() => {
      if (typeof options.loadingFallback === 'function') {
        return options.loadingFallback();
      }
      return options.loadingFallback || <React.Fragment />;
    }, [options.loadingFallback]);

    if (isAuthenticated) {
      return <Component {...p} />;
    }

    return loading;
  };
};
