import React from 'react';

import { ButtonAnchor, IconBook } from '@joggrdocs/riker';

import useMermaidDocs from '../hooks/useMermaidDocs';
import type { MermaidGraphType } from '../lib/mermaidUtils';

/*
|==========================================================================
| SirenaDocsButton
|==========================================================================
|
| Documentation button for mermaid graphs.
|
*/

export interface SirenaDocsButtonProps {
  graphType?: MermaidGraphType;
}

export const SirenaDocsButton: React.FC<SirenaDocsButtonProps> = (props) => {
  const { update, url } = useMermaidDocs(props.graphType);

  React.useEffect(() => {
    if (props.graphType) {
      update(props.graphType);
    }
  }, [props.graphType, update]);

  return (
    <ButtonAnchor
      href={url!}
      color='secondary'
      variant='contained'
      size='small'
      external
      startIcon={<IconBook />}
    >
      Docs
    </ButtonAnchor>
  );
};

const MemoizedSirenaDocsButton = React.memo(SirenaDocsButton);
MemoizedSirenaDocsButton.displayName = 'SirenaDocsButton';

export default MemoizedSirenaDocsButton;
