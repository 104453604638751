import { useConfig } from '@stargate/hooks';
import React from 'react';

import { createLogger } from '../lib/logger';

/**
 * Logger hook, to log messages, to our HTTP API for logs.
 *
 * @returns A hook to use the logger.
 */
export const useLogger = () => {
  const config = useConfig();
  return React.useMemo(() => createLogger(config), [config]);
};
